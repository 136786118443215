<!-- Permissions -->
<div [appEnforcePermission]="authConfig.contactsProfileContactInformationViewAssigned" #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationView" #contactsProfileCompanyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsInvestigations" #contactsInvestigations="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsDelete" #contactsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mat-typography">
  <h2 *ngIf="!config.companyId">Contacts</h2>
  <div class="content-wrapper mat-elevation-z2">
    <div class="row px-4 pb-2 align-items-center justify-content-center" [class.justify-content-between]="mutateView">
      <ng-container *ngIf="!config.hideLists">
        <div class="col-2 pr-2">
          <mat-form-field>
            <mat-label>Select a Position</mat-label>
            <mat-select (selectionChange)="onPositionChange($event)">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let position of positions" [value]="position.id" [innerHTML]="position.name">
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2 pr-2">
          <app-company-select (selectionChanged)="onCompanyChange($event)"></app-company-select>
        </div>
        <div class="col-2 pr-2">
          <mat-form-field>
            <mat-label>Search for a Firstname</mat-label>
            <input matInput placeholder="Search for a Firstname" aria-label="Search for a Firstname" [(ngModel)]="firstName" (appDebouncedInputChange)="onSearchFirstNameChange()" />
            <button mat-icon-button disableRipple matSuffix (click)="firstName = ''; onSearchFirstNameChange();" *ngIf="firstName">
              <mat-icon>clear</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-2 pr-2">
          <mat-form-field>
            <mat-label>Search for a Lastname</mat-label>
            <input matInput placeholder="Search for a Lastname" aria-label="Search for a Lastname" [(ngModel)]="lastName" (appDebouncedInputChange)="onSearchLastNameChange()" />
            <button mat-icon-button disableRipple matSuffix (click)="lastName = ''; onSearchLastNameChange();" *ngIf="lastName">
              <mat-icon>clear</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
      <div [class]="mutateView ? '' : 'col-3'">
        <mat-form-field class="equal search" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [(ngModel)]="search" (appDebouncedInputChange)="onSearchChange()" name="search">
          <button mat-icon-button disableRipple matSuffix (click)="search = ''; onSearchChange();">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div [class]="mutateView ? '' : 'col-2'">
        <mat-button-toggle-group #active="matButtonToggleGroup" [value]="null" (change)="onActivityChange($event)">
          <mat-button-toggle [value]="null">All</mat-button-toggle>
          <mat-button-toggle value="true">Active</mat-button-toggle>
          <mat-button-toggle value="false">Inactive</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="col-2 text-right" *ngIf="!config.hideAddContact">
        <button mat-raised-button class="rounded-button" (click)="addContactModal()" *ngIf="contactsCreateConfig?.enabled">
          <mat-icon color="accent">playlist_add</mat-icon>
          New Contact
        </button>
      </div>
    </div>
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let contact">
          <a [routerLink]="['/contacts/', contact.id]"
            *ngIf="(contactsProfileContactInformationViewAssigned?.enabled || contactsProfileCompanyInformationView?.enabled || contactsInvestigations?.enabled) else contactNameView">
            {{contact?.lastName ? (contact?.lastName + ', ' + contact?.firstName) : contact?.firstName}}
          </a>
          <ng-template #contactNameView>
            {{contact?.lastName ? (contact?.lastName + ', ' + contact?.firstName) : contact?.firstName}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Position</th>
        <td mat-cell *matCellDef="let contact" [innerHTML]="contact.Role.name"></td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let contact" class="pt-2">
          <app-email-phone-view *ngFor="let phone of contact.Phones | primarySort" [phone]="phone"></app-email-phone-view>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let contact">
          <app-email-phone-view *ngFor="let email of contact.Emails" [email]="email"></app-email-phone-view>
        </td>
      </ng-container>
      <ng-container matColumnDef="branches">
        <th mat-header-cell *matHeaderCellDef>
          <table class="text-left">
            <thead>
              <tr>
                <th class="pr-2" width="40%">Company</th>
                <th class="pr-2" width="40%">Branch</th>
                <th width="10%" class="text-center">Start Date</th>
                <th width="10%" class="text-center">End Date</th>
              </tr>
            </thead>
          </table>
        </th>
        <td mat-cell *matCellDef="let contact">
          <table class="text-left">
            <tbody>
              <tr *ngFor="let branch of contact.BranchAssignments">
                <td class="pr-2" width="40%">
                  {{ branch?.Branch?.Company?.name }}
                </td>
                <td class="pr-2" width="40%">
                  {{ branch?.Branch?.name }}
                </td>
                <td width="10%" class="text-center">
                  {{ branch.startDate ? (branch.startDate | customDate: 'MM/dd/yyyy') : "N/A"}}
                </td>
                <td width="10%" class="text-center">
                  {{ branch.endDate ? (branch.endDate | customDate: 'MM/dd/yyyy') : "N/A"}}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" *ngIf="contactsDelete?.enabled">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button [matMenuTriggerFor]="catAction">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #catAction="matMenu">
            <button mat-menu-item (click)="removeContact(row)">
              Delete
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
    <mat-paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
  </div>
</section>