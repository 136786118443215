<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsList" #manageUserRolePermissionsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsCreate" #manageUserRolePermissionsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsDelete" #manageUserRolePermissionsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersCreate" #manageUserRoleStaffMembersCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersList" #manageUserRoleStaffMembersList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersDelete" #manageUserRoleStaffMembersDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffViewAll" #adminPerm="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsList" #manageUserRoleNotificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsView" #manageUserRoleNotificationsView="appEnforcePermission"></div>
<!-- Permissions -->
<section >
  <div class="page-title">
    <p><a class="fs-12" [routerLink]="['/admin', 'permissions', 'list']">User Roles</a><span> / User Roles: {{role?.name}}</span></p>
    <h4 class="page-title mt-24 mb-24">User Roles: {{role?.name}}</h4>
  </div>
  
  <div class="tabs">
    <div class="button-container" *ngIf="selectedTab === 1 && manageUserRoleStaffMembersCreate?.enabled">
      <button mat-raised-button class="rounded-button" (click)="showAssignUsers()">
        <mat-icon>add</mat-icon>
        Add Staff Members
      </button>
    </div>
    <div class="content-wrapper p-0">
    <kendo-tabstrip class="user-role-tabs"  #tabstrip [scrollable]="false" (tabSelect)="onTabChanged($event)">
      <kendo-tabstrip-tab [selected]="selectedTab === 0" *ngIf="manageUserRolePermissionsList?.enabled">
          <ng-template kendoTabTitle>
            <span>Permissions</span>
            <kendo-badge
                class="fw600 ml-8"
                position="none"
                size="large"
                rounded="full"
                themeColor="primary"
            >
                {{role?.AssignedPermissions.length || 0}}
            </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <section class="tab-content">
            <ng-container *ngTemplateOutlet="permissionsList"></ng-container>
          </section>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [selected]="selectedTab === 1" *ngIf="manageUserRoleStaffMembersList?.enabled">
        <ng-template kendoTabTitle>
          <span>Staff Members</span>
          <kendo-badge
              class="fw600 ml-8"
              position="none"
              size="large"
              rounded="full"
              themeColor="primary"
          >
              {{role?.AssignedUsers.length || 0}}
          </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <section class="tab-content">
            <ng-container *ngTemplateOutlet="staffList"></ng-container>
          </section>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [selected]="selectedTab === 2" *ngIf="manageUserRoleNotificationsList?.enabled && manageUserRoleNotificationsView?.enabled">
        <ng-template kendoTabTitle>
          <span>Notifications</span>
          <kendo-badge
              class="fw600 ml-8"
              position="none"
              size="large"
              rounded="full"
              themeColor="primary"
          >
              {{role?.EnabledNotifications.length || 0}}
          </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <section class="tab-content">
            <app-notification-settings (reload)="refresh()"></app-notification-settings>
          </section>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
    </div>
  </div>

  <ng-template #permissionsList>
    <mat-card class="mb-3 p-24" *ngIf="manageUserRolePermissionsCreate?.enabled">
      <p class="font-weight-bold">Add Permissions to User Role</p>
      <div class="row align-items-center">
        <div class="col-3">
          <mat-form-field>
            <mat-label>Category</mat-label>
            <mat-select [(ngModel)]="selectedCategory">
              <mat-option *ngFor="let category of (categories$ | async)" [value]="category">{{ category.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field>
            <mat-label>Scope</mat-label>
            <mat-select [(ngModel)]="selectedScope" [disabled]="!selectedCategory">
              <mat-option *ngFor="let scope of selectedCategory?.AvailableScopes" [value]="scope">{{ scope.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field>
            <mat-label>Action</mat-label>
            <mat-select [(ngModel)]="selectedAction" [disabled]="!selectedScope">
              <mat-option *ngFor="let action of filteredActions()" [value]="action">{{ action.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2 text-right">
          <button mat-button class="blue-action-button" (click)="addPermission()" [hidden]="!selectedAction">
            Add Permission
          </button>
        </div>
      </div>
    </mat-card>
    <!-- <mat-card *ngFor="let perm of data"> -->
    <!-- <h4 [innerHTML]="perm.title"></h4> -->
    <table mat-table [dataSource]="this.permissions">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let row">
          <!-- <button mat-icon-button (click)="removeCategory(row.id)">
              <mat-icon>remove_circle</mat-icon>
            </button> -->
          {{ row.category }}
        </td>
      </ng-container>
      <ng-container matColumnDef="scope">
        <th mat-header-cell *matHeaderCellDef>Scope</th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.scope"></td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-left">Actions</th>
        <td mat-cell *matCellDef="let row">
          <mat-chip-list >
            <mat-chip *ngFor="let action of row.actions" [selectable]="false" (removed)="removePermission(action.id)">
              {{ action.name }}
              <mat-icon matChipRemove *ngIf="manageUserRolePermissionsDelete?.enabled">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="permissionColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: permissionColumns;"></tr>
    </table>
    <!-- </mat-card> -->
  </ng-template>

  <ng-template #contacts let-data="data">
    <section class="mt-1 mb-1">
      <strong class="d-block">{{ data.Type.name }}:</strong>
      <span *ngIf="data.number; else email" [innerHTML]="data.number"></span>
      <ng-template #email>
        <a href="mailto:{{ data.address }}" [innerHTML]="data.address"></a>
      </ng-template>
    </section>
  </ng-template>

  <ng-template #staffList>
    <table class="mt-24" mat-table [dataSource]="role?.AssignedUsers" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let staff">
          <button mat-icon-button (click)="removeStaff(staff.id)" *ngIf="manageUserRoleStaffMembersDelete?.enabled">
            <mat-icon>remove_circle</mat-icon>
          </button>
          <a *ngIf="adminPerm?.enabled else nonadmin" [routerLink]="['/staff', staff.id]" target="_blank">{{ staff.lastName }}, {{ staff.firstName }}</a>
          <ng-template #nonadmin>
            {{ staff.lastName }}, {{ staff.firstName }}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Position(s)</th>
        <td mat-cell *matCellDef="let staff">
          <p *ngFor="let role of staff.Positions" [innerHTML]="role.name"></p>
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let staff" [innerHTML]="(staff.Addresses | primaryAssociation)?.state"></td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let staff">
          <section *ngFor="let phone of staff.Phones">
            <ng-container *ngTemplateOutlet="contacts; context: { data: phone }"></ng-container>
          </section>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let staff">
          <section *ngFor="let email of staff.Emails">
            <ng-container *ngTemplateOutlet="contacts; context: { data: email }"></ng-container>
          </section>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="staffColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: staffColumns"></tr>
    </table>
  </ng-template>
</section>