import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IApiInvestigationRole, IApiUploadTypes, IApiUser, IApiUserSignatureInput } from 'src/app/shared/modules/graphql/types/types';
import { StateService, UserService, InvestigationRoleService, AuthService } from 'src/app/shared/services';
import { switchMap, take, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { removeTypename } from 'src/app/shared/rxjs.pipes';
import { NotificationsService } from '../../modules/notifications/notifications.service';
import { CreateUpdateStaffComponent } from 'src/app/components/shared/staff/create-update-staff/create-update-staff.component';
import {
  staffWorkingHourOwnView,
  staffContactInformationUpdate,
  staffContactInformationOwnUpdate,
  staffContactInformationOwnView,
  staffContactInformationView,
  staffUserInformationView,
  staffUserInformationUpdate,
  staffEmergencyInformationView,
  staffEmergencyInformationUpdate,
  staffWorkingHoursView,
  staffEmergencyInformationOwnUpdate,
  staffEmergencyInformationOwnView,
  staffUserInformationOwnUpdate,
  staffUserInformationOwnView,
  staffUpdateConfig,
  staffCertificationsList,
  staffCertificationsOwnList
} from "src/app/shared/helpers/auth-config/staff.config";
import { IApiInvestigationRoleNames } from 'src/app/shared/modules/graphql/types/types';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from '../file-select-modal/file-select-modal.component';
import { imageMimeType } from '../../helpers/helper';
import { FileInterface } from '../../services/s3/s3-service';

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.scss']
})

export class StaffProfileComponent implements OnInit, OnChanges {

  public authConfig = {
    staffContactInformationView,
    staffContactInformationOwnView,
    staffContactInformationUpdate,
    staffUserInformationView,
    staffUserInformationUpdate,
    staffEmergencyInformationView,
    staffEmergencyInformationUpdate,
    staffEmergencyInformationOwnView,
    staffEmergencyInformationOwnUpdate,
    staffWorkingHoursView,
    staffWorkingHourOwnView,
    staffContactInformationOwnUpdate,
    staffUserInformationOwnUpdate,
    staffUserInformationOwnView,
    staffUpdateConfig,
    staffCertificationsList,
    staffCertificationsOwnList
  };

  @Output() staffName = new EventEmitter<string>();
  @Input() userId = null;
  public authenticatedUserId = null;

  // Controls which delete view to show
  public showDelete = null;

  public get sidebar() {
    return !!this.userId;
  }

  public get hasTechReviewer() {
    const hasReviewer = [
      IApiInvestigationRoleNames.Investigator,
      IApiInvestigationRoleNames.ElectricalEngineer,
      IApiInvestigationRoleNames.AutoExport,
      IApiInvestigationRoleNames.HvacExpert,
      IApiInvestigationRoleNames.FireProtectionEngineer
    ];
    return  this.user.Positions.find((obj) => hasReviewer.includes(obj.title as IApiInvestigationRoleNames));
  }

  public user: IApiUser = {
    id: null,
    firstName: "",
    lastName: "",
    middleName: "",
    nickname: "",
    suffix: "",
    title: "",
    email: null,
    UserDetail: {
      id: null,
      requiredHoursQuarterly: null,
      additionalInformation: "",
      hasNefcoVehicle: false,
      hasNefcoCreditCard: false,
      vehicleRegistrationState: "",
      vehicleInsuranceCompany: "",
      emergencyContactName: "",
      emergencyContactInfo: "",
      payRateHourly: 0,
      status: "",
      fullTime: null,
      driversLicenseNumber: "",
      driversLicenseState: "",
      startDate: "",
      endDate: ""
    },
    Positions: [
      {
        id: null,
        title: ""
      }
    ],
    Addresses: [
      {
        id: null,
        state: "",
        address1: "",
        address2: "",
        city: "",
        country: "",
        postal: "",
        isPrimary: false,
        Type: {
          id: "",
          name: ""
        },
        latitude: null,
        longitude: null
      }
    ],
    Emails: [{
      id: null,
      TypeId: null,
      address: "",
      isPrimary: false,
      Type: {
        id: null,
        name: ""
      }
    }],
    Phones: [
      {
        id: null,
        TypeId: null,
        number: "",
        isPrimary: false,
        Type: {
          id: null,
          name: ""
        }
      }
    ],
    Certifications: [{
      id: null,
      title: ""
    }],
    Vehicles: []
  };

  // Edit variables
  public editContact = false;
  public editEmergencyContact = false;
  public editUserInfo = false;

  // TODO get list of each of the below items for the form selects
  public selectOpts = {
    positions: [],
    phones: [],
    emailTypes: [],
    addressTypes: [],
    states: []
  };

  // Positions / Investigation Roles
  public roles: IApiInvestigationRole[];
  public selectedRoles: [];
  public isSignatureModalOpen = false;
  public uploadedSignature: string = null;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public statesService: StateService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private _investigationRoleService: InvestigationRoleService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    this._investigationRoleService.investigationRoles.subscribe(roles => this.roles = roles);
   }

  public gotoStaffList() {
    return this.router.navigate(['/admin/staff/list']);
  }

  public investigatorFilter(position) {
    return position.title === 'TECH_REVIEWER';
  }

  private getProfileData(id: string) {
    return this.loader.show$(
      forkJoin([
        this.userService.getByIdCelendarViewProfile(id)
      ])).pipe(
        removeTypename(),
        tap(([user]) => {
          if (!user.UserDetail) user.UserDetail = {};
          this.user = user;
          this.selectedRoles = user.Positions.map(roles => roles.id);
          this.staffName.emit(`${user.firstName || ''} ${user.lastName || ''}`);
        })
      );
  }

  private getUser() {
    if (!this.userId) {
      return this.activatedRoute.params.pipe(
        take(1),
        switchMap((params) => this.getProfileData(params.id))
      );
    } else {
      return this.getProfileData(this.userId);
    }
  }

  public ngOnInit() {
    this.getUser().subscribe();
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if(changes?.userId?.firstChange === false && changes?.userId?.currentValue){
      this.getProfileData(this.userId).subscribe();
    }
  }

  public get isOwnerUser(): boolean {
    return (this.userId || this.activatedRoute.snapshot.params.id) === this.authenticatedUserId;
  }

  // Open edit user modal
  public editUser() {
    this.dialog.open(CreateUpdateStaffComponent, {
      width: "80%",
      height: "90%",
      data: { userId: this.user.id }
    }).afterClosed().pipe(
      switchMap(() => this.getUser())
    ).subscribe();
  }

  public openSinguareModal(isOpen: boolean = true) {
    this.isSignatureModalOpen = isOpen;
    if (this.isSignatureModalOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
      this.uploadedSignature = null;
    }
  }

  uploadSuccess(files: FileInterface[]) {
    this.uploadedSignature = files[0].key || '';
  }

  public fileSelector() {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: null,
      FolderId: null,
      UserId: this.user.id,
      type: IApiUploadTypes.Signature,
      restrictions: [...imageMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.uploadSuccess(res);
      }
    });
  }

  public saveSignature() {
    const payload: IApiUserSignatureInput = {
      s3Uri: this.uploadedSignature,
      UserId: this.user.id
    };

    this.loader.show$(this.userService.addUserSignature(payload)).pipe(
      this.notifications.catchAlertPipe("Error uploading singnature"),
      this.notifications.snackbarPipe("Singnature uploaded successfully.")
    ).subscribe((res) => {
      if (this.user.UserSignature?.length) {
        this.user.UserSignature[0] = {
          id: res?.id,
          s3Uri: res?.s3Uri,
          CreatedBy: res?.CreatedBy,
          createdAt: res?.createdAt,
          updatedAt: res?.updatedAt
        }
      } else {
        this.user.UserSignature.push({
          id: res?.id,
          s3Uri: res?.s3Uri,
          CreatedBy: res?.CreatedBy,
          createdAt: res?.createdAt,
          updatedAt: res?.updatedAt
        });
      }
      this.openSinguareModal(false);
    })
  }
}
