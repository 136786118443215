<!-- Permissions -->
<div [appEnforcePermission]="authConfig.contacts" #contactsPerm="appEnforcePermission"></div>
<!-- Permissions -->
<mat-form-field>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input [disabled]="disabled || !contactsPerm?.enabled" type="text" [placeholder]="placeholder" aria-label="User" #search [(ngModel)]="selected" (blur)="blur()" name="selection" matInput [matAutocomplete]="auto" (appDebouncedInputChange)="onSearchChange($event)">
  <button mat-icon-button disableRipple matSuffix (click)="select(null)" *ngIf="selected">
    <mat-icon>clear</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" [hidden]="!contacts?.length && search.value?.length < 3" (optionSelected)="select($event.option.value)" [displayWith]="selectedDisplay.bind(this)" (closed)="closed()" >
    <mat-option *ngFor="let option of contacts" [value]="option">
      {{option | fullName}}{{option.insuredName ? ', ' : ''}}{{option.insuredName}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
