<p class="kendo-caption-body mb-0" *ngIf="email; else phoneTemplate">
  {{ email?.address }}
  <span *ngIf="email?.Type">({{ email?.Type?.name }})</span>
</p>
<ng-template #phoneTemplate>
  <p class="kendo-caption-body mb-0">
    {{ phone?.number | phoneFormat : "US" }} {{ phone?.extension }}
    <span *ngIf="phone?.Type">({{ phone?.Type?.name }})</span>&nbsp;<span
      *ngIf="phone?.isPrimary"
      >(Primary)</span>
      <br/>
      <span *ngIf="phone?.type" class="text-overflow type-description">{{phone?.type}}</span>
  </p>
</ng-template>
