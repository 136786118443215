<section>
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companiesBranchesView" #companiesBranchesView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBranchesDelete" #companiesBranchesDelete="appEnforcePermission"></div>

  <!-- Permissions -->

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <!-- TODO link to branch page -->
      <td mat-cell *matCellDef="let branch">
        <a [routerLink]="['/admin','companies', 'management', company.id, 'branch', branch.id]"
          *ngIf="companiesBranchesView?.enabled else branchName">{{branch.name}}</a>
        <ng-template #branchName>{{branch.name}}</ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let branch">
        <app-address-view *ngFor="let address of branch.Addresses" [address]="address"></app-address-view>
      </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Phone</th>
      <td mat-cell *matCellDef="let branch">
        <app-email-phone-view *ngFor="let phone of branch.Phones" [phone]="phone"></app-email-phone-view>
      </td>
    </ng-container>
    <ng-container matColumnDef="contacts">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Contacts</th>
      <td mat-cell *matCellDef="let branch" class="text-center" [innerHTML]="branch.Contacts?.length"></td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let branch" class="text-center">
        <button mat-icon-button [matMenuTriggerFor]="companyMenu" *ngIf="companiesBranchesDelete?.enabled">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #companyMenu="matMenu" yPosition="below">
          <a mat-menu-item (click)="deleteBranch(branch)">Delete</a> <!-- open add contacts/staff modal-->
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let branch; columns: columnsToDisplay;"></tr>
  </table>
</section>
