<section>
   <!-- Permissions -->
   <div [appEnforcePermission]="authConfig.companiesViewAll" #companiesViewAll="appEnforcePermission"></div>
   <div [appEnforcePermission]="authConfig.companiesCreateConfig" #companiesCreateConfig="appEnforcePermission"></div>
   <div [appEnforcePermission]="authConfig.companiesBranchesList" #companiesBranchesList="appEnforcePermission"></div>
   <div [appEnforcePermission]="authConfig.companiesBranchesView" #companiesBranchesView="appEnforcePermission"></div>
   <div [appEnforcePermission]="authConfig.companiesBranchesCreate" #companiesBranchesCreate="appEnforcePermission"></div>
   <div [appEnforcePermission]="authConfig.companiesDelete" #companiesDelete="appEnforcePermission"></div>
   <!-- Permissions -->
  <h4 class="page-title">Companies</h4>
  <mat-card>
    <div class="row align-items-center">
      <div class="col">
        <mat-form-field appearance="legacy">
          <mat-label>Type</mat-label>
          <mat-select (selectionChange)="setCompanyType($event)">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let companyType of companyTypes" [value]="companyType.id">
              {{companyType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="ml-2">
          <mat-label>State</mat-label>
          <mat-select (selectionChange)="setState($event)">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let state of stateService?.allStates" [value]="state.value" [innerHTML]="state.text">
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col text-right">
        <mat-form-field class="equal search" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" (appDebouncedInputChange)="onSearchChange()" name="search" />
          <button mat-icon-button disableRipple matSuffix>
            <mat-icon (click)="searchValue = null; onSearchChange()">clear</mat-icon>
          </button>
        </mat-form-field>
        <button mat-raised-button class="rounded-button ml-2" (click)="gotoCreateCompany();" *ngIf="companiesCreateConfig?.enabled">
          <mat-icon color="accent">playlist_add</mat-icon>
          New Company
        </button>
      </div>
    </div>
    <!-- <ng-container *ngIf="companies.length === 0; else companiesList">
      <div class="no-results">
        There are no results to display.
      </div>
    </ng-container> -->
    <section>
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions?.sort($event)">
        <ng-container matColumnDef="NAME">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="sortHeaders.Name">Name</th>
          <td mat-cell *matCellDef="let company; let i = index">
            <a [routerLink]="['/admin/companies/management', company.id]" *ngIf="companiesViewAll?.enabled else companyName">
              {{company.name}}
            </a>
            <ng-template #companyName>
              {{company.name}}
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="TYPE">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let company">
            {{company.Type?.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="BRANCHES">
          <th mat-header-cell *matHeaderCellDef>
            <table class="text-left">
              <thead>
                <tr>
                  <th width="30%">Branch</th>
                  <th width="30%" class="pr-2">Address</th>
                  <th width="30%">Phone Number</th>
                  <th width="10%">
                    <!-- leave this empty cell so these cells line up with nested table cells below -->
                  </th>
                </tr>
              </thead>
            </table>
          </th>
          <td mat-cell *matCellDef="let company">
            <table class="text-left">
              <tbody>
                <tr *ngFor="let branch of company.Branches">
                  <td width="30%">
                    <a [routerLink]="['/admin/companies/management', company.id, 'branch', branch.id]" *ngIf="companiesBranchesView?.enabled else branchName">{{branch.name}}</a>
                    <ng-template #branchName>{{branch?.name}}</ng-template>
                  </td>
                  <td width="30%" class="pr-2">
                    <app-address-view *ngFor="let address of branch.Addresses" [address]="address"></app-address-view>
                  </td>
                  <td width="30%">
                    <app-email-phone-view *ngFor="let phone of branch.Phones | primarySort" [phone]="phone"></app-email-phone-view>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </ng-container>
        <ng-container matColumnDef="ACTION">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let company">
            <button mat-icon-button [matMenuTriggerFor]="companyMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #companyMenu="matMenu" yPosition="below">
              <a mat-menu-item [routerLink]="['/admin/companies/management', company.id]" *ngIf="companiesViewAll?.enabled">View Company Info</a>
              <a mat-menu-item [routerLink]="['/admin/companies/management', company.id]" [queryParams]="{tab: 'branches'}" *ngIf="companiesBranchesList?.enabled">View Branches</a>
              <a mat-menu-item (click)="addBranch()" *ngIf="companiesBranchesCreate?.enabled">Add Branch</a>
              <a mat-menu-item [routerLink]="['/admin', 'companies', 'management', company.id]" [queryParams]="{tab: 'contacts'}">View Contacts</a> <!-- take to the contact tab for the given company -->
              <a mat-menu-item (click)="addContact()">Add Contacts</a>
              <a mat-menu-item (click)="deleteCompany(company)" *ngIf="companiesDelete?.enabled">Delete</a> <!-- open add contacts/staff modal-->
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
    </section>
  </mat-card>
</section>