import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApiAddContactBranchAssignmentInput, IApiBranch, IApiCompany, IApiContact, IApiContactFilterType, IApiUpdateContactBranchAssignmentInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { CompanyService, ContactService, StateService } from 'src/app/shared/services';
import { ContactDataSource } from 'src/app/shared/services/contact/contact.datasource';
import { CreateUpdateContactComponent, ICreateUpdateContactData } from '../../contacts/create-update-contact/create-update-contact.component';
import { filter } from 'rxjs/operators';
import { SharedService } from 'src/app/common/shared.service';

export interface ICompanyBranchContactSelectData {
  company: IApiCompany;
  branchId?: string;
}

@Component({
  selector: 'app-company-branch-contact-select',
  templateUrl: './company-branch-contact-select.component.html',
  styleUrls: ['./company-branch-contact-select.component.scss']
})
export class CompanyBranchContactSelectComponent implements OnInit {

  private _dataSource: ContactDataSource;
  public displayedColumns = ["checked", "name", "position", "branch", "role", "emails", "phones"];
  public searchValue = "";

  public content: IApiContact[] = [];

  public selectedBranch: string = null;
  public state = null;
  public company = null;
  public companies: IApiCompany[] = [];

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  constructor(
    private contactService: ContactService,
    private loader: LoaderService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CompanyBranchContactSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICompanyBranchContactSelectData,
    public stateService: StateService,
    private companyService: CompanyService,
    private notification: NotificationsService,
    private sharedService: SharedService
  ) { }

  public ngOnInit(): void {
    this._dataSource = new ContactDataSource(this.contactService);
    this.loader.attachObservable(this._dataSource.loading$);
    this.dataSource.contents$.subscribe((content) => this.content = content);
    // Preset selected branch if it has been provided.
    if (this.data.branchId) this.selectedBranch = this.data.branchId;

  }

  public selectContact(contact: IApiContact) {
    if (!this.selectedBranch) return this.notification.alert("Please select a branch");
    const assignment: IApiAddContactBranchAssignmentInput = {
      startDate: new Date(),
      BranchId: this.selectedBranch,
      ContactId: contact.id
    };
    this.loader.show$(
      this.contactService.update({ id: contact.id, AddBranch: assignment })
    ).subscribe(() => this.dialogRef.close(true));
  }

  public createNewContact() {
    if (!this.selectedBranch) return this.notification.alert("Please select a branch");
    const data: ICreateUpdateContactData = {
      branchId: this.selectedBranch,
      companyRoles: true
    };
    this.dialog.open(CreateUpdateContactComponent, {
      width: "80%",
      height: "90%",
      data
    }).afterClosed().pipe(
      filter(result => !!result)
    ).subscribe(() => this.sharedService.afterCreateContact());
    this.dialogRef.close(false);
  }

  public load() {
    if (this.searchValue) this.dataSource.applyFilter(IApiContactFilterType.Search, this.searchValue);
    if (this.state) this.dataSource.applyFilter(IApiContactFilterType.State, this.state);
    if (this.company) this.dataSource.applyFilter(IApiContactFilterType.Company, this.company);
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  onCompanyChange(event) {
    this.company = event?.id || null;
  }


}
