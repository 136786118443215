import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReactiveFormHelper } from 'src/app/shared/helpers/reactive-form.class';
import { AuthService } from '@auth0/auth0-angular';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { PortalService } from 'src/app/shared/services';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private loader: LoaderService,
    private portalService: PortalService,
    private notifications: NotificationsService
  ) { }

  public form = this.fb.group({
    email: ['', [Validators.required, Validators.email, Validators.compose([ReactiveFormHelper.emailValidator])]]
  });

  public onSubmit() {
    if (this.form.valid) {
      this.form.get('email').setErrors({notExist: false});
      this.form.get('email').updateValueAndValidity();
      const formEmail = this.form?.value?.email?.toLowerCase();
      this.loader.show$(this.portalService.resetPassword(formEmail)).pipe(this.notifications.catchAlertPipe()).subscribe((res) => {
        if (res.data.resetPassword) {
          this.notifications.notify('We just sent you an email to reset your password.');
          setTimeout(() => {
            this.auth.loginWithRedirect({ redirect_uri: window.origin });
          }, 2000);
        } else {
          this.form.get('email').setErrors({notExist: true});
        }
      })
    } else {
      this.form.markAllAsTouched();
    }
  }

  public backToLogin() {
    this.auth.loginWithRedirect({ redirect_uri: window.origin });
  }

}
