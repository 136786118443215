import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SharedService {
	private subject = new Subject<any>();
	private createContact = new Subject<any>();

	sendClickEvent() {
		this.subject.next();
	}
	
	getClickEvent(): Observable<any>{ 
		return this.subject.asObservable();
	}

	afterCreateContact() {
		this.createContact.next();
	}
	
	loadContactList(): Observable<any>{ 
		return this.createContact.asObservable();
	}

	public setLocalStorageByKey(key: string, val: any) {
		return localStorage.setItem(key, JSON.stringify(val, (k, v) => v === undefined ? null : v));
	}

	public getLocalStorageByKey(key: string) {
		return JSON.parse(localStorage.getItem(key));
	}

	public removeLocalStorageByKey(key: string) {
		return localStorage.removeItem(key);
	}

	public clearLocalStorage() {
		return localStorage.clear();
	}
}