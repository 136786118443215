import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from 'src/app/shared/directives/enforce-permission.directive';
import { IApiPermissionRole } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PermissionRoleService } from './../../../../shared/services/permissions/permission-role/permission-role.service';
import { PermissionRoleDataSource } from './../../../../shared/services/permissions/permission-role/permission-role.datasource';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PermissionRoleModalComponent } from "../permission-role-modal/permission-role-modal.component";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import {
  manageUserRolePermissionsList,
  manageUserRoleStaffMembersList,
  manageUserRolesCreate,
  manageUserRolesUpdate,
  manageUserRolesDelete,
  manageUserRolesView,
  manageUserRoleNotificationsList,
  manageUserRoleNotificationsView,
  manageUserRoleNotificationsUpdate
} from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';

@Component({
  selector: 'app-permissions-roles-list',
  templateUrl: './permissions-roles-list.component.html',
  styleUrls: ['./permissions-roles-list.component.scss']
})
export class PermissionsRolesListComponent implements OnInit {

  public authConfig = {
    manageUserRolesCreate,
    manageUserRolesUpdate,
    manageUserRolesDelete,
    manageUserRolesView,
    manageUserRoleStaffMembersList,
    manageUserRolePermissionsList,
    manageUserRoleNotificationsList,
    manageUserRoleNotificationsView,
    manageUserRoleNotificationsUpdate
  }

  @ViewChild(MatPaginator) viewPaginator: MatPaginator;
  @ViewChild(MatSort) viewSort: MatSort;

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public permissionUserRolesConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.MANAGE_USER_ROLES,
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  private _dataSource: PermissionRoleDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public displayedColumns = ["role", "staffCount", "actions"];
  
  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];
  
  public permissionRoleList: IApiPermissionRole[] = [];
 
  constructor(
    private permissionRoles: PermissionRoleService,
    private dialog: MatDialog,
    private notifications: NotificationsService,
    private loader: LoaderService
  ) {
  }

  public addRole() {
    this.dialog.open(PermissionRoleModalComponent, {
      width: "25%"
    }).afterClosed().pipe(
      filter(result => !!result)
    ).subscribe(() => this.load());
  }

  public editRole(role: IApiPermissionRole) {
    this.dialog.open(PermissionRoleModalComponent, {
      data: { ...role },
      width: "25%"
    }).afterClosed().pipe(
      filter(result => !!result)
    ).subscribe(() => this.load());
  }

  public removeRole(id: string) {
    this.notifications.confirm("Really remove this Permission Role?", "Are you sure?").afterClosed().pipe(
      filter(response => !!response),
      this.loader.showPipe(
        this.permissionRoles.remove(id)
      ),
      this.notifications.alertPipe("Permission Role removed"),
      this.notifications.catchAlertPipe("Error removing Permission Role; please try again")
    ).subscribe(() => this.load());
  }

  ngOnInit() {
    this._dataSource = new PermissionRoleDataSource(this.permissionRoles);
    this.loader.attachObservable(this._dataSource.loading$);
    
    this._dataSource.listPage.orderBy = "NAME";
    this._dataSource.listPage.limit = 5;
    // populate the data
    this.load();
  }

  private load() {
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.permissionRoleList = res;
      });
    });
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Edit':
        this.editRole(dataItem);
        break;
      case 'Delete':
        this.removeRole(dataItem?.id)
        break;
      default:
        break;
    }
  }
}
