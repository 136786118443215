import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BranchService } from 'src/app/shared/services';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { BranchOrderBy, BranchFilter } from 'src/app/shared/modules/graphql/enums/branch.enums';
import { IGqlBranchOptions, IGqlBranchFilter } from 'src/app/shared/modules/graphql/interfaces/branch.interfaces';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IApiBranch, IApiBranchConnection, IApiBranchFilterType, IApiBranchOrderBy, IApiCompany, IApiPageInfo, IApiSortOrder } from "src/app/shared/modules/graphql/types/types";
import { BranchDataSource } from 'src/app/shared/services/branch/branch.datasource';
import {
  companiesBranchesView,
  companiesBranchesDelete
} from "src/app/shared/helpers/auth-config/companies.config";
import { filter } from 'rxjs/operators';
@UntilDestroy()
@Component({
  selector: 'app-company-branch-list',
  templateUrl: './company-branch-list.component.html',
  styleUrls: ['./company-branch-list.component.scss']
})
export class CompanyBranchListComponent implements OnInit {
  public authConfig = {
    companiesBranchesView,
    companiesBranchesDelete
  };

  @Input() company: IApiCompany;

  private _dataSource: BranchDataSource;
  public get dataSource(): BranchDataSource {
    return this._dataSource;
  }
  public set dataSource(val: BranchDataSource) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  constructor(
    private route: ActivatedRoute,
    private branchService: BranchService,
    private loaderService: LoaderService,
    private notifications: NotificationsService
  ) { }

  public columnsToDisplay: string[] = ["name", "address", "phone", "contacts", 'action'];

  ngOnInit() {
    this._dataSource = new BranchDataSource(this.branchService);

    this.loaderService.attachObservable(this.dataSource.loading$);
    this._dataSource.listPage.orderBy = IApiBranchOrderBy.Name;
    this._dataSource.listPage.sortOrder = SortOrder.ASC;
    this._dataSource.listPage.limit = -1;
    // Init Table
    // This filter should always be applied so we only get the branches for this company
    this._dataSource.applyFilter(IApiBranchFilterType.Company, this.company.id);
    this._dataSource.load();
  }

  public load(): Promise<IApiBranchConnection> {
    return this._dataSource.load();
  }

  deleteBranch(branch) {
    try {
      this.notifications.confirm("Are you sure you want to delete this branch?", "Delete Branch").afterClosed().pipe(
        filter(response => !!response),
        this.loaderService.showPipe(
          this.branchService.remove(branch?.id)
        ),
        this.notifications.catchAlertPipe("You cannot delete this branch as it is associated with contacts or investigations.", "Error Deleting The Branch")
      ).subscribe(() => {
        this.load();
        this.notifications.notify("Branch deleted successfully!");
      });
    } catch (error) {
      console.log('error', error)
    }
  }
}

