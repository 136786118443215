import { InvestigationTimeAndExpQuickbooksModalComponent } from './../../investigations/investigation-time-and-exp-quickbooks-modal/investigation-time-and-exp-quickbooks-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { Component, OnInit } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PageEvent } from "@angular/material/paginator";
import { InvoiceService, UserService } from "src/app/shared/services";
import { InvoiceDataSource } from "src/app/shared/services/invoice/invoice.datasource";
import { IApiInvoice, IApiInvoiceOrderBy, IApiUserFilterType, IApiUserOrderBy } from "src/app/shared/modules/graphql/types/types";
import { IApiInvoiceFilterType } from 'src/app/shared/modules/graphql/types/types';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { ViewportScroller } from '@angular/common';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { bookKeepingInvoiceListDelete, bookKeepingInvoiceListUpdate } from 'src/app/shared/helpers/auth-config/bookkeeping.config';

@Component({
  selector: 'app-bookkeeping-invoice-list',
  templateUrl: './bookkeeping-invoice-list.component.html',
  styleUrls: ['./bookkeeping-invoice-list.component.scss']
})
export class BookkeepingInvoiceListComponent implements OnInit {

  public authConfig = {
    bookKeepingInvoiceListUpdate,
    bookKeepingInvoiceListDelete
  }

  public filtersActive = false;

  // Table Setup
  private _dataSource: InvoiceDataSource;
  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }
  public displayedColumns = ["invoice", "invId", "billTo", "client", "createdBy", "createdAt", "status", "qbErrors", "actions"];
  public pageSize: number = null;
  public totalResults = 0;

  // Filter options
  public searchValue: string = null;
  public invoiceNumber: number = null;
  public investigationId: string = null;
  public createdBy = null;
  public startDate = null;
  public endDate = null;

  // Select options - need to be fetched
  public invoices = null;
  public investigations = null;
  public orderByOptions = IApiInvoiceOrderBy;

  constructor(
    private invoiceService: InvoiceService,
    private notifications: NotificationsService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private viewport: ViewportScroller,
  ) {
    this.dataSource = new InvoiceDataSource(this.invoiceService);
    this.dataSource.listPage.orderBy = IApiInvoiceOrderBy.CreatedAt;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  // Table Interaction
  public clearFilters() {
    this.investigationId = null;
    this.invoiceNumber = null;
    this.createdBy = null;
    this.startDate = null;
    this.endDate = null;
    this.searchValue = null;
    this.dataSource.removeFilter(IApiInvoiceFilterType.Investigation);
    this.dataSource.removeFilter(IApiInvoiceFilterType.InvoiceNumber);
    this.dataSource.removeFilter(IApiInvoiceFilterType.CreatedBy);
    this.dataSource.removeFilter(IApiInvoiceFilterType.InvoiceDate);
    this.dataSource.removeFilter(IApiInvoiceFilterType.Search);
    this.load();
  }

  public filterTable() {
    // execute data fetch here with filter parameters
    this.dataSource.applyFilter(IApiInvoiceFilterType.Investigation, this.investigationId);
    this.dataSource.applyFilter(IApiInvoiceFilterType.InvoiceNumber, this.invoiceNumber?.toString());
    this.dataSource.applyFilter(IApiInvoiceFilterType.CreatedBy, this.createdBy);
    this.dataSource.applyFilter(IApiInvoiceFilterType.Search, this.searchValue);
    if (this.startDate && this.endDate) this.dataSource.applyFilter(IApiInvoiceFilterType.InvoiceDate, NefcoDateHelper.dateFilterString(this.startDate, this.endDate));
    else this.dataSource.removeFilter(IApiInvoiceFilterType.InvoiceDate);
    this.load();
  }


  ngOnInit() {
    this.loader.attachObservable(this.dataSource.loading$);
    this.load();
  }

  public load() {
    // TODO - apply filters
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public resubmitInvoice(invoice: IApiInvoice) {
    this.notifications.confirm("Are you sure this invoice is ready for resubmission?").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.resubmit(invoice.id)),
      this.notifications.catchAlertPipe("Error resubmitting invoice"),
      this.notifications.alertPipe("Invoice resubmitted!")
    ).subscribe(() => this.load());
  }

  public deleteInvoice(invoice: IApiInvoice) {
    this.notifications.confirm("Are you sure you want to delete this invoice?").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.remove(invoice.id)),
      this.notifications.catchAlertPipe("Error deleting invoice"),
      this.notifications.alertPipe("Invoice deleted.")
    ).subscribe(() => this.load());
  }

  public openQbDialog(invoice?: IApiInvoice, disableEdit = false) {

    // Needed for click and drag to work in QB modal
    this.viewport.scrollToPosition([0, 0]);

    const dialogRef = this.dialog.open(InvestigationTimeAndExpQuickbooksModalComponent, {
      width: '75%',
      height: '95vh',
      data: {
        invoice,
        disableEdit,
      }
    }).afterClosed().pipe(
      filter((v) => !!v)
    ).subscribe(() => {
      this.load();
    });
  }
}
