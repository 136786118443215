<section>
  <div class="pb-3" *ngIf="selectableMode">
    <mat-chip-list #selectedChipList>
      <mat-chip *ngFor="let sel of selection.selected" [selectable]="false" [removable]="true">{{sel.name}}
        <mat-icon matChipRemove (click)="removeChip(sel)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <table mat-table [dataSource]="content">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected" [indeterminate]="selection.hasValue() && !isAllSelected">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectRow(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef>Service Type Name </th>
      <td mat-cell *matCellDef="let type" [innerHTML]="type.name"></td>
    </ng-container>
    <ng-container matColumnDef="SERVICES">
      <th mat-header-cell *matHeaderCellDef>Services Included</th>
      <td mat-cell *matCellDef="let type">
        <div *ngFor="let service of type.SubServiceTypes" [innerHTML]="service.name"></div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>
  <div class="d-flex justify-content-end" *ngIf="selectableMode">
    <button mat-button (click)="done.emit([])">Cancel</button>
    <button class="blue-action-button" mat-button (click)="save()">Save</button>
  </div>
</section>