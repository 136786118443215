<section class="mat-typography">
  <!-- Modal Header -->
  <div mat-dialog-title class="header">
    <h2 class="text-center">{{ role.id ? "Update" : "Create" }} Permission Role</h2>
  </div>
  <div class="flexContainer">
    <form #frm="ngForm">
      <div class="equal">
        <div class="row">

          <mat-form-field class="flex-grow-1" appearance="legacy">
            <mat-label>Role Name</mat-label>
            <input appNoWhiteSpace matInput name="name" maxlength="150" type="text" minlength="3"
              [(ngModel)]="role.name" cdkFocusInitial required />
          </mat-form-field>

        </div>
        <div class="row">
          <mat-form-field class="flex-grow-1" appearance="legacy">
            <mat-label> Default Time Entry Type </mat-label>
            <mat-select name="type" [(ngModel)]="role.TimeEntryTypeId">
              <mat-option *ngFor="let type of timeEntryTypes" [value]="type.id" [innerHTML]="type.name">
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row">
        <mat-form-field class="flex-grow-1">
          <mat-label>Additional Details</mat-label>
          <textarea name="details" matInput [(ngModel)]="model.details"></textarea>
        </mat-form-field>
      </div> -->
    </form>

  </div>
  <div mat-dialog-actions class="d-flex flex-row-reverse">
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!frm.valid" class="ml-2">{{ role.id ?
      "Save" : "Add" }}</button>
    <button mat-button (click)="onCancelClick()" class="ml-4">Cancel</button>
  </div>
</section>
