<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companies" #companiesPerm="appEnforcePermission"></div>
<!-- Permissions -->
<mat-form-field>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input type="text" [placeholder]="placeholder" aria-label="Company" #search [(ngModel)]="selected" (blur)="blur()" name="selection" matInput [matAutocomplete]="auto" (appDebouncedInputChange)="onSearchChange($event)"
  [disabled]="!companiesPerm?.enabled">
  <button mat-icon-button disableRipple matSuffix (click)="select(null)" *ngIf="selected">
    <mat-icon>clear</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" [hidden]="search.value?.length < 3" (optionSelected)="select($event.option.value)" [displayWith]="selectedDisplay.bind(this)" (closed)="closed()" >
    <mat-option *ngFor="let option of companies" [value]="option">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>