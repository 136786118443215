<!-- <app-certifications-list [selectableMode]="true" (done)="saveCertificationSelection($event)"></app-certifications-list> -->
  <!-- Modal Header -->
  <div class="header">
    <h2 class="text-center">Add Certification</h2>
  </div>

  <!-- New Request Form -->
  <div class="body mt-4">
    <form #certificateForm="ngForm">
      <div class="row">

        <!-- Certification Name -->
        <div class="col-10">
          <mat-form-field>
            <mat-label>Certification Name</mat-label>
            <mat-select name="certificationName" [(ngModel)]="newCertification.TypeId" required>
              <mat-option *ngFor="let cert of certifications" [value]="cert.id" [innerHTML]="cert.title"></mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Document Upload -->
        <div class="col-12 my-3 d-flex flex-row-reverse" *ngIf="!s3Doc">
            <div class="buttonContainer">
              <button mat-raised-button class="rounded-button" (click)="fileSelector()">
                  <mat-icon color="accent">cloud_upload</mat-icon>
                  Add Certification
              </button>
            </div>
        </div>
        <div class="col-12 my-3 d-flex flex-row-reverse" *ngIf="s3Doc">
          <mat-chip-list>
            <mat-chip [selectable]="false" [removable]="true">{{s3Doc.filename}}
              <mat-icon matChipRemove (click)="removeDocument()">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>

        <!-- Issue Date -->
        <div class="col-4">
          <app-datepicker [(ngModel)]="newCertification.issuedAt" name="issueDate" [label]="'Issue Date'" [required]="true"></app-datepicker>
        </div>

        <!-- Expiration Date -->
        <div class="col-4">
          <app-datepicker [(ngModel)]="newCertification.expiresAt" name="expiresAt" [label]="'Expiration Date'" [required]="true"></app-datepicker>
        </div>

        <!-- RequestType-->
        <div class="col-4">
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select name="status" required [(ngModel)]="newCertification.status">
              <mat-option *ngFor="let type of statusTypes | keyvalue" [value]="type.value" [innerHTML]="type.value"></mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <!-- Comments -->
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Comments</mat-label>
            <textarea name="notes" matInput appearance="outline" [(ngModel)]="newCertification.Comments" name="comments" >
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </form>

    <!-- Submit Request Button -->
    <div mat-dialog-actions class="d-flex flex-row-reverse">
      <button mat-raised-button color="primary"
        [disabled]="!certificateForm.valid" (click)="saveCertificationSelection()">Save</button>
      <button mat-button (click)="dialogRef.close()">Cancel</button>
    </div>
  </div>