import { StateService } from 'src/app/shared/services/state.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContactService } from "src/app/shared/services";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IApiCompany, IApiContact, IApiContactFilter, IApiContactFilterType, IApiContactOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { ContactDataSource } from 'src/app/shared/services/contact/contact.datasource';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { CreateUpdateContactComponent } from '../../contacts/create-update-contact/create-update-contact.component';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  templateUrl: './investigation-select-contact-company-modal.component.html',
  styleUrls: ['./investigation-select-contact-company-modal.component.scss']
})
export class InvestigationSelectContactCompanyModalComponent implements OnInit {

  private _dataSource: ContactDataSource;
  private _filters: IApiContactFilter[] = [];

  public companies: IApiCompany[] = [];

  // filter values
  public firstName = "";
  public lastName = "";
  public selectedCompany = "";
  public selectedCompanyState = "";

  public hasData = false;

  // todo: "role", "position-company", "branch", "email", "phone-1"
  public columnsToDisplay = ["name", "position-company", "branch", "role", "emails", "phones"];

  public get dataSource(): ContactDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public modal: any = {
    partyAdd: false
  };
  public recentContactCreatedId: string = null;
  constructor(
    private contactService: ContactService,
    public stateService: StateService,
    public dialogRef: MatDialogRef<InvestigationSelectContactCompanyModalComponent>,
    private loader: LoaderService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._dataSource = new ContactDataSource(this.contactService);
    this.modal = data;
  }

  public ngOnInit() {
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSource.contents$.pipe(
      untilDestroyed(this)
    ).subscribe((data) => this.hasData = !!data.length);

  }

  public searchContacts() {
    this.selectedCompanyState ? this.dataSource.applyFilter(IApiContactFilterType.CompanyState, this.selectedCompanyState) : this.dataSource.removeFilter(IApiContactFilterType.CompanyState);
    this.firstName ? this.dataSource.applyFilter(IApiContactFilterType.FirstName, this.firstName) : this.dataSource.removeFilter(IApiContactFilterType.FirstName);
    this.lastName ? this.dataSource.applyFilter(IApiContactFilterType.LastName, this.lastName) : this.dataSource.removeFilter(IApiContactFilterType.LastName);
    this.selectedCompany ? this.dataSource.applyFilter(IApiContactFilterType.Company, this.selectedCompany) : this.dataSource.removeFilter(IApiContactFilterType.Company);
    this.dataSource.listPage.orderBy = IApiContactOrderBy.LastName;
    this.dataSource.listPage.sortOrder = SortOrder.ASC;
    this.dataSource.listPage.limit = 10;
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public selectContact(contact, branch = null) {
    let newContact = false
    /* trigger notification when contact is created from investigation and attached to investigation */ 
    if (this.recentContactCreatedId === contact?.id && this.modal.partyAdd) {
      newContact = true;
    }
    this.dialogRef.close({ contact, branch, newContact });
  }

  public findCompany({ Branches }, branchId) {
    const foundBranch = Branches.find((obj) => obj.id === branchId);
    return foundBranch ? foundBranch?.Company?.name : null;
  }

  public openContactModal(contact: IApiContact | null = null) {
      this.dialog.open(CreateUpdateContactComponent, {
        width: "80%",
        height: "95vh",
        data: { 
          contactId: contact ? contact.id : null,
          partyAdd: this.modal?.partyAdd || false,
        }
      }).afterClosed().pipe(
        filter((v) => !!v)
        ).subscribe((response) => {
          if (this.modal?.partyAdd) {
            this.recentContactCreatedId = response?.id || null
          }
          this.dataSource.load()
        });

  }

}
