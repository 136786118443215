<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageUserRolesCreate" #manageUserRolesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolesUpdate" #manageUserRolesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolesDelete" #manageUserRolesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolesView" #manageUserRolesView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolePermissionsList" #manageUserRolePermissionsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleStaffMembersList" #manageUserRoleStaffMembersList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsList" #manageUserRoleNotificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRoleNotificationsView" #manageUserRoleNotificationsView="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="page-header">
      <h4 class="page-title mb-0">User Roles</h4>
      <div *ngIf="manageUserRolesCreate?.enabled">
          <kendo-button
            kendoButton
            [themeColor]="'primary'"
            fillMode="solid"
            iconClass="fas fa-plus"
            (click)="addRole();"
          >Add New User Role</kendo-button>
      </div>
  </div>
  <kendo-grid
    class="permission-role-list pl-24 pr-24"
    [data]="{
      data: permissionRoleList,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
  >
    <kendo-grid-column
          field="NAME"
          title="Role"
          [width]="30"
          class="id-column"
          [sortable]="true"
          class="font-weight600"
          [headerClass]="'sort-header'"
        >
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            [routerLink]="['/admin/permissions/management', dataItem?.id]"
            [queryParams]="{tab: 'permissions'}"
            tel=""
            class="text-decoration"
            *ngIf="(manageUserRolesView?.enabled && manageUserRolePermissionsList?.enabled); else displayName"
          >
            {{dataItem?.name}}
          </a>
          <ng-template #displayName>
            <span [innerHTML]="dataItem?.name"></span>
          </ng-template>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    field="id"
    title="Staff Members"
    [width]="30"
    [sortable]="false"
    [headerClass]="'nocursor'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/admin/permissions/management', dataItem?.id]"
          [queryParams]="{tab: 'staff'}"
          tel=""
          class="text-decoration"
          *ngIf="(manageUserRolesView?.enabled && manageUserRoleStaffMembersList?.enabled) else viewStaffCount"
        >
          <kendo-badge
            class="fw600 ml-8"
            position="none"
            size="large"
            rounded="full"
            themeColor="primary"
          >
              {{dataItem?.AssignedUserCount || 0}}
          </kendo-badge>
        </a>
        <ng-template #viewStaffCount>
          <kendo-badge
            class="fw600 ml-8"
            position="none"
            size="large"
            rounded="full"
            themeColor="primary"
          >
              {{dataItem?.AssignedUserCount || 0}}
          </kendo-badge>
        </ng-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    field="id"
    title="Notifications Enabled"
    [width]="30"
    [sortable]="false"
    [headerClass]="'nocursor'"
    *ngIf="manageUserRoleNotificationsList?.enabled && manageUserRoleNotificationsView?.enabled"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/admin/permissions/management', dataItem?.id]"
          [queryParams]="{tab: 'notifications'}"
          tel=""
          class="text-decoration"
        >
          <span
          kendoTooltip
          position="bottom"
          [tooltipTemplate]="notificationTemplate"
          filter="kendo-badge"
          tooltipClass="tooltipwidth200"
          *ngIf="dataItem?.EnabledNotifications?.length else noTooltip">
            <kendo-badge
              class="fw600 ml-8"
              style="margin-left: 15px"
              position="none"
              size="large"
              rounded="full"
              themeColor="primary"
            >
              {{dataItem?.EnabledNotifications?.length || 0}}
            </kendo-badge>
            <ng-template #notificationTemplate>
                <ng-container *ngFor="let item of dataItem?.EnabledNotifications">
                  <span class="dflex">
                      {{item}}
                  </span>
              </ng-container>
            </ng-template>
          </span>
          <ng-template #noTooltip>
            <kendo-badge
              class="fw600 ml-8"
              style="margin-left: 15px"
              position="none"
              size="large"
              rounded="full"
              themeColor="primary"
            >
              {{dataItem?.EnabledNotifications?.length || 0}}
            </kendo-badge>
          </ng-template>
        </a>
      </ng-template>
    </kendo-grid-column>

    <!-- Action -->
    <kendo-grid-column
    field="id"
    title="Actions"
    [width]="10"
    [sortable]="false"
    [headerClass]="'nocursor'"
    *ngIf="manageUserRolesUpdate?.enabled || manageUserRolesDelete?.enabled"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownbutton
          [data]="(manageUserRolesUpdate?.enabled && manageUserRolesDelete?.enabled) 
          ? [{ text: 'Edit' }, { text: 'Delete' }] : manageUserRolesUpdate?.enabled 
          ? [{ text: 'Edit' }] : manageUserRolesDelete?.enabled 
          ? [{ text: 'Delete' }] : []"
          themeColor="primary"
          fillMode="link"
          buttonClass="grid-action-items"
          iconClass="fa-solid fa-ellipsis"
          (itemClick)="itemSelected($event, dataItem)"
          [popupSettings]="{
            appendTo: 'component',
            animate: false,
            width: 250,
            popupClass: 'grid-action-items-menu'
          }"
        >
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>