<h1 mat-dialog-title [innerHTML]="data.title"></h1>
<hr />
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Please enter notes below:</mat-label>
    <textarea matInput [(ngModel)]="data.notes" rows="6"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="d-flex flex-row-reverse">
  <button mat-raised-button color="primary" [mat-dialog-close]="data.notes" cdkFocusInitial>Save</button> 
  <button *ngIf="modified" mat-raised-button class="mr-2" color="accent" (click)="revert()">Undo</button> 
  <button mat-raised-button class="mr-2" [mat-dialog-close]="null">Cancel</button>
</div>