<div class="company-branch-create-modal">
  <form #branchForm="ngForm">
    <div class="title text-center mb-3" *ngIf="!config?.hideModalContent">
      <h4>Add Branch</h4>
      <h6 class="font-weight-normal" *ngIf="data?.companyName" [innerHTML]="data.companyName"></h6>
    </div>
    <mat-card class="mat-elevation-z4">
      <mat-card-header>
        <mat-card-title>Branch Information</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="w-100">
              <mat-label>Branch Name</mat-label>
              <input matInput type="text" appNoWhiteSpace [(ngModel)]="branch.name" [maxLength]="255" name="branchName" #branchName="ngModel" required />
              <mat-error *ngIf="!branchName?.valid && (branchName?.dirty || branchName?.touched) && branchName?.errors?.isSpace" >White spaces are not allowed</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h5 class="font-weight-bold mt-3 mb-1">Address</h5>
            <div class="d-flex" *ngFor="let address of branch.Addresses; let i = index;">
              <app-address [ngModel]="branch.Addresses[i]" [address]="branch.Addresses[i]" [name]="'address' + i" (addressChange)="updateAddress($event, i)"></app-address>
              <div>
                <button mat-icon-button (click)="removeAddress(i)" *ngIf="branch?.Addresses?.length > 1">
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div class="buttons">
              <button mat-flat-button (click)="addAddress()" class="rounded-button adder">
                <mat-icon color="accent">add</mat-icon>
                add address
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 phones">
            <h5 class="font-weight-bold mt-3 mb-1">Phone</h5>
            <div class="d-flex" *ngFor="let phone of branch.Phones; let i = index">
              <app-phone [ngModel]="branch.Phones[i]" [name]="'phone' + i" (change)="this.emitBranchChange()" (isPrimary)="isPrimaryToggle($event, i)"></app-phone>
              <div>
                <button mat-icon-button (click)="removePhone(i)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div class="buttons">
              <button mat-flat-button (click)="addPhone()" class="rounded-button adder">
                <mat-icon color="accent">add</mat-icon>
                add phone
              </button>
            </div>
          </div>
          <div class="col-6 emails">
            <h5 class="font-weight-bold mt-3 mb-1">Email</h5>
            <div class="d-flex" *ngFor="let email of branch.Emails; let i = index;">
              <app-email [(ngModel)]="branch.Emails[i]" [name]="'email' + i" (change)="this.emitBranchChange()"></app-email>
              <div>
                <button mat-icon-button (click)="removeEmail(i)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </div>
            <div class="buttons">
              <button mat-flat-button (click)="addEmail()" class="rounded-button adder">
                <mat-icon color="accent">add</mat-icon>
                add email
              </button>
            </div>
          </div>
        </div>
        <div class="m-2">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Additional Information</mat-label>
            <textarea matInput name="additionalInformation" [maxLength]="255" [(ngModel)]="branch.additionalInformation"></textarea>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end" *ngIf="!config?.hideModalContent">
          <button mat-button (click)="close(false)">
            Cancel
          </button>
          <button mat-button class="blue-action-button" [disabled]="!branchForm.valid" (click)="save()">
            Save
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
