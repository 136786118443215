import { MonoTypeOperatorFunction, pipe, throwError, of, ReplaySubject, Observable } from 'rxjs';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from "@angular/material/snack-bar";
import { NotificationModalComponent } from "./modal-dialog/notification-modal.component";
import { environment } from "src/environments/environment";
import { switchMap, mergeMap, tap, catchError, filter, map } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { KendoModelComponent } from './kendo-model/kendo-model.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService as KendoNotificationService, NotificationRef, NotificationSettings, Type } from "@progress/kendo-angular-notification";

// enum notify_types {
//   default,
//   info,
//   success,
//   wait,
//   error,
//   warning
// }

@Injectable()
export class NotificationsService {

  private configDefaults: MatSnackBarConfig = new MatSnackBarConfig();
  private _registeredForms: NgForm[] = [];

  public get dirtyForms(): boolean {
    // Check if any form controls are dirty. Sometimes the form remains marked as dirty even though all controls are pristine and valid
    return this._registeredForms.some(({ form }) => Object.values(form.controls).some((c) => c.dirty));
  }

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private dialogService: DialogService,
    private kendoNotificationService: KendoNotificationService,
  ) {

    this.configDefaults.verticalPosition = "bottom";
    this.configDefaults.duration = 4000;

    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    ).subscribe(() => {
      // this.dirtyForms = false;
      this._registeredForms = [];
    });
  }

  public registerForm(form: NgForm) {
    this._registeredForms.push(form);
  }

  notify(message: string | TemplateRef<any> | Function, action?: Type, config?: NotificationSettings): NotificationRef | null {
    const defaults: NotificationSettings = {
      content: message,
      hideAfter: environment.notifyTimeoutMs,
      animation: { type: 'fade', duration: 200},
      position: { horizontal: "left", vertical: "bottom" },
      type: {...{ icon: true, style: 'success' }, ...action}
    };
    return this.kendoNotificationService.show({...defaults, ...(config || {}) });
  }

  alert(message: string, title?: string, buttonCaption?: string, width: string = "40%", options?: object): MatDialogRef<NotificationModalComponent> {
    let dialogRef;  // needs to be defined before the options object below for linter

    const dataObj: any = {
      message: message,
      material_ligature: "check_circle",
      buttons: [
        {
          caption: buttonCaption || "Ok",
          callback: () => {
            dialogRef.close(true);
          },
          material_ligature: "check_circle",
          color: "primary"
        }
      ],
      ...options
    };

    // so it's not automatically overridden
    if (title) { dataObj.title = title; }

    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }

    dialogRef = this.dialog.open(NotificationModalComponent, {
      panelClass: "notification",
      width: width || defaultWidth,
      data: dataObj,
    });

    return dialogRef;
  }

  kendoConfirm(
    message: string = 'Are you sure you want to leave this page? Any changes made to the investigation form will not be saved.',
    title: string = "Are you sure?",
		leftBtn: string = 'No',
		rightBtn: string = 'Yes',
    width: number = 600,
		headerIcon: boolean = true,
    subTitle?: string
  ) {

    let defaultWidth = width;
    // if (screen.width <= 768) {
    //   if (width === '40%') {
    //     width = defaultWidth;
    //   }
    // }

    const dialogRef: DialogRef = this.dialogService.open({
      content: KendoModelComponent,
      width: defaultWidth,
      maxWidth: defaultWidth,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const confirmInfo = dialogRef.content.instance as KendoModelComponent;

    confirmInfo.title = title;
    confirmInfo.leftBtn = leftBtn;
    confirmInfo.rightBtn = rightBtn;
    confirmInfo.bodyMessage = message;
    confirmInfo.headerIcon = headerIcon;
    confirmInfo.subTitle = subTitle;

    return dialogRef.result.pipe(map((res) => {
			const res_ = res === true ? true : false;
      return res_;
    }));
  }

  confirm(message: string, title: string = "Are you sure?", buttons?: object[], width: string = "40%", options?: object): MatDialogRef<NotificationModalComponent> {
    let dialogRef;  // needs to be defined before the options object below for linter

    const dataObj: any = {
      title: title,
      message: message,
      material_ligature: "help",
      buttons: buttons || [
        {
          caption: "Yes",
          callback: () => {
            dialogRef.close(true);
          },
          material_ligature: "check_circle",
          color: "primary"
        },
        {
          caption: "No",
          callback: () => {
            dialogRef.close(false);
          },
          material_ligature: "cancel",
          color: "warn"
        }
      ],
      ...options
    };

    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }

    dialogRef = this.dialog.open(NotificationModalComponent, {
      width: width || defaultWidth,
      disableClose: true,
      panelClass: "notification",
      data: dataObj
    });

    return dialogRef;
  }

  error(message: string, title = "Error", options?) {
    return this.alert(message, title, "Ok", null, { material_ligature: "error" });
  }

  snackbarPipe(message: string | TemplateRef<any> | Function, action?: Type, options?: NotificationSettings): MonoTypeOperatorFunction<any> {
    return pipe(
      tap(() => this.notify(message, action, options))
    );
  }

  alertPipe(message: string, title = "Success!", buttonCaption = "Ok", width?, options?): MonoTypeOperatorFunction<any> {
    return pipe(
      tap(() => this.alert(message, title, buttonCaption, width, options))
    );
  }

  resetDirtyFormPipe(predicate: () => boolean): MonoTypeOperatorFunction<any> {
    return pipe(
      filter(predicate),
      tap(() => {
        // this.dirtyForms = false;
        this._registeredForms.forEach(({ form }) => form.markAsPristine());
      })
    );
  }


  catchAlertPipe(message?: string, title = "Uh oh, something went wrong!", buttonCaption = "Ok", width?, options?): MonoTypeOperatorFunction<any> {
    return pipe(
      catchError((err) => {
        console.log("Error:", err);
        this.alert(message || err, title, buttonCaption, width, options);
        return throwError(null);
      }),
      // stop the subscription here
      filter(v => !!v)
    );
  }

  confirmPipe(message = "Are you sure?", title = "Hang on!", buttons?: any[], width?, options?): MonoTypeOperatorFunction<any> {
    return pipe(
      mergeMap((data) => this.confirm(message, title, buttons, width, options).afterClosed().pipe(
        switchMap(result => result ? of(data) : throwError(""))
      ))
    );
  }
}
