import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { NotesModalComponent } from './notes-modal/notes-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Input, forwardRef, Component } from '@angular/core';

@Component({
  selector: 'app-notes',
  templateUrl: "notes.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotesComponent),
      multi: true
    }
  ]
})
export class NotesComponent implements ControlValueAccessor {
  @Input() modalWidth = "45%";
  @Input() title = "Notes:";

  private _originalNotes = "";
  private _notes = "";
  public get notes() {
    return (this._notes || "");
  }
  public set notes(val: string) {
    this._notes = val;
    if (!this._originalNotes) this._originalNotes = this.notes;
  }

  public get modified() {
    return this.notes !== this._originalNotes;
  }

  public get badge() {
    if (this.modified) return "!";
    return this.notes.length ? "1" : false;
  }
  
  public onChange(_: string): any { }
  public onTouch(): any { }
  private _propagateChange(_: any) { }

  constructor(
    private dialog: MatDialog,
  ) { 
  }

  public click() {
    this.dialog.open(NotesModalComponent, {
      data: {
        notes: this.notes,
        original: this._originalNotes,
        title: this.title
      },
      width: this.modalWidth
    }).afterClosed().pipe(
      filter(v => v !== null)
    ).subscribe((notes) => {
      this.notes = notes;
      this._propagateChange(this.notes);
    });
  }

  public writeValue(value: null | string): void {
    this.notes = value;
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }
}
