<section class="mat-typography">
  <!-- Modal Header -->
  <div mat-dialog-title class="header">
    <h2 class="text-center">{{ !data?.id ? 'Create' : 'Update' }} Evidence Storage Location</h2>
  </div>
  <div class="flexContainer">
    <section class="p-3 mt-1">
      <form #form="ngForm">

        <div class="row">
          <!-- name -->
          <div class="col-5">
            <mat-form-field>
              <mat-label> Location Name </mat-label>
              <input appNoWhiteSpace matInput type="text" name="name" [(ngModel)]="model.name" #name="ngModel" required maxlength="255">
              <mat-error *ngIf="!name?.valid && (name?.dirty || name?.touched || model?.name?.length)" >Location Name cannot be left blank</mat-error>
            </mat-form-field>
          </div>
          <!-- type -->
          <div class="col-4">
            <mat-form-field>
              <mat-label> Location Type </mat-label>
              <mat-select name="type" [(value)]="model.type" required>
                <mat-option [value]="locationTypeNames.Transfer">Transfer Locations</mat-option>
                <mat-option [value]="locationTypeNames.Storage">Storage Locations</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- hasDetails - what is this? -->
          <div class="col-3 d-flex align-items-center">
            <mat-checkbox name="hasdetails" [(ngModel)]="model.hasDetails">Has Details (section/row)</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <!-- companyDetails -->
          <div class="col-5">
            <mat-form-field>
              <mat-label> Storage Company Name </mat-label>
              <input appNoWhiteSpace matInput type="text" name="companyName" [(ngModel)]="model.companyName" #companyName="ngModel" placeholder="" required maxlength="255"/>
              <mat-error *ngIf="!companyName?.valid && (companyName?.dirty || companyName?.touched || model?.companyName?.length)" >Storage Company Name cannot be left blank</mat-error>
            </mat-form-field>
          </div>
          <!-- contactName -->
          <div class="col-4">
            <mat-form-field>
              <mat-label> Contact Name </mat-label>
              <input matInput name="contactName" [(ngModel)]="model.contactName" maxlength="50"/>
            </mat-form-field>
          </div>
          <!-- phone -->
          <div class="col-3">
            <mat-form-field>
              <mat-label> Phone </mat-label>
              <input matInput name="phone" [(ngModel)]="model.Phone.number" maxlength="15"/>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- address1 -->
          <div class="col-5">
            <mat-form-field>
              <mat-label> Address 1 </mat-label>
              <input appNoWhiteSpace matInput name="address1" [(ngModel)]="model.Address.address1" #address1="ngModel" required maxlength="255"/>
              <mat-error *ngIf="!address1?.valid && (address1?.dirty || address1?.touched || model?.Address?.address1?.length)" >Address 1 cannot be left blank</mat-error>
            </mat-form-field>
          </div>
          <!-- address2 -->
          <div class="col-6">
            <mat-form-field>
              <mat-label> Address 2 </mat-label>
              <input matInput name="address2" [(ngModel)]="model.Address.address2" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <!-- city -->
          <div class="col-5">
            <mat-form-field>
              <mat-label> City </mat-label>
              <input appNoWhiteSpace matInput name="city" [(ngModel)]="model.Address.city" #city="ngModel" required maxlength="30">
              <mat-error *ngIf="!city?.valid && (city?.dirty || city?.touched || model?.Address?.city?.length)" >City cannot be left blank</mat-error>
            </mat-form-field>
          </div>
          <!-- state -->
          <div class="col-4">
            <app-states-select name="state" [(ngModel)]="model.Address.state" required></app-states-select>
          </div>
          <!-- postal -->
          <div class="col-3">
            <mat-form-field>
              <mat-label> Postal </mat-label>
              <input appNoWhiteSpace matInput name="postal" [(ngModel)]="model.Address.postal" #postal="ngModel" required maxlength="15">
              <mat-error *ngIf="!postal?.valid && (postal?.dirty || postal?.touched || model?.Address?.postal?.length)" >Postal cannot be left blank</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <mat-form-field class="flex-grow-1">
            <mat-label>Additional Details</mat-label>
            <textarea name="additionalDetails" matInput [(ngModel)]="model.additionalDetails"></textarea>
          </mat-form-field>
        </div>
        <div class="d-flex flex-row-reverse">
          <div class="btn-group text-right">
            <button mat-raised-button type="button" (click)="cancel()">Cancel</button> 
            <button mat-raised-button color="primary" type="button" [disabled]="!form.valid" (click)="onSubmit()">{{ data?.id ? 'Update' : 'Add' }}</button>
          </div>
        </div>
      </form>
      <!-- </div> -->
    </section>

  </div>
</section>