<section *ngIf="company">
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companiesUpdateConfig" #companiesUpdateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBillingRulesView" #companiesBillingRulesView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesEligeabilityView" #companiesEligeabilityView="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesRequiredServicesView" #companiesRequiredServicesView="appEnforcePermission"></div>
  <!-- Permissions -->
  <div class="d-flex flex-row-reverse">
    <button mat-icon-button (click)="editCompany()" *ngIf="companiesUpdateConfig?.enabled">
      <mat-icon color="accent">border_color</mat-icon>
    </button>
  </div>
  <!-- Company Information -->
  <mat-card>
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mat-h2 mat-title" [innerHTML]="company.name"></h2>
      </div>
    </div>
    <mat-card-content>
      <div *ngIf="!toggleCompanyInformation" class="toggle-card-container-view">
        <div class="row">
          <div class="col-md-4">
            <p class="mat-small mat-caption">Type:</p>
            <p class="mat-body" [innerHTML]="company?.Type?.name"></p>
          </div>
          <div class="col-md-8">
            <p class="mat-small mat-caption">Description:</p>
            <p class="mat-body" [innerHTML]="company?.description"></p>
          </div>
        </div>
        <p class="mat-small mat-caption">Additional Information:</p>
        <p class="mat-body" [innerHTML]="company.additionalInfo"></p>
        <p class="mat-small mat-caption">Permission to Enter Property Notification:</p>
        <p class="mat-body" [innerHTML]="company.enterPropertyEnabled"></p>
        <p class="mat-small mat-caption">Recorded interview notification:</p>
        <p class="mat-body" [innerHTML]="company.recordedInterviewNotification"></p>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- Billing Information -->
  <mat-card *ngIf="companiesBillingRulesView?.enabled">
    <div class="row">
      <div class="col">
        <h2 class="mat-h2 mat-title">Billing Information</h2>
      </div>
    </div>
    <mat-card-content>
      <div class="toggle-card-container-view">
        <p class="mat-body">Quickbooks List Id: {{ company.qbListId }}</p>
        <div class="row">
          <div class="col-md-4">
            <h3 class="mat-subheading-2">Special Billing Instructions:</h3>
            <p class="mat-body" [innerHTML]="company?.billingInstructions"></p>
          </div>
          <div class="col-md-8">
            <h3 class="mat-subheading-2">Fee Schedule Overrides:</h3>
            <div class="row" *ngFor="let rule of company.BillingRules">
              <div class="col-md-4">
                <p class="mat-small mat-caption">Category:</p>
                <p [innerHTML]="rule?.ExpenseItem?.id ? 'Expense' : 'Hours'"></p>
              </div>
              <div class="col-md-4">
                <p class="mat-small mat-caption">Type:</p>
                <p [innerHTML]="rule?.ExpenseItem?.id ? rule?.ExpenseItem?.name : rule?.TimeEntryType?.name"></p>
              </div>
              <div class="col-md-4">
                <p class="mat-small mat-caption">Rate:</p>
                <p [innerHTML]="rule.value | currency: 'USD'"></p>
              </div>
            </div>
            <h3 class="mat-subheading-2">Flat Rates for Standard Investigations:</h3>
            <div class="row" *ngFor="let flatRate of company.FlatRates">
              <div class="col-md-4">
                <p class="mat-small mat-caption">Risk Type:</p>
                <p [innerHTML]="flatRate?.Risk?.name"></p>
              </div>
              <div class="col-md-4">
                <p class="mat-small mat-caption">Rate:</p>
                <p [innerHTML]="flatRate.value | currency: 'USD'"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <!-- Eligibility Information -->
  <mat-card *ngIf="companiesEligeabilityView?.enabled">
    <h2 class="mat-h2 mat-title">Eligibility Information</h2>
    <div class="row">
      <div class="col-md-6">
        <h3 class="mat-subheading-2">Certifications required by this company:</h3>
        <mat-list>
          <mat-list-item *ngFor="let cert of company.RequiredCertifications" [innerHTML]="cert.title"></mat-list-item>
        </mat-list>
      </div>
      <div class="col-md-6">
        <h3 class="mat-subheading-2">Staff excluded from assginment:</h3>
        <mat-list>
          <mat-list-item *ngFor="let staff of company.ExcludedStaff" [innerHTML]="staff | fullName"></mat-list-item>
        </mat-list>
      </div>
    </div>
  </mat-card>
  <!-- Services -->
  <mat-card *ngIf="companiesRequiredServicesView?.enabled">
    <div class="row align-items-center">
      <div class="col">
        <h2 class="mat-h2 mat-title">Services</h2>
      </div>
    </div>
    <mat-card-content>
      <h3 class="mat-subheading-2">Services required by this company:</h3>
      <mat-list>
        <mat-list-item *ngFor="let service of company.RequiredServices" [innerHTML]="service.name"></mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <!-- Main Office -->
  <mat-card>
    <div class="row">
      <div class="col">
        <h2 class="mat-h2 mat-title">Main Office:</h2>
        <h2 class="mat-h2 mat-title" [innerHTML]="company.MainOffice?.name"></h2>
      </div>
    </div>
    <mat-card-content>
      <div class="row">
        <div class="col-md-6">
          <p class="mat-small mat-caption">Address:</p>
          <app-address-view *ngFor="let address of company.MainOffice?.Addresses" [address]="address"></app-address-view>
        </div>
        <div class="col-md-3">
          <p class="mat-small mat-caption">Phone:</p>
          <app-email-phone-view *ngFor="let phone of company.MainOffice?.Phones" [phone]="phone"></app-email-phone-view>
        </div>
        <div class="col-md-3">
          <p class="mat-small mat-caption">Email:</p>
          <app-email-phone-view *ngFor="let email of company.MainOffice?.Emails" [email]="email"></app-email-phone-view>
        </div>
      </div>
      <mat-card *ngFor="let assignment of company.MainOffice?.ContactAssignments">
        <mat-card-content>
          <div class="office-details-container">
            <div class="office-details">
              <div class="row">
                <div class="col-md-3">
                  <p class="mat-small mat-caption">Contact:</p>
                  <p class="mat-body">
                    <a [routerLink]="['/contacts', assignment?.Contact?.id]" [innerHTML]="assignment?.Contact | fullName"></a>
                  </p>
                </div>
                <div class="col-md-3">
                  <p class="mat-small mat-caption">Role:</p>
                  <p class="mat-body" [innerHTML]="assignment?.Contact?.Role?.name"></p>
                </div>
                <div class="col-md-3">
                  <p class="mat-small mat-caption">Phones:</p>
                  <app-email-phone-view *ngFor="let phone of assignment?.Contact?.Phones" [phone]="phone"></app-email-phone-view>
                </div>
                <div class="col-md-3">
                  <p class="mat-small mat-caption">Emails:</p>
                  <app-email-phone-view *ngFor="let email of assignment?.Contact?.Emails" [email]="email"></app-email-phone-view>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <p class="mat-small mat-caption">Start Date:</p>
                  <p class="mat-body" [innerHTML]="assignment?.startDate | customDate: 'MM/dd/yyyy'"></p>
                </div>
                <div class="col-md-3">
                  <p class="mat-small mat-caption">End Date:</p>
                  <p class="mat-body" [innerHTML]="assignment.endDate ? (assignment.endDate | customDate: 'MM/dd/yyyy') : 'N/A'"></p>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</section>
