import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IApiRiskType } from '../../modules/graphql/types/types';
import { RiskTypesService } from '../../services';

@Component({
  selector: 'app-risk-type-multi-select-kendo',
  templateUrl: './risk-type-multi-select-kendo.component.html',
  styleUrls: ['./risk-type-multi-select-kendo.component.scss']
})
export class RiskTypeMultiSelectKendoComponent implements OnInit, OnChanges {

  @Input() placeholder = "Select type";
  @Input() label = "Risk Type";
  @Input() disabled = false;
  @Input() selectedId: Array<string> = [];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selectedValue = [];
  public riskTypes: IApiRiskType[] = [];
 
  constructor(
    private riskTypeService: RiskTypesService
  ) {}

  ngOnInit(): void {
    this.riskTypeService.riskTypes().subscribe((types) => this.riskTypes = types);
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  public ngOnChanges() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  public searchSelect($event) {
    this.selectedId = $event;
    this.selectionChanged.emit(this.selectedId);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
