import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select/select';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { ContactDataSource } from 'src/app/shared/services/contact/contact.datasource';
import { AuthService, ContactRoleService, ContactService } from 'src/app/shared/services';
import {
  IApiCompany, IApiContactConnection, IApiContactFilterType, IApiContactRole, IApiContactRoleFilterType
} from 'src/app/shared/modules/graphql/types/types';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdateContactComponent } from '../create-update-contact/create-update-contact.component';
import {
  contactsProfileContactInformationViewAssigned,
  contactsProfileCompanyInformationView,
  contactsInvestigations,
  contactsCreateConfig,
  contactsDelete,
} from "src/app/shared/helpers/auth-config/contacts.config";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { take } from 'rxjs/operators';
import { KendoModelComponent } from 'src/app/shared/modules/notifications/kendo-model/kendo-model.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { filter } from 'lodash';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

export interface IContactListConfig {
  hideLists?: boolean;
  companyId?: string;
  hideAddContact?: boolean;
}

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})

export class ContactsListComponent implements OnInit {

  public authConfig = {
    contactsProfileContactInformationViewAssigned,
    contactsProfileCompanyInformationView,
    contactsInvestigations,
    contactsCreateConfig,
    contactsDelete,
  };

  @Input() config: IContactListConfig = {
    hideLists: false
  };

  public search = "";
  public firstName = "";
  public lastName = "";

  public get mutateView(): boolean {
    return this.config.hideLists && this.config.hideAddContact;
  }

  // Select data
  public positions: IApiContactRole[] = [];

  public companies: IApiCompany[] = [];
  public oldFilter: any[] = [];

  public columnsToDisplay = ["name", "position", "phone", "email", "branches"];

  private _dataSource: ContactDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  constructor(
    private contactService: ContactService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private contactRoleService: ContactRoleService,
    private dialogService: DialogService,
    private notification: NotificationsService,
    public auth: AuthService
  ) { }

  // add contact modal
  public addContactModal() {
    this.oldFilter = [...this.dataSource.lastFilters];
    this.dialog.open(CreateUpdateContactComponent, {
      width: "80%",
      height: "90%"
    }).afterClosed().subscribe((res) => {
      if (this.oldFilter && this.oldFilter?.length > 0) {
        this.oldFilter.map((obj) => {
          this.dataSource.applyFilter(obj?.type, obj?.value);
        });
        this.oldFilter = [];
      }
      if (res) {
        this.updateTable(res);
      }
    });
  }

  public updateTable(a) {
    this.dataSource?.contents$?.pipe(take(1)).subscribe((res) => {
      res.pop();
      res.unshift(a);
      this.dataSource.data = res;
      this.dataSource.updateTotalCount(this.dataSource.listPage.totalCount + 1);
    })
  }

  ngOnInit() {
    this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection()
    ).subscribe(data => this.positions = data.filter(({ isPrivate }) => !isPrivate));

    // Init table
    this._dataSource = new ContactDataSource(this.contactService);
    this.loader.attachObservable(this._dataSource.loading$);
    this.dataSource.applyFilter(IApiContactFilterType.ViewListView, "true");
    if (this.config.companyId) this.dataSource.applyFilter(IApiContactFilterType.Company, this.config.companyId);
    this.auth.hasCategoryPermission(contactsDelete.category, contactsDelete.appliedPermissions).subscribe((response) =>{
      if(response === true){
        this.columnsToDisplay.push('actions');
      }
    })
    this.load();
  }

  public onActivityChange(event: MatButtonToggleChange) {
    this.dataSource.applyFilter(IApiContactFilterType.IsActive, event.value);
    this.load();
  }

  public onSearchChange() {
    this.dataSource.applyFilter(IApiContactFilterType.Search, this.search || null);
    this.load();
  }

  public onSearchFirstNameChange() {
    this.dataSource.applyFilter(IApiContactFilterType.FirstName, this.firstName || null);
    this.load();
  }

  public onSearchLastNameChange() {
    this.dataSource.applyFilter(IApiContactFilterType.LastName, this.lastName || null);
    this.load();
  }

  public onCompanyChange(company?: IApiCompany) {
    this.dataSource.applyFilter(IApiContactFilterType.Company, company?.id);
    this.load();
  }

  public onPositionChange(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiContactFilterType.Position, event.value);
    this.load();
  }

  public load(): Promise<IApiContactConnection> {
    this.dataSource.pagingReset();
    return this.dataSource.load();
  }

  public removeContact(contact): void {
    const dialog: DialogRef = this.dialogService.open({
      content: KendoModelComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as KendoModelComponent;
    dialogInstance.title = 'Delete Contact';
    dialogInstance.bodyMessage = `Are you sure you want to delete this <b>${contact?.lastName ? (contact?.lastName + ', ' + contact?.firstName) : contact?.firstName}</b> contact?`
    dialog.result.subscribe((result: DialogCloseResult) => {
      if(result === true){
        this.loader.show$(this.contactService.remove({id: contact?.id}))
        .subscribe(res => {
          this.load();
        }, (err) => {
          if (err?.message?.includes('CONTACT_HAS_ASSIGNED_INVESTIGATION')) {
            this.notification.error(`${contact?.lastName ? (contact?.lastName + ', ' + contact?.firstName) : contact?.firstName} is attached to one or more investigation case records.`, "Can't delete contact!");
          }
        });
      }
    })
  }

}
