import { Component, Output, EventEmitter, Input, OnDestroy, forwardRef } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NullableTimePipe } from '../../pipes/date.pipe';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true
    }
  ]
})
export class TimepickerComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() disabled: boolean;
  @Output() timeSelected = new EventEmitter<string>();
  @Input() set lossTime(lossTimeInput) {
    this.displayTime = this._timePipe.transform(lossTimeInput, 'h:mm A', 'HH:mm:ss');
    this._lossTime = lossTimeInput;
  }
  get lossTime() {
    return this._lossTime;
  }
  
  private _appTimeSavingFormat = "HH:mm:ss";
  private _lossTime;
  public nefcoTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#020e26',
      buttonColor: '#90bbfe'
    },
    dial: {
      dialBackgroundColor: '#020e26',
    },
    clockFace: {
      clockFaceBackgroundColor: '#020e26',
      clockHandColor: '#969696',
      clockFaceTimeInactiveColor: '#90bbfe'
    }
  };

  public displayTime;

  constructor(private _timePipe: NullableTimePipe) { }

  public modelChange(input) {
    this.lossTime = this._timePipe.transform(input);
    this.timeSelected.emit(this._lossTime);
    this._propagateChange(this._lossTime);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.lossTime = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

}
