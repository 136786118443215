import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanyTypes } from 'src/app/shared/modules/graphql/enums/company.enums';
import { StateService } from 'src/app/shared/services/state.service';
import { ActivatedRoute } from '@angular/router';
import { IApiCompany } from 'src/app/shared/modules/graphql/types/types';
import { CompanyCreateUpdateComponent, ICompanyCreateUpdateData } from '../company-create-update/company-create-update.component';
import { filter } from 'rxjs/operators';
import {
  companiesUpdateConfig,
  companiesBillingRulesView,
  companiesEligeabilityView,
  companiesRequiredServicesView,
} from "src/app/shared/helpers/auth-config/companies.config";

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent {
  public authConfig = {
    companiesUpdateConfig,
    companiesBillingRulesView,
    companiesEligeabilityView,
    companiesRequiredServicesView
  };

  @Input() company: IApiCompany;
  @Output() reload = new EventEmitter<boolean>();
  public CompanyTypes = CompanyTypes;

  // TOGGLE FLAGS
  public toggleCompanyInformation = false;
  public toggleBillingInstructions = false;
  public toggleEligibility = false;
  public toggleServices = false;
  public toggleAddresses = false;

  constructor(
    public dialog: MatDialog,
    public stateService: StateService,
    private route: ActivatedRoute
  ) { }

  public editCompany() {
    const data: ICompanyCreateUpdateData = {
      companyId: this.company.id
    };

    this.dialog.open(CompanyCreateUpdateComponent, {
      width: "80%",
      height: "100vh",
      data
    }).afterClosed().pipe(
      filter((v) => !!v)
    ).subscribe(() => this.reload.emit(true));
  
  }

}
