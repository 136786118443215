<!-- Permissions -->
<div [appEnforcePermission]="permissionConfig.contactsProfileCompanyInformationView" #contactsProfileCompanyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileContactInformationViewAssigned" #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileContactInformationUpdate" #contactsProfileContactInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileCompanyInformationUpdate" #contactsProfileCompanyInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.companiesViewAll" #companiesViewAll="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.companiesBranchesView" #companiesBranchesView="appEnforcePermission"></div>
<!-- Permissions -->
<section class="mat-typography">
    <div class="d-flex flex-row-reverse" *ngIf="contactsProfileContactInformationUpdate?.enabled || contactsProfileCompanyInformationUpdate?.enabled">
        <button mat-icon-button (click)="editContact()">
            <mat-icon color="accent">border_color</mat-icon>
        </button>
    </div>
    <!-- Contact Information -->
    <mat-card *ngIf="contactsProfileContactInformationViewAssigned?.enabled">
        <div class="flexContainer">
            <h2 class="mat-h2 mat-title">Contact Information</h2>
        </div>
        <!-- View Info -->
        <mat-card-content>
            <ng-container>
                <div class="flexContainer">
                    <div class="equal" *ngIf="contact?.insuredName">
                        <p class="mat-small mat-caption">Insured Name:</p>
                        <p class="mat-body mat-body-1" [innerHTML]="contact.insuredName"></p>
                    </div>
                    <div class="equal">
                        <p class="mat-small mat-caption">Name:</p>
                        <p class="mat-body mat-body-1" [innerHTML]="contact | fullName"></p>
                    </div>
                    <div class="third">
                        <p class="mat-small mat-caption">Role:</p>
                        <div class="flexContainer roleContainer">
                            <p class="mat-body mat-body-1 equal" [innerHTML]="contact.Role.name"></p>
                        </div>
                    </div>
                    <div class="equal">
                        <p class="mat-small mat-caption">Phones:</p>
                        <app-email-phone-view *ngFor="let phone of contact.Phones" [phone]="phone"></app-email-phone-view>
                    </div>
                    <div class="equal">
                        <p class="mat-small mat-caption">Emails:</p>
                        <app-email-phone-view *ngFor="let email of contact.Emails" [email]="email"></app-email-phone-view>
                    </div>
                    <div class="equal">
                        <p class="mat-small mat-caption">Addresses:</p>
                        <app-address-view *ngFor="let address of contact.Addresses" [address]="address"></app-address-view>
                    </div>
                </div>
                <p class="mat-small mat-caption">Quickbooks List Id </p>
                <p class="mat-body mat-body-1" [innerHTML]="contact.qbListId"></p>
                <p class="mat-small mat-caption">Additional Information:</p>
                <p class="mat-body mat-body-1" [innerHTML]="contact.additionalInformation"></p>
            </ng-container>
        </mat-card-content>
    </mat-card>
    <!-- Branch Assignments -->
    <mat-card *ngIf="contactsProfileCompanyInformationView?.enabled">
        <div class="flexContainer">
            <h2 class="mat-h2 mat-title">Company Information</h2>
        </div>
        <mat-card-content>
            <!-- Loop over all companies associated -->
            <mat-card *ngFor="let branch of contact.BranchAssignments | sortByDate: 'endDate';">
                <mat-card-content>
                    <div class="flexContainer">
                        <div class="flex9">
                            <div class="flexContainer">
                                <div class="equal">
                                    <p class="mat-small mat-caption">Company Name</p>
                                    <p><a [routerLink]="['/admin', 'companies', 'management', branch?.Branch?.Company?.id]" [queryParams]="{tab: 'company'}" *ngIf="companiesViewAll?.enabled else companyView"> {{ branch?.Branch?.Company?.name }} </a></p>
                                    <ng-template #companyView>
                                        {{ branch?.Branch?.Company?.name }}
                                    </ng-template>
                                </div>
                                <div class="equal">
                                    <p class="mat-small mat-caption">Branch</p>
                                    <p><a [routerLink]="['/admin', 'companies', 'management', branch?.Branch?.Company?.id]" [queryParams]="{tab: 'branches', branchId: branch.Branch.id}" *ngIf="companiesBranchesView?.enabled else branchView"> {{ branch.Branch.name }} </a></p>
                                    <ng-template #branchView>
                                        {{branch?.Branch?.name}}
                                    </ng-template>
                                </div>
                                <div class="equal">
                                    <p class="mat-small mat-caption">Primary Address: ({{ getPrimary(branch.Branch.Addresses)?.Type?.name }})</p>
                                    <app-address-view [address]="getPrimary(branch.Branch.Addresses)"></app-address-view>
                                </div>
                                <div class="equal">
                                    <!-- here for alignment -->
                                </div>
                            </div>
                            <div class="flexContainer">
                                <div class="equal">
                                    <p class="mat-small mat-caption">Primary Phone</p>
                                    <app-email-phone-view [phone]="getPrimary(branch.Branch.Phones)"></app-email-phone-view>
                                </div>
                                <div class="equal">
                                    <p class="mat-small mat-caption">Primary Email</p>
                                    <app-email-phone-view [email]="getPrimary(branch.Branch.Emails)"></app-email-phone-view>
                                </div>
                                <div class="equal">
                                    <p class="mat-small mat-caption">Start Date</p>
                                    <p [innerHTML]="branch.startDate | customDate: 'MM/dd/yyyy'"> </p>
                                </div>
                                <div class="equal">
                                    <p class="mat-small mat-caption">End Date</p>
                                    <p [innerHTML]="branch.endDate? (branch.endDate | customDate: 'MM/dd/yyyy') : 'N/A'"></p>
                                </div>
                            </div>
                        </div>
                        <div class="equal alignRight">
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-card-content>
    </mat-card>
</section>
