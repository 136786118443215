<section class="report-matric-filters">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']">
        <kendo-gridlayout-item>
            <div class="kendo-formfield date-range-wrapper">
                <kendo-daterange #dateRangePicker >
                    <kendo-label text="Received Date (Start)" class="mr-16">
                      <kendo-dateinput #startDateRange #startDateInput="ngModel" name="startDateInput" calendarType="classic" class="dateinput width100" placeholder="Start Date"
                      [fillMode]="'outline'" kendoDateRangeStartInput [(ngModel)]="range.startDate" (blur)="updateDateFilters()" (ngModelChange)="updateDateFilters()"
                      (click)="focusDateInput(true)" ></kendo-dateinput>
                    </kendo-label>
                    <kendo-label text="Received Date (End)">
                      <kendo-dateinput #endDateRange #endDateInput="ngModel" name="endDateInput" calendarType="classic" class="mr-0 dateinput width100" placeholder="End Date"
                      [fillMode]="'outline'" kendoDateRangeEndInput [(ngModel)]="range.endDate" (blur)="updateDateFilters()" (ngModelChange)="updateDateFilters()"
                      (click)="focusDateInput(false)"></kendo-dateinput>
                    </kendo-label>
                    <kendo-daterange-popup #dateRangePickerPopup autoCorrectOn="blur"></kendo-daterange-popup>
                </kendo-daterange>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
            <app-company-select-kendo
            label="Client (Company)"
            placeholder="Search Client (Company)"
            [selectedId]="filterByIdValue(filterTypes.Client)"
            (selectionChanged)="setFilters($event, filterTypes.Client)"
            ></app-company-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-contact-multi-select-kendo
          placeholder="Select contact"
          label="Client (Contact)"
          [selectedId]="filterValue(filterTypes.ClientContact)"
          (selectionChanged)="setFilters($event, filterTypes.ClientContact)"
          >
          </app-contact-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-state-multi-select-kendo
          placeholder="Select state"
          label="Loss State"
          [selectedId]="filterValue(filterTypes.LossState)"
          (selectionChanged)="setFilters($event, filterTypes.LossState)"
          >
          </app-state-multi-select-kendo>
        </kendo-gridlayout-item>
    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(4,minmax(0,1fr))']" *ngIf="showAllFilters">
        <kendo-gridlayout-item>
          <app-company-select-kendo
          label="Bill To (Company)"
          placeholder="Select bill to"
          [selectedId]="filterByIdValue(filterTypes.BillToCompany)"
          (selectionChanged)="setFilters($event, filterTypes.BillToCompany)"
          ></app-company-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-user-multi-select-kendo
          label="Investigator"
          [userViewFilter]="userViewFilter"
          placeholder="Select investigator"
          [selectedId]="filterValue(filterTypes.Investigator)"
          [investigationRole]="userRoleName.Investigator"
          [includeInactive]="true"
          (selectionChanged)="setFilters($event, filterTypes.Investigator)"
          ></app-user-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-user-multi-select-kendo
          label="Tech Reviewer"
          [userViewFilter]="userViewFilter"
          placeholder="Select reviewer"
          [selectedId]="filterValue(filterTypes.TechReviewer)"
          [investigationRole]="userRoleName.TechReviewer"
          [includeInactive]="true"
          (selectionChanged)="setFilters($event, filterTypes.TechReviewer)"
          ></app-user-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-causes-multi-select-kendo
          placeholder="Select cause"
          label="Cause of Fire"
          [selectedId]="filterValue(filterTypes.CauseOfFire)"
          (selectionChanged)="setFilters($event, filterTypes.CauseOfFire)"
          >
          </app-causes-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <app-risk-type-multi-select-kendo
          placeholder="Select type"
          label="Risk Type"
          [selectedId]="filterValue(filterTypes.RiskType)"
          (selectionChanged)="setFilters($event, filterTypes.RiskType)"
          >
          </app-risk-type-multi-select-kendo>
        </kendo-gridlayout-item>


        <kendo-gridlayout-item>
          <div class="kendo-formfield">
              <kendo-label text="View By"></kendo-label>
              <kendo-dropdownlist
                [data]="[{text: 'Active Investigations', value: 'ACTIVE'}, {text: 'Closed Investigations', value: 'CLOSED'}]"
                textField="text" valueField="value" fillMode="outline" [valuePrimitive]="true"
                [defaultItem]="{text: 'All Investigations', value: null }" [value]="filterValue(filterTypes.ViewBy)"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
                (selectionChange)="setFilters($event?.value, filterTypes.ViewBy)">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem.text }} </span>
                  <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem.text }} </span>
                </ng-template>
              </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item *ngIf="investigationFilter">
          <app-investigation-multi-select-kendo
            label="Investigation ID"
            placeholder="Search by nefco number"
            [selectedIds]="filterByIdValue(filterTypes.Ids)"
            (selectionChanged)="setVal($event, filterTypes.Ids)"
          ></app-investigation-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item *ngIf="editorFilter">
          <app-user-multi-select-kendo
          label="Editor"
          [userViewFilter]="userViewFilter"
          placeholder="Select editor"
          [selectedId]="filterValue(filterTypes.Editor)"
          [investigationRole]="userRoleName.Editor"
          [includeInactive]="true"
          (selectionChanged)="setFilters($event, filterTypes.Editor)"
          ></app-user-multi-select-kendo>
        </kendo-gridlayout-item>

    </kendo-gridlayout>

    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']" style="margin-top: -16px;">
      <kendo-gridlayout-item>
        <div class="toggle-filters">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="base" fillMode="solid" size="medium" iconClass="fa-solid fa-filter"
              (click)="showAllFilters = !showAllFilters">{{showAllFilters ? 'Hide Filters' : 'Show All Filters'}} </kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="filter-actions">
        <div class="mr-16 applied-filter">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="appliedFilters()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="mt-5 clear-filter">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
</section>
