<table mat-table [dataSource]="comments">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Date/Time </th>
    <td mat-cell *matCellDef="let comment;">{{ comment.createdAt | customDate: 'MM/dd/yyyy, h:mm a' }}</td>
  </ng-container>
  <ng-container matColumnDef="comment">
    <th mat-header-cell *matHeaderCellDef class="font-weight-bold"> Comment </th>
    <td mat-cell *matCellDef="let comment;">{{ comment.text}}</td>
  </ng-container>
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef class="font-weight-bold text-left"> User </th>
    <td class="text-left" mat-cell *matCellDef="let comment;">{{ comment.CreatedBy | fullName }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="commentLogColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: commentLogColumns;"></tr>
</table>