import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent {

  public get modified(): boolean {
    return this.data.notes !== this.data.original;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { notes: string, title: string, original: string }
  ) { }

  public revert() {
    this.data.notes = this.data.original;
  }

}
