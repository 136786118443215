import { IApiContact, IApiContactFilterType, IApiContactOrderBy, IApiContactRole, IApiInvestigationRoleNames } from './../../modules/graphql/types/types';
import { map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { StandardNamePipe } from './../../pipes/name.pipe';
import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ContactService, UserService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IApiUser } from "../../modules/graphql/types/types";
import { unwrapConnection } from '../../rxjs.pipes';
import { SortOrder } from '../../modules/graphql/enums/generic.enums';
import {
  contacts
} from "src/app/shared/helpers/auth-config/contacts.config";
@Component({
  selector: 'app-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactSelectComponent),
      multi: true
    }
  ]
})
export class ContactSelectComponent implements OnInit, ControlValueAccessor {
  public authConfig = {
    contacts
  };

  @Input() placeholder = "Search for a Contact";
  @Input() label = "";
  @Input() staticContacts: IApiContact[];
  @Input() contactRole: IApiContactRole = null;
  @Input() disabled = false;

  @Input() contactId: string = null;
  public contacts: IApiContact[] = [];

  @Output() selectionChanged = new EventEmitter<IApiContact | null>();

  public selected: IApiContact = null;

  @Input() public set user(val: IApiContact) {
    this.select(val);
  }

  constructor(
    // private userService: UserService,
    private contactService: ContactService,
    private fullNamePipe: StandardNamePipe,
    private loader: LoaderService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    if (this.contactId) {
      // this.contactService.get([], { sortOrder: SortOrder.DESC, orderBy: IApiContactOrderBy.FullName }).pipe().subscribe((contacts) => {})
      this.contactService.getById(this.contactId).pipe(
        this.notifications.catchAlertPipe(`Error loading user: ${this.contactId}`)
      ).subscribe((contact) => {
        this.contacts = [contact];
        this.selected = contact;
      });
    }
    if (this.staticContacts) this.contacts.push(...this.staticContacts);
  }

  public select(selected: IApiContact | null) {
    this.selected = selected;
    this.selectionChanged.emit(selected);
    this._propagateChange(this.selected);
  }

  public onSearchChange(search: string) {
    this.contacts = [];
    if (search?.length < 3) return;

    const filters = [{
      type: IApiContactFilterType.Search,
      value: search
    }];

    if (this.contactRole) filters.push({
      type: IApiContactFilterType.Position,
      value: this.contactRole.toString()
    });

    this.loader.show$(
      this.contactService.get(filters).pipe(
        unwrapConnection(),
        map(results => {
          if (this.staticContacts) results.push(...this.staticContacts);
          return results;
        })
      )
    ).subscribe(results => this.contacts = results);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.contactId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

  public selectedDisplay(val: IApiUser) {
    return this.fullNamePipe.transform(val);
  }

  public closed() {
    if (typeof this.selected === "string") this.select(null);
  }

  public blur() {
    if (typeof this.selected === "string" && (this.selected as string).length < 3) this.select(null);
  }

}
