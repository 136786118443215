import { Component, Input } from '@angular/core';
import { IApiEmail, IApiPhone } from '../../modules/graphql/types/types';

@Component({
  selector: 'app-email-phone-view',
  templateUrl: './email-phone-view.component.html',
  styleUrls: ['./email-phone-view.component.scss']
})
export class EmailPhoneViewComponent {

  @Input() email: IApiEmail;
  @Input() phone: IApiPhone;

  constructor() { }

}
