import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { TimeEntryType } from './../../../../shared/modules/graphql/interfaces/time-entry.interfaces';
import { ExpenseCategory } from './../../../../shared/modules/graphql/interfaces/expense.interfaces';
import { TimeEntryTypeService } from './../../../../shared/services/time-entry/time-entry-type/time-entry-type.service';
import { ExpenseCategoryService } from './../../../../shared/services/expenses/expense-category/expense-category.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { IApiExpenseType, IApiInvoiceableTypeTypes, IApiInvoiceItem, IApiInvoiceLine, IApiInvoiceItemInput, IApiTimeEntry, IApiExpense, IApiInvoiceFilterType, IApiInvoiceOrderBy, IApiInvoice, IApiExpenseItem, IApiTimeEntryType } from './../../../../shared/modules/graphql/types/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { IApiInvestigation } from "src/app/shared/modules/graphql/types/types";
import { ExpenseItemService, InvestigationService, InvoiceService } from "src/app/shared/services";
import { FormExpenseType } from '../investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface IQbInvoiceLine extends Partial<IApiInvoiceLine> {
  ExpenseType?: Partial<IApiExpenseType>;
  selected?: boolean;
  qbAccount?: string;
  id?: string;
}

interface IQbInvoiceItemUsers {
  userId: string;
  invoiceableId: string;
}

@Component({
  selector: 'app-investigation-time-and-exp-quickbooks-modal',
  templateUrl: './investigation-time-and-exp-quickbooks-modal.component.html',
  styleUrls: ['./investigation-time-and-exp-quickbooks-modal.component.scss']
})
export class InvestigationTimeAndExpQuickbooksModalComponent implements OnInit {

  public formExpenseType: typeof FormExpenseType = FormExpenseType;

  public dataSource = new MatTableDataSource();
  public qbBillColumns = ["date", "staff", "description", "qty", "type", "qbAccount", "rate", "total"];
  public invoiceLines: IQbInvoiceLine[] = [];
  private invoiceItems: IApiInvoiceItemInput[] = [];
  private invoiceItemUsers: IQbInvoiceItemUsers[] = [];
  public details = "";
  public invoiceNumber = "";

  public expenseItems: IApiExpenseItem[] = [];
  public timeEntryTypes: TimeEntryType[] = [];
  public invoices: IApiInvoice[] = [];

  public get total(): number {
    return this.invoiceLines.reduce((prev, curr) => {
      prev += curr.quantity * curr.rate;
      return prev;
    }, 0);
  }

  public get selectedLines(): IQbInvoiceLine[] {
    return this.invoiceLines.filter(({ selected }) => selected);
  }

  public get investigation(): IApiInvestigation {
    return this.data?.investigation || null;
  }

  public get qbAccounts(): string[] {
    return [...new Set([
      ...this.timeEntryTypes,
      ...this.expenseItems
    ].map(({ qbAccount }) => qbAccount).filter(v => !!v).sort((a, b) => a < b ? -1 : 1)
    )];
  }

  @ViewChild('dataTable') table: MatTable<IQbInvoiceLine>;

  // NOTE: For the click and drag to work on the modal, you need to scroll to the top in the parent component
  // this.viewport.scrollToPosition([0, 0])
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private notificationService: NotificationsService,
    private invoiceService: InvoiceService,
    private investigationService: InvestigationService,
    private loader: LoaderService,
    private expenseItemService: ExpenseItemService,
    private timeTypes: TimeEntryTypeService,
    private dialogRef: MatDialogRef<InvestigationTimeAndExpQuickbooksModalComponent>,
  ) {
  }

  async ngOnInit() {
    await Promise.all([
      this.timeTypes.get().pipe(
        unwrapConnection()
      ).toPromise(),
      this.expenseItemService.get().pipe(
        unwrapConnection()
      ).toPromise(),
      this.invoiceService.get([{ type: IApiInvoiceFilterType.Investigation, value: this.investigation?.id ? this.investigation?.id : null }], { limit: -1 }).pipe(
        unwrapConnection()
      ).toPromise()
    ]).then(([timeEntryTypes, expenseItems, invoices]) => {
      this.timeEntryTypes = timeEntryTypes;
      this.expenseItems = expenseItems;
      this.invoices = invoices;
    });

    if (!this.data.disableEdit && !this.data.invoice) {
      this.qbBillColumns.unshift("checkbox");
    }

    if (!this.data.invoice && this.investigation) {

      // Set default Invoice#
      const charList = '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      // Seach for last invoice that uses NEFCO number format
      const lastInvoiceNum = this.invoices.filter((obj) => obj.invoiceNumber.includes(this.investigation?.nefcoNumber)).map((obj) => obj.invoiceNumber).sort().slice(-1);
      const lastInvCharIndex = lastInvoiceNum.length ? charList.indexOf(lastInvoiceNum[0].slice(-1)) : -1;
      const nextChar = (lastInvoiceNum.length && lastInvCharIndex !== -1) ? charList[lastInvCharIndex + 1] : charList[0];
      // If end of list or char not found, set default to "custom-num"
      this.invoiceNumber = (lastInvoiceNum.length && lastInvCharIndex === -1) || !nextChar ? `CUSTOM-NUM` : `${this.investigation?.nefcoNumber}${nextChar}`;

      // use this to filter out items that should not be added as invoice lines (already pending or invoiced)
      const itemFilter = ({ InvoiceItems }: any) => !InvoiceItems.length;
      const itemBuilder = <T>(invoiceableType: IApiInvoiceableTypeTypes, _data: T | any) => {
        // Build Item arry
        this.invoiceItems.push({
          invoiceableId: _data.id,
          invoiceableType
        });

        // Build User array
        this.invoiceItemUsers.push({
          userId: _data.User.id,
          invoiceableId: _data.id
        });

        return _data;
      };

      // Time Entries
      this.invoiceLines = this.investigation.TimeEntries
        .filter(itemFilter)
        .map(item => itemBuilder<IApiTimeEntry>(IApiInvoiceableTypeTypes.Hours, item))
        .map(({ id, hours, description, workday, Type, User }) => ({
          quantity: Type.isFlatRate ? 1 : hours,
          rate: this.findRate(Type),
          description: (`${description}`).slice(0, 4000),
          ExpenseType: { name: this.formExpenseType.HOURS },
          qbAccount: Type.qbAccount,
          staffNames: `${User.firstName} ${User.lastName}`,
          createdAt: workday,
          id
        }));

      // Mileage
      this.invoiceLines.push(
        ...[
          ...this.investigation.Mileage
        ].filter(itemFilter)
          .map(item => itemBuilder<IApiExpense>(IApiInvoiceableTypeTypes.Miles, item))
          .map(({ id, billableQuantity, description, expenseDate, ExpenseItem, User }) => {

            const foundRate = this.findRate(ExpenseItem);

            return ({
              quantity: ExpenseItem.isFlatRate ? 1 : (foundRate === 1 ? foundRate : billableQuantity),
              rate: (foundRate === 1 && !ExpenseItem.isFlatRate) ? billableQuantity : foundRate,
              description: (`${description}`).slice(0, 4000),
              ExpenseType: { name: this.formExpenseType.MILEAGE },
              qbAccount: ExpenseItem?.qbAccount,
              staffNames: `${User.firstName} ${User.lastName}`,
              createdAt: expenseDate,
              id
            });
          })
      );

      // Expense
      this.invoiceLines.push(
        ...[
          ...this.investigation.Expenses
        ].filter(itemFilter)
          .map(item => itemBuilder<IApiExpense>(IApiInvoiceableTypeTypes.Expense, item))
          .map(({ id, billableQuantity, description, expenseDate, ExpenseItem, User }) => {

            const foundRate = this.findRate(ExpenseItem);

            return ({
              quantity: ExpenseItem.isFlatRate ? 1 : (foundRate === 1 ? foundRate : billableQuantity),
              rate: (foundRate === 1 && !ExpenseItem.isFlatRate) ? billableQuantity : foundRate,
              description: (`${description}`).slice(0, 4000),
              ExpenseType: { name: this.formExpenseType.EXPENSE },
              qbAccount: ExpenseItem?.qbAccount,
              staffNames: `${User.firstName} ${User.lastName}`,
              createdAt: expenseDate,
              id
            });
          })
      );

      if (this.investigation.Company?.FlatRates.length) {

        const foundRisk = this.investigation.Company.FlatRates.find(obj => obj.Risk.id === this.investigation.RiskType.id);

        if (foundRisk) {
          this.invoiceLines.unshift(
            {
              quantity: 1,
              rate: foundRisk.value,
              qbAccount: 'A. Investigation',
              description: (`${foundRisk.Risk.name}`).slice(0, 4000),
              ExpenseType: { name: this.formExpenseType.HOURS },
              staffNames: '',
              createdAt: new Date()
            }
          );
        }
      }

      // Sort invoice lines
      this.sortInvoiceLines();

    }
    else if (this.data.invoice) {

      // Find & set investigation if it isn't passed into modal
      if (!this.data?.investigation && this.data?.invoice?.Investigation.id) {
        this.investigationService.getById(this.data.invoice.Investigation.id).pipe(
          tap((inv) => this.data.investigation = inv)
        ).subscribe();
      }

      this.details = this.data.invoice.details ? this.data.invoice.details : '';
      this.invoiceNumber = this.data.invoice.invoiceNumber;
      // Format ExpenseType correctly & sort invoice lines by date
      this.invoiceLines = this.data.invoice.InvoiceLines.map((obj) => {
        return {
          ...obj,
          ExpenseType: { name: obj.expenseType }
        };
      });

    }
    else {
      this.invoiceLines = [{
        quantity: 1,
        rate: 1,
        description: "New Invoice Line Item",
        ExpenseType: { name: this.formExpenseType.EXPENSE },
        qbAccount: null,
        staffNames: '',
        createdAt: new Date()
      }];
    }
  }

  private findRate(item: IApiExpenseItem | IApiTimeEntryType | any) {
    const ruleOverride = this.investigation.Company?.BillingRules.find((obj) => (obj?.ExpenseItem?.id === item.id) || (obj?.TimeEntryType?.id === item.id));
    return !!ruleOverride ? ruleOverride.value : ('value' in item ? item.value : item.rate);
  }

  public send() {
    if (!this.data.invoice) {

      const userIds = this.invoiceItemUsers.map(user => user.userId);
      const users = this.investigation ? this.investigation.InvestigationStaff.filter(user => userIds.includes(user.User.id)) : null;
      const formattedUsers = users ? users.map(({ User, Role }) => `${User.firstName} ${User.lastName} - ${Role.title}`) : [];

      this.notificationService.confirm("Really send this invoice data to Quickbooks?").afterClosed().pipe(
        filter(v => !!v),
        switchMap(() => this.invoiceService.add({
          details: this.details,
          invoiceNumber: this.invoiceNumber,
          InvestigationId: this.investigation ? this.investigation.id : null,
          InvoiceLines: this.invoiceLines.map(({ quantity, rate, description, ExpenseType: { name }, qbAccount, staffNames, createdAt }, index) => ({
            // For some reason, even though "typeof quantity" returns number, graphql thought values with decimal were strings
            quantity: parseFloat(quantity.toString()),
            rate,
            description,
            expenseType: name,
            qbAccount,
            displayOrder: index,
            staffNames,
            createdAt
          })),
          InvoiceItems: this.invoiceItems,
          memo: formattedUsers.join(', '),
          BillToBranchId: this.investigation.BillToBranch ? this.investigation.BillToBranch.id : null,
          BillToId: this.investigation.BillTo ? this.investigation.BillTo.id : null,
        })),
        this.notificationService.alertPipe("Invoice successfully created!"),
        this.notificationService.catchAlertPipe()
      ).subscribe(() => this.dialogRef.close(true));
    }
    else {
      this.invoiceService.update({
        id: this.data.invoice.id,
        details: this.details,
        invoiceNumber: this.invoiceNumber,
        InvoiceLines: this.invoiceLines.map(({ quantity, rate, description, expenseType, qbAccount, staffNames, createdAt }, index) => ({
          quantity,
          rate,
          description,
          expenseType,
          qbAccount,
          displayOrder: index,
          staffNames,
          createdAt
        }))
      }).pipe(
        this.notificationService.alertPipe("Invoice successfully modified!"),
        this.notificationService.catchAlertPipe()
      ).subscribe(() => this.dialogRef.close(true));
    }
  }

  public combine() {
    const flatRate = this.selectedLines.find((line) => this.investigation?.Company?.FlatRates.find((rate) => rate?.Risk?.name === line?.description));

    const combined = this.selectedLines.reduce<IQbInvoiceLine>((prev, { description, rate, quantity, createdAt, qbAccount, staffNames, ExpenseType: { name } }) => {
      const prevStaffNames = prev.staffNames ? prev.staffNames.split(', ') : [];
      const nextStaffNames = staffNames.split(', ') || [];

      if (prev.description) description = "\n" + description;
      prev.description += description;
      // Default to flatRate if one exists in the list. Otherwise, use next rate.
      prev.rate = flatRate?.rate || rate;
      prev.quantity += quantity;
      prev.createdAt = createdAt;
      prev.ExpenseType.name = name;
      prev.qbAccount = qbAccount;
      prev.staffNames = [...new Set([...prevStaffNames, ...nextStaffNames])].sort().join(', ');
      return prev;
    }, {
      rate: 0,
      description: "",
      quantity: 0,
      createdAt: null,
      qbAccount: "",
      staffNames: "",
      ExpenseType: {
        name: ""
      }
    });

    // put the combined line where the topmost selected line is
    const firstSelectedIndex = this.selectedLines.reduce((prev, curr) => {
      const idx = this.invoiceLines.indexOf(curr);
      return !prev || idx < prev ? idx : prev;
    }, null) as number;

    // rebuild invoice lines, including combined, then filter out the selected now captured in the combined line
    this.invoiceLines = [
      ...this.invoiceLines.slice(0, firstSelectedIndex || 0),
      combined,
      ...this.invoiceLines.slice(firstSelectedIndex)
    ].filter(l => !this.selectedLines.includes(l));

    // Sort invoice lines
    this.sortInvoiceLines();
  }

  public newLine() {
    // NOTE: using spread assignment here specifically to get change detection to fire when appending an item; .push does not trigger it.
    this.invoiceLines = [
      ...this.invoiceLines,
      {
        quantity: 1,
        rate: 1,
        qbAccount: null,
        description: "New Invoice Line Item",
        ExpenseType: { name: this.formExpenseType.EXPENSE },
        staffNames: "",
        createdAt: new Date()
      }
    ];
  }

  public deleteLines() {
    this.invoiceItems = this.invoiceItems.filter(item => !this.selectedLines.find(_line => _line?.id === item?.invoiceableId));
    this.invoiceLines = this.invoiceLines.filter(line => !this.selectedLines.find(_line => _line === line));
    this.invoiceItemUsers = this.invoiceItemUsers.filter(item => !this.selectedLines.find(_line => _line?.id === item?.invoiceableId));
  }

  public get sameType(): boolean {
    const selectedTypes = this.selectedLines.map((obj) => obj.ExpenseType.name);
    const selectedQBTypes = this.selectedLines.map((obj) => obj.qbAccount);
    return selectedTypes.every((val) => val === selectedTypes[0]) && selectedQBTypes.every((val) => val === selectedQBTypes[0]);
  }

  // Sort invoice lines by
  // Investigators > Hours / Mileage / Photos / Expense / Reports > Oldest to Newest
  private sortInvoiceLines() {

    // Sort the array by createdAt in ascending order
    this.invoiceLines.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    // Group by staffNames for the same createdAt
    let groupedExpenses = {};
    this.invoiceLines.forEach(expense => {
      let key = expense.createdAt + '-' + expense.staffNames;
      if (!groupedExpenses[key]) {
        groupedExpenses[key] = [];
      }
      groupedExpenses[key].push(expense);
    });

    // Sort grouped expenses by ExpenseType
    Object.keys(groupedExpenses).forEach(key => {
      groupedExpenses[key].sort((a, b) => {
        let typeOrder = { 'hours': 1, 'mileage': 2, 'expense': 3 };
        return typeOrder[a.ExpenseType.name] - typeOrder[b.ExpenseType.name];
      });
    });

    // Concatenate sorted grouped expenses with remaining expenses
    let result = Object.values(groupedExpenses).flatMap(group => group).concat(this.invoiceLines.filter(expense => {
      let key = expense.createdAt + '-' + expense.staffNames;
      return !groupedExpenses[key];
    }));

    result.sort((a: any, b: any) => {
      if (a.staffNames === "NEFCO-APPLICATION NEFCO-APPLICATION" && b.staffNames !== "NEFCO-APPLICATION NEFCO-APPLICATION") {
        return 1;
      } else if (a.staffNames !== "NEFCO-APPLICATION NEFCO-APPLICATION" && b.staffNames === "NEFCO-APPLICATION NEFCO-APPLICATION") {
        return -1;
      } else {
        return 0;
      }
    });
    this.invoiceLines = result;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.invoiceLines, event.previousIndex, event.currentIndex);
    this.table.renderRows();
  }

}
