import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CertificationService, CertificationTypeService, DocumentService, DocumentTypeService } from 'src/app/shared/services';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiAddCertificationInput, IApiCertificationType, IApiCertificationStatusType, IApiDocumentType, IApiDocument, IApiUser, IApiUploadTypes } from 'src/app/shared/modules/graphql/types/types';
import { filter } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { imageMimeType, pdfMimeType, wordMimeTypes } from 'src/app/shared/helpers/helper';
import { FileInterface } from 'src/app/shared/services/s3/s3-service';

type S3DocType = {
  key: string,
  filename: string
};

export interface IStaffAddCertificationModalData {
  User: Partial<IApiUser>;
}

@Component({
  selector: 'app-staff-add-certification-modal',
  templateUrl: './staff-add-certification-modal.component.html',
  styleUrls: ['./staff-add-certification-modal.component.scss']
})
export class StaffAddCertificationModalComponent implements OnInit {

  public newCertification: IApiAddCertificationInput = {
    issuedAt: new Date(),
    expiresAt: new Date(),
    TypeId: null,
    UserId: null,
    // Use "current" status as default
    status: IApiCertificationStatusType.Current,
    Comments: null,
    DocumentId: null
  };

  // Certification Types
  public certifications: IApiCertificationType[];

  // Certification Status Types
  public statusTypes = IApiCertificationStatusType;

  // Document Types
  public _documentTypes: [IApiDocumentType];

  // Uploaded S3 Doc
  public s3Doc: S3DocType;

  // Uploaded Document
  public uploadedDoc: IApiDocument;

  constructor(
    public dialogRef: MatDialogRef<StaffAddCertificationModalComponent>,
    private certificationService: CertificationService,
    private loader: LoaderService,
    private certificationTypeService: CertificationTypeService,
    private notifications: NotificationsService,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private dialogService: DialogService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IStaffAddCertificationModalData,
  ) { }

  // Saving certificate
  public async saveCertificationSelection() {

    const typeId = this._documentTypes.find(t => t.name === "Certification")?.id;
    const certificationTitle = this.certifications.find(({ id }) => this.newCertification.TypeId === id)?.title;
    if (!typeId) {
      this.notifications.alert("Unable to find correct document. Please try again");
      return;
    }

    // Create document if one is uploaded
    if (!!this.s3Doc) {
      const { key, filename } = this.s3Doc;
      await this.documentService.add({
        uri: '',
        s3Uri: key,
        title: `${certificationTitle || 'Generic_Certification'}-${this.data.User.firstName}-${this.data.User.lastName}`,
        caption: filename,
        TypeId: typeId,
      }).pipe(
        this.notifications.catchAlertPipe("Error uploading document; please try again")
      ).toPromise().then((r) => {
        // Is there a way to return this and use in observable stream without setting a local variable?
        this.uploadedDoc = r;
      });
    }

    // Create certification
    this.loader.show$(
      this.certificationService.add({
        ...this.newCertification,
        DocumentId: !!this.uploadedDoc ? this.uploadedDoc.id : null
      })
    ).pipe(
      this.notifications.snackbarPipe("Certification was created successfully"),
      this.notifications.catchAlertPipe("Error creating certification; please try again")
    ).subscribe(() => this.dialogRef.close({ reload: true }));
  }

  async uploadSuccess(files: FileInterface[]) {
    this.s3Doc = { key: files[0].key, filename: files[0].file.name };
  }
  
  public fileSelector() {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      cssClass: 'increase-z-index',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: null,
      FolderId: null,
      UserId: this.data?.User?.id,
      type: IApiUploadTypes.Certificates,
      restrictions: [...imageMimeType, ...pdfMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.uploadSuccess(res);
      }
    });
  }

  // Removing document
  public removeDocument() {
    this.notifications.confirm("Are you sure you want to remove this document?").afterClosed().pipe(
      filter(result => !!result)
    ).subscribe(() => this.s3Doc = null);
  }

  ngOnInit(): void {

    // Get Certifications, Restrictions removed
    this.certificationTypeService.get().pipe(
      unwrapConnection()
    ).subscribe((r) => {
      this.certifications = r;
      this.newCertification.UserId = this.data.User.id;
    });

    // Get Document Types
    this.documentTypeService.get([], { sortOrder: SortOrder.ASC }).pipe(
      unwrapConnection(),
    ).subscribe((result) => {
      this._documentTypes = result;
    });
  }

}
