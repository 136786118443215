import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 's3'
})
export class S3Pipe implements PipeTransform {
  private cloudFrontS3 = environment.cloudFrontS3;
  transform(url: string, width?: number, height?: number): string {
    if (!url) {
      return url;
    }
    url = encodeURIComponent(url);
    url = `${this.cloudFrontS3}/` + url;
    if (!height && !width) {
      return url;
    }
    const urlParts = url.split('.');
    urlParts[urlParts.length - 1] = 'jpeg';
    url = urlParts.join('.')
    const parts = url.split('/');
    parts.splice(parts.length - 1, 0, `${width || ''}x${height || ''}`);
    return parts.join('/');
  }
}
