<p>Phone</p>
<div class="row">
  <div class="col-3" *ngIf="!config.hideType">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select [name]="'type' + name" [(ngModel)]="value.Type" [compareWith]="selectCompare" [disabled]="config.static" required>
        <mat-option *ngFor="let phone of phoneTypes" [value]="phone">{{ phone.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input matInput [name]="'number' + name" [(ngModel)]="value.number" mask="(000) 000-0000" required [disabled]="config.static" />
    </mat-form-field>
  </div>
  <div class="col-3">
    <mat-form-field>
      <mat-label>Ext</mat-label>
      <input matInput [name]="'ext' + name" [(ngModel)]="value.extension" [disabled]="config.static">
    </mat-form-field>
  </div>
  <div class="col-3 align-self-end pb-3" *ngIf="!config.hidePrimary">
    <!-- Will need some logic to make sure only phone is marked as primary -->
    <mat-checkbox [(ngModel)]="value.isPrimary" [name]="'isPrimary' + name" (change)="togglePrimary($event)">Primary</mat-checkbox>
  </div>
</div>
