import { Component, Input, Output, ViewChild, AfterViewInit, forwardRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DebouncedChangeDirective } from 'src/app/shared/directives/debounced-change.directive';
import { Observable, Subject } from 'rxjs';

import { IApiAddAddressInput, IApiAddress, IApiAddressType, IApiUpdateAddressInput } from 'src/app/shared/modules/graphql/types/types';
import { AddressTypeService } from '../../services';
import { removeTypename, unwrapConnection } from '../../rxjs.pipes';
import { ControlContainer, Form, NG_VALUE_ACCESSOR, NgForm } from "@angular/forms";
import { EmailComponent } from 'src/app/components/shared/forms';

export interface IAddressConfig {
  hideAddress2: boolean;
  hideType: boolean;
  static: boolean;
  privateContactType: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})
export class AddressComponent implements AfterViewInit {
  // @ViewChild(DebouncedChangeDirective) formChanges;
  // @ViewChild("form") private _form: NgForm;

  // public get valid(): boolean {
  //   if (!this._form) return false;
  //   return this._form.valid;
  // }

  @Input() address: IApiAddress = { TypeId: null, id: null, address1: null, city: null, state: null, postal: null, country: "United States" };
  @Input() config: IAddressConfig = { hideAddress2: false, hideType: false, static: false, privateContactType: false };
  @Input() public name;
  private _addressChange = new Subject<IApiAddress>();
  @Output() addressChange: Observable<IApiAddress> = this._addressChange;

  public addressTypes: IApiAddressType[] = [];

  private _value: IApiAddress = { TypeId: null, id: null, address1: null, city: null, state: null, postal: null, country: "United States" };
  public set value(val: IApiAddress) {
    if (val) {
      this._value = val;
      this.onChange(val);
    }
  }

  public get value() {
    return this._value;
  }

  constructor(
    private addressTypeService: AddressTypeService
  ) { }

  onChange: any = (): void => { };
  onTouch: any = (): void => { };

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.value.TypeId = this.value.Type ? this.value.Type.id : null;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngAfterViewInit(): void {
    this.addressTypeService.get().pipe(
      unwrapConnection(),
    ).subscribe((types) => {
      this.addressTypes = types;
      if (this.value.Type?.id) this.value.TypeId = this.value.Type.id;
      else if (!this.value.TypeId) this.value.TypeId = types[0].id;
    });
  }

}
