<mat-dialog-content class="mat-typography">
  <!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companiesBillingRulesCreate" #companiesBillingRulesCreate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBillingRulesUpdate" #companiesBillingRulesUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesBillingRulesDelete" #companiesBillingRulesDelete="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesEligeabilityCreate" #companiesEligeabilityCreate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesEligeabilityUpdate" #companiesEligeabilityUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesEligeabilityDelete" #companiesEligeabilityDelete="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesRequiredServicesCreate" #companiesRequiredServicesCreate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesRequiredServicesUpdate" #companiesRequiredServicesUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesRequiredServicesDelete" #companiesRequiredServicesDelete="appEnforcePermission"></div>
  <!-- Permissions -->
  <div class="content-wrapper" [ngSwitch]="viewType">
    <form #companyForm="ngForm">
      <ng-container *ngSwitchCase="viewTypes.Company">
        <h2 *ngIf="!data; else editTitle">Create New Company</h2>
        <ng-template #editTitle>
          <h2 class="text-center">Edit {{ company.name }}</h2>
        </ng-template>
        <mat-card>
          <mat-card class="mat-elevation-z4 mb-4">
            <mat-card-header>
              <mat-card-title>Company Information</mat-card-title>
            </mat-card-header>
            <div class="row align-items-center">
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput [(ngModel)]="company.name" appNoWhiteSpace #name="ngModel" required name="companyName" />
                  <mat-error *ngIf="!name?.valid && (name?.dirty || name?.touched) && company?.name?.length" >Company name cannot be left blank</mat-error>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Description</mat-label>
                  <input matInput [(ngModel)]="company.description" name="companyDescription" />
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Type</mat-label>
                  <mat-select [(ngModel)]="company.Type.id" name="companyType" required>
                    <mat-option *ngFor="let type of companyTypes" [value]="type.id">{{ type.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-4">
                <mat-form-field>
                  <mat-label>Additional Information</mat-label>
                  <textarea matInput [(ngModel)]="company.additionalInfo" name="additionalInfo"></textarea>
                </mat-form-field>
              </div>
              <div class="col-4">
                  <mat-checkbox [(ngModel)]="company.enterPropertyEnabled" name="enterPropertyEnabled">Permission to Enter Property Notification</mat-checkbox>
              </div>
              <div class="col-4">
                <mat-checkbox [(ngModel)]="company.recordedInterviewNotification" name="recordedInterviewNotification">Recorded interview notification</mat-checkbox>
            </div>
            </div>
          </mat-card>
          <div class="flexContainer" *ngIf="!data">
            <div class="equal mb-4">
              <!-- Main Office / Primary Branch -->
              <app-company-branch-create-modal [config]="officeConfig" (branchChange)="branchChanged($event)"></app-company-branch-create-modal>
            </div>
          </div>
          <!-- Billing Information -->
          <mat-card class="mat-elevation-z4"
            *ngIf="companiesBillingRulesCreate?.enabled || companiesBillingRulesUpdate?.enabled || companiesBillingRulesDelete?.enabled">
            <div class="flexContainer">
              <div class="equal">
                <mat-card-header>
                  <mat-card-title>Billing Information</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row">
                    <div class="col-3">
                      <mat-form-field class="w-100">
                        <mat-label>Special Billing Instructions</mat-label>
                        <textarea matInput [(ngModel)]="company.billingInstructions" appearance="outline" name="billingInstructions">
                    </textarea>
                      </mat-form-field>
                      <mat-form-field class="w-100">
                        <mat-label>Quickbooks List Id</mat-label>
                        <input matInput [(ngModel)]="company.qbListId" name="qbListId" />
                      </mat-form-field>
                    </div>
                    <div class="col-8 offset-1">
                      <div class="row">
                        <div class="col-12">
                          <mat-slide-toggle [(ngModel)]="overrideStandardFees" name="overrideFees">Override Standard Fees</mat-slide-toggle>
                        </div>
                        <ng-container *ngIf="overrideStandardFees">
                          <ng-container *ngFor="let rule of company.BillingRules; let i = index;">
                            <div class="col-md-1">
                              <button mat-icon-button>
                                <mat-icon class="remove-icon" (click)="removeBillingRule(i)">cancel</mat-icon>
                              </button>
                            </div>
                            <div class="col-md-11">
                              <ng-container *ngTemplateOutlet="billingRule; context: { rule: rule, index: i }"></ng-container>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                      <div class="buttons">
                        <button mat-flat-button (click)="addBillingRule()" class="rounded-button" [disabled]="!overrideStandardFees">
                          <mat-icon color="accent">add</mat-icon>
                          add another
                        </button>
                      </div>
                      <div class="row mt-5">
                        <div class="col-12">
                          <mat-slide-toggle [(ngModel)]="hasFlatRate" name="hasFlatRates">Flat Rate for Standard Investigations</mat-slide-toggle>
                        </div>
                        <ng-container *ngFor="let flatRate of company.FlatRates; let i = index;">
                          <div class="d-inline-flex align-items-center w-100">
                            <div class="col-md-1">
                              <button class="mr-1" mat-icon-button (click)="removeFlatRate(i)">
                                <mat-icon class="remove-icon">cancel</mat-icon>
                              </button>
                            </div>
                            <div class="col-5">
                              <app-risk-types-select [(ngModel)]="flatRate.Risk.id" [name]="'flatRateType' + i"></app-risk-types-select>
                            </div>
                            <div class="col-4">
                              <mat-form-field class="w-50" appearance="legacy">
                                <span matPrefix>$</span>
                                <mat-label>Rate</mat-label>
                                <input class="numeric-input" matInput type="number" [(ngModel)]="flatRate.value" [name]="'flatRate' + i" />
                              </mat-form-field>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="buttons">
                        <button mat-flat-button class="rounded-button" [disabled]="!hasFlatRate" (click)="addFlatRate()">
                          <mat-icon color="accent">add</mat-icon>
                          add another
                        </button>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </mat-card>
          <!-- Eligibility Information -->
          <mat-card class="mat-elevation-z4"
            *ngIf="companiesEligeabilityCreate?.enabled || companiesEligeabilityUpdate?.enabled || companiesEligeabilityDelete?.enabled">
            <div class="flexContainer">
              <div class="equal">
                <mat-card-header>
                  <mat-card-title>Eligibility Information</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row">
                    <div class="col-6">
                      <p class="font-weight-normal">Certifications required by this company:</p>
                      <div class="d-flex" *ngFor="let cert of company.RequiredCertifications; let i = index;">
                        <button mat-icon-button (click)="removeCertification(i)">
                          <mat-icon class="remove-icon">cancel</mat-icon>
                        </button>
                        <span [innerHTML]="cert.title"></span>
                      </div>
                      <a class="modal-link" (click)="viewType = viewTypes.Certification">Select certification</a>
                    </div>
                    <div class="col-6">
                      <p class="font-weight-normal">Staff excluded from assignment:</p>
                      <div class="d-flex" *ngFor="let staff of company.ExcludedStaff; let i = index;">
                        <button mat-icon-button (click)="removeStaff(i)">
                          <mat-icon class="remove-icon">cancel</mat-icon>
                        </button>
                        <span [innerHTML]="staff | fullName"></span>
                      </div>
                      <a class="modal-link" (click)="viewType = viewTypes.Staff">Select staff</a>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </mat-card>
          <!-- Services -->
          <mat-card class="mat-elevation-z4"
          *ngIf="companiesRequiredServicesCreate?.enabled || companiesRequiredServicesUpdate?.enabled || companiesRequiredServicesDelete?.enabled">
            <div class="flexContainer">
              <div class="equal">
                <mat-card-header>
                  <mat-card-title>Services</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row">
                    <div class="col-12">
                      <p class="font-weight-normal">Services required by this company:</p>
                      <div class="d-flex" *ngFor="let service of company.RequiredServices; let i = index;">
                        <button mat-icon-button (click)="removeService(i)">
                          <mat-icon class="remove-icon">cancel</mat-icon>
                        </button>
                        <span [innerHTML]="service.name"></span>
                      </div>
                      <a class="modal-link" (click)="viewType = viewTypes.Services">Select services</a>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </div>
          </mat-card>
          <!-- <div class="row mt-3">
            <div class="primary-buttons col-12 d-flex justify-content-end">

              <button mat-raised-button (click)="dialogRef.close(false)">
                Cancel
              </button>
              <button mat-raised-button class="ml-1 pl-5 pr-5 pb-1 pt-1" color="primary" [disabled]="!companyForm.valid" (click)="save()">
                <span>Save</span>
              </button>
            </div>
          </div> -->
        </mat-card>
      </ng-container>
      <!-- view modes for selection lists -->
      <ng-container *ngSwitchCase="viewTypes.Certification">
        <app-certifications-list [selectableMode]="true" (done)="closeCertificationSelection($event)"></app-certifications-list>
      </ng-container>
      <ng-container *ngSwitchCase="viewTypes.Services">
        <app-service-type-list [selectableMode]="true" (done)="closeServiceSelection($event)"></app-service-type-list>
      </ng-container>
      <ng-container *ngSwitchCase="viewTypes.Staff">
        <app-staff-list [config]="staffListConfig" (done)="closeStaffSelection($event)"></app-staff-list>
      </ng-container>
    </form>
  </div>
</mat-dialog-content>

<!-- Need ngIf to prevent confusion of multiple save buttons -->
<mat-dialog-actions class="float-right" *ngIf="viewType === viewTypes.Company">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!company?.name?.trim() || !company?.Type?.id || mainOfficeInvalid()">Save</button>
</mat-dialog-actions>

<ng-template #billingRule let-rule="rule" let-index="index">
  <div class="d-flex justify-content-between">
    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select name="selectedExpenseType" [(ngModel)]="rule.type" (selectionChange)="typeChange(index)">
        <mat-option value="{{ formExpenseType.EXPENSE }}">Expense</mat-option>
        <mat-option value="{{ formExpenseType.HOURS }}">Hours</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="rule.type === formExpenseType.EXPENSE">
      <mat-label>Expense Type</mat-label>
      <mat-select name="expenseType" [(ngModel)]="rule.ExpenseItem.id" [name]="'billingRuleType' + index">
        <mat-option *ngFor="let type of expenseItems" [value]="type.id">{{ type.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="rule.type === formExpenseType.HOURS">
      <mat-label>Hours Type</mat-label>
      <mat-select name="timeType" [(ngModel)]="rule.TimeEntryType.id" [name]="'billingRuleType' + index">
        <mat-option *ngFor="let type of timeEntryTypes" [value]="type.id">{{ type.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Rate</mat-label>
      <span matPrefix>$</span>
      <input matInput name="rateValue" class="numeric-input" type="number" [(ngModel)]="rule.value" mask="separator.2" thousandSeparator="," separatorLimit="1000" [name]="'billingRuleValue' + index" required />
      <span matSuffix>/hr</span>
    </mat-form-field>
  </div>
  <div class="d-flex">
    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea matInput name="ruleNotes" matTextareaAutosize [(ngModel)]="rule.notes" [name]="'billingRuleNote' + index"></textarea>
    </mat-form-field>
  </div>

</ng-template>
