<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffCertificationsDelete" #staffCertificationsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsUpdate" #staffCertificationsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnUpdate" #staffCertificationsOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnDelete" #staffCertificationsOwnDelete="appEnforcePermission"></div>
<!-- Permissions -->
<mat-card *ngFor="let cert of certifications; let i = index;">
    <div class="d-flex justify-content-between">
        <h5 [innerHTML]="cert.Type.title"></h5>
        <div>
            <button *ngIf="staffCertificationsDelete.enabled  || (staffCertificationsOwnDelete.enabled && isOwnerUser)" mat-icon-button title="delete" (click)="deleteStaffCertification(trackEdit[i].id)">
                <mat-icon color="warn">delete</mat-icon>
            </button>
            <button mat-icon-button (click)="trackEdit[i].edit = false" *ngIf="trackEdit[i].edit; else editIcon">
                <mat-icon>cancel</mat-icon>
            </button>
            <ng-template #editIcon>
                <button mat-icon-button (click)="trackEdit[i].edit = true" *ngIf="staffCertificationsUpdate.enabled  || (staffCertificationsOwnUpdate.enabled && isOwnerUser)">
                    <mat-icon color="accent">border_color</mat-icon>
                </button>
            </ng-template>
        </div>
    </div>
    <!-- View Mode -->
    <ng-container *ngIf="!trackEdit[i].edit; else editMode">
        <div class="row">
            <div class="col-2">
                <p class="muted">Issue Date</p>
                <p [innerHTML]="cert.issuedAt | customDate: 'MM/dd/yyyy'"></p>
            </div>
            <div class="col-2">
                <p class="muted">Expiration Date</p>
                <p [innerHTML]="cert.expiresAt | customDate: 'MM/dd/yyyy'"></p>
            </div>
            <div class="col-2 offset-1">
                <p class="muted">Status</p>
                <p [innerHTML]="cert.status || 'N/A'"></p>
            </div>
            <div class="col-2 offset-1">
                <p class="muted">Issuing Agency</p>
                <p [innerHTML]="cert.Type?.CertificationAgency?.name || 'N/A'"></p>
            </div>
            <div class="col-2">
                <p class="muted">State</p>
                <p [innerHTML]="cert.Type?.state || 'N/A'"></p>
            </div>
            <div class="col-12 mb-3">
                <a *ngIf="cert.Document" [href]="cert.Document?.s3Uri | s3" target="_blank">{{ cert.Document?.title }}</a>
            </div>
        </div>
        
    </ng-container>
    <!-- Edit Mode -->
    <ng-template #editMode>
        <div class="row">
            <div class="col-2">
              <app-datepicker [(ngModel)]="cert.issuedAt" name="issueDate" [label]="'Issue Date'" (changed)="updateCertification(cert)" ></app-datepicker>
            </div>
            <div class="col-2">
              <app-datepicker [(ngModel)]="cert.expiresAt" name="expiresDate" [label]="'Expiration Date'" (changed)="updateCertification(cert)" ></app-datepicker>
            </div>
            <div class="col-2" *ngIf="!cert.Type?.isRestriction">
                <mat-form-field>
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="cert.status" (ngModelChange)="updateCertification(cert)">
                        <mat-option *ngFor="let status of (statuses | keyvalue)" [value]="status.value" [innerHTML]="status.value"></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-2 offset-1">
                <!-- Can only be updated when updating the certification type directly -->
                <mat-form-field *ngIf="cert.Type?.CertificationAgency?.name">
                    <mat-label>Issuing Agency</mat-label>
                    <mat-select [value]="cert.Type.CertificationAgency.name" disabled >
                        <mat-option [value]="cert.Type.CertificationAgency.name" [innerHTML]="cert.Type.CertificationAgency.name">
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-2 offset-1">
                <mat-form-field *ngIf="cert.Type?.state">
                    <mat-label>State</mat-label>
                    <mat-select [value]="cert.Type.state" disabled>
                        <mat-option [value]="cert.Type.state" [innerHTML]="cert.Type.state"></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex" *ngIf="cert.Document; else upload">
            <button mat-icon-button (click)="removeDocument(cert)">
                <mat-icon>remove_circle</mat-icon>
            </button>
            <a [href]="cert.Document?.s3Uri | s3" target="_blank">{{ cert.Document?.title }}</a>
        </div>
        <ng-template #upload>
            <div class="d-inline-block">
                <button mat-button color="accent" class="ml-auto" (click)="fileSelector(cert)">
                    <mat-icon color="accent" class="mr-2">backup</mat-icon>Upload Certification
                </button>
            </div>
        </ng-template>
    </ng-template>
    <!-- Comment Log -->
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <h6>Comment Log</h6>
        </mat-expansion-panel-header>
        <div class="row">
            <div class="col-6">
                <table mat-table [dataSource]="cert.Comments">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>Date</th>
                        <td mat-cell *matCellDef="let row" [innerHTML]="row.createdAt | customDate: 'MM/dd/yyyy'"></td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef>Comment</th>
                        <td mat-cell *matCellDef="let row" [innerHTML]="row.text"></td>
                    </ng-container>
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef>User</th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.CreatedBy | fullName }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
            <div class="col-6 text-right">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Comments</mat-label>
                    <textarea matInput #comment></textarea>
                </mat-form-field>
                <button mat-button class="blue-action-button" (click)="addNewComment(cert, comment.value)">Add Comment</button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-card>