import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { GqlCommentService } from 'src/app/shared/modules/graphql/services';
import { EvidenceService } from 'src/app/shared/services';
import { IApiCommentableType, IApiEvidence, IApiEvidenceFilterType } from '../../../../shared/modules/graphql/types/types';

@Component({
  selector: 'app-investigation-evidence-sidebar',
  templateUrl: './investigation-evidence-sidebar.component.html',
  styleUrls: ['./investigation-evidence-sidebar.component.scss']
})
export class InvestigationEvidenceSidebarComponent {

  public columnsToDisplay = ["createdAt", "name", "comment", "createdBy"];
  public comment: string = null;
  public commentHistory: Array<{ id: string, text: string }> = null;
  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<InvestigationEvidenceSidebarComponent>,
    @Inject(MAT_DIALOG_DATA) public evidence: IApiEvidence,
    private evidenceService: EvidenceService,
    private commentService: GqlCommentService
  ) { }

  public addComment() {
    this.loading = true;
    this.commentService.addComment({ text: this.comment, commentableId: this.evidence.id, commentableType: IApiCommentableType.Evidence }).pipe(
      switchMap(() => this.getEvidence())
    ).subscribe(() => {
      this.loading = false;
      this.comment = null;
    });
  }

  private getEvidence() {
    return this.evidenceService.getById(this.evidence.id).pipe(
      tap((val) => this.evidence = val)
    );
  }

}
