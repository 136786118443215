import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from 'src/app/shared/directives/enforce-permission.directive';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { switchMap, filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { IStaffListConfig, StaffListColumns } from "src/app/components/shared/staff/staff-list/staff-list.component";
import { combineLatest, Observable } from 'rxjs';
import { AuthService, PermissionCategoryService, PermissionRoleService } from "src/app/shared/services";
import { IApiPermissionAction, IApiPermissionCategory, IApiPermissionRole, IApiPermissionScope } from "src/app/shared/modules/graphql/types/types";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { PermissionRoleAssignModalComponent } from "../permission-role-assign-modal/permission-role-assign-modal.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { staffViewAll } from "../../../../shared/helpers/auth-config/staff.config";
import {
  manageUserRoleStaffMembersDelete,
  manageUserRolePermissionsList,
  manageUserRolePermissionsCreate,
  manageUserRolePermissionsDelete,
  manageUserRoleStaffMembersCreate,
  manageUserRoleStaffMembersList,
  manageUserRoleNotificationsList,
  manageUserRoleNotificationsView
} from "../../../../shared/helpers/auth-config/manage.config";
import { SelectEvent } from '@progress/kendo-angular-layout';

@UntilDestroy()
@Component({
  selector: 'app-permissions-management',
  templateUrl: './permissions-management.component.html',
  styleUrls: ['./permissions-management.component.scss']
})
export class PermissionsManagementComponent implements OnInit {

  public authConfig = {
    manageUserRolePermissionsCreate,
    manageUserRolePermissionsDelete,
    manageUserRoleStaffMembersCreate,
    manageUserRoleStaffMembersList,
    staffViewAll,
    manageUserRoleStaffMembersDelete,
    manageUserRolePermissionsList,
    manageUserRoleNotificationsList,
    manageUserRoleNotificationsView
  }

  private _staffListConfig: IStaffListConfig = {
    permissionsType: null,
    permissionsView: true,
    columns: [StaffListColumns.NAME, StaffListColumns.POSITION, StaffListColumns.LOCATION, StaffListColumns.PHONE, StaffListColumns.EMAIL]
  };

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  public get staffListConfig() {
    return this._staffListConfig;
  }
  public set staffListConfig(val) {
    this._staffListConfig = val;
  }

  public permissionsData = [];

  public selectedTab = 0;

  public roleType: string = null;

  public permissionColumns = ["category", "scope", "actions"];
  public staffColumns = ["name", "position", "location", "phone", "email"];

  public role: IApiPermissionRole;

  // Selects Setup
  public selectedCategory: IApiPermissionCategory;
  public selectedScope: IApiPermissionScope;
  public selectedAction: IApiPermissionAction;

  public categories$: Observable<IApiPermissionCategory[]>;

  public permissions: any[];

  constructor(
    private route: ActivatedRoute,
    private roles: PermissionRoleService,
    private categories: PermissionCategoryService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private dialog: MatDialog,
    private auth: AuthService
  ) {
    this.reload();
    this.categories$ = this.categories.get();
  }

  private reload() {
    this.loader.show$(
      this.roles.getById(this.route.snapshot.params.id)
    ).subscribe(role => {
      this.role = role;

      // build out permissions dataset - partition by category & scope combined, then actions
      // note: there's probably a lodash function to pivot/partition like this ...
      const tmpObj = role.AssignedPermissions.reduce((prev, curr) => {
        const {
          id,
          Category: { name: categoryName },
          Scope: { name: scopeName },
          Action: { name: actionName }
        } = curr;

        // just for organization
        const combinedKey = `${categoryName}-${scopeName}`;

        // create partition of category/scope
        if (!prev[combinedKey]) prev[combinedKey] = {
          category: categoryName,
          scope: scopeName,
          actions: []
        };

        // many actions -> 1 scope
        prev[combinedKey].actions.push({
          id,
          name: actionName
        });

        return prev;
      }, {});

      this.permissions = Object.keys(tmpObj).sort((a, b) => a > b ? 1 : -1).map(k => tmpObj[k]);
    });
  }

  // filters list of available actions per-scope to exclude already assigned
  public filteredActions() {
    if (!this.selectedScope) return [];
    return this.selectedScope.AvailableActions.filter(action => {
      return !this.role.AssignedPermissions.find(p => p.Category.id === this.selectedCategory.id && p.Scope.id === this.selectedScope.id && p.Action.id === action.id);
    });
  }

  public removePermission(assnId: string) {
    this.notifications.confirm("Are you sure?", "Confirm permission removal").afterClosed().pipe(
      filter(response => !!response),
      this.loader.showPipe(
        this.roles.removePermission(assnId)
      ),
      this.notifications.catchAlertPipe()
    ).subscribe(() => {
      this.reload();
      this.notifications.notify("Permission removed.");
    });
  }

  public removeCategory(id: string) {

  }

  public addPermission() {
    const { id: permissionCategoryId } = this.selectedCategory;
    const { id: permissionScopeId } = this.selectedScope;
    const { id: permissionActionId } = this.selectedAction;
    const { id: permissionRoleId } = this.role;

    this.loader.show$(this.roles.assignPermission({
      permissionRoleId,
      permissionCategoryId,
      permissionScopeId,
      permissionActionId
    })).pipe(
      this.notifications.catchAlertPipe("Error assigning permission; please try again.")
    ).subscribe(() => {
      this.selectedScope = null;
      this.selectedAction = null;
      this.reload()
      this.notifications.notify("Permission assigned.");
    });
  }

  public showAssignUsers() {
    this.dialog.open(PermissionRoleAssignModalComponent, {
      data: { ...this.role },
      width: "90%",
      height: "95%"
    }).afterClosed().pipe(
      filter(result => !!result)
    ).subscribe(() => this.reload());
  }

  public removeStaff(userId: string) {
    this.notifications.confirm("Are you sure?", "Confirm user removal").afterClosed().pipe(
      filter(response => !!response),
      this.loader.showPipe(
        this.roles.removeUser({
          roleId: this.role.id,
          userId
        })
      ),
      this.notifications.catchAlertPipe("Error removing user; please try again.")
    ).subscribe(() => {
      this.reload()
      this.notifications.notify("User removed.");
    });
  }

  // Change tab based on params
  private changeTab(tab) {
    let permissionListPerm = false;
    this.auth.hasCategoryPermission(manageUserRolePermissionsList.category, manageUserRolePermissionsList.appliedPermissions)
    .subscribe(res => {
      permissionListPerm = res;
    });

    let staffListPerm = false;
    this.auth.hasCategoryPermission(manageUserRoleStaffMembersList.category, manageUserRoleStaffMembersList.appliedPermissions)
    .subscribe(res => {
      staffListPerm = res;
    });

    let notificationList = false;
    this.auth.hasCategoryPermission(manageUserRoleNotificationsList.category, manageUserRoleNotificationsList.appliedPermissions)
    .subscribe(res => {
      notificationList = res;
    });

    let notificationView = false;
    this.auth.hasCategoryPermission(manageUserRoleNotificationsView.category, manageUserRoleNotificationsView.appliedPermissions)
    .subscribe(res => {
      notificationView = res;
    });

    this.selectedTab =
      tab === "permissions" && permissionListPerm
        ? 0
        : tab === "staff" && staffListPerm
        ? 1
        : tab === "notifications" && notificationList && notificationView
        ? 2
        : 0;
  }

  public onTabChanged(event: SelectEvent) {
    this.selectedTab = event?.index;
  }

  public refresh() {
    this.reload();
  }

  ngOnInit() {

    // Will work on this more as we establish patterns for observables
    combineLatest([this.route.params, this.route.queryParams]).pipe(
      untilDestroyed(this)
    ).subscribe(data => {
      // Better way to extract elements from the array?
      const tab = data.find(elem => elem.tab);

      if (tab) this.changeTab(tab.tab);
    });
  }
}
