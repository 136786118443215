<div [appEnforcePermission]="authConfig.investigationVehiclesUpdate" #investigationVehiclesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesCreate" #investigationVehiclesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesDelete" #investigationVehiclesDelete="appEnforcePermission"></div>
<form #form="ngForm" appDebouncedChange>
  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-label>Make</mat-label>
        <input matInput name="make" type="text" [(ngModel)]="vehicle.make" minlength="3" required cdkFocusInitial />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <mat-label>Model</mat-label>
        <input matInput name="model" type="text" [(ngModel)]="vehicle.model" minength="3" required />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <mat-label>Year</mat-label>
        <input matInput name="year" type="number" [(ngModel)]="vehicle.year" minlength="4" required />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <mat-label>VIN #</mat-label>
        <input matInput name="vinNumber" type="text" [(ngModel)]="vehicle.vinNumber" pattern="[a-zA-Z0-9]{17}" required />
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <mat-label>Stock #</mat-label>
        <input matInput name="stockNumber" type="text" [(ngModel)]="vehicle.stockNumber" />
      </mat-form-field>
    </div>
    <div class="col text-left mt-2">
      <button mat-icon-button type="button" *ngIf="(!vehicle?.id && form.dirty && investigationVehiclesCreate?.enabled) || (vehicle?.id && investigationVehiclesUpdate?.enabled)" [disabled]="!form.dirty || !form.valid" (click)="save()">
        <mat-icon>check</mat-icon>
      </button>
      <button *ngIf="vehicle?.id && investigationVehiclesDelete?.enabled" mat-icon-button type="button" (click)="delete()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</form>