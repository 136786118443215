/// <reference types="googlemaps" />
import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { IApiAddAddressInput, IApiAddress, IApiUpdateAddressInput } from '../graphql/types/types';

interface GoogleMapsWindow extends Window {
  google?: typeof google;
}

@Injectable()
export class GoogleMapService {

  private API_KEY = environment.google_maps.api_key;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, private _httpClient: HttpClient, @Inject(DOCUMENT) private document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public loadGoogleMapApi() {
    const googleMapsWindow: GoogleMapsWindow = window;
    if (!googleMapsWindow.google) return from(new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.API_KEY}&libraries=places`;
      script.text = ``;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer.appendChild(this.document.head, script);
    }));
    return of(0);
  }

  public fetchGeoCoordinates(address: IApiAddAddressInput) {
    // todo: this works for our purposes, but could be hardened a little
    // geocoding api will return multiple valid locations
    const mapsUri = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
    const headers: HttpHeaders = new HttpHeaders({ Accept: 'application/json' });
    return this._httpClient.get(
      `${mapsUri}${this._formatAddress(address)}${'&key='}${this.API_KEY}`,
      { headers: headers }
    ).pipe(
      map((data: any) => {
        const status = data.status;
        const results: google.maps.GeocoderResult = data.results;
        if (status === "OK") return results[0].geometry.location;
        throw new Error('Not a valid location, according to Google');
      })
    );
  }

  private _formatAddress(address: IApiAddAddressInput) {
    const reducer = (accumulator, currentValue) => (currentValue) ? accumulator + currentValue + ' ' : accumulator;
    return Object.values(address).reduce(reducer, '').trim(' ');
  }

}
