<mat-dialog-content class="p-5">
  <h4 class="text-center">Add Contact</h4>
  <h6 class="text-center" [innerHTML]="data.company.name"></h6>
  <h6>Add To Branch</h6>
  <mat-form-field>
    <mat-label>Branch Name</mat-label>
    <mat-select [(ngModel)]="selectedBranch">
      <mat-option *ngFor="let branch of data.company.Branches" [value]="branch.id">{{ branch.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-card class="m-2">
    <h4>Select Existing Contact</h4>
    <!-- Filters -->
    <div class="d-flex justify-content-between align-items-center">
      <mat-form-field class="search" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #search />
        <button mat-icon-button disableRipple matSuffix (click)="searchValue = null">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select [(ngModel)]="state">
          <mat-option *ngFor="let state of stateService.allStates" [value]="state.value">{{ state.text }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field> -->
        <app-company-select (selectionChanged)="onCompanyChange($event)"></app-company-select>
      <div>
        <button mat-raised-button class="rounded-button" (click)="load()">
          <mat-icon>search</mat-icon>Search
        </button>
      </div>
    </div>
    <mat-card class="mb-3">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="checked">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let contact">
            <button mat-icon-button (click)="selectContact(contact)">
              <mat-icon>playlist_add_check</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let contact" [innerHTML]="contact | fullName"></td>
        </ng-container>

        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>Position/Company</th>
          <td mat-cell *matCellDef="let contact">
            <ng-container *ngIf="contact.BranchAssignments?.length;">
              <p *ngFor="let assignment of contact.BranchAssignments" [innerHTML]="assignment.Branch.Company.name"></p>
            </ng-container>
            <!-- <ng-template #role>
            <p [innerHTML]="contact.Role.name"></p>
          </ng-template> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="branch">
          <th mat-header-cell *matHeaderCellDef>Branch</th>
          <td mat-cell *matCellDef="let contact">
            <p *ngFor="let branch of contact.BranchAssignments" [innerHTML]="branch.Branch.name"></p>
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let contact" [innerHTML]="contact.Role.name"></td>
        </ng-container>

        <ng-container matColumnDef="emails">
          <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header> Emails </th>
          <td class="text-left" mat-cell *matCellDef="let contact">
            <app-email-phone-view *ngFor="let email of contact.Emails" [email]="email"></app-email-phone-view>
          </td>
        </ng-container>

        <ng-container matColumnDef="phones">
          <th class="text-left" mat-header-cell *matHeaderCellDef mat-sort-header> Phones </th>
          <td class="text-left" mat-cell *matCellDef="let contact">
            <app-email-phone-view *ngFor="let phone of contact.Phones" [phone]="phone"></app-email-phone-view>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="!content?.length">
        <p class="text-center">Enter search criteria to see results</p>
      </div>
      <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
    </mat-card>
    <button mat-button (click)="createNewContact()">
      <mat-icon>playlist_add</mat-icon>create new contact
    </button>
  </mat-card>
  <div class="d-flex justify-content-end">
    <div>
      <button mat-raised-button class="blue-action-button mt-2" (click)="dialogRef.close(false)">Cancel</button>
    </div>
  </div>
</mat-dialog-content>
