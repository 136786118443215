import { Component, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { isArray } from 'lodash';
import moment from 'moment';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationRoleNames, IApiQuarterlyUtilizationReport, IApiQuarterlyUtilizationReportFilter, IApiQuarterlyUtilizationReportFilterType, IApiQuarterlyUtilizationReportOrderBy, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { QuarterlyUtilizationReportService, StateService } from 'src/app/shared/services';
import { QuarterlyUtilizationReportDataSource } from 'src/app/shared/services/reports/quarterly-utilization-report/quarterly-utilization-report.datasource';
import { staffViewAll } from 'src/app/shared/helpers/auth-config/staff.config';

@Component({
  selector: 'app-quarterly-utilization-report',
  templateUrl: './quarterly-utilization-report.component.html',
  styleUrls: ['./quarterly-utilization-report.component.scss']
})
export class QuarterlyUtilizationReportComponent implements OnInit {

  public authConfig = {
    staffViewAll,
  };

  private _filters: IApiQuarterlyUtilizationReportFilter[] = [];
  public filterTypes = IApiQuarterlyUtilizationReportFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  private _dataSource: QuarterlyUtilizationReportDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  currentQuarter = {
    startDate: NefcoDateHelper.toUtcStartOfDay(moment().startOf("quarter").toDate(), true),
    endDate: NefcoDateHelper.toUtcStartOfDay(moment().endOf("quarter").toDate(), false),
  }

  public data = [];
  public sort: SortDescriptor[] = [
    {
      field: IApiQuarterlyUtilizationReportOrderBy.UserName,
      dir: "asc",
    },
  ];

  public excludeTitles = [IApiInvestigationRoleNames.Editor];

  constructor(private loaderService: LoaderService, private quarterlyUtilizationReportService: QuarterlyUtilizationReportService, private stateService: StateService) { }

  ngOnInit(): void {
    this.loaderService.isMatLoader = false;
    this.dataSource = new QuarterlyUtilizationReportDataSource(this.quarterlyUtilizationReportService);
    this.dataSource.applyFilter(IApiQuarterlyUtilizationReportFilterType.WorkDayRange, JSON.stringify(this.currentQuarter));
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.orderBy = IApiQuarterlyUtilizationReportOrderBy.UserName;
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiQuarterlyUtilizationReportOrderBy.UserName;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  private load(): void {
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.data = res;
        this.data = this.data?.map((row) => {
          return {
            ...row,
            showAddress: row?.Addresses?.find(address => address?.Type?.name === 'Home') || null
          }
        })
      });
    });
  }


  public setFilters(value: string | undefined, type: IApiQuarterlyUtilizationReportFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
    if(type === IApiQuarterlyUtilizationReportFilterType.WorkDayRange){
      this.applyFilter();
    }
  }

  public filterValue(filter: IApiQuarterlyUtilizationReportFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  applyFilter() {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  clearFilter() {
    this.filters = [];
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.applyFilter(IApiQuarterlyUtilizationReportFilterType.WorkDayRange, JSON.stringify(this.currentQuarter));
    this.dataSource.pagingReset();
    this.load();
  }

  public getStateName(stateCode: string) {
    return this.stateService.allStates.find(i => i.value === stateCode);
  }

}
