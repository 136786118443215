<section class="mat-typography">
  <!-- Modal Header -->
  <div mat-dialog-title class="header">
    <h2 class="text-center">Assign Staff to Permission Role</h2>
  </div>
  <div class="flexContainer">
    <section class="mt-2 mb-2">
      <mat-form-field class="search" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" #search [(ngModel)]="searchValue" (appDebouncedInputChange)="load(searchValue)" />
        <button mat-icon-button disableRipple matSuffix (click)="searchValue = null; load(null)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </section>
    <mat-chip-list class="float-right mb-4">
      <mat-chip *ngFor="let user of selectedUsers" [selectable]="false" [removable]="true" (removed)="removeUser(user)">
        {{ user.lastName }}, {{ user.firstName }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)">

      <ng-container matColumnDef="checked">
        <th mat-header-cell *matHeaderCellDef> Select </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (change)="toggleUser(row)" [checked]="!!selected[row.id]"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="LASTNAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.lastName}}, {{row.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Position(s)</th>
        <td mat-cell *matCellDef="let staff">
          <p *ngFor="let role of staff.Positions" [innerHTML]="role.title"></p>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let staff" [innerHTML]="(staff?.Addresses | primaryAssociation)?.state"></td>
      </ng-container>

      <ng-container matColumnDef="permissionRole">
        <th mat-header-cell *matHeaderCellDef>Permission Role</th>
        <td mat-cell *matCellDef="let staff">
          <span class="d-block" *ngFor="let role of staff.PermissionRoles">{{ role.name }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="EMAIL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let staff"> {{ (staff.Emails | primaryAssociation)?.address}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let staff"> {{ (staff.Phones | primaryAssociation)?.number}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
  </div>
  <div mat-dialog-actions class="d-flex flex-row-reverse">
    <button mat-raised-button color="primary" (click)="assignUsers()" [disabled]="!selectedUsers.length">Assign Users</button>
    <button mat-button autofocus (click)="cancel()">Cancel</button>
  </div>
</section>