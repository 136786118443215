import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  DomSafePipe,
  PhoneFormatPipe,
  PhoneBranchFormatPipe,
  ActiveBranchPipe,
  NullableDatePipe,
  NullableTimePipe,
  CustomDatePipe,
  ContactCompanyPipe,
  BranchNamePipe,
  StandardNamePipe,
  StandardInsuredNamePipe,
  InvestigationStaffRolePipe,
  AddressPipe,
  AddressBranchPipe,
  NefcoNumberPipe,
  EvidenceNumberPipe,
  PrimaryAssociationPipe,
  PrimarySortPipe,
  AlphabeticalIndexPipe,
  PhotoInUseFilterPipe,
  DocumentSnapshotPipe,
  QuietPipe,
  EvidenceLocationPipe,
  EvidenceItemsPipe,
  EvidenceStatusPipe,
  EvidenceArrivalDatePipe,
  EvidenceTransferDatePipe,
  CertificationErrorPipe,
  TimeEntryNamePipe,
  MoreThanPipe,
  ArraySortByDatePipe,
  VisibleExpenseItemsPipe,
  VisibleTimeEntryItemsPipe,
  ArraySortPipe,
  ReimbursementRatePipe,
  FormatFileSizePipe,
  S3Pipe
} from './';

@NgModule({
  imports: [],
  declarations: [
    DomSafePipe,
    AddressPipe,
    AddressBranchPipe,
    ActiveBranchPipe,
    ContactCompanyPipe,
    BranchNamePipe,
    StandardNamePipe,
    StandardInsuredNamePipe,
    InvestigationStaffRolePipe,
    NefcoNumberPipe,
    EvidenceNumberPipe,
    NullableDatePipe,
    NullableTimePipe,
    CustomDatePipe,
    PrimaryAssociationPipe,
    PrimarySortPipe,
    AlphabeticalIndexPipe,
    PhotoInUseFilterPipe,
    DocumentSnapshotPipe,
    QuietPipe,
    PhoneFormatPipe,
    PhoneBranchFormatPipe,
    EvidenceLocationPipe,
    EvidenceItemsPipe,
    EvidenceStatusPipe,
    EvidenceArrivalDatePipe,
    EvidenceTransferDatePipe,
    CertificationErrorPipe,
    TimeEntryNamePipe,
    MoreThanPipe,
    ArraySortByDatePipe,
    VisibleExpenseItemsPipe,
    VisibleTimeEntryItemsPipe,
    ReimbursementRatePipe,
    ArraySortPipe,
    FormatFileSizePipe,
    S3Pipe
  ],
  exports: [
    AddressPipe,
    AddressBranchPipe,
    ActiveBranchPipe,
    ContactCompanyPipe,
    DocumentSnapshotPipe,
    DomSafePipe,
    BranchNamePipe,
    StandardNamePipe,
    StandardInsuredNamePipe,
    InvestigationStaffRolePipe,
    NefcoNumberPipe,
    EvidenceNumberPipe,
    NullableDatePipe,
    NullableTimePipe,
    CustomDatePipe,
    PrimaryAssociationPipe,
    PrimarySortPipe,
    AlphabeticalIndexPipe,
    PhoneFormatPipe,
    PhoneBranchFormatPipe,
    PhotoInUseFilterPipe,
    QuietPipe,
    EvidenceLocationPipe,
    EvidenceItemsPipe,
    EvidenceStatusPipe,
    EvidenceArrivalDatePipe,
    EvidenceTransferDatePipe,
    CertificationErrorPipe,
    TimeEntryNamePipe,
    MoreThanPipe,
    ArraySortByDatePipe,
    VisibleExpenseItemsPipe,
    VisibleTimeEntryItemsPipe,
    ReimbursementRatePipe,
    ArraySortPipe,
    FormatFileSizePipe,
    S3Pipe
  ],
})

export class PipesModule {

  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [
        AddressPipe,
        AddressBranchPipe,
        ActiveBranchPipe,
        ContactCompanyPipe,
        BranchNamePipe,
        StandardNamePipe,
        StandardInsuredNamePipe,
        InvestigationStaffRolePipe,
        NefcoNumberPipe,
        EvidenceNumberPipe,
        NullableDatePipe,
        NullableTimePipe,
        CustomDatePipe,
        PrimaryAssociationPipe,
        PrimarySortPipe,
        AlphabeticalIndexPipe,
        PhoneFormatPipe,
        PhoneBranchFormatPipe,
        PhotoInUseFilterPipe,
        QuietPipe,
        EvidenceLocationPipe,
        EvidenceItemsPipe,
        EvidenceStatusPipe,
        EvidenceArrivalDatePipe,
        EvidenceTransferDatePipe,
        CertificationErrorPipe,
        TimeEntryNamePipe,
        ArraySortByDatePipe,
        VisibleExpenseItemsPipe,
        VisibleTimeEntryItemsPipe,
        ReimbursementRatePipe,
        ArraySortPipe,
        FormatFileSizePipe,
        S3Pipe
      ],
    };
  }
}
