import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from 'rxjs';
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiAddInsuredPartyInput, IApiInsuredParty, IApiInsuredPartyFilter, IApiUpdateInsuredPartyInput } from "../../types/types";
import { addInsuredPartyMutation, insuredPartyConnectionQuery, updateInsuredPartyMutation } from "./insured-party.operations";
import { IGqlBaseOptions } from "../../interfaces";
import { connectionQuery } from "../../helpers.class";

@Injectable()
export class GqlInsuredPartyService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiInsuredPartyFilter[], options: IGqlBaseOptions): Observable<IApiInsuredParty> {
    return this.apollo.query(connectionQuery(insuredPartyConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public add(input: IApiAddInsuredPartyInput) {
    return this.apollo.mutate({
      mutation: addInsuredPartyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }

  public update(input: IApiUpdateInsuredPartyInput) {
    return this.apollo.mutate({
      mutation: updateInsuredPartyMutation,
      variables: { input }
    }).pipe(
      safeResolve(true)
    );
  }
}
