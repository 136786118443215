<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companiesBranchesUpdate" #companiesBranchesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<!-- Permissions -->

<section *ngIf="company && branch">
    <div class="d-flex justify-content-between">
        <h4 class="page-title" [innerHTML]="company.name"></h4>
        <div class="d-flex text-right">
            <button mat-button color="accent" (click)="goBack()">
                <mat-icon color="accent">keyboard_backspace</mat-icon>
                <span class="text-uppercase">Back</span>
            </button>
            <button mat-button color="accent">
                <mat-icon aria-hidden="false" color="accent">open_in_new</mat-icon>
            </button>
        </div>
    </div>
    <mat-card *ngIf="branch">
        <div class="d-flex justify-content-between align-items-center mb-3" *ngIf="contactsCreateConfig?.enabled">
            <h5 style="max-width: 90%;">Branch: {{ branch.name }}</h5>
            <button mat-raised-button (click)="addContact()" class="rounded-button">
                <mat-icon color="accent">add</mat-icon>
                Add Contact
            </button>
        </div>
        <div class="col text-right" *ngIf="companiesBranchesUpdate?.enabled">
            <button mat-icon-button (click)="editBranch()">
                <mat-icon color="accent">border_color</mat-icon>
            </button>
        </div>
        <!-- Branch Info -->
        <mat-card>
            <div class="row">
                <div class="col-md-12">
                    <p class="muted">Branch Name:</p>
                    <p [innerHTML]="branch.name"></p>
                </div>
                <div class="col-md-4">
                    <p class="muted">Addresses:</p>
                    <app-address-view *ngFor="let address of branch.Addresses" [address]="address"></app-address-view>
                </div>
                <div class="col-md-4">
                    <p class="muted">Phones:</p>
                    <app-email-phone-view *ngFor="let phone of branch.Phones" [phone]="phone"></app-email-phone-view>
                </div>
                <div class="col-md-4">
                    <p class="muted">Emails:</p>
                    <app-email-phone-view *ngFor="let email of branch.Emails" [email]="email"></app-email-phone-view>
                </div>
                <div class="col-md-12">
                    <p class="muted">Additional Information:</p>
                    <p [innerHTML]="branch.additionalInformation"></p>
                </div>
            </div>
        </mat-card>
        <mat-card *ngFor="let contact of branch.ContactAssignments">
            <div class="row">
                <div class="col-md-3">
                    <p class="muted">Contact</p>
                    <p><a [routerLink]="['/contacts', contact?.Contact?.id]">{{ contact?.Contact | fullName }}</a></p>
                </div>
                <div class="col-md-3">
                    <p class="muted">Role</p>
                    <p [innerHTML]="contact?.Contact?.Role.name"></p>
                </div>
                <div class="col-md-3">
                    <p class="muted">Primary Phone</p>
                    <app-email-phone-view [phone]="getPrimary(contact?.Contact?.Phones)"></app-email-phone-view>
                </div>
                <div class="col-md-3">
                    <p class="muted">Primary Email</p>
                    <app-email-phone-view [email]="getPrimary(contact?.Contact?.Emails)"></app-email-phone-view>
                </div>
                <div class="col-md-3">
                    <p class="muted">Office Phone</p>
                    <app-email-phone-view [phone]="getOffice(contact?.Contact?.Phones)"></app-email-phone-view>
                </div>
                <div class="col-md-3">
                    <p class="muted">Office Email</p>
                    <app-email-phone-view [email]="getOffice(contact?.Contact?.Emails)"></app-email-phone-view>
                </div>
                <div class="col-md-3">
                    <p class="muted">Start Date</p>
                    <p [innerHTML]="contact.startDate | customDate: 'MM/dd/yyyy'"></p>
                </div>
                <div class="col-md-3">
                    <p class="muted">End Date</p>
                    <p [innerHTML]="contact.endDate | customDate: 'MM/dd/yyyy'"></p>
                </div>
            </div>
        </mat-card>
    </mat-card>

    <!-- TODO cleanup during branch create/update modal creation -->
    <!-- PAGE TITLE BAR -->

    <!-- MAIN CONTENT CARD -->
    <!-- <mat-card>
        <div class="mainWrapper">
            <div class="row">
                <div class="col">
                    <h2 class="mat-h2 mat-title">Branch: {{ branch.name }}</h2>
                </div>
                <div class="col text-right">
                    <button mat-raised-button (click)="addContact()" class="rounded-button">
                        <mat-icon color="accent">add</mat-icon>
                        Add Contact
                    </button>
                </div>
            </div> -->

    <!-- Branch Info Card -->
    <!-- <mat-card>
                <div class="row position-absolute edit">
                    <div class="col text-right">
                        <button mat-icon-button (click)="toggleAddresses = !toggleAddresses">
                            <mat-icon color="accent">border_color</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-card-content>
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="w-75">
                                <mat-label>Branch Name</mat-label>
                                <input matInput type="text" [value]="branch.name" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="w-75">
                                <mat-label>Primary Address: (Office)</mat-label> -->
    <!-- <input matInput type="text" [value]="branch | addressBranchFormat" /> -->
    <!-- </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="w-75">
                                <mat-label>Primary Phone: (Office)</mat-label> -->
    <!-- <input matInput type="tel" [value]="branch | phoneBranchFormat" /> -->
    <!-- </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="w-75">
                                <mat-label>Email:</mat-label>
                                <input matInput type="email" [value]="''" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row"></div>
                </mat-card-content>
            </mat-card> -->

    <!-- BEGIN CONTACT CARD LOOP -->
    <!-- <mat-card *ngFor="let contact of contacts">
                <mat-card-content>
                    <div class="row contactCardRow">
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Contact:</mat-label>
                                <input matInput type="text" [value]="contact.fullName" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Role:</mat-label>
                                <input matInput type="text" [value]="contact.role" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Primary Phone: ({{contact.primaryPhone.type}})</mat-label>
                                <input matInput type="tel" [value]="contact.primaryPhone.number" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Primary Email: ({{contact.primaryEmail.type}})</mat-label>
                                <input matInput type="email" [value]="contact.primaryEmail.email" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row contactCardRow">
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Office Phone:</mat-label>
                                <input matInput type="tel" [value]="contact.officePhone" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Office Email</mat-label>
                                <input matInput type="text" [value]="contact.officeEmail" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>Start Date:</mat-label>
                                <input matInput type="date" [value]="contact.startDate" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <mat-label>End Date:</mat-label>
                                <input matInput type="date" [value]="contact.endDate" />
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card> -->
    <!-- END CONTACT CARD LOOP -->
    <!-- </div>
    </mat-card>/MAIN CONTENT CARD -->
</section>
