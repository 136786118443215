<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffContactInformationView" #staffContactInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView" #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView" #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView" #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView" #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsList" #staffCertificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffInvestigationsList" #staffInvestigationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsCreate" #staffCertificationsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnList" #staffCertificationsOwnList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffInvestigationsOwnList" #staffInvestigationsOwnList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnCreate" #staffCertificationsOwnCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
    <!-- Main header section -->
    <div class="row">
        <div class="col-10">
            <h3>{{ staffName ? staffName : 'New Staff Member'}}</h3>
        </div>
        <div class="col-2 text-right">
            <button mat-button (click)="back()">
                <mat-icon color="primary">keyboard_backspace</mat-icon>
                Back
            </button>
            <button mat-icon-button (click)="openSidebar()">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </div>
    </div>
    <!-- Tabular Area -->
    <div class="mat-elevation-z2 tabs">
        <div class="buttonContainer" *ngIf="staffCertificationsCreate.enabled || (staffCertificationsOwnCreate.enabled && isOwnerUser)">
            <button mat-raised-button class="rounded-button" (click)="addCertification()">
                <mat-icon color="primary">add</mat-icon>
                Add Certification
            </button>
        </div>
        <mat-tab-group [(selectedIndex)]="managementTabIndex" (selectedTabChange)="onTabChanged($event);">
            <mat-tab *ngIf="staffContactInformationView.enabled
                || staffContactInformationOwnView?.enabled
                || staffEmergencyInformationView?.enabled
                || staffEmergencyInformationOwnView?.enabled
                || staffUserInformationView?.enabled
                || staffUserInformationOwnView?.enabled" [label]="'profile'">
                <ng-template matTabLabel>
                  PROFILE
                </ng-template>
                <!-- Staff Profile -->
                <app-staff-profile *ngIf="managementTabIndex === 0" (staffName)="staffName = $event"></app-staff-profile>
            </mat-tab>
            <mat-tab *ngIf="staffCertificationsList.enabled || (staffCertificationsOwnList.enabled && isOwnerUser)" [label]="'certifications'">
                <ng-template matTabLabel>
                  CERTIFICATIONS
                </ng-template>
                <div class="p-3">
                    <!-- Staff Certifications -->
                    <app-staff-certifications *ngIf="managementTabIndex === 1" #staffCert></app-staff-certifications>
                </div>
            </mat-tab>
            <mat-tab *ngIf="staffInvestigationsList.enabled || (staffInvestigationsOwnList.enabled && isOwnerUser)" [label]="'investigations'">
                <ng-template matTabLabel>
                  INVESTIGATIONS
                </ng-template>
                <div *ngIf="userConfig" class="p-3">
                    <!-- Staff Investigations -->
                    <app-investigations-table *ngIf="managementTabIndex === getCurrentTabIndex" [config]="userConfig"></app-investigations-table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>
