import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

export interface IDatePeriod {
  start: Date;
  end: Date;
}

export interface IPayrollPeriod {
  start: Date;
  end: Date;
  payDate: Date;
}

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

export abstract class NefcoDateHelper {

  private static _now = new Date();

  private static _quarterDates: Map<number, IDatePeriod> = new Map<number, IDatePeriod>([
    [1, { start: new Date(new Date().getFullYear(), 0, 1), end: new Date(new Date().getFullYear(), 3, 1) }],
    [2, { start: new Date(new Date().getFullYear(), 3, 1), end: new Date(new Date().getFullYear(), 5, 1) }],
    [3, { start: new Date(new Date().getFullYear(), 6, 1), end: new Date(new Date().getFullYear(), 9, 1) }],
    [4, { start: new Date(new Date().getFullYear(), 10, 1), end: new Date(new Date().getFullYear(), 12, 1) }],
  ]);

  public static get quarterDates(): Map<number, IDatePeriod> {
    return this._quarterDates;
  }

  public static get currentQuarter(): IDatePeriod {
    const currentQuarter = Math.ceil(((this._now.getMonth() + 1) / 3));
    return this._quarterDates.get(currentQuarter);
  }

  public static currentPayPeriod(givenDate = new Date()): IPayrollPeriod {

    // Initial pay period "start" date to use as a comparison
    // Note: this initial pay period is 'Sunday, 11-7-2021' to 'Saturday, 11-20-21' with a pay date of 'Friday, 11-26-21'
    const initPayPeriod = dayjs('2021-11-7', 'YYYY-MM-DD');

    // Find the Sunday of today to easily compare to the inital date
    const lastSunday = dayjs(givenDate).day(0)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);

    // If the value is even, it is in the first week of a pay period
    // If the value is odd, it is in the second week of a pay period
    const isEven = (lastSunday.diff(initPayPeriod, 'week', true) % 2 === 0);

    // DO NOT convert to "America/New_York" timezone, handle on backend
    const payPeriod = {
      start: isEven ? lastSunday.toDate() : lastSunday.subtract(1, 'week').toDate(),
      end: lastSunday.add(isEven ? 1 : 0, 'week').day(6).endOf('day').toDate(),
      // payDate is the next Tuesday after the "end" week
      payDate: lastSunday.add(isEven ? 2 : 1, 'week').day(2).toDate()
    };
    return payPeriod;
  }

  public static payPeriodbyPaidDate(givenPaidDate: Date): IPayrollPeriod {
    // DO NOT convert to "America/New_York" timezone, handle on backend
    const payPeriod = {
      start: dayjs(givenPaidDate).startOf('week').subtract(2, 'week').toDate(),
      end: dayjs(givenPaidDate).endOf('week').subtract(1, 'week').toDate(),
      // payDate is the next Tuesday after the "end" week
      payDate: dayjs(givenPaidDate).startOf('day').toDate()
    };

    return payPeriod;
  }

  public static isPaidDate(givenDate: Date): boolean {
    const { payDate } = NefcoDateHelper.currentPayPeriod(givenDate);
    // Need to subtract 2 weeks because the "payDate" is jumping to the next week when this is supposed to be "true"
    return dayjs(givenDate).isBetween(dayjs(payDate).startOf('day').subtract(2, 'week'), dayjs(payDate).endOf('day').subtract(2, 'week'), null, '[]');
  }

  public static toLocal(givenDate: Date): Date {
    return new Date(dayjs(givenDate).utc().format('MM/DD/YYYY'));
  }

  public static toUtc(givenDate: Date, addHours = 0): string {
    const formattedDate = dayjs(givenDate);
    const month = formattedDate.month();
    const day = formattedDate.date();
    const year = formattedDate.year();

    return dayjs(new Date(year, month, day)).add(addHours, 'hour').utc(true).format();
  }

  public static toUtcStartOfDay(givenDate: Date, start = true): string {
    const formattedDate = dayjs(givenDate);
    const month = formattedDate.month();
    const day = formattedDate.date();
    const year = formattedDate.year();

    if (start) {
      return dayjs(new Date(year, month, day)).startOf('day').utc(true).format();
    } else {
      return dayjs(new Date(year, month, day)).endOf('day').utc(true).format();
    }
  }

  public static fromEstToLocal(givenDate: Date): Date {
    // Convert to "America/New_York", since we are storing every time value as that
    const formattedDate = dayjs(givenDate);
    const month = formattedDate.month();
    const day = formattedDate.date();
    const year = formattedDate.year();

    // Translate this to the local time since the datepicker update won't work for Angular v10
    // Handle conversion to correct timezone in service.ts file with toUtc() function
    return dayjs(new Date(year, month, day)).toDate();
  }

  public static dateFilterString(startDate: Date, endDate: Date): string {
    const formattedDates = {
      startDate: startDate ? NefcoDateHelper.toUtcStartOfDay(startDate) : null,
      endDate: endDate ? NefcoDateHelper.toUtcStartOfDay(endDate, false) : null
    };

    return JSON.stringify(formattedDates);
  }

  // This is used for the date selector for loss address
  public static toEstToUtcTime(givenDate: Date): string {
    const formattedDate = dayjs(givenDate).utc();
    const updatedTime = formattedDate.format('HH:mm:ss');
    return updatedTime;
  }

  // This is used to combine loss date and loss address
  // Do this using dayJs because native JS Date might have given issues depending on timezone
  public static toUtcTime(givenDate: Date, givenTime: string): string {

    const [hours, minutes, seconds] = givenTime.split(":");
    if (givenDate && hours && minutes && seconds) {

      const formattedDate = dayjs(givenDate).utc(true)
        .add(parseInt(hours, 10), 'hour')
        .add(parseInt(minutes, 10), 'minute')
        .add(parseInt(seconds, 10), 'second')
        .format();

      return formattedDate;
    } else {
      return null;
    }
  }


  public static getYearList() {
    const selectedYear = new Date().getFullYear();
    const years: { text: string, value: string }[] = [];
    for (let year = selectedYear; year >= 1900; year--) {
      years.push({ text: `${year}`, value: `${year}` });
    }
    return years;
  }

  public static dateFilterStringUTCFromLocal(startDate: Date | string, endDate: Date | string): string {
    const formattedDates = {
      startDate: startDate ? new Date(startDate).toISOString() : null,
      endDate: endDate ? new Date(endDate).toISOString() : null
    };

    return JSON.stringify(formattedDates);
  }


}
