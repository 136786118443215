<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationAssignStaffCreate" #investigationAssignStaffCreate="appEnforcePermission"></div>
<!-- Permissions -->
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Investigators
      <button mat-raised-button (click)="findStaff(roleNames.Investigator)" [disabled]="!roles" *ngIf="investigationAssignStaffCreate?.enabled">
        <mat-icon color="accent" matSuffix>location_on</mat-icon> Find an Investigator
      </button>
    </mat-card-title>
  </mat-card-header>
  <app-investigation-assignments-table [columnsToDisplay]="investigatorColumns" [data]="assignedInvestigators" [investigationId]="investigation.id" (updated)="this.updated.emit()" (deleted)="getStaff()"></app-investigation-assignments-table>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      Tech Reviewers
      <app-user-select [userViewFilter]="userViewFilter" #tselect class="d-inline-block ml-2" placeholder="Search ..." (selectionChanged)="addStaff($event) && tselect.select(null)" investigationRole="TECH_REVIEWER"></app-user-select>
    </mat-card-title>
  </mat-card-header>
  <app-investigation-assignments-table [columnsToDisplay]="techReviewerColumns" [data]="assignedTechReviewers" [investigationId]="investigation.id" (deleted)="getStaff()"></app-investigation-assignments-table>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div class="otherStaff" *ngIf="investigationAssignStaffCreate?.enabled">
        <app-role-select class="d-inline-block ml-2" #otherSelect [config]="roleSelectConfig"></app-role-select>
        <button mat-raised-button (click)="findStaff(otherSelect.selectedRole.title)" [disabled]="!otherSelect.roleId || !roles">
          <mat-icon color="accent" matSuffix>location_on</mat-icon> Find Staff
        </button>
        <!-- <app-user-select #uselect class="d-inline-block" [hidden]="!otherSelect.roleId" placeholder="Search ..." (selectionChanged)="addStaff($event?.id, otherSelect.selectedRole?.title) && uselect.select(null)" [investigationRole]="otherSelect.selectedRole?.title"></app-user-select> -->
      </div>
      Experts/Other
    </mat-card-title>
  </mat-card-header>
  <app-investigation-assignments-table [columnsToDisplay]="otherStaffColumns" [data]="assignedOther" [investigationId]="investigation.id" (deleted)="getStaff()"></app-investigation-assignments-table>
</mat-card>