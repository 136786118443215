import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { IInvestigationTableConfig, TableType, Columns } from "src/app/components/shared/investigations-table-view/investigations-table/investigations-table.component";
import { InvestigationStatuses } from "src/app/shared/modules/graphql/enums/investigation.enums";
import { IApiCertification, IApiCertificationFilter, IApiCertificationFilterType, IApiInvestigationStatusNames, IApiUser, IApiUserDetail, IApiVehicle, IApiVehicleFilterType } from 'src/app/shared/modules/graphql/types/types';
import { AuthService, CertificationService, UserService } from 'src/app/shared/services';
import { VehicleService } from 'src/app/shared/services/vehicle';
import { StaffAddCertificationModalComponent } from 'src/app/components/shared/staff/staff-add-certification-modal/staff-add-certification-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { StaffCertificationsComponent } from 'src/app/components/shared/staff/staff-certifications/staff-certifications.component';
import {
  staffContactInformationView,
  staffContactInformationOwnView,
  staffEmergencyInformationView,
  staffEmergencyInformationOwnView,
  staffUserInformationView,
  staffUserInformationOwnView,
  staffCertificationsList,
  staffInvestigationsList,
  staffCertificationsCreate,
  staffCertificationsOwnCreate,
  staffCertificationsOwnList,
  staffInvestigationsOwnList,
} from "src/app/shared/helpers/auth-config/staff.config";

@UntilDestroy()
@Component({
  selector: 'app-staff-management',
  templateUrl: './staff-management.component.html',
  styleUrls: ['./staff-management.component.scss'],
})
export class StaffManagementComponent implements OnInit, AfterViewInit{

  @ViewChild(StaffCertificationsComponent) staffCert: StaffCertificationsComponent;
  @ViewChild("staffCertificationsList") staffCertificationsListPerm;
  @ViewChild("staffCertificationsOwnList") staffCertificationsOwnListPerm;

  public authConfig = {
    staffContactInformationView,
    staffContactInformationOwnView,
    staffEmergencyInformationView,
    staffEmergencyInformationOwnView,
    staffUserInformationView,
    staffUserInformationOwnView,
    staffCertificationsList,
    staffInvestigationsList,
    staffCertificationsCreate,
    staffCertificationsOwnList,
    staffInvestigationsOwnList,
    staffCertificationsOwnCreate
  };

  public tabs = ['profile', 'certifications', 'investigations'];
  public managementTabIndex = 0;

  public staffName: string;

  private staffId: string;
  private user: IApiUser;
  public authenticatedUserId: string = null;
  public get isOwnerUser(): boolean {
    return (this.activatedRoute.snapshot.params.id) === this.authenticatedUserId;
  }

  public get getCurrentTabIndex(): number {
    return this.staffCertificationsListPerm?.enabled || (this.staffCertificationsOwnListPerm?.enabled && this.isOwnerUser) ? 2 : 1;
  }

  public certifications: IApiCertification[] = null;

  // User/staff investigation table setup
  public userConfig: IInvestigationTableConfig = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) { }

  public back() {
    return this.router.navigate(['/admin/staff/list']);
  }

  public openSidebar() {
    // TODO open sidebar
  }

  public addCertification() {

    this.dialog.open(StaffAddCertificationModalComponent, {
      width: "40%",
      disableClose: true,
      data: { User: this.user }
    }).afterClosed().pipe(
      filter((v) => !!v)
    )
      .subscribe(({ reload }) => {
        if (!!reload) {
          this.staffCert.loadData();
        }
      });
  }

  public changeTab(tab: string) {
    this.router.navigate([], { queryParams: { tab }, relativeTo: this.activatedRoute });
  }

  public ngOnInit() {

    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);

    // Get user id
    this.activatedRoute.params.pipe(
      untilDestroyed(this),
      switchMap(({ id }) => {
        this.userConfig = {
          type: TableType.user,
          contactId: id,
          displayedColumns: [Columns.createdAt, Columns.nefcoNumber, Columns.priority, Columns.lossDate, Columns.riskType, Columns.investigationLocation, Columns.status],
          viewType: null,
          startWithCount: false
        };
        this.staffId = id;
        return this.userService.getByIdWithoutIntestigationRole(id);
      })
    ).subscribe((user) => {
      this.user = user;
      this.staffName = `${user.firstName || ''} ${user.lastName || ''}`
    });

    // TODO get user data

  }

  public ngAfterViewInit(){
    this.activatedRoute.queryParams.pipe(
      untilDestroyed(this)
    ).subscribe(({ tab }) => {
      switch (tab) {
        case "profile":
          this.managementTabIndex = 0;
          break;
        case "certifications":
          this.managementTabIndex = 1;
          break;
        case "investigations":
          this.managementTabIndex = this.getCurrentTabIndex;
          break;
      }
    });
    this.cdr.detectChanges();
  }

  onTabChanged(e){
    this.changeTab(e?.tab?.textLabel || '');
  }
}
