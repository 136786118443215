import { AfterViewInit, OnChanges, ViewChild } from '@angular/core';
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { Subject, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { IApiInvestigation, IApiUser, IApiUserAvailabilityInput } from '../../modules/graphql/types/types';
import { InvestigationOnSceneService, UserService } from '../../services';
import { CalendarDetailModalComponent } from './calendar-detail-modal/calendar-detail-modal.component';
import { EventStyleArgs, SchedulerEvent } from '@progress/kendo-angular-scheduler';
import dayjs from 'dayjs';
import { InvestigationEditScheduleDateComponent } from 'src/app/components/shared/investigations/investigation-edit-schedule-date/investigation-edit-schedule-date.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationsService } from '../../modules/notifications/notifications.service';
import { LoaderService } from '../../modules/loader/loader.service';

export interface ICalendarEvent {
  title: string;
  start: Date;
  end: Date;
  startTime?: string;
  endTime?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() public investigator: IApiUser;
  @Input() public investigation: IApiInvestigation;
  @Input() public from: string;

  @ViewChild("calendar", { static: false }) calendar: any;
  @ViewChild("scheduler", { static: false }) scheduler: any;



  public buttonClicked = new Subject<string>();

  public activeDate = moment().toISOString();
  public selectedDate: Date = new Date();
  public selectedViewIndex = 1;
  public calendarLoading = false;

  public calendarViews = [
    { text: "Day", value: "day" },
    { text: "Month", value: "month" }
  ];

  public eventsRequest: IApiUserAvailabilityInput = {
    msGraphId: null,
    userEmails: [],
    startTime: this.isoMonth(true),
    endTime: this.isoMonth(false),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  public calendarOptions = {
    headerToolbar: {
      start: "",
      center: "title",
      end: ""
    },
    initialView: "timeGridDay",
    initialDate: new Date().toISOString(),
    titleFormat: { year: "numeric", month: "long", day: "numeric" },
    events: []
  };

  public isViewEventModelOpen: boolean = false;
  public viewEventData = null;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private notifications: NotificationsService,
    private loader: LoaderService,
    private investigationOnSceneService: InvestigationOnSceneService

  ) {
    // debounce button click to prevent accidental double clicks and more safely retrieve data
    this.buttonClicked.pipe(
      untilDestroyed(this),
      debounceTime(200)
    ).subscribe(direction => this.dateChange(direction));

  }

  public ngOnInit() {
    this.fetchUserByGraphId();
  }

  public fetchUserByGraphId() {
    this.userService.getByIdOnlyMsGraphId(this.investigator.id).pipe(
      take(1),
      switchMap((user) => {
        this.eventsRequest = {
          ...this.eventsRequest,
          userEmails: [user.msGraphId],
          msGraphId: user.msGraphId
        };
        return this.getEvents();
      })
    ).subscribe(() => { }, error => {
      this.calendarLoading = false;
      this.calendarOptions.events = [];
    });
  }

  public ngOnChanges(data) {
    if (data?.investigator?.firstChange === false) {
      this.fetchUserByGraphId();
    }
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  private getEvents() {
    this.calendarLoading = true;
    if (this.eventsRequest?.msGraphId) {
      return this.userService.getUsersAvailability(this.eventsRequest).pipe(
        // convert our msGraph data to event object the calendar component can consume
        map((events: any[]) => {
          this.calendarLoading = false;
          return events?.map((e: any) => {
            const calendarEvent: any = {
              title: e.subject, start: new Date(e.start.dateTime), end: new Date(e.end.dateTime), id: e.id, onScene: e.onScene, location: e.location
            };
            return calendarEvent;
          });
        }),
        tap((availability) => this.calendarOptions.events = availability)
      );
    } else {
      this.calendarLoading = false;
      return of(null);
    }

  }

  private isoMonth(start: boolean, date?): string {
    return start ? moment(date).startOf("month").toISOString() : moment(date).endOf("month").toISOString();
  }

  public calendarViewChange(event: MatSelectChange) {
    const cal = this.calendar.getApi();
    switch (event.value) {
      case "month":
        this.calendarOptions.initialView = "dayGridMonth";
        break;
      case "day":
        this.calendarOptions.initialView = "timeGridDay";
        break;
    }
    cal.changeView(this.calendarOptions.initialView);
  }

  public dateChange(e) {
    const currentDate = e.selectedDate;
    // only get more events if we are in a new month
    if (moment(this.activeDate).get("month") !== moment(currentDate).get("month")) {
      this.eventsRequest.startTime = this.isoMonth(true, currentDate);
      this.eventsRequest.endTime = this.isoMonth(false, currentDate);
      this.getEvents().pipe(take(1)).subscribe();
      // Keep track of what month's events we currently have
      this.activeDate = currentDate.toISOString();
    }
  }

  private calendarTooltip(event: ICalendarEvent) {
    // width and position will center it in the sidebar modal.. based on a width of 45%
    this.dialog.open(CalendarDetailModalComponent, {
      data: event,
      width: "30%",
      position: {
        right: "7.5%",
      }
    });
  }

  setCalendarViewIndex(number) {
    this.selectedViewIndex = number;
  }

  public getSlotClass = (args) => {
    const date = args.start;
    if (date.toLocaleDateString() === this.selectedDate.toLocaleDateString()) {
      return {
        highlightDate: true,
      };
    }
    if (dayjs(date).isBefore(dayjs(this.selectedDate))) {
      return {
        disabledSlot: true,
      };
    }
  };

  public getEventClass = (args: EventStyleArgs) => {
    const start = dayjs(args.event.dataItem.start);
    const end = dayjs(args.event.dataItem.end);
    const past = dayjs(args.event.dataItem.start).isBefore(dayjs()) ? 'disabledSlot' : "";
    return start.get('date') !== end.get('date') ? `multi-day ${past}` : `single-day ${past}`;
  };

  public reload() {
    this.fetchUserByGraphId();
  }

  public viewEvent(e) {
    // only allow future events to edit
    if (e?.event?.dataItem?.onScene?.id && !dayjs(e?.event?.start).isBefore(dayjs())) {
      const eData = e.event.dataItem;
      delete eData.onScene.onSceneCount;
      this.viewEventData = {
        id: eData.id,
        investigationId: eData?.onScene?.InvestigationId,
        nefcoNumber: eData?.onScene?.Investigation?.nefcoNumber,
        userId: eData?.onScene?.UserId,
        userName: `${eData?.onScene?.User?.firstName || ''} ${eData?.onScene?.User?.lastName || ''}`?.trim(),
        scheduledDate: eData?.onScene?.scheduledDate,
        location: `${eData?.location?.address?.street || ''}\n${eData?.location?.address?.city}, ${eData?.location?.address?.state} ${eData?.location?.address?.postalCode}`?.trim(),
        onSceneId: eData?.onScene?.id,
        start: eData?.start,
        end: eData?.end,
        onScene: eData?.onScene
      }

      this.toggleViewEventModel(true);
    }
  }

  public toggleViewEventModel(action = false) {
    this.isViewEventModelOpen = action;
    if (this.isViewEventModelOpen) {
      /* Not needed due to modal is open */
      // document.body.classList.add("kendo-dialog-open");
    } else {
      this.viewEventData = null;
      /* Not needed due to modal is open */
      // document.body.classList.remove('kendo-dialog-open');
    }
  }

  public editDetails() {
    this.isViewEventModelOpen = false;
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEditScheduleDateComponent,
      width: 800,
      maxWidth: 800,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const userInfo = dialog.content.instance as InvestigationEditScheduleDateComponent;
    userInfo.investigationStaff = this.viewEventData?.onScene?.Investigation?.Staff;
    const onScene = this.viewEventData?.onScene;
    if (onScene?.id) {
      userInfo.investigationOnScenes = [
        { ...onScene }
      ];
    }

    userInfo.investigation = this.viewEventData?.onScene?.Investigation;
    userInfo.headerIcon = 'assets/svg/warning.svg';
    userInfo.mode = "EDIT_PENDING_SCHEDULE_CALENDAR";

    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.fetchUserByGraphId();
      }
    })
  }

  deleteSingle(id): void {
    this.notifications.kendoConfirm(
      "Are you sure you want to delete this on scene investigation? All data associated with this on scene investigation will be removed from Extranet 3.",
      "Delete On Scene Investigation?",
      "No, Don’t Delete",
      "Yes, Delete",
      600,
      true,
      (this.investigation?.nefcoNumber ? `Investigation ID: ${this.investigation?.nefcoNumber}` : "")
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() =>
          this.loader.show$(
            this.investigationOnSceneService.updateInvestigationOnSceneScheduledDate(
              {
                removeIds: [id],
                data: []
              }
            )
          ))
      )
      .subscribe(() => {
        this.toggleViewEventModel(false);
        this.fetchUserByGraphId();
      });
  }

}
