<!-- Permissions -->
<div [appEnforcePermission]="authConfig.companiesBranchesList" #companiesBranchesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companiesBranchesCreate" #companiesBranchesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contacts" #contacts="appEnforcePermission"></div>
<!-- Permissions -->

<section *ngIf="company">
  <div class="row page-title-container">
    <div class="col">
      <h4 class="page-title" [innerHTML]="company.name"></h4>
    </div>
    <div class="col text-right">
        <button mat-button color="accent" (click)="goBack()">
          <mat-icon color="accent">keyboard_backspace</mat-icon>
          <span class="text-uppercase">Back</span>
        </button>
        <button mat-button color="accent">
          <mat-icon aria-hidden="false" color="accent">open_in_new</mat-icon>
        </button>
    </div>
  </div>
  <mat-card>
    <div class="mainWrapper">
      <div class="buttonContainer">
        <button mat-raised-button (click)="addContact()" class="rounded-button" *ngIf="contactsCreateConfig?.enabled">
          <mat-icon color="accent">add</mat-icon>
          Add Contact
        </button>
        <button mat-raised-button (click)="addBranch()" class="rounded-button ml-2" *ngIf="companiesBranchesCreate?.enabled">
          <mat-icon color="accent">add</mat-icon>
          Add Branch
        </button>
      </div>
      <div class="tabs">
        <mat-tab-group color="accent" [selectedIndex]="selectedTab">
          <mat-tab label="Company">
            <app-company-information [company]="company" (reload)="reload()"></app-company-information>
          </mat-tab>
          <mat-tab *ngIf="companiesBranchesList?.enabled">
            <ng-template mat-tab-label>
              <div (click)="branchList.load()">
                Branches
              </div>
            </ng-template>
            <app-company-branch-list [company]="company" #branchList></app-company-branch-list>
          </mat-tab>
          <mat-tab *ngIf="contacts?.enabled">
            <ng-template mat-tab-label>
              <div (click)="contactList.load()">
                Contacts
              </div>
            </ng-template>
              <app-contacts-list [config]="contactListConfig" #contactList></app-contacts-list>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-card>
</section>
