<!-- Permissions -->
<div [appEnforcePermission]="authConfig.bookKeepingInvoiceListCreate" #bookKeepingInvoiceListCreate="appEnforcePermission"></div>
<!-- Permissions -->
<h4>Evidence Billing</h4>
<mat-tab-group (selectedTabChange)="tabsChanged($event)">
  <mat-tab label="To Be Invoiced">
    <ng-container *ngTemplateOutlet="tableArea; context: {invoiced: false, dataSource: dataSource}">
    </ng-container>
  </mat-tab>
  <mat-tab label="Recently Invoiced">
    <ng-container *ngTemplateOutlet="tableArea; context: {invoiced: true, dataSource: dataSource}"></ng-container>
  </mat-tab>
</mat-tab-group>

<ng-template #tableArea let-invoiced="invoiced" let-dataSource="dataSource">
  <section class="p-5">
    <div class="row align-items-center">
      <div class="col-2">
        <app-contact-select (selectionChanged)="filterTable(filterOptions.EvidenceBillingBillto, $event?.id)" placeholder="Search for bill to"></app-contact-select>
      </div>
      <div class="col-2">
        <app-contact-select (selectionChanged)="filterTable(filterOptions.EvidenceBillingClient, $event?.id)" placeholder="Search for client"></app-contact-select>
      </div>
      <div class="col-2">
        <app-user-select [userViewFilter]="userViewFilter" *ngIf="invoiced" (selectionChanged)="filterTable(filterOptions.EvidenceBillingCreatedby, $event?.id)" placeholder="Search for created by"></app-user-select>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" (appDebouncedInputChange)="filterTable(filterOptions.EvidenceBillingSearch, $event)" name="search" />
          <button mat-icon-button disableRipple matSuffix (click)="searchValue = null; filterTable(filterOptions.EvidenceBillingSearch, null)">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <!-- Table Info -->
    <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows (matSortChange)="pageOptions.sort($event)">
      <ng-container matColumnDef="CREATED_AT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Bill Date </th>
        <td mat-cell *matCellDef="let row">
          <span class="ml-2 pb-4" *ngIf="row.createdAt">{{ row.createdAt | customDate: 'MM/dd/yyyy' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="invId">
        <th mat-header-cell *matHeaderCellDef> Nefco # </th>
        <td mat-cell *matCellDef="let row">
          <a [routerLink]="['/investigations/', row.Investigation.id]" target="_blank" [innerHTML]="row?.Investigation?.nefcoNumber"></a>
        </td>
      </ng-container>
      <ng-container matColumnDef="EXPENSE_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Bill </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.Investigation?.EvidenceBilling?.lastExpensed | customDate: 'MM/dd/yyyy'"></td>
      </ng-container>
      <ng-container matColumnDef="schedule">
        <th mat-header-cell *matHeaderCellDef> Schedule </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.Investigation?.EvidenceBilling?.BillingFrequency?.name"></td>
      </ng-container>
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef> Billable </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.billableQuantity"></td>
      </ng-container>
      <ng-container matColumnDef="pallets">
        <th mat-header-cell *matHeaderCellDef> Pallets </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.Investigation?.EvidenceBilling?.palletCount"></td>
      </ng-container>
      <ng-container matColumnDef="billingRate">
        <th mat-header-cell *matHeaderCellDef> Rate </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.Investigation?.EvidenceBilling?.rate"></td>
      </ng-container>
      <ng-container matColumnDef="billTo">
        <th mat-header-cell *matHeaderCellDef> Bill To </th>
        <td mat-cell *matCellDef="let row">
          {{ row.Investigation.BillTo | fullName }}<br />
          <app-address-view *ngIf="row?.Investigation?.BillToBranch; else BillToAddress" [address]="row?.Investigation?.BillToBranch?.BillingAddress"></app-address-view>
          <ng-template #BillToAddress><app-address-view [address]="row?.Investigation?.BillTo?.BillingAddress"></app-address-view></ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef> Client </th>
        <td mat-cell *matCellDef="let row">
          {{ row.Investigation.Client | fullName }} <br />
          <app-address-view *ngIf="row?.Investigation?.ClientBranch; else ClientAddress" [address]="row?.Investigation?.ClientBranch?.Addresses | primaryAssociation"></app-address-view>
          <ng-template #ClientAddress><app-address-view [address]="row?.Investigation?.Client?.Addresses | primaryAssociation"></app-address-view></ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button *ngIf="!invoiced && bookKeepingInvoiceListCreate?.enabled" mat-raised-button color="primary" (click)="createInvoice($event, row);">Create Invoice</button>
        </td>
      </ng-container>
      <!-- Expandable Row -->
      <ng-container matColumnDef="details">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail justify-content-center" [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">
            <mat-card class="p-3 my-2 mat-elevation-z3 expanded-content">
              <table mat-table [dataSource]="row.Investigation?.Evidence">
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> Evidence # </th>
                  <td mat-cell *matCellDef="let ev">
                    <a [routerLink]="['/investigations/', row.Investigation.id]" [queryParams]="{tab: 'evidence'}" [innerHTML]="ev.evidenceNumber"></a>
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef> Description </th>
                  <td mat-cell *matCellDef="let ev" [innerHTML]="ev.description">
                  </td>
                </ng-container>
                <ng-container matColumnDef="location">
                  <th mat-header-cell *matHeaderCellDef> Location </th>
                  <td mat-cell *matCellDef="let ev" [innerHTML]="ev.EvidenceStorageLocation?.name">
                  </td>
                </ng-container>
                <ng-container matColumnDef="arrival">
                  <th mat-header-cell *matHeaderCellDef> Arrival Date </th>
                  <td mat-cell *matCellDef="let ev" [innerHTML]="ev.createdAt | customDate: 'MM/dd/yyyy'">
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="expandedRowCols" class="text-left"></tr>
                <tr mat-row *matRowDef="let ev; columns: expandedRowCols" class="text-left"></tr>
              </table>
            </mat-card>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element;">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['details']" class="example-detail-row"></tr>
    </table>
    <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
  </section>
</ng-template>
