import { EventEmitter } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AfterViewInit, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IApiContact, IApiCompany, IApiInvestigation, IApiBranch } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationSelectContactCompanyModalComponent } from '../investigation-select-contact-company-modal/investigation-select-contact-company-modal.component';

export interface IClientDetails {
  Client: IApiContact;
  BillTo: IApiContact;
  Company: IApiCompany;
  ClientBranch: IApiBranch;
  BillToBranch: IApiBranch;
  newBillTo: boolean;
  newClient: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-client',
  templateUrl: './investigation-client.component.html',
  styleUrls: ['./investigation-client.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InvestigationClientComponent),
      multi: true
    }
  ]
})
export class InvestigationClientComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, OnChanges {

  private _investigation: IApiInvestigation;
  @Input() set investigation(val: IApiInvestigation) {
    this._investigation = val;
    this.f.Client.setValue(val.Client);
    this.f.ClientBranch.setValue(val.ClientBranch);
    this.f.Company.setValue(val.Company);
    if (this._investigation?.BillTo?.id !== this._investigation?.Client?.id) {
      this.f.isBillToClient.setValue(false);
      this.f.BillTo.setValue(val.BillTo);
      this.f.BillToBranch.setValue(val.BillToBranch);
    }
  }
  
  @Input() set resetForm(val: boolean) {
    if (val) {
      this.clientForm.reset();
    }
  }
  
  @Input() touched: boolean;
  @Output() selected = new EventEmitter<IClientDetails>();

  clientForm = this.fb.group({
    Client: [null, Validators.required],
    BillTo: [null, Validators.required],
    Company: [null],
    ClientBranch: [null],
    BillToBranch: [null],
    isBillToClient: [true],
    isBillingRulesClient: [true],
    newBillTo: [false],
    newClient: [false],
  });

  private _subscription = new Subscription();

  public onChange: any = (_: IClientDetails) => { };
  public onTouch: any = () => { };

  public get isClientBilling(): boolean {
    if (!this.f?.Client?.value?.id && !this.f?.Company?.value?.id) return true;
    return this.f.Client.value?.Branches?.find(({ Company: { id }}) => id === this.f.Company.value?.id);
  }
  public set isClientBilling(val: boolean) {
    if (!this.f?.Client?.value?.id) return;
    this.f.Company.reset(val ? this.f.ClientBranch.value.Company : null);
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private notifications: NotificationsService
  ) { }

  public removeClient() {
    if (this.isClientBilling) this.f.Company.reset(null);
    this.f.Client.reset(null);
    this.changed();
  }

  public removeBillTo() {
    this.f.BillTo.reset(null);
    this.f.BillToBranch.reset(null);
    this.f.isBillToClient.setValue(false);
    this.changed();
  }

  public removeBillingRulesCompany() {
    this.f.Company.reset(null);
    this.changed();
  }

  public ngOnInit() {

    // Client
    this._subscription.add(
      this.f.Client.valueChanges.subscribe((val) => {
        if (this.f.isBillToClient.value) this.f.BillTo.setValue(val);
      })
    );

    // Client Branch
    this._subscription.add(
      this.f.ClientBranch.valueChanges.subscribe((val) => {
        if (this.f.isBillToClient.value) this.f.BillToBranch.setValue(val);
        if (val && this.f.isBillingRulesClient.value) this.f.Company.setValue(val?.Company || null);
      })
    );

    // isBillToClient
    this._subscription.add(
      this.f.isBillToClient.valueChanges
        .subscribe((val) => {
          
          if (val) {
            this.f.BillTo.setValue(this.f.Client.value);
            this.f.BillToBranch.setValue(this.f.ClientBranch.value);
          } else {
            this.f.BillTo.reset();
            this.f.BillToBranch.reset();
          }
        })
    );

    // isBillingRulesClient
    this._subscription.add(
      this.f.isBillingRulesClient.valueChanges
        .subscribe((val) => {
          if (val) {
            this.f.Company.setValue(this.f.ClientBranch.value.Company);
          } else {
            this.f.Company.reset();
          }
        })
    );
  }

  private changed(value?) {
    this.selected.emit(value);
    this.onChange(value);
  }

  public ngAfterViewInit() {
    // Form Changes
    this._subscription.add(
      this.clientForm.valueChanges.pipe(
        filter(result => this.clientForm.valid),
      ).subscribe((value) => {
        this.changed(value);
      })
    );
  }

  public selectBillingRulesCompany(company) {
    this.f.Company.setValue(company);
  }

  public selectBillTo() {
    const dialogRef = this.dialog.open(InvestigationSelectContactCompanyModalComponent, {
      width: '80%',
      data: {
        partyAdd: true,
      }
    });
    dialogRef.afterClosed().pipe(
      filter(result => !!result),
      this.notifications.catchAlertPipe("There was an error setting the BillTo contact. Please try again")
    ).subscribe(({contact, branch = null, newContact}) => {
      // Set BillTo Branch
      this.f.BillTo.setValue(contact);
      this.f.BillToBranch.setValue(branch);
      this.f.newBillTo.setValue(newContact);
    });
  }

  public selectClient() {
    const dialogRef = this.dialog.open(InvestigationSelectContactCompanyModalComponent, {
      width: '90%',
      height: "90vh",
      data: {
        partyAdd: true,
      }
    });
    dialogRef.afterClosed().pipe(
      filter(result => !!result),
      this.notifications.catchAlertPipe("There was an error setting the Client contact. Please try again.")
    ).subscribe(({contact, branch = null, newContact}) => {
      // Set Client Branch
      this.f.Client.setValue(contact);
      this.f.ClientBranch.setValue(branch);
      this.f.newClient.setValue(newContact);
    });
  }

  // Easy control access
  public get f() { return this.clientForm.controls; }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.touched && simpleChanges.touched.currentValue) {
      this.clientForm.markAllAsTouched();
    }
  }

  public writeValue(value: null | IClientDetails): void {
    if (value) {
      this.clientForm.reset(value);
    }
  }

  public registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (_: IClientDetails) => {}): void {
    this.onTouch = fn;
  }
}
