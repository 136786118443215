<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsCreate" #manageEvidenceLocationsCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsUpdate" #manageEvidenceLocationsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsDelete" #manageEvidenceLocationsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsDeactivate" #manageEvidenceLocationsDeactivate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="content-wrapper">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'Select state'"
          [selectedId]="filterValue(filterTypes.State)"
          (selectionChanged)="setFilters($event, filterTypes.State)"></app-state-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <app-evidence-storage-location-multi-select-kendo label="Location" placeholder="Search location"
          [selectedId]="filterValue(filterTypes.Locations)"
          (selectionChanged)="setFilters($event, filterTypes.Locations)"></app-evidence-storage-location-multi-select-kendo>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="d-flex">
        <div class="mr-16 applied-filter">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="load()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="mt-5 clear-filter">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="2" class="filter-actions right-group">
        <form #form="ngForm">
          <mat-button-toggle-group #active="matButtonToggleGroup" name="status" [(ngModel)]="selectedStatus"
            (ngModelChange)="load()" class="ml-3">
            <mat-button-toggle [value]="null">All</mat-button-toggle>
            <mat-button-toggle [value]="1">Active</mat-button-toggle>
            <mat-button-toggle [value]="0">Inactive</mat-button-toggle>
          </mat-button-toggle-group>
        </form>
        <button mat-raised-button class="rounded-button" (click)="openAddNewLocationModal()" *ngIf="manageEvidenceLocationsCreate?.enabled">
          <mat-icon color="primary">playlist_add</mat-icon>
          New Location
        </button>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)">
      <ng-container matColumnDef="NAME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location Name</th>
        <td mat-cell *matCellDef="let item">
          <a appEventLink (click)="edit(item)" [innerHTML]="item.name" *ngIf="manageEvidenceLocationsUpdate?.enabled else onlyName"></a>
          <ng-template #onlyName>
            {{item.name}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="TYPE">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let item" [innerHTML]="item.type"></td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Company Name/<br />Address</th>
        <td mat-cell *matCellDef="let item">
          {{ item.companyName }}
          <br />
          <app-address-view [address]="item.Address"></app-address-view>
        </td>
      </ng-container>
      <ng-container matColumnDef="contactInfo">
        <th mat-header-cell *matHeaderCellDef>Contact/<br />Phone</th>
        <td mat-cell *matCellDef="let item">
          {{ item.contactName }}
          <br />
          <app-email-phone-view [phone]="item.Phone"></app-email-phone-view>
        </td>
      </ng-container>
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef>Details</th>
        <td mat-cell *matCellDef="let item" [innerHTML]="item.hasDetails ? 'Yes' : 'No'"></td>
      </ng-container>
      <ng-container matColumnDef="additionalDetails">
        <th mat-header-cell *matHeaderCellDef>Additional<br />Details</th>
        <td mat-cell *matCellDef="let item" [innerHTML]="item.additionalDetails"></td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{manageEvidenceLocationsUpdate?.enabled || manageEvidenceLocationsDelete?.enabled || manageEvidenceLocationsDeactivate?.enabled ? 'Actions' : ''}}
        </th>
        <td mat-cell *matCellDef="let item">
          <button mat-icon-button [matMenuTriggerFor]="catAction" *ngIf="manageEvidenceLocationsUpdate?.enabled || manageEvidenceLocationsDelete?.enabled || manageEvidenceLocationsDeactivate?.enabled">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #catAction="matMenu">
            <button mat-menu-item (click)="edit(item)" *ngIf="manageEvidenceLocationsUpdate?.enabled">Edit</button>
            <button mat-menu-item (click)="toggleActivation(item)" *ngIf="manageEvidenceLocationsDeactivate?.enabled"
              [innerHTML]="(!item.isActive ? 'Activate' : 'Deactivate')"></button>
            <!-- TODO disable based on some condition -->
            <button mat-menu-item (click)="deleteLocation(item.id)" *ngIf="manageEvidenceLocationsDelete?.enabled">Delete</button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="text-center mat-cell" [attr.colspan]="7">
          There is no result to display.
        </td>
      </tr>
    </table>
    <mat-paginator #paginator [length]="pageOptions.totalCount" [pageSize]="pageOptions.limit"
      [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions.paginate($event)"></mat-paginator>
  </div>
</section>