import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ExpenseService, InvestigationService } from 'src/app/shared/services';
import { ExpenseDataSource, ExpenseItemService } from 'src/app/shared/services/expenses';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiEvidenceBilling, IApiExpense, IApiExpenseFilterType, IApiExpenseItemFilterType, IApiExpenseItemOrderBy, IApiInvestigation, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { map, switchMap } from 'rxjs/operators';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SelectionModel } from '@angular/cdk/collections';
import { InvestigationTimeAndExpQuickbooksModalComponent } from '../../investigations';
import { MatDialog } from '@angular/material/dialog';
import { ViewportScroller } from '@angular/common';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import * as dayjs from 'dayjs';
import { bookKeepingInvoiceListCreate } from 'src/app/shared/helpers/auth-config/bookkeeping.config';

@Component({
  selector: 'app-bookkeeping-evidence-billing',
  templateUrl: './bookkeeping-evidence-billing.component.html',
  styleUrls: ['./bookkeeping-evidence-billing.component.scss'],
  // Allows triggering of expanding row
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BookkeepingEvidenceBillingComponent implements OnInit {

  public authConfig = {
    bookKeepingInvoiceListCreate
  };

  // Main Table parameters
  public displayedColumns = ["CREATED_AT", "invId", "EXPENSE_DATE", "schedule", "pallets", "billingRate", "rate", "billTo", "client", "action"];
  public expandedElement = null; // Add interface for data element

  // For table inside expanded row
  public expandedRowCols = ["id", "description", "location", "arrival"];

  public searchValue: string = null;

  // Selected Values
  public selectedBillTo = null;
  public selectedClient = null;
  public selectedInvestigation = null;
  public selectedCreator = null;

  // Select Options Lists
  public billToList = [];
  public clients = [];
  public investigations = [];
  public creators = [];

  public selection = new SelectionModel(true);

  public filterOptions = IApiExpenseFilterType;

  private _dataSource: ExpenseDataSource;
  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  constructor(
    private expenseService: ExpenseService,
    private investigationService: InvestigationService,
    private loader: LoaderService,
    private expenseItemService: ExpenseItemService,
    public dialog: MatDialog,
    private viewport: ViewportScroller,
  ) { }

  public createInvoice(event: MouseEvent, expense: IApiExpense) {
    event.stopPropagation();

    // Needed for click and drag to work in QB modal
    this.viewport.scrollToPosition([0, 0]);

    // Need full investigation for modal
    // Find investigation, then replace expenses with evidence expense only
    this.investigationService.getById(expense.Investigation.id).pipe(
      switchMap((investigation) => {
        return this.dialog.open(InvestigationTimeAndExpQuickbooksModalComponent, {
          width: '75%',
          height: '95vh',
          data: {
            // Had to do this way because overwriting the expense with the row data was causing values to not be passed through
            investigation: { ...investigation, Expenses: [investigation.Expenses.find((obj) => obj.id === expense.id)] }
          }
        }).afterClosed();
      }),
    ).subscribe(() => this.dataSource.load());
  }

  public selectEvidenceBilling({ checked }: MatCheckboxChange, row: IApiExpense) {
    if (checked) this.selection.select(row);
    else this.selection.deselect(row);
  }

  public filterTable(type: IApiExpenseFilterType, value: string) {
    this.dataSource.applyFilter(type, value);
    this.dataSource.load();
  }

  public tabsChanged(event: MatTabChangeEvent) {
    // Reset selection between tab changes
    this.selection.clear();
    if (event.index === 0) {
      this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, "false");
      this.dataSource.removeFilter(IApiExpenseFilterType.InvoicedDate);
    } else {
      this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, "true");
      this.dataSource.applyFilter(IApiExpenseFilterType.InvoicedDate, NefcoDateHelper.dateFilterString(dayjs().subtract(7, 'days').toDate(), dayjs().toDate()));
    }
    this.dataSource.load();
  }

  ngOnInit() {
    this._dataSource = new ExpenseDataSource(this.expenseService);
    this.loader.attachObservable(this.dataSource.loading$);
    this.expenseItemService.get([], { limit: -1, orderBy: IApiExpenseItemOrderBy.CreatedAt, sortOrder: SortOrder.DESC }).pipe(
      unwrapConnection(),
      map((items) => items.find(({ name }) => name.toLowerCase().includes('annual evidence storage')))
    ).subscribe((item) => {
      this.dataSource.applyFilter(IApiExpenseFilterType.EvidenceBillingItem, 'true');
      this.dataSource.applyFilter(IApiExpenseFilterType.ExpenseItem, item?.id);
      this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, "false");
      this.dataSource.load();
    });



  }

}
