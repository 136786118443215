import { tap, switchMap, filter } from 'rxjs/operators';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { forkJoin, of, throwError } from 'rxjs';
import { IApiAddBillingRuleInput, IApiAddCompanyInput, IApiBillingRule, IApiBillingRuleCategory, IApiBillingRuleType, IApiCertificationType, IApiCompany, IApiCompanyType, IApiExpenseItem, IApiExpenseType, IApiRiskType, IApiServiceType, IApiTimeEntryType, IApiUpdateBranchInput, IApiUpdateCompanyInput, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { CompanyService, ExpenseItemService, TimeEntryTypeService } from 'src/app/shared/services';
import { CompanyTypeService } from 'src/app/shared/services/company/company-type/company-type.service';
import { IStaffListConfig } from '../../staff/staff-list/staff-list.component';
import { ICompanyBranchConfig } from '../company-branch-create-modal/company-branch-create-modal.component';
import { FormExpenseType } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import {
  companiesBillingRulesCreate,
  companiesBillingRulesUpdate,
  companiesBillingRulesDelete,
  companiesEligeabilityCreate,
  companiesEligeabilityUpdate,
  companiesEligeabilityDelete,
  companiesRequiredServicesCreate,
  companiesRequiredServicesUpdate,
  companiesRequiredServicesDelete,
} from "src/app/shared/helpers/auth-config/companies.config";

export interface ICompanyCreateUpdateData {
  companyId: string;
}

export interface IApiBillingRuleAndType extends IApiBillingRule {
  type: string;
}

export interface IBillingOverrideType {
  typeId: string;
  typeName: string;
  type: string;
}

enum ICompanyCreateUpdateViewType {
  Company = "company",
  Staff = "staff",
  Certification = "certification",
  Services = "services"
}

@Component({
  selector: 'app-company-create-update',
  templateUrl: './company-create-update.component.html',
  styleUrls: ['./company-create-update.component.scss']
})
export class CompanyCreateUpdateComponent implements OnInit {
  public authConfig = {
    companiesBillingRulesCreate,
    companiesBillingRulesUpdate,
    companiesBillingRulesDelete,
    companiesEligeabilityCreate,
    companiesEligeabilityUpdate,
    companiesEligeabilityDelete,
    companiesRequiredServicesCreate,
    companiesRequiredServicesUpdate,
    companiesRequiredServicesDelete,
  };

  public formExpenseType: typeof FormExpenseType = FormExpenseType;

  public company: IApiCompany = {
    id: null,
    name: "",
    enterPropertyEnabled: false,
    recordedInterviewNotification: false,
    MainOffice: {
      id: null,
      Addresses: [],
      Emails: [],
      Phones: []
    },
    Type: {
      id: null,
      name: ""
    },
    RequiredCertifications: [],
    RequiredServices: [],
    ExcludedStaff: [],
    BillingRules: [],
    FlatRates: []
  };

  public officeConfig: ICompanyBranchConfig = { hideModalContent: true };
  public mainOffice: IApiUpdateBranchInput = null;

  public viewType = ICompanyCreateUpdateViewType.Company;
  public viewTypes = ICompanyCreateUpdateViewType;

  // lists
  public companyTypes: IApiCompanyType[] = [];
  public billingRuleTypes: IApiBillingRuleType[] = [];
  public billingRuleCategories: IApiBillingRuleCategory[] = [];
  public riskTypes: IApiRiskType[] = [];
  public expenseItems: IApiExpenseItem[] = [];
  public timeEntryTypes: IApiTimeEntryType[] = [];

  public staffListConfig: IStaffListConfig = {
    selectableMode: true
  };

  // UI Flags
  public overrideStandardFees = false;
  public hasFlatRate = false;
  // public flagStandardInvestigationRate

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CompanyCreateUpdateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ICompanyCreateUpdateData,
    private companyService: CompanyService,
    private loader: LoaderService,
    private companyTypeService: CompanyTypeService,
    private timeEntryTypeService: TimeEntryTypeService,
    private expenseItemService: ExpenseItemService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.loader.show$(
      forkJoin([
        this.expenseItemService.get().pipe(unwrapConnection()),
        this.timeEntryTypeService.get().pipe(unwrapConnection()),
        this.companyTypeService.get().pipe(unwrapConnection()),
      ]).pipe(
        this.notifications.catchAlertPipe(),
        tap(([expenseItems, timeEntryTypes, companyTypes]) => {
          this.expenseItems = expenseItems;
          this.timeEntryTypes = timeEntryTypes;
          this.companyTypes = companyTypes;
        }),
        switchMap(() => this.data?.companyId ? this.companyService.getById(this.data.companyId) : of(null)),
        filter(r => !!r),
        tap(company => {
          this.overrideStandardFees = !!company.BillingRules?.length;
          this.hasFlatRate = !!company.FlatRates?.length;
          this.company = company;
          this.company.BillingRules = this.company.BillingRules.map((obj) => {
            return {
              ...obj,
              type: this.expenseHoursType(obj)
            } as IApiBillingRuleAndType;
          });
          this.officeConfig = {
            branch: company.MainOffice,
            hideModalContent: true,
          };
        })
      )
    ).subscribe(company => {
      // if (company) {
      //   this.overrideStandardFees = !!company.BillingRules?.length;
      //   this.hasFlatRate = !!company.FlatRates?.length;
      //   this.company = company;
      //   this.officeConfig = {
      //     branch: company.MainOffice,
      //     hideModalContent: true,
      //   };
      // }
    });
  }

  public expenseHoursType(rule: IApiBillingRule) {
    return rule?.ExpenseItem?.id ? this.formExpenseType.EXPENSE : (rule.TimeEntryType?.id ? this.formExpenseType.HOURS : null );
  }

  public typeChange(index: number) {
    if ( index >= 0 && index < this.company.BillingRules.length) {
      this.company.BillingRules[index].ExpenseItem = { id: null, name: null };
      this.company.BillingRules[index].TimeEntryType = { id: null, name: null, createdAt: null, updatedAt: null };
    }
  }

  public addBillingRule() {
    const billingRule: IApiBillingRuleAndType = { id: null, notes: null, value: null, type: this.formExpenseType.EXPENSE, ExpenseItem: {id: null, name: null}, TimeEntryType: {id: null, name: null, createdAt: null, updatedAt: null} };
    this.company.BillingRules.push(billingRule);
  }

  public removeBillingRule = (index: number) => {
    this.company.BillingRules.splice(index, 1);
  }

  public addFlatRate() {
    this.company.FlatRates.push({ id: null, value: null, Risk: { id: null, name: null } });
  }

  public removeFlatRate(index: number) {
    this.company.FlatRates.splice(index, 1);
  }

  public closeCertificationSelection(e: IApiCertificationType[]) {
    // Only change the list if selections were made & add to existing list
    if (e.length) {
      e.forEach(cert => {
        if (!this.company.RequiredCertifications.filter(currentCert => currentCert.id === cert.id).length) this.company.RequiredCertifications.push(cert);
      });
    }
    this.viewType = this.viewTypes.Company;
  }

  public closeStaffSelection(e: IApiUser[]) {
    // Only change the list if selections were made & add to existing list
    if (e.length) {
      e.forEach(staff => {
        if (!this.company.ExcludedStaff.filter(currentStaff => currentStaff.id === staff.id).length) this.company.ExcludedStaff.push(staff);
      });
    }
    this.viewType = this.viewTypes.Company;
  }
  public closeServiceSelection(e: IApiServiceType[]) {
    // Only change the list if selections were made & add to existing list
    if (e.length) {
      e.forEach(service => {
        if (!this.company.RequiredServices.filter(currentService => currentService.id === service.id).length) this.company.RequiredServices.push(service);
      });
    }
    this.viewType = this.viewTypes.Company;
  }

  public removeCertification(index: number) {
    this.company.RequiredCertifications.splice(index, 1);
  }
  public removeStaff(index: number) {
    this.company.ExcludedStaff.splice(index, 1);
  }
  public removeService(index: number) {
    this.company.RequiredServices.splice(index, 1);
  }

  public filterRuleTypes(categoryId: string) {
    // Filter out the types that don't match the selected category id
    return this.billingRuleTypes.filter((v) => v.Category.id === categoryId);
  }

  public branchChanged(e) {
    this.mainOffice = e;
  }

  public mainOfficeInvalid() {
    if (this.company.id) {
      return false;
    } else {
      return !(this.mainOffice?.name && this.mainOffice?.Addresses.length && this.mainOffice?.Addresses[0]?.address1 && this.mainOffice?.Addresses[0]?.city && this.mainOffice?.Addresses[0]?.state && this.mainOffice?.Addresses[0]?.postal);
    }
  }

  public save() {
    // Clone to new memory reference so we can safely delete values
    const companyCopy = cloneDeep(this.company);

    const requiredServiceIds = companyCopy.RequiredServices.map((v) => v.id);
    const excludedStaffIds = companyCopy.ExcludedStaff.map((v) => v.id);
    const requiredCertificationsIds = companyCopy.RequiredCertifications.map((v) => v.id);
    const billingRules: IApiAddBillingRuleInput[] = (companyCopy.BillingRules as IApiBillingRuleAndType[]).map((v) => {
      const timeEntryId = v?.TimeEntryType?.id ? v?.TimeEntryType?.id : null;
      const expenseItemId = v?.ExpenseItem?.id ? v?.ExpenseItem?.id : null;
      delete v.type;
      delete v.TimeEntryType;
      delete v.ExpenseItem;
      delete v.id;
      delete v.__typename;
      return {
        ...v,
        TimeEntryTypeId: timeEntryId,
        ExpenseItemId: expenseItemId
      } as IApiAddBillingRuleInput;
    });

    const billingTimeEntryIds = billingRules.map((v) => v.TimeEntryTypeId).filter((v) => v !== null);
    const timeEntryIds = billingRules.map((v) => v.ExpenseItemId).filter((v) => v !== null);

    if ((billingTimeEntryIds.length !== new Set(billingTimeEntryIds).size) || (timeEntryIds.length !== new Set(timeEntryIds).size)) {
      return throwError('Enter only one rate per expense or hours type.').pipe(
        this.notifications.catchAlertPipe(),
      ).subscribe();
  }

    const type = companyCopy.Type.id;

    delete companyCopy.id;
    this.removeExtras(companyCopy);

    const payload: any = {
      ...companyCopy as any, // not preferred but all conflicting values have been removed at this point and linter complains
      ExcludedStaffIds: excludedStaffIds,
      RequiredServiceIds: requiredServiceIds,
      RequiredCertificationIds: requiredCertificationsIds,
      TypeId: type,
      FlatRates: companyCopy.FlatRates.map((r) => ({ value: r.value, RiskTypeId: r.Risk.id })),
      BillingRules: billingRules,
    };

    if (this.mainOffice?.id) payload.MainOffice = this.mainOffice;
    else if (this.mainOffice) payload.AddMainOffice = this.mainOffice;

    this.loader.show$(
      this.data?.companyId ?
        this.companyService.update({ ...payload, id: this.data.companyId } as IApiUpdateCompanyInput)
        : this.companyService.add(payload as IApiAddCompanyInput)
    ).pipe(
      this.notifications.snackbarPipe(`Company successfully ${this.data?.companyId ? 'updated' : 'created'}!`),
      this.notifications.catchAlertPipe())
    .subscribe(() => this.dialogRef.close(true));

  }

  private removeExtras(obj: any) {
    delete obj.RequiredCertifications;
    delete obj.ExcludedStaff;
    delete obj.RequiredServices;
    delete obj.__typename;
    delete obj.Branches;
    delete obj.BillingRules;
    delete obj.MainOffice;
    delete obj.Type;
    delete obj.Investigations;
    delete obj.Contacts;
  }

}
