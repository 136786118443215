<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffViewAll" #staffViewAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsList" #staffCertificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffInvestigationsList" #staffInvestigationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffCertificationsOwnList" #staffCertificationsOwnList="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffInvestigationsOwnList" #staffInvestigationsOwnList="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView"
  #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView"
  #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission">
</div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView"
  #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView"
  #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationView"
  #staffContactInformationView="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <h3 *ngIf="!config.permissionsView">Staff</h3>
  <!-- Filtering Options -->
  <div class="content-wrapper">
    <div class="d-flex filter-options justify-content-around align-items-center" *ngIf="!config.permissionsView">
      <div>
        <mat-form-field>
          <mat-label>Position</mat-label>
          <mat-select (selectionChange)="onPositionChange($event)">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let position of selectOpts.positions" [value]="position.title"
              [innerHTML]="position.title">
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!config.selectableMode">
        <div>
          <mat-form-field>
            <mat-label>Full/Part Time</mat-label>
            <mat-select (selectionChange)="onTimeChange($event)">
              <mat-option>All</mat-option>
              <mat-option [value]="'true'">Full Time </mat-option>
              <mat-option [value]="'false'">Part Time </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="onStatusChange($event)" [(ngModel)]="statusFilter">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let status of selectOpts.statuses | keyvalue" [value]="status.value"
                [innerHTML]="status.value">
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>State</mat-label>
            <mat-select (selectionChange)="onStateChange($event)">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let state of stateService.allStates" [value]="state.value" [innerHTML]="state.text">
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Certification</mat-label>
            <mat-select (selectionChange)="onCertificationChange($event)">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let certification of selectOpts.certifications" [value]="certification.id">
                {{ certification.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="search">
        <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [(ngModel)]="search" name="search"
            (appDebouncedInputChange)="onSearchChange()">
          <button mat-icon-button disableRipple matSuffix>
            <mat-icon (click)="search = null; onSearchChange()">clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="text-center" *ngIf="!config.selectableMode">
        <button mat-raised-button class="rounded-button" (click)="createStaff();"
          [appEnforcePermission]="authConfig.staffCreateConfig">
          <mat-icon color="accent">playlist_add</mat-icon>
          New Staff
        </button>
      </div>
    </div>
    <div class="pb-3" *ngIf="config.selectableMode">
      <mat-chip-list #selectedChipList>
        <mat-chip *ngFor="let sel of selection.selected" [selectable]="false" [removable]="true">{{sel | fullName}}
          <mat-icon matChipRemove (click)="removeChip(sel)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <!-- Table - mocked for now - data structure not accurate -->
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.select(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="notificationEnabled">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let staff">
          <span kendoTooltip position="right" [tooltipTemplate]="templateInfo" filter="i"
            tooltipClass="tooltipwidthauto" *ngIf="staffUserInformationView?.enabled">
            <i class="fa-solid fa-bell fa-lg" style="cursor: pointer;" *ngIf="staff?.notificationEnabled"></i>
            <i class="fa-solid fa-bell-slash fa-lg" style="cursor: pointer;" *ngIf="!staff?.notificationEnabled"></i>
          </span>
          <ng-template #templateInfo>
            {{staff?.notificationEnabled ? 'Notifications Enabled': 'Notifications Disabled'}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef [mat-sort-header]="orderByOptions.Lastname">Name</th>
        <td mat-cell *matCellDef="let staff">
          <button mat-icon-button *ngIf="config.permissionsView">
            <mat-icon>cancel_circle</mat-icon>
          </button>
          <a [routerLink]="['/staff', staff.id]" *ngIf="profileView(staff.id); else nameOnly">{{staff | fullName}}</a>
          <ng-template #nameOnly>
            {{ staff | fullName }}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Position(s)</th>
        <td mat-cell *matCellDef="let staff">
          <div class="mt-1" *ngFor="let position of staff.Positions" [innerHTML]="position.title"></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let staff">
          <div class="mt-2" *ngFor="let address of staff.Addresses">
            {{address.Type.name}}: {{address.address1}}<br />
            {{address.city}}, {{address.state}}<br />
            {{address.postal}}, {{address.country}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <!-- TODO change this to just the state of the address for permissions view -->
        <td mat-cell *matCellDef="let staff" [innerHTML]="staff.address"></td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let staff">
          <div class="mt-2">
            <app-email-phone-view *ngFor="let phone of staff.Phones | primarySort"
              [phone]="phone"></app-email-phone-view>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let staff">
          <div class="mt-1" *ngFor="let email of staff.Emails">
            {{ email.address }} ({{ email.Type.name }}) {{ email.isPrimary ? '(Primary)' : '' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="certifications">
        <th mat-header-cell *matHeaderCellDef>Certifications</th>
        <td mat-cell *matCellDef="let staff">
          <div class="mt-1" *ngFor="let cert of staff.Certifications" [innerHTML]="cert?.Type?.title"></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let staff">
          <ng-container
            *ngIf="profileView(staff.id)|| (staffCertificationsList.enabled || (staffCertificationsOwnList.enabled && authenticatedUserId === staff.id))|| (staffInvestigationsList.enabled || (staffInvestigationsOwnList.enabled && authenticatedUserId === staff.id))">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <!-- TODO add actions for each of these menu items -->
              <button mat-menu-item *ngIf="staff?.notificationEnabled"
                (click)="updateUser(staff, false); staff.notificationEnabled = false">Disable Notifications</button>
              <button mat-menu-item *ngIf="!staff?.notificationEnabled"
                (click)="updateUser(staff, true); staff.notificationEnabled = true">Enable Notifications</button>
              <button mat-menu-item [routerLink]="['/staff', staff.id]" *ngIf="profileView(staff.id)">View
                Profile</button>
              <button mat-menu-item [routerLink]="['/staff', staff.id]"
                *ngIf="staffCertificationsList.enabled || (staffCertificationsOwnList.enabled && authenticatedUserId === staff.id)"
                [queryParams]="{tab: 'certifications'}">View Certifications</button>
              <button mat-menu-item [routerLink]="['/staff', staff.id]"
                *ngIf="staffInvestigationsList.enabled || (staffInvestigationsOwnList.enabled && authenticatedUserId === staff.id)"
                [queryParams]="{tab: 'investigations'}">View Investigations</button>
              <!-- <button mat-menu-item [routerLink]="[]">View Calendar</button> -->
              <!-- <button mat-menu-item (click)="deactivateStaff(staff.id)">Deactivate</button> -->
            </mat-menu>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
    <mat-paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit"
      [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
    <div class="d-flex justify-content-end" *ngIf="config.selectableMode">
      <button mat-button (click)="done.emit([])">Cancel</button>
      <button mat-button class="blue-action-button" (click)="save()">Save</button>
    </div>
  </div>

</section>
