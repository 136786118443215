import gql from 'graphql-tag';
import { buildConnectionQuery } from '../../helpers.class';

const phoneFragment = `
  id
  isPrimary
  number
  extension
  TypeId
  type
  Type {
    id
    name
  }
`;

const emailFragment = `
  id
  isPrimary
  address
  TypeId
  Type {
    id
    name
  }
`;

const addressFragment = `
  id
  isPrimary
  address1
  address2
  address3
  city
  state
  postal
  country
  TypeId
  Type {
    id
    name
  }
`;

const addressesFragment = `
  Addresses { ${addressFragment} }
`;


const templateFragment = `
  id
  insuredName
  contactName
  title
  suffix
  Phones { ${phoneFragment} }
  Emails { ${emailFragment} }
  createdAt
  updatedAt
  {{ ADDRESSES }}
`;


const addressesRegex = /\{\{\s?ADDRESSES\s?\}\}/g;

const nodeFragment = templateFragment
  .replace(addressesRegex, addressesFragment)

export const insuredPartyConnectionQuery = gql`${buildConnectionQuery("InsuredParty", "insuredPartyConnection", nodeFragment)}`;

export const addInsuredPartyMutation = gql`mutation addInsuredParty($input: AddInsuredPartyInput!) {
  addInsuredParty(input: $input) { ${nodeFragment} }
}`;

export const updateInsuredPartyMutation = gql`mutation updateInsuredParty($input: UpdateInsuredPartyInput!) {
  updateInsuredParty(input: $input) { ${nodeFragment} }
}`;
