import { LoaderModule } from './shared/modules/loader/loader.module';
import { SharedComponentModule } from './components/shared/shared-component.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { NotificationsModule } from './shared/modules/notifications/notifications.module';
import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from "src/environments/environment";
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { SharedService } from './common/shared.service';
import { ToolbarService } from "@progress/kendo-angular-scheduler";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: (localStorage.getItem('clientemail')) ? window.origin + '/portal/intake' : window.origin
      }
    }),
    BrowserAnimationsModule,
    LoaderModule.forRoot(),
    SharedModule.forRoot(),
    NotificationsModule.forRoot(),
    SharedComponentModule,
    AppRoutingModule,
  ],
  providers: [{provide: ErrorHandler, useClass: AirbrakeErrorHandler}, SharedService, ToolbarService],
  bootstrap: [AppComponent]
})
export class AppModule { }
