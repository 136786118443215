import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { catchError, take } from "rxjs/operators";
import { InvestigationService, ContactService, AuthService, CompanyService } from 'src/app/shared/services';
import { IApiCompany, IApiContact, IApiInvestigation } from './modules/graphql/types/types';
import { removeTypename } from "./rxjs.pipes";
import { LoaderService } from './modules/loader/loader.service';


@Injectable()
export class PermissionsResolver implements Resolve<Observable<any>> {
  constructor(
    private auth: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.auth.assignedCategories.pipe(
      take(1)
    );
  }
}

@Injectable()
export class InvestigationResolver implements Resolve<Observable<IApiInvestigation>> {
  constructor(
    private investigationService: InvestigationService,
    private loader: LoaderService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.loader.show$(this.investigationService.getById(route.params.investigationId)).pipe(
      removeTypename(),
      catchError((err: any) => {
        if (err?.message === 'Permission denied.') this.router.navigate(['admin/access-restricted']);
        return err;
      })
    );
  }
}

@Injectable()
export class ContactResolver implements Resolve<Observable<IApiContact>> {
  constructor(
    private contactService: ContactService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.contactService.getById(route.params.contactId).pipe(
      removeTypename()
    );
  }
}
