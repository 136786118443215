<app-loader></app-loader>
<span style="margin-left: 16px; margin-right: 16px; margin-top: 16px; display: block;" *ngIf="(authService.online$ | async) === false">
  <app-kendo-alert [showIcon]="false" [message]="'Please check your internet connection, then try again'"></app-kendo-alert>
</span>
<ng-container *ngIf="!portal; else portalLayout">
  <section>
    <app-header *ngIf="portal === false"></app-header>
    <app-navigation></app-navigation>
    <section *ngIf="portal === false" class="outlet-content content-part">
      <router-outlet></router-outlet>
      <div id="dialog-div">
        <div kendoDialogContainer></div>
      </div>
    </section>
    <app-footer *ngIf="portal === false"></app-footer>
  </section>
</ng-container>
<ng-template #portalLayout>
    <router-outlet></router-outlet>
    <div id="dialog-div">
      <div kendoDialogContainer></div>
    </div>
</ng-template>