<ng-container *ngIf="loaderService?.isMatLoader === true; else kendo">
  <div class="loader" [class.active]="loaderService.active || loaderService?.activeOther">
    <mat-spinner color="primary" mode="indeterminate" diameter="48"></mat-spinner>
  </div>
</ng-container>
<ng-template #kendo>
  <div  class="k-loading-panel" *ngIf="loaderService.active || loaderService?.activeOther">
    <div class="k-loading-panel-mask"></div>
    <div class="k-loading-panel-wrapper">
      <kendo-loader
        type="infinite-spinner"
        themeColor="primary"
        size="large"
      >
      </kendo-loader>
      <div class="k-loading-panel-text" *ngIf="loaderService?.displayText">{{loaderService?.displayText}}</div>
    </div>
  </div>
</ng-template>