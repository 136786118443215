import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { IApiContact } from 'src/app/shared/modules/graphql/types/types';
import { ContactService } from 'src/app/shared/services';
import { CreateUpdateContactComponent } from '../create-update-contact/create-update-contact.component';
import {  companiesViewAll, companiesBranchesView } from 'src/app/shared/helpers/auth-config/companies.config';
import {
  contactsProfileCompanyInformationView,
  contactsProfileContactInformationViewAssigned,
  contactsProfileContactInformationUpdate,
  contactsProfileCompanyInformationUpdate,
} from "src/app/shared/helpers/auth-config/contacts.config";
@UntilDestroy()
@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  public permissionConfig = {
    contactsProfileCompanyInformationView,
    contactsProfileContactInformationViewAssigned,
    contactsProfileContactInformationUpdate,
    contactsProfileCompanyInformationUpdate,
    companiesViewAll,
    companiesBranchesView
  };

  public contact: IApiContact;

  // Form options to be fetched
  public roles: any = [];

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public contactService: ContactService
  ) { }

  ngOnInit() {
    this.contact = this.route.snapshot.data.contact;
  }

  private load() {
    this.contactService.getById(this.contact.id).toPromise().then((contact) => this.contact = contact);
  }

  public editContact() {
    this.dialog.open(CreateUpdateContactComponent, {
      width: "80%",
      height: "90%",
      data: { contactId: this.contact.id }
    }).afterClosed().subscribe(() => this.load());
  }

  public getPrimary(data: any[]) {
    return data?.find((v) => v.isPrimary) || {};
  }

}
