<h3 mat-dialog-title>Loss Proximity Check</h3>
<mat-dialog-content>
  <section class="d-flex justify-content-between">
    <div>
      <div class="mat-caption">Loss Address:</div>
      <div class="mat-body-2" [innerHTML]="data.LossAddress | addressFormat"></div>
    </div>
    <div *ngIf="data.InsuredId">
      <div class="mat-caption">Insured:</div>
      <div class="mat-body-2">
        <span [innerHTML]="insured | fullName"></span>
      </div>
    </div>
  </section>
  <section class="d-flex justify-content-between">
    <div>
      <div class="mat-caption">Loss Date:</div>
      <div class="mat-body-2" [innerHTML]="data?.lossDate | customDate: 'MM/dd/YY' : true"></div>
    </div>
    <div>
      <div class="mat-caption">Loss Time:</div>
      <div class="mat-body-2" [innerHTML]="data?.lossDate | customDate: 'h:mm a' : true"></div>
    </div>
    <div>
      <div class="mat-caption">Risk Type:</div>
      <div class="mat-body-2" [innerHTML]="riskTypeName"></div>
    </div>
  </section>
  <hr />
  <app-investigation-loss-address-check-table #lossAddressTable [input]="data" (markersChanged)="markers = $event" (rowClick)="selectedInvestigationId = $event"></app-investigation-loss-address-check-table>
  <div class="centered-map-container">
    <app-google-map [lon]="data.LossAddress.longitude" [lat]="data.LossAddress.latitude" [markers]="markers" [selectedLocation]="selectedInvestigationId" (iconClick)="lossAddressTable.selectedInvestigationId = $event"></app-google-map>
  </div>
</mat-dialog-content>
<hr />
<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" [mat-dialog-close]="null" (click)="reject()">Close</button>
  <button mat-raised-button color="primary" cdkFocusInitial (click)="accept()">{{ data.id ? 'Save' : 'Add' }}</button>
</mat-dialog-actions>
<!--
<mat-dialog-content>
  <section class="mat-typography">
    <div class="header">
      <h2>Loss Proximity Check</h2>
      <div class="flexContainer">
        <div class="equal">
          <div class="flexContainer">
            <div class="equal">
              <div class="mat-caption">Loss Address:</div>
              <div class="mat-body-2" [innerHTML]="data.LossAddress | addressFormat"></div>
            </div>
            <div class="equal" *ngIf="data.InsuredId">
              <div class="mat-caption">Insured:</div>
              <div class="mat-body-2">
                <span [innerHTML]="insured | fullName"></span>
              </div>
            </div>
            <div class="equal">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="flexContainer">
            <div class="equal">
              <div class="mat-caption">Loss Date:</div>
              <div class="mat-body-2" [innerHTML]="data.lossDate | nullableDate"></div>
            </div>
            <div class="equal">
              <div class="mat-caption">Loss Time:</div>
              <div class="mat-body-2" [innerHTML]="data.lossDate | nullableTime: 'h:mm A' : 'HH:mm:ss'"></div>
            </div>
            <div class="equal">
              <div class="mat-caption">Risk Type:</div>
              <div class="mat-body-2" [innerHTML]="riskTypeName"></div>
            </div>
            <div class="equal" *ngIf="!data.id">
              <div class="d-flex flex-row-reverse button-row">
                <button mat-raised-button color="accent" (click)="accept()">ACCEPT CASE</button>
                <button class="button-row-left" mat-stroked-button color="accent" (click)="reject()">REJECT
                  CASE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <app-investigation-loss-address-check-table #lossAddressTable [input]="data" (markersChanged)="markers = $event" (rowClick)="selectedInvestigationId = $event"></app-investigation-loss-address-check-table>
    <div class="centered-map-container">
      <app-google-map [lon]="data.LossAddress.longitude" [lat]="data.LossAddress.latitude" [markers]="markers" [selectedLocation]="selectedInvestigationId" (iconClick)="selectedInvestigationId = $event"></app-google-map>
    </div>
  </section>
</mat-dialog-content>-->
