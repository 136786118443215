import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import moment from 'moment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const appDateTransform = (isoDate: string, transformFormat: string = 'MMMM D, YYYY', inputFormat: string = 'YYYY-MM-DDTHH:mm:ssZ') => isoDate ? moment(isoDate, [inputFormat]).format(transformFormat) : '';

const appTimeTransform = (isoDate, transformFormat: string = 'HH:mm:ss', inputFormat: string = 'h:mm A') => appDateTransform(isoDate, transformFormat, inputFormat);

@Injectable()
@Pipe({ name: 'nullableDate' })
export class NullableDatePipe implements PipeTransform {
    transform(isoDate: string, transformFormat?: string, inputFormat?: string): string {
        return appDateTransform(isoDate, transformFormat, inputFormat);
    }
}

@Injectable()
@Pipe({ name: 'nullableTime' })
export class NullableTimePipe implements PipeTransform {
    transform(time: string, transformFormat?: string, inputFormat?: string): string {
        return appTimeTransform(time, transformFormat, inputFormat);
    }
}

@Injectable()
@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
    // Use the same formats needed for the Angular date pipe
    transform(date: string, format: string, isUTC = false): string {
        if (isUTC) {
            return new DatePipe('en-US').transform(date, format, 'UTC');
        } else {
            return new DatePipe('en-US').transform(date, format);
        }
    }
}