<div [dialogRef]="dialog" (appClickOutside)="close()">
<kendo-dialog-titlebar (close)="close()">
  <div class="modal-title">
    <span>Request</span>
    <div class="modal-subtitle">
      {{ data.Investigation?.nefcoNumber ? 'Investigation #: ' + data.Investigation?.nefcoNumber : "Unlinked" }}
    </div>
  </div>
</kendo-dialog-titlebar>
<section>
  <!-- New Request Form -->
  <ng-container *ngIf="!data?.id">
    <form #requestForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Request Type"></kendo-label>
            <kendo-dropdownlist
              [data]="requestTypes"
              name="RequestTypeId" 
              #RequestTypeId="ngModel"
              [(ngModel)]="request.RequestTypeId"
              textField="name"
              valueField="id"
              fillMode="outline"
              [valuePrimitive]="true"
              [defaultItem]="{id: null, name: 'Select Request Type'}"
              [required]="true"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="!dataItem?.id" class="placeholder">
                  {{ dataItem?.name }}
                </span>
                <span *ngIf="dataItem?.id" class="not-placeholder">
                  {{ dataItem?.name }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="RequestTypeId?.errors && (RequestTypeId?.dirty || RequestTypeId?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="RequestTypeId?.errors.required">Request type cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Authorized By"></kendo-label>
            <kendo-textbox
              name="authorizedBy"
              #authorizedBy="ngModel"
              [(ngModel)]="request.authorizedBy"
              fillMode="outline"
              [clearButton]="false"
              [required]="true"
              appNoWhiteSpace
            ></kendo-textbox>
            <kendo-formerror *ngIf="authorizedBy?.invalid && (authorizedBy?.dirty || authorizedBy?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="authorizedBy?.errors?.required">Authorized by cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label class="asterisk" text="Please provide a brief explanation about this request."></kendo-label>
            <kendo-textarea name="notes" #notes="ngModel" [(ngModel)]="request.notes" 
            placeholder="Please provide a brief explanation about this request." fillMode="outline"
            class="min-height-96" resizable="both" [required]="true" appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="notes?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="notes?.errors.required">Notes cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item *ngIf="requiresAddress">
          <app-address-kendo  [(ngModel)]="request.Address" [name]="'address0'" [(address)]="request.Address" (addressChanged)="request.Address = $event"></app-address-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </ng-container>

  <!-- Update Request Form -->
  <ng-container *ngIf="data?.id">
    <form #requestStatusForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Request Type"></kendo-label>
            <p [innerHTML]="data.RequestType.name"></p>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Request Status"></kendo-label>
            <kendo-dropdownlist
              #requestS
              name="requestStatus" 
              [(ngModel)]="requestStatus.name"
              [data]="requestStatusNames | keyvalue"
              textField="value"
              valueField="value"
              fillMode="outline"
              [valuePrimitive]="true"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }"
            >
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span class="not-placeholder">
                  {{ dataItem?.value }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Submitted"></kendo-label>
            <p [innerHTML]="data.createdAt | customDate:'MM-dd-yyyy hh:mm:ss a'"></p>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Submitted By"></kendo-label>
            <p>{{data?.CreatedBy | fullName }}</p>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Authorized by"></kendo-label>
            <p>{{data.authorizedBy || '-'}}</p>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Description"></kendo-label>
            <p *ngIf="data?.notes" [innerHtml]="data?.notes"></p>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item>
          <div class="kendo-formfield">
            <kendo-label text="Address"></kendo-label>
            <p *ngIf="data?.Address else showblank" [innerHtml]="(data.Address | addressFormat)"></p>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-panelbar [expandMode]="2" class="mb-16">
        <kendo-panelbar-item title="Request Log" [expanded]="true">
          <ng-template kendoPanelBarContent>
            <kendo-grid
              class="request-logs"
              [data]="data.StatusHistory"
              [pageable]="false"
              [sortable]="false"
            >
              <kendo-grid-column
                title="Updated"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.createdAt | customDate: 'MM/dd/yyyy, h:mm a' }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                title="Updated by"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.CreatedBy | fullName }}
                </ng-template>
              </kendo-grid-column>
        
              <kendo-grid-column
                title="Comment"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="text-overflow" [innerHtml]="dataItem.comment"></span>
                </ng-template>
              </kendo-grid-column>
        
              <kendo-grid-column
                title="Status"
                [width]="20"
                [sortable]="false"
                [headerClass]="'nocursor'"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.name | quiet }}
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
          <kendo-gridlayout-item>
            <kendo-formfield>
              <kendo-label class="asterisk" text="Please provide an explanation for this update."></kendo-label>
              <kendo-textarea name="comment" #comment="ngModel" [(ngModel)]="requestStatus.comment" 
              placeholder="Please provide an explanation for this update." fillMode="outline"
              class="min-height-96" resizable="both" required appNoWhiteSpace></kendo-textarea>
              <kendo-formerror *ngIf="comment?.errors">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="comment?.errors.required">Comment cannot be left blank</span>
              </kendo-formerror>
            </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
        *ngIf="docModel.s3Doc.filename && docModel.s3Doc.key">
        <kendo-gridlayout-item class="text-right">
          <kendo-label text=""></kendo-label>
          <div class="text-overflow">
            <kendo-badge class="fw600 ml-8" position="none" size="large" rounded="full" themeColor="primary">
              <span>{{docModel.s3Doc.filename}}</span> <i (click)="removeDocument()"
                style="margin-top: 2px; margin-left: 8px;" class="fa fa-xmark cursor"></i>
            </kendo-badge>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item>
            <div class="kendo-formfield">
              <kendo-label text="Document Type"></kendo-label>
              <kendo-dropdownlist
                [data]="uploadTypes"
                name="docTypeId" 
                #docTypeId="ngModel"
                [(ngModel)]="docModel.documentTypeId"
                (ngModelChange)="autofillDocTitleChange($event)"
                textField="name"
                valueField="id"
                fillMode="outline"
                [valuePrimitive]="true"
                [defaultItem]="{id: null, name: 'Select Document Type'}"
                [required]="false"
                [popupSettings]="{
                  appendTo: 'component',
                  animate: false,
                  width: 250
                }"
              >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span *ngIf="!dataItem?.id" class="placeholder">
                    {{ dataItem?.name }}
                  </span>
                  <span *ngIf="dataItem?.id" class="not-placeholder">
                    {{ dataItem?.name }}
                  </span>
                </ng-template>
              </kendo-dropdownlist>
              <kendo-formerror *ngIf="docTypeId?.invalid && (docTypeId?.dirty || docTypeId?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="docTypeId?.errors.required">Document type cannot be left blank</span>
              </kendo-formerror>
            </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label [ngClass]="docModel?.documentTypeId ? 'asterisk' : ''" text="Document Title"></kendo-label>
              <kendo-textbox
                name="docTitle"
                #docTitle="ngModel"
                [(ngModel)]="docModel.documentTitle"
                placeholder="Document Title"
                fillMode="outline"
                appNoWhiteSpace
                [clearButton]="false"
                [required]="docModel?.documentTypeId ? true : false">
              </kendo-textbox>
              <kendo-formerror *ngIf="docTitle?.invalid && (docTitle?.dirty || docTitle?.touched)">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <span *ngIf="docTitle?.errors.required">Document title cannot be left blank</span>
              </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button
                kendoButton
                themeColor="primary"
                fillMode="solid"
                size="medium"
                [disabled]="!docModel.documentTitle"
                (click)="fileSelector()"
                [iconClass]="'fa fa-cloud-upload'"
                >
                  Add Document
            </kendo-button>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <ng-template #showblank>
        <p>-</p>
      </ng-template>
    </form>
  </ng-container>
</section>
<kendo-dialog-actions layout="end">
  <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
    Cancel
  </button>
  <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="save()">
    Save
  </button>
</kendo-dialog-actions>
</div>