  <div class="flexContainer" [hidden]="config.hideType">
    <div class="equal">
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select [name]="'type' + name" [(ngModel)]="value.TypeId" [disabled]="config.static">
          <mat-option *ngFor="let type of addressTypes" [value]="type.id">{{ type.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="flexContainer">
    <div class="equal">
      <mat-form-field>
        <mat-label> Address <span *ngIf="config.privateContactType">(or Department)</span></mat-label>
        <input matInput [(ngModel)]="value.address1" appNoWhiteSpace [name]="'address1' + name" [maxLength]="255" #address1="ngModel" [disabled]="config.static" required />
        <mat-error *ngIf="!address1?.valid && (address1?.dirty || address1?.touched) && address1?.errors?.required" >Address1 cannot be left blank</mat-error>
      </mat-form-field>
    </div>
    <ng-container *ngIf="!config.hideAddress2">
      <div class="equal">
        <mat-form-field>
          <mat-label> Address2 </mat-label>
          <input matInput [(ngModel)]="value.address2" #address2="ngModel" appNoWhiteSpace [maxLength]="255" [name]="'address2' + name" [disabled]="config.static"/>
          <mat-error *ngIf="!address2?.valid && (address2?.dirty || address2?.touched) && address2?.errors?.required" >Address2 can not be left blank</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <div class="flexContainer">
    <div class="equal">
      <mat-form-field>
        <mat-label> City </mat-label>
        <input matInput [(ngModel)]="value.city" appNoWhiteSpace [name]="'city' + name" [maxLength]="255" #city="ngModel" [disabled]="config.static" required />
        <mat-error *ngIf="!city?.valid && (city?.dirty || city?.touched) && city?.errors?.required" >City cannot be left blank</mat-error>
      </mat-form-field>
    </div>
    <div class="equal">
      <app-states-select [(ngModel)]="value.state" [name]="'state' + name" [disabled]="config.static"></app-states-select>
    </div>
    <div class="equal">
      <mat-form-field>
        <mat-label> Zip Code </mat-label>
        <input matInput [(ngModel)]="value.postal" [maxLength]="255"  appNoWhiteSpace [name]="'investigationZipCode' + name" #zipCode="ngModel" [disabled]="config.static" required />
        <mat-error *ngIf="!zipCode?.valid && (zipCode?.dirty || zipCode?.touched) && zipCode?.errors?.required" >Zip code cannot be left blank</mat-error>
      </mat-form-field>
    </div>
  </div>
