import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiAddExpenseInput, IApiContactRoleFilterType, IApiDocument, IApiDocumentType, IApiDocumentTypeFilterType, IApiEvidence, IApiEvidenceFilterType, IApiExpense, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseItemFilterType, IApiExpenseItemOrderBy, IApiExpenseOrderBy, IApiInvestigationFilter, IApiInvestigationFilterType, IApiInvestigationOrderBy, IApiInvestigationPriority, IApiInvestigationSource, IApiInvestigationStaff, IApiInvestigationStatusNames, IApiInvoice, IApiInvoiceFilterType, IApiPageInfo, IApiRiskType, IApiService, IApiServiceStatus, IApiSubService, IApiTimeEntry, IApiTimeEntryFilterType, IApiTimeEntryOrderBy, IApiUserFilterType } from '../../../../shared/modules/graphql/types/types';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, AfterViewInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { IPriority } from 'src/app/shared/interfaces/priority.interfaces';
import { InvestigationStatuses, InvestigationRiskTypes, InvestigationPriorities, InvestigationServiceStatuses } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { AuthService, ContactRoleService, DocumentTypeService, EvidenceService, ExpenseItemService, ExpenseService, InvestigationMarkCloseService, InvestigationPriorityService, InvestigationService, InvestigationSourceService, InvestigationStatusService, InvoiceService, RiskTypesService, ServiceStatusService, SubServiceService, TimeEntryService } from "src/app/shared/services";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { MatDialog } from "@angular/material/dialog";
import { IApiInvestigation, IApiUser } from '../../../../shared/modules/graphql/types/types';
import { InvestigationDataSource } from 'src/app/shared/services/investigation/investigation.datasource';
import { DocumentSnapshotPipe } from "../../../../shared/pipes/document-snapshot.pipe";
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { CalendarProfileSidebarComponent } from 'src/app/shared/components';
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';
import { PermissionCategories, PermissionActions } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { EnforcePermissionDirective, IEnforcePermissionConfig, EnforcePermissionDisplayModes } from 'src/app/shared/directives/enforce-permission.directive';
import { Subscription, forkJoin } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IApiInvestigationRoleNames } from 'src/app/shared/modules/graphql/types/types';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { ViewportScroller } from '@angular/common';
import { DashboardModalViewComponent } from 'src/app/components/shared/dashboard-modal';
import { DashboardDialogType, IDashboardModalData } from 'src/app/components/shared/dashboard-modal/interfaces';
import { InvestigationDetailsSidebarComponent, InvestigationDocumentsSidebarComponent, InvestigationTimeAndExpModalKendoComponent, InvestigationTimeAndExpQuickbooksModalComponent } from 'src/app/components/shared/investigations';
import { IInvestigationTimeExpenseModalData } from 'src/app/components/shared/investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { InvestigationReportO365LaunchComponent } from 'src/app/components/shared/investigations/investigation-report-o365-launch/investigation-report-o365-launch.component';
import { RowClassArgs } from "@progress/kendo-angular-grid";
import { getRiskTypeIcon } from 'src/app/shared/helpers/reactive-form.class';
import { SortDescriptor } from '@progress/kendo-data-query';
import { InvestigationStatusNames } from 'src/app/shared/helpers/helper';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

enum BillingStatuses {
  HasReport = "HasReport",
  NoReport = "NoReport",
  Unbilled = "Unbilled"
}

// todo can columns include matching enum types from OrderBy?
export enum Columns {
  indicator = 'indicator',
  createdAt = "createdAt",
  nefcoNumber = "nefcoNumber",
  priority = "priority",
  lossDate = "lossDate",
  riskType = "riskType",
  serviceStatus = "serviceStatus",
  status = "status",
  followUp = "followUp",
  investigationLocation = "investigationLocation",
  contact = "contact",
  billTo = "billTo",
  client = "client",
  assigned = "assigned",
  progress = "progress",
  actions = "actions",
  review = "review",
  contactRole = "contactRole",
  policyClaimNumbers = "policyClaimNumbers",
  comments = "comments",
  editor = "editor",
  conflicts = "conflicts"
}

// todo table types should include matching enum types for investigation status
export enum TableType {
  readyToBill = "readyToBill",
  pending = "pending",
  assigned = "assigned",
  progress = "progress",
  ongoing = "ongoing",
  contact = "contact",
  investigations = "investigations",
  user = "user",
  review = "review",
}

export interface IInvestigationTableConfig {
  type?: TableType;
  displayedColumns?: Columns[];
  viewType?: IApiInvestigationStatusNames;
  startWithCount?: boolean;
  contactId?: string;
  filterNumber?: number;
}
import {
  investigationStatusList,
  investigationStatusCreate,
} from "../../../../shared/helpers/auth-config/investigations.config";
import { SharedService } from 'src/app/common/shared.service';
@UntilDestroy()
@Component({
  selector: 'app-investigations-table-new',
  templateUrl: './investigations-table-new.component.html',
  styleUrls: ['./investigations-table-new.component.scss'],
  providers: [DocumentSnapshotPipe],
  // Handles the display of expandable table rows
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class InvestigationsTableNewComponent implements OnInit, AfterViewInit, OnDestroy {

  public refreshPendingSubscription: Subscription;
  public investigationStatusNames = InvestigationStatusNames;
  public authConfig = {
    investigationStatusList,
    investigationStatusCreate,
  };

  private _config: IInvestigationTableConfig = null;
  public get config() {
    return this._config;
  }

  @Input() public set config(val: IInvestigationTableConfig) {
    this._config = val;
    this.columnsNameList = val?.displayedColumns;
  }
  private _count: boolean;
  @Input() public set count(val: boolean) {
    this._count = val;
  }
  public get count() {
    return this._count;
  }
  @Output() total = new EventEmitter<number>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(EnforcePermissionDirective) perms: EnforcePermissionDirective;

  private _dataSource: InvestigationDataSource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }

  public investigationServices = []; // Add graphql ENUM here for investigation services - used for checkboxes

  public investigations: IApiInvestigation[] = [];

  public evidenceColumns = ["date", "description", "enteredBy", "rate", "schedule", "billable"];
  public billingColumns = ["date", "staff", "item", "purpose", "nonBillableQuantity", "billableQuantity", "description", "paid", "billable"];
  public invoiceColumns = ["date", "desc", "amount", "qbErrors", "qbSubmitted", "edit", "invoice_num"];

  // todo: is this used?
  public staffMember = "";
  public searchValue = "";
  public InvestigationStatuses = InvestigationStatuses;
  // todo: is this used?
  public InvestigationRiskTypes = InvestigationRiskTypes;
  public InvestigationServiceStatuses = InvestigationServiceStatuses;
  public users: IApiUser[] = [];
  public riskTypes: IApiRiskType[] = [];
  public investigationPriorities: IPriority[];
  public pageInfo: IApiPageInfo;
  public pageSize = 25;
  public nextCursor: any = null;
  public prevCursor: any = null;
  public readyToBillTotalResults = 0;
  public sortOrder = SortOrder.ASC;
  public orderBy = IApiInvestigationOrderBy.CreatedAt;
  public type = TableType;
  public contactRoles: string[] = [];
  public quickBookErrors: string[] = [];
  public billingStatusOpts = BillingStatuses;
  public billingStatus: BillingStatuses = BillingStatuses.HasReport;
  public status: any = null;
  public orderByOptions = IApiInvestigationOrderBy;
  // public content = false;
  public loaded = false;
  public primaryInvestigator = false;
  public unBilledOnly = false;
  public markCloseOnly = false;
  public userId = null;
  public serviceStatuses: IApiServiceStatus[] = [];
  /* START Detail Row Bill To Tab */
  public invoices: IApiInvoice[] = [];
  public expenseEntry: IApiExpense[] = [];
  public mileageEntry: IApiExpense[] = [];
  public timeEntry: IApiTimeEntry[] = [];
  public timeAndExpenses: IApiExpense[] | IApiTimeEntry[]  = [];
  public evidenceExpenses: IApiExpense[] = [];
  public evidenceExpanded: IApiEvidence[] = [];
  /* END Detail Row Bill To Tab */

  public apiUserFilterType = IApiUserFilterType.ViewStaffUser;;
  public columnsNameList;
  public expandedRaw = null;

  private _filters: IApiInvestigationFilter[] = [];
  public filterTypes = IApiInvestigationFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  public get displayedColumns() {
    if (!this.config?.displayedColumns) return [];
    return this.config.displayedColumns;
  }

  public get tableType(): typeof TableType {
    return TableType;
  }

  public previousFilters: any[] = [];

  // For creating an expense item for evidence
  private _evidenceExpenseItemId: string;
  private get evidenceExpenseItemId() {
    return this._evidenceExpenseItemId;
  }

  // This is for rows with expandable data
  private _expandedElement: IApiInvestigation = null;
  public set expandedElement(val: IApiInvestigation) {
    this._expandedElement = val;
    if (!val) {
      this.expandedElementFilteredServices = [];
      this.expandedElementReports = [];
    } else {
      if (val.Services && val.Services.length > 0) this.filterServiceList(val.InvestigationStaff[0].User.id);
      else this.expandedElementFilteredServices = [];
      // Filtering reports
      if (val.Documents && val.Documents.length > 0) {
        this.expandedElementReports = val.Documents.filter((doc) => doc.Type.Category.name === DocumentCategories.REPORT.valueOf());
      }
      else this.expandedElementReports = [];
    }
  }
  public get expandedElement(): IApiInvestigation {
    return this._expandedElement;
  }

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW],
      AllAssigned: [PermissionActions.VIEW],
      Assigned: [PermissionActions.VIEW],
      Own: [PermissionActions.VIEW]
    }
  };

  private permissionAdminConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION,
    appliedPermissions: {
      All: [PermissionActions.VIEW, PermissionActions.UPDATE, PermissionActions.CREATE],
      AllAssigned: [],
      Assigned: [],
      Own: []
    }
  };

  public expandedElementFilteredServices: IApiService[] = null;
  public expandedElementReports: IApiDocument[] = [];
  // public expandedElementSortedDocuments: IApiDocumentSnapshot[] = null;

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  // Toggle for comments on table
  public showComments = false;
  public commentLogColumns = ["date", "comment", "user"];
  public displayCommentToggle = false;

  private _isAdmin: boolean;

  private get isAdmin() {
    return this._isAdmin;
  }
  private set isAdmin(val) {
    this._isAdmin = val;
  }

  private user: IApiUser;

  private arrayIsEqual(x, y) {
    return _(x).xorWith(y, _.isEqual).isEmpty();
  }

  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];

  public expandedDetailKeys: any[] = [];
  public tableData: IApiInvestigation[] = [];
  public investigationSource: IApiInvestigationSource[] = [];
  public filterColumns = [`repeat(${5},minmax(0,1fr))`];
  public readyToBill: string = 'HasReport';
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public firstLoad: boolean = true;
  private expenseItems: IApiExpenseItem[] = [];
  private documentTypes: IApiDocumentType[] = [];
  public showMoreChar = 100;

  constructor(
    private investigationService: InvestigationService,
    private loaderService: LoaderService,
    private notifications: NotificationsService,
    public dialog: MatDialog,
    private riskTypeService: RiskTypesService,
    private serviceStatusService: ServiceStatusService,
    private contactRoleService: ContactRoleService,
    private documentSnapshotPipe: DocumentSnapshotPipe,
    private investigationStatusService: InvestigationStatusService,
    private subServiceService: SubServiceService,
    private auth: AuthService,
    private invoiceService: InvoiceService,
    private expenseItemService: ExpenseItemService,
    private expenseService: ExpenseService,
    private route: ActivatedRoute,
    private viewport: ViewportScroller,
    private investigationSourceService: InvestigationSourceService,
    private investigationPriorityService: InvestigationPriorityService,
    private dialogService: DialogService,
    private documentTypeService: DocumentTypeService,
    private investigationMarkCloseService: InvestigationMarkCloseService,
    private sharedService: SharedService,
    private timeEntryService: TimeEntryService,
    private evidenceService: EvidenceService
  ) {
    this.dataSource = new InvestigationDataSource(this.investigationService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);

    // Default to sort by newest at top
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.listPage.orderBy = IApiInvestigationOrderBy.CreatedAt;

    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

    // Find permissions
    this.auth.hasCategoryPermission(this.permissionAdminConfig.category, this.permissionAdminConfig.appliedPermissions).pipe(
      untilDestroyed(this)
    ).subscribe((perm) => {
      this._isAdmin = perm;
    });

    this.dataSource?.contents$.subscribe((res) => {
      if (res && res?.length) {
        res.map((obj) => {
          obj['assigned'] = this.orderInvestigationStaff(obj?.InvestigationStaff);
        });
      }
      this.tableData = res;
      if (this.expandedRaw) {
        setTimeout(() => {
          this.expandedElement = this.expandedRaw;
          this.expandedDetailKeys = [this.expandedRaw?.id];
          this.allExpandedInvoices();
        }, 10);
      }
    });
  }

  public toggleInvestigationMarkClose(dataItem, markClosed) {
    let input = {
      markClosed,
      InvestigationId: dataItem.id
    };
    this.loaderService.show$(
      this.investigationMarkCloseService.add(input),
    ).pipe(
      this.notifications.catchAlertPipe(markClosed ? "Error Mark to close investigation" : "Error keep as active investigation"),
      this.notifications.alertPipe(markClosed ? "Investigation mark to close successfully" : "Investigation keep as active successfully")
    ).subscribe(response => {
      dataItem.InvestigationMarkClose = [response];
    })
  }

  public itemSelected(event, dataItem) {
    console.log(event);
    switch (event?.text) {
      case 'Change to Ongoing':
        this.changeToOngoing(dataItem);
        break;
      case 'Hold for Billing':
        this.putOnHold(dataItem)
        break;
      case 'Move to In Progress':
        this.moveToInProgress(dataItem)
        break;
      case "Send to Billing":
        this.sendToBilling(dataItem);
        break;
      case "Mark to Close":
        this.toggleInvestigationMarkClose(dataItem, true);
        break;
      case "Keep as Active":
        this.toggleInvestigationMarkClose(dataItem, false);
        break;
      default:
        break;
    }
  }

  public filterTable() {
    this.load();
  }

  public filterValue(filter: IApiInvestigationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return (value === "true" || value === "false") ? JSON.parse(value) : value;
  }

  public clearFilter() {
    this.dataSource.removeFilter(this.filterTypes.Priority);
    this.dataSource.removeFilter(this.filterTypes.RiskType);
    this.dataSource.removeFilter(this.filterTypes.InvestigationSource);
    this.dataSource.removeFilter(this.filterTypes.Search);
    this.dataSource.removeFilter(this.filterTypes.UserPrimary);
    this.dataSource.removeFilter(this.filterTypes.User);
    this.dataSource.removeFilter(this.filterTypes.BillingStatusUnBilled);
    this.dataSource.removeFilter(this.filterTypes.ShowMarkCloseOnly);
    if (this.config.type === this.type.ongoing) {
      this.dataSource.applyFilter(this.filterTypes.ViewOngoingView, 'true');
      this.dataSource.applyFilter(this.filterTypes.Status, this.config.viewType);
    }
    this.filters = [];
    this.searchValue = '';
    this.readyToBill = 'HasReport';
    this.userId = null;
    this.primaryInvestigator = false;
    this.markCloseOnly = false;
    this.unBilledOnly = false;
    this.filterTable();
  }

  public showPrimary(event) {
    this.primaryInvestigator = event;
    this.setFilters(this.userId, this.filterTypes.User);
  }

  public showUnBilledOnly(event) {
    this.unBilledOnly = event;
    if (this.unBilledOnly === true) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.BillingStatusUnBilled, 'true');
    } else {
      this.dataSource.removeFilter(IApiInvestigationFilterType.BillingStatusUnBilled);
    }
  }

  public showMarkClosedOnly(event) {
    this.markCloseOnly = event;
    if (this.markCloseOnly === true) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.ShowMarkCloseOnly, 'true');
    } else {
      this.dataSource.removeFilter(IApiInvestigationFilterType.ShowMarkCloseOnly);
    }
  }

  // handles changes on the filters select
  public setFilters(event: string, type): void {
    if (type === this.filterTypes.Search) {
      this.searchValue = event;
    }
    if (type === this.filterTypes.User) {
      this.userId = event;
      if (this.primaryInvestigator) {
        this.dataSource.applyFilter(IApiInvestigationFilterType.UserPrimary, this.userId);
        this.dataSource.removeFilter(IApiInvestigationFilterType.User);
      } else {
        this.dataSource.applyFilter(IApiInvestigationFilterType.User, this.userId);
        this.dataSource.removeFilter(IApiInvestigationFilterType.UserPrimary);
      }
    } else {
      if (!event || event === 'All') {
        this.dataSource.removeFilter(type);
      } else {
        this.dataSource.applyFilter(type, event);
      }
    }

    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = !!event ? [...filtersCopy, {
      type: type,
      value: event
    }] : filtersCopy;
  }

  public pageChange(event) {
    this.showComments = false;
    this.pageOptions?.paginate(event)
  }

  public toggleCommentExpand(show: boolean): void {
    this.showComments = show;
    if (!show) {
      this.expandedDetailKeys = [];
    } else {
      this.expandedDetailKeys = this.tableData.map(
        (item) => item.id
      );
    }
  }

  public displayComments(dataItem: IApiInvestigation) {
    return dataItem.Comments.length > 0;
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'CREATED_AT';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public getIcon(RiskTypeId: string) {
    return getRiskTypeIcon(RiskTypeId);
  }

  // Investigation actions
  public closeInvestigation(event: MouseEvent, investigation: IApiInvestigation) {
    event.stopPropagation();
    const { id } = investigation;
    const message = "Are you sure you want to mark this investigation as Closed?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.InProgress, message);
  }
  public changeToOngoing(investigation: IApiInvestigation) {
    const { id } = investigation;
    const message = "Are you sure you want to move this investigation On Going?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.Ongoing, message);
  }
  public putOnHold(investigation: IApiInvestigation) {
    const { id } = investigation;
    const message = "Are you sure you want to send this investigation to billing?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.HoldForBilling, message);
  }

  public moveToInProgress(investigation: IApiInvestigation) {
    const { id } = investigation;
    const message = "Are you sure you want to move this investigation In Progress?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.InProgress, message);
  }

  public createInvoice(event: MouseEvent, investigation: IApiInvestigation) {
    event.stopPropagation();

    // Needed for click and drag to work in QB modal
    this.viewport.scrollToPosition([0, 0]);

    const dialogRef = this.dialog.open(InvestigationTimeAndExpQuickbooksModalComponent, {
      width: '75%',
      height: '95vh',
      data: {
        investigation: { ...investigation, Expenses: this.expenseEntry, TimeEntries: this.timeEntry, Mileage: this.mileageEntry }
      }
    }).afterClosed().subscribe((data) => {
      if (data) {
        this.load()
      }
    });

  }
  /**
   * Function is not in use
   * @param data
   */
  public openStaffSidebar(data: IApiInvestigation) {
    const dialogRef = this.dialog.open(CalendarProfileSidebarComponent, {
      width: '45%',
      height: "100vh",
      position: { right: "0" },
      data
    });
  }

  public changeFlatRate(investigation: IApiInvestigation) {
    // TODO change the things
  }

  public sendToBilling(investigation: IApiInvestigation) {
    const { id } = investigation;
    const message = "Are you sure you want to send this investigation to billing?";
    this.updateInvestigation(id, IApiInvestigationStatusNames.ReadyToBill, message);
  }

  public indicateCause(e: MouseEvent, investigation: IApiInvestigation, doc: IApiDocument) {
    e.stopPropagation();
    const modalData: IDashboardModalData = {
      dialogType: DashboardDialogType.INDICATE_CAUSE,
      nefcoNumber: investigation.nefcoNumber,
      investigationId: investigation.id,
      document: doc,
    };

    this.dialog.open(DashboardModalViewComponent, {
      width: "40%",
      height: "auto",
      data: modalData
    });
  }

  public sendBack(e: MouseEvent, investigation: IApiInvestigation, doc: IApiDocument) {
    e.stopPropagation();
    const modalData: IDashboardModalData = {
      dialogType: DashboardDialogType.SENDBACK,
      nefcoNumber: investigation.nefcoNumber,
      investigationId: investigation.id,
      document: doc,
    };

    this.dialog.open(DashboardModalViewComponent, {
      width: "40%",
      height: "auto",
      data: modalData
    });
  }

  public approveAsFinal(e: MouseEvent, investigation: IApiInvestigation, doc: IApiDocument) {
    e.stopPropagation();
    const modalData: IDashboardModalData = {
      dialogType: DashboardDialogType.APPROVE_FINAL,
      nefcoNumber: investigation.nefcoNumber,
      investigationId: investigation.id,
      document: doc
    };

    this.dialog.open(DashboardModalViewComponent, {
      width: "40%",
      height: "auto",
      data: modalData
    });
  }

  public unassign(e: MouseEvent, investigation: IApiInvestigation, doc: IApiDocument) {
    e.stopPropagation();

    const data: IDashboardModalData = {
      dialogType: DashboardDialogType.UNASSIGN,
      nefcoNumber: investigation.nefcoNumber,
      investigationId: investigation.id,
      document: doc
    };

    this.dialog.open(DashboardModalViewComponent, {
      width: "40%",
      height: "auto",
      data
    });

  }

  private updateInvestigation(id: string, status: IApiInvestigationStatusNames, message: string) {
    this.notifications.confirm(message).afterClosed().pipe(
      filter(v => !!v)
    ).subscribe(() => this.investigationStatusService.addInvestigationStatus({ InvestigationId: id, name: status, comment: "" }).pipe(
      switchMap(() => this.load())
    ).subscribe());
  }

  public assignToMe(e: MouseEvent, investigation: IApiInvestigation, doc: IApiDocument) {
    e.stopPropagation();
    const data: IDashboardModalData = {
      dialogType: DashboardDialogType.ASSIGN,
      nefcoNumber: investigation.nefcoNumber,
      investigationId: investigation.id,
      document: doc
    };

    this.dialog.open(DashboardModalViewComponent, {
      width: "40%",
      height: "auto",
      data
    });

  }

  // Dialog triggers ////
  public reportDialog(report: IApiDocument, investigation) {
    const assigned = this.documentSnapshotPipe.transform(report) as IApiDocument;
    const data: IDashboardModalData = {
      dialogType: DashboardDialogType.INVESTIGATION_COMMENT,
      nefcoNumber: investigation.nefcoNumber,
      investigationId: investigation.id,
      assignedUser: assigned.CreatedBy || {} as any,
      updatedAt: report.updatedAt,
      document: report
    };

    this.dialog.open(DashboardModalViewComponent, {
      width: "40%",
      height: "auto",
      data
    });
  }

  public ngOnInit() {
    // Show comment toggle
    this.displayCommentToggle = !!this.displayedColumns.find((a) => a === Columns.comments);

    // This is used to update the graphql call to return fewer sub-fields
    if (this.config.type === TableType.investigations && this.config.viewType === null) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewListView, "true");
    if (this.config.type === TableType.ongoing && this.config.viewType === IApiInvestigationStatusNames.Ongoing) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewOngoingView, "true");
    if (this.config.type === TableType.pending && this.config.viewType === IApiInvestigationStatusNames.Pending) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewPendingView, "true");
    if (this.config.type === TableType.assigned && this.config.viewType === IApiInvestigationStatusNames.Assigned) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewAssignedView, "true");
    if (this.config.type === TableType.progress && this.config.viewType === IApiInvestigationStatusNames.InProgress) this.dataSource.applyFilter(IApiInvestigationFilterType.ViewInprogressView, "true");
    if (this.config.type === TableType.readyToBill && this.config.viewType === IApiInvestigationStatusNames.ReadyToBill) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.ViewReadytobillView, "true")
      forkJoin([
        this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(unwrapConnection()),
        this.documentTypeService.get([{ type: IApiDocumentTypeFilterType.ViewListName, value: "true" }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection())
      ]).subscribe(([expenseItems, documentTypes]) => {
        this.expenseItems = expenseItems;
        this.documentTypes = documentTypes;
      });
    };

    // Handle if we just get the count or not, add to filter;
    if (this.count) {
      // if ((this.config.startWithCount || this.config.startWithCount === undefined) && this.config.type !== TableType.contact) this.dataSource.applyFilter(IApiInvestigationFilterType.CountOnly, "1");
      this.dataSource.applyFilter(IApiInvestigationFilterType.CountOnly, "1");
    }
    if (!this.config.viewType) {
      // Default to remove Closed / Archived
      this.dataSource.applyFilter(IApiInvestigationFilterType.Closed, "false");
    }

    // Filter by user if user view
    if (this.config.type === TableType.user) this.dataSource.applyFilter(IApiInvestigationFilterType.User, this.config.contactId);
    if (this.config.type === TableType.review) this.dataSource.applyFilter(IApiInvestigationFilterType.Document, "TECH_REVIEW");

    // Filter by user if contact view & get contactRole filter
    if (this.config.type === TableType.contact) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.Contact, this.config.contactId);
      this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection()).subscribe((r) => {
        const otherRoles = ["Client", "BillTo", "SceneContact", "Insured"];
        const foundContactRoles = r.map(obj => obj.name);
        // Extract role names only
        this.contactRoles = [...otherRoles, ...foundContactRoles].sort();
      });
    }
    if (this.config.viewType === IApiInvestigationStatusNames.Pending) {
      this.investigationSourceService.get().subscribe((sources) => this.investigationSource = sources);
    }

    if (this.config.viewType !== IApiInvestigationStatusNames.ReadyToBill) {
      this.dataSource.applyFilter(IApiInvestigationFilterType.Status, this.config.viewType);
      this.investigationPriorityService.get([], {}).pipe(unwrapConnection()).subscribe(p => this.investigationPriorities = p);
    } else {
      const defaultFilter = [
        { type: IApiInvestigationFilterType.CountOnly, value: "true" },
      ];

      // TODO - refactor this count
      this.dataSource.applyFilter(IApiInvestigationFilterType.BillingStatus, this.billingStatus);
      // Need the cumulative count of all three queries
      forkJoin([
        this.investigationService.get([
          ...defaultFilter,
          { type: IApiInvestigationFilterType.BillingStatus, value: this.billingStatusOpts.HasReport }
        ], {}),
        this.investigationService.get([
          ...defaultFilter,
          { type: IApiInvestigationFilterType.BillingStatus, value: this.billingStatusOpts.NoReport }
        ], {}),
        this.investigationService.get([
          ...defaultFilter,
          { type: IApiInvestigationFilterType.BillingStatus, value: this.billingStatusOpts.Unbilled }
        ], {})
      ]).subscribe((val) => {
        this.readyToBillTotalResults = val.reduce((p, { totalCount }) => p + totalCount, 0);
      });
    }

    // If we started with count only, remove that after the initial load so we actually retrieve data;
    if (this.config.type === TableType.investigations) {
      if (!this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST') || !this.sharedService.getLocalStorageByKey('INVESTIGATION_LIST')?.length) {
        this.load().then(() => this.dataSource.removeFilter(IApiInvestigationFilterType.CountOnly));
      }
    } else {
      this.load().then(() => this.dataSource.removeFilter(IApiInvestigationFilterType.CountOnly));
    }
    this.filterColumns = [`repeat(${this.config?.filterNumber || 5},minmax(0,1fr))`];

    if (this.config.viewType === IApiInvestigationStatusNames.Pending) {
      this.refreshPendingSubscription = this.investigationService.refreshPendingInvestigation().subscribe(() => {
        this.load(false);
      });
    }
  }

  ngAfterViewInit() {

    // TODO - Old pattern, refactor to newer pattern
    this.riskTypeService.riskTypes().subscribe((types) => this.riskTypes = types);

    // Load Silently
    forkJoin([
      // this.serviceStatusService.get([], { limit: -1 }).pipe(unwrapConnection()),
      this.expenseItemService.get([{ type: IApiExpenseItemFilterType.ExpenseItemName, value: 'Annual Evidence Storage' }]).pipe(unwrapConnection()),
    ]).subscribe(([expenseTypes]) => {
      // Set Found Filters
      // this.serviceStatuses = serviceStatuses;
      if (expenseTypes && expenseTypes?.length) {
        this._evidenceExpenseItemId = expenseTypes.find((obj) => obj.name === 'Annual Evidence Storage').id;
      }

      // Mark as 'loaded' once filters have completed fetching
      this.loaded = true;
    });
  }

  public calcServiceComplete(service: IApiService): boolean {
    return service.SubServices.find(s => s.isComplete === false) ? false : true;
  }
  public mileage(val: number) {
    return val ? val + 'miles' : '';
  }

  public filterServiceList(id: string) {
    this.expandedElementFilteredServices = this.expandedElement.Services.filter(({ AssignedUsers }) => AssignedUsers.find((u) => u.id === id));
  }

  // public newestDocument(doc: IApiDocument): IApiDocumentSnapshot {
  //   return doc.History.sort((a, b) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1)[0];
  // }

  public async load(resetPage = true): Promise<any> {
    if (resetPage) {
      this.dataSource.pagingReset();
    }
    await this.dataSource.load();
    this.toggleCommentExpand(this.showComments);
    return this.total.emit(this.config.viewType === IApiInvestigationStatusNames.ReadyToBill ? this.readyToBillTotalResults : this.pageOptions.totalCount);
  }

  // Update the opened row's content without closing it
  public async softLoad(): Promise<any> {
    await this.dataSource.load();
    this.dataSource.contents$.subscribe(arr => {
      const foundObj = arr.find(obj => obj.id === this.expandedElement.id);
      this.expandedElement = foundObj;
    });
  }

  // Filter change detection below
  public filterInvestigations(filterList: IApiInvestigationFilter[], load = true) {

    // If no new filters, return
    // if (this.arrayIsEqual(filterList, this.previousFilters)) return;

    this.previousFilters.forEach(({ type }) => this.dataSource.removeFilter(type)); // Remove previous filters and only apply the ones that came in

    // If Admin/Right Permissions, allow searches with closed status or NEFCO# to include closed/archived investigations
    if (this.isAdmin && filterList.find((obj) => (obj.type === IApiInvestigationFilterType.Status && obj.value === IApiInvestigationStatusNames.Closed) || obj.type === IApiInvestigationFilterType.NefcoNumber || obj.type === IApiInvestigationFilterType.PolicyClaimNumber)) {
      this.dataSource.removeFilter(IApiInvestigationFilterType.Closed);
    } else {
      this.dataSource.applyFilter(IApiInvestigationFilterType.Closed, 'false');
    }
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value || null));
    if (load) this.load();
    this.previousFilters = filterList;
  }

  public billingStatusChange(val: string) {
    this.dataSource.applyFilter(IApiInvestigationFilterType.BillingStatus, val);
    this.load();
  }

  // handle changes on the 'assigned | scheduled' toggle buttons
  public setStatus(event: MatButtonToggleChange) {
    this.dataSource.applyFilter(IApiInvestigationFilterType.Status, event.value);
    this.load();
  }

  // Handle contact role filter
  public setContactRole({ value }: MatButtonToggleChange) {
    !!value ? this.dataSource.applyFilter(IApiInvestigationFilterType.ContactRole, JSON.stringify([value, this.config.contactId])) : this.dataSource.removeFilter(IApiInvestigationFilterType.ContactRole);
    this.load();
  }

  // Handles changes on 'Service Status' select
  public setServiceStatus(event: MatButtonToggleChange) {
    this.dataSource.applyFilter(IApiInvestigationFilterType.ServiceStatus, event.value);
    this.load();
  }

  public hasQuickBookError(id: string) {
    // TODO need to push errors into the quickBookErrors array once that workflow is complete
    return this.quickBookErrors.find(e => e === id);
  }

  public openQuickbooksDocument(event: MouseEvent, row: IApiInvestigation) {
    event.stopPropagation();
  }

  public viewInvoice(row: IApiExpense) {
    // TODO implement invoice viewing once that workflow has been completed
  }

  public editExpense(row: IApiExpense | IApiTimeEntry, e: MouseEvent, expandedElement) {
    e.stopPropagation();
    const data: IInvestigationTimeExpenseModalData = {
      expense: (row.__typename === 'Expense' ? { ...row as IApiExpense } : null),
      time: (row.__typename === 'TimeEntry' ? { ...row as IApiTimeEntry } : null),
      investigationId: expandedElement?.id,
      nefcoNumber: expandedElement?.nefcoNumber
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = [];
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.load(false);
    });
  }

  public allExpandedExpenses(row: IApiInvestigation) {
    return [
      ...row.Expenses,
      ...row.TimeEntries,
      ...row.Mileage
    ].filter((obj) => !obj?.InvoiceItems.length);
  }

  public allExpandedInvoices() {
    if (this.expandedElement) {
     this.loaderService.show$(forkJoin([
        this.expenseService.get([
          { type: IApiExpenseFilterType.Investigation, value: this.expandedElement.id },
          { type: IApiExpenseFilterType.IsInvoiced, value: "false" },
        ],{ limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiExpenseOrderBy.CreatedAt }).pipe(unwrapConnection()),
        this.timeEntryService.get([
          { type: IApiTimeEntryFilterType.Investigation, value: this.expandedElement.id },
          { type: IApiTimeEntryFilterType.Invoiced, value: "false" },
        ],{ limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiTimeEntryOrderBy.CreatedAt }).pipe(unwrapConnection()),
        this.invoiceService.get([
          { type: IApiInvoiceFilterType.Investigation, value: this.expandedElement.id },
          { type: IApiInvoiceFilterType.FailedOrPending, value: 'true' },
        ]).pipe(unwrapConnection()),
        this.evidenceService.get([
          { type: IApiEvidenceFilterType.Investigation, value: this.expandedElement.id },
        ]).pipe(unwrapConnection())
      ])).subscribe(([expenseEntry = [], timeEntry = [], invoices = [], evidences = []]) => {
          this.expenseEntry = (expenseEntry).filter((item) => item.ExpenseItem.name !== 'Mileage');;
          this.mileageEntry = (expenseEntry).filter((item) => item.ExpenseItem.name === 'Mileage');
          this.timeEntry = timeEntry;
          this.timeAndExpenses = [...expenseEntry, ...timeEntry];
          this.evidenceExpenses = expenseEntry.sort((a, b) => (a.expenseDate > b.expenseDate) ? 1 : -1).filter(({ ExpenseItem: { id } }) => id === this.evidenceExpenseItemId);
          this.evidenceExpanded = evidences;
          this.invoices = invoices;
      });
    }
  }

  // Calculate % of completed subservices
  public servicesCount(services: IApiService[]) {
    let allSubServices: IApiSubService[] = [];
    let completeSubServices: IApiSubService[] = [];

    services.forEach(obj => allSubServices = [...allSubServices, ...obj.SubServices]);
    completeSubServices = allSubServices.filter((subService) => subService.isComplete);

    return (allSubServices.length ? Math.round((completeSubServices.length / allSubServices.length) * 100) : 0);
  }

  // Subservice Actions
  public subServiceCheckboxClicked(event: MatCheckboxChange, id: string) {
    this.loaderService.show$(
      this.subServiceService.update({
        id,
        isComplete: event.checked
      })
    ).pipe(
      this.notifications.catchAlertPipe("Error updating SubService; please try again.")
    ).subscribe((results) => {
      this.softLoad();
      this.notifications.notify("SubService updated.");
    });
  }

  public serviceCheckboxClicked({ checked }: MatCheckboxChange, serviceObj: IApiService) {
    // set all subservices as complete
    this.loaderService.show$(forkJoin(
      serviceObj.SubServices.map((s) => this.subServiceService.update({ id: s.id, isComplete: checked }))
    )).pipe(
      this.notifications.catchAlertPipe("Error updating Service; please try again.")
    ).subscribe(() => {
      this.softLoad();
      this.notifications.notify("Service updated.");
    });
  }

  // Report Actions
  public openReport(report) {
    const dialogRef = this.dialog.open(InvestigationDocumentsSidebarComponent, {
      width: '50%',
      height: "100vh",
      position: { right: "0" },
      data: report
    });

    dialogRef.afterClosed().pipe(
      filter(r => r)
    ).subscribe(() => this.softLoad());
  }

  public showLaunchReport(report) {
    if (!this.user.msGraphToken) {
      this.notifications.error("You do not currently have an o365 ID associated with your account. Please enter this in the space provided in your user account profile an then try again.");
      return;
    }

    this.dialog.open(InvestigationReportO365LaunchComponent, {
      width: '45%',
      data: report
    });
  }

  // Invoice Actions
  public resubmitInvoice(invoice: IApiInvoice) {
    this.notifications.confirm("Are you sure this invoice is ready for resubmission?").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.resubmit(invoice.id)),
      this.notifications.catchAlertPipe("Error resubmitting invoice"),
      this.notifications.alertPipe("Invoice resubmitted!")
    ).subscribe(() => this.load());
  }

  public deleteInvoice(invoice: IApiInvoice) {
    this.notifications.confirm("Are you sure you want to delete this invoice?").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.remove(invoice.id)),
      this.notifications.catchAlertPipe("Error deleting invoice"),
      this.notifications.alertPipe("Invoice deleted.")
    ).subscribe(() => {
      this.load();
    });
  }

  public openQbDialog(invoice?: IApiInvoice, disableEdit = false) {

    // Needed for click and drag to work in QB modal
    this.viewport.scrollToPosition([0, 0]);

    const dialogRef = this.dialog.open(InvestigationTimeAndExpQuickbooksModalComponent, {
      width: '75%',
      height: '95vh',
      data: {
        investigation: this.expandedElement,
        invoice,
        disableEdit
      }
    }).afterClosed().pipe(
      filter((v) => !!v)
    ).subscribe(() => {
      this.load();
    });
  }

  public calculateAmount(invoice: IApiInvoice) {
    return (invoice?.InvoiceLines || []).reduce((prev, { rate, quantity }) => {
      prev += rate * quantity;
      return prev;
    }, 0);
  }

  // Creating evidenceExpense
  public addEvidenceExpense(dataItemMain) {
    const input: IApiAddExpenseInput = {
      ExpenseItemId: this.evidenceExpenseItemId,
      InvestigationId: dataItemMain?.id
    };

    this.notifications.confirm("Are you sure you want to add the current evidence items to an expense? This action will overwrite an existing evidence expense that hasn't been invoiced yet.").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.expenseService.add(input)),
      this.notifications.catchAlertPipe(),
      this.notifications.alertPipe("Evidence expense successfully added")
    ).subscribe(() => {
      this.load();
    });
  }

  public orderInvestigationStaff(investigationStaff: IApiInvestigationStaff[]) {
    // Order investigation staff correctly
    // 1) Lead Investigator 2) Lead Tech Reviewer/Engineer 3) Remaining staff, alpha order
    if (investigationStaff && investigationStaff.length) {
      // First sort by created Date
      // This way we find the correct investigator / tech reviewer
      investigationStaff = investigationStaff.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      const staff = [];
      // Then find lead investigator / tech reviewer
      const leadInvestigator = investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator);
      const leadTechOnly = investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer);

      if (leadInvestigator) staff.push(leadInvestigator);
      if (leadTechOnly) staff.push(leadTechOnly);
      return staff;
    } else {
      return [];
    }
  }

  public onCellClick(event) {
    this.expandedElement = this.expandedElement === event?.dataItem ? null : event.dataItem;
    this.expandedRaw = this.expandedElement ? { ...this.expandedElement } : null;
    this.expandedDetailKeys = [event?.dataItem?.id];
    this.allExpandedInvoices();
  }

  /**
   * return true if sms is sent and history record is not deleted yet
   * @param dataItem
   * @returns
   */
  public isWaitingForReply(dataItem) {
    return dataItem?.InvestigationAssignmentSmsHistory?.length && !dataItem?.InvestigationAssignmentSmsHistory?.find(rec => rec.deletedAt !== null)
  }
  /**
   * return true if sms is sent and at least one record is deleted
   * @param dataItem
   * @returns
   */
  public isAssnRequestDeclined(dataItem) {
    return dataItem?.InvestigationAssignmentSmsHistory?.length && dataItem?.InvestigationAssignmentSmsHistory?.find(rec => rec.deletedAt !== null)
  }

  public openInvestigationSidebar(dataItem) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationDetailsSidebarComponent,
      width: "50%",
      height: "100vh",
      cssClass: 'right-position history-log',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationDetailsSidebarComponent;
    dialogInstance.data = {
      investigation: dataItem,
      from: "INVESTIGATION_LIST"
    };
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.load(false);;
    })
  }

  ngOnDestroy(): void {
    if (this.refreshPendingSubscription) {
      this.refreshPendingSubscription.unsubscribe();
    }
  }

}
