<mat-dialog-content>
  <h4 class="text-center">{{ data?.evidence?.length > 1 ? 'Transfer Items' : 'Transfer Item' }}
    <span class="evidence-ids" [innerHTML]="evidenceIds"></span>
  </h4>
  <h4 class="text-center muted" *ngIf="nefcoNumber">Investigation # {{ nefcoNumber }}</h4>
  <div class="d-flex mt-3">
    <form #transferForm="ngForm">
      <div class="row mx-4">
        <div class="col-6">

          <mat-form-field>
            <mat-label>Transfer From</mat-label>
            <input matInput [value]="currentLocation" disabled />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Transfer To</mat-label>
            <mat-select [(ngModel)]="transfer.ToLocationId" name="transferTo" required [disabled]="this.data?.receiveTransfer">
              <mat-option *ngFor="let loc of locations" [value]="loc.id">{{ loc.name }}</mat-option>
              <mat-option (click)="createStorageLocation()">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Carrier</mat-label>
            <mat-select [(ngModel)]="transfer.ShippingCarrierId" name="shippingCarrier" required [disabled]="this.data?.receiveTransfer">
              <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">{{ carrier?.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Tracking Number</mat-label>
            <input matInput [(ngModel)]="transfer.trackingNumber" name="trackingNumber" [disabled]="this.data?.receiveTransfer" />
          </mat-form-field>
        </div>
        <div class="col-12 justify-content-end" *ngIf="!uploadedDoc && !this.data?.receiveTransfer; else docUploaded">
            <button mat-button color="accent" class="ml-auto" (click)="fileSelector()"><mat-icon color="accent" class="mr-2">backup</mat-icon>upload shipping receipt</button>
        </div>
        <ng-template #docUploaded>
          <div *ngIf="uploadedDoc" class="mb-3">
            <button mat-icon-button (click)="removeDocument()" [disabled]="this.data?.receiveTransfer"><mat-icon>remove_circle</mat-icon></button>
            <a href="{{uploadedDoc.s3Uri | s3}}" target="_blank" [innerHTML]="uploadedDoc.title"></a>
          </div>
        </ng-template>
        <div class="col-12">
          <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput [(ngModel)]="transfer.comment" name="comment" [disabled]="this.data?.receiveTransfer"></textarea>
          </mat-form-field>
        </div>
        <!-- If this is receiving a transfer, show these fields -->
        <ng-container *ngIf="this.data?.receiveTransfer">

          <!-- Section -->
          <div class="col-3">
            <mat-form-field>
              <mat-label>Section</mat-label>
              <input matInput name="section" [(ngModel)]="transfer.evidenceSection" />
            </mat-form-field>
          </div>

          <!-- Row -->
          <div class="col-3">
            <mat-form-field>
              <mat-label>Row</mat-label>
              <input matInput name="row" [(ngModel)]="transfer.evidenceRow" />
            </mat-form-field>
          </div>

          <!-- Received Date -->
          <div class="col-3">
            <mat-form-field class="datePicker">
              <mat-label> Date </mat-label>
              <input matInput [matDatepicker]="picker" name="date" required [(ngModel)]="transfer.receivedDate" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Received Time -->
          <div class="col-3">
            <ngx-timepicker-field [disabled]="!transfer.receivedDate" [(ngModel)]="receivedTime" [controlOnly]="true" [ngModelOptions]="{standalone: true}"></ngx-timepicker-field>
          </div>

          <!-- Received Comments -->
          <div class="col-12">
            <mat-form-field>
              <mat-label>Received Comments</mat-label>
              <textarea matInput [(ngModel)]="transfer.receivedComment" name="commentReceived"></textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="d-flex justify-content-end">
    <button mat-button [mat-dialog-close]>CANCEL</button>
    <button class="blue-action-button" mat-button (click)="saveTransfer()" [disabled]="!transferForm.form.valid">{{this.data?.receiveTransfer ? 'RECEIVE' : 'SAVE'}} TRANSFER</button>
  </div>

</mat-dialog-content>