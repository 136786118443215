import gql from 'graphql-tag';

const nodeFragment = `
  id
  uri
  caption
  createdAt
  Type {
    id,
    name
    Category {
      id
      name
    }
  }
  CreatedBy {
    id
    email
    firstName
    lastName
  }
`;

export const createInvestigationReportMutation = gql`mutation CreateInvestigationReport($input: CreateInvestigationReportInput!) {
  createReport(input: $input) { ${ nodeFragment } }
}`;

export const advanceInvestigationReportMutation = gql`mutation AdvanceInvestigationReport($input: AdvanceInvestigationReportInput!) {
  advanceReport(input: $input)
}`;

export const generateInvestigationReportQuery = gql`query GenerateInvestigationMetricsCSV($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $sortOrder: SortOrder) {
  generateInvestigationMetricsCSV(filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) 
}`;

export const getInvestigationReportQuery = gql`query InvestigationMetricsConnection($filters: [InvestigationMetricsFilter], $orderBy: InvestigationMetricsOrderBy, $first: Int, $last: Int, $before: ID, $after: ID, $sortOrder: SortOrder) {
  investigationMetricsConnection(first: $first, last: $last, before: $before, after: $after, filters: $filters, orderBy: $orderBy, sortOrder: $sortOrder) {
    pageInfo {
      limit
      offset
      __typename
    }
    totalCount
    edges {
      cursor
      node {
        id
        nefcoNumber
        status
        lossDate
        InvoiceTotal 
        Insured {
          id
          insuredName
          __typename
        }
        receivedDate
         Expenses {
          id
          expenseDate
          __typename
        }
        RiskType {
          id
          name
          __typename
        }
        LossAddress {
          id
          isPrimary
          address1
          address2
          address3
          city
          state
          postal
          country
          __typename
        }
        Detail {
          id
          policyNumber
          claimNumber
          __typename
        }
        InvestigationStaff {
          id
          isPrimary
          isDefault
          clientRequested
          createdAt
          User {
            id
            firstName
            lastName
            __typename
          }
          Role {
            id
            title
            __typename
          }
          __typename
        }
        BillTo {
            id
            firstName
            middleName
            lastName   
            __typename
        }
        Company {
          id
          name
          __typename
        }
        OnSceneDate
       
        __typename
      }
      __typename
    }
    __typename
  }
}`;
