<kendo-card width="100%" class="mb-16">
  <kendo-card-body class="pt-16">
    <kendo-gridlayout
      [rows]="['auto']"
      [gap]="{ rows: 5, cols: 16 }"
      [cols]="['98%', '1%']"
    >
      <kendo-gridlayout-item class="expence-left-section">
        <div>
          <div class="user-details mb-14">
            <input
              type="checkbox"
              kendoCheckBox
              (change)="toggleAll($event?.target)"
              [checked]="
                unpaidDays.length === selectedTimeEntries.selected.length &&
                unpaidDays.length > 0
              "
            />
            <span class="user-name"
              >{{ user?.lastName }}, {{ user?.firstName }}</span
            >
          </div>
          <div class="user-sub-details">
            <span class="mr-16">
              <span
                *ngFor="let position of user?.Positions | slice : 0 : 1"
                [class]="'position'"
                >{{ position.title }}</span
              ><span [class]="'position'">|</span><span>Part Time</span>
              <span
                kendoTooltip
                position="bottom"
                *ngIf="user?.Positions?.length > 1"
                [tooltipTemplate]="assignTooltip"
                filter="a[href]"
                tooltipClass="tooltipwidth200"
                style="margin-left: 4px"
              >
                <a href="javascript:void(0)" class="more-assigne primary-color"
                  >+{{ user?.Positions?.length - 1 }}</a
                >
                <ng-template #assignTooltip>
                  <span
                    class="dflex"
                    *ngFor="
                      let position of user?.Positions
                        | slice : 1 : user?.Positions?.length
                    "
                  >
                    {{ position?.title }}
                  </span>
                </ng-template>
              </span>
            </span>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <kendo-grid
      [data]="payPeriodData"
      class="user-payroll-table"
      [pageable]="false"
    >
      <kendo-grid-column
        field="Pay_Period"
        title="payPeriod"
        [width]="40"
        [sortable]="false"
        class="user-payroll-table-section-cell from-to-date"
        [headerClass]="'sort-header remove-bar'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <p class="mb-0"><b>Pay Period</b></p>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- <section class="user-payroll-table-section from-to-date"> -->
          <span
            >{{ dataItem?.fromDate | customDate : "MM-dd-yyyy" }} -
            {{ dataItem?.toDate | customDate : "MM-dd-yyyy" }}</span
          >
          <!-- </section> -->
        </ng-template>
      </kendo-grid-column>
      <!-- Sunday column start -->
      <kendo-grid-column
        field="Sun"
        title="Sunday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >S</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[0] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Sunday column end -->
      <!-- Monday column start -->
      <kendo-grid-column
        field="Mon"
        title="Monday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >M</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[1] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Monday column end -->
      <!-- Tuesday column start -->
      <kendo-grid-column
        field="Tue"
        title="Tuesday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >T</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[2] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Tuesday column end -->
      <!-- Wednesday column start -->
      <kendo-grid-column
        field="Wed"
        title="Wednesday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >W</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[3] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Wednesday column end -->
      <!-- Thursday column start -->
      <kendo-grid-column
        field="Thu"
        title="Thursday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >T</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[4] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Thursday column end -->
      <!-- Friday column start -->
      <kendo-grid-column
        field="Fri"
        title="Friday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >F</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[5] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Friday column end -->
      <!-- Saturday column start -->
      <kendo-grid-column
        field="Sat"
        title="Saturday"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-center'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <kendo-badge
            class="badge-custom mr-16 Low"
            position="none"
            size="large"
            rounded="full"
            themeColor="tertiary"
            >S</kendo-badge
          >
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem.days[6] }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
      <!-- Saturday column end -->
      <!-- weekTotals-->
      <kendo-grid-column
        field="weektotals"
        title="WeekTotals"
        [width]="25"
        [sortable]="false"
        class="font-weight600"
        [headerClass]="'sort-header remove-bar text-right'"
      >
        <ng-template
          kendoGridHeaderTemplate
          let-column
          let-columnIndex="columnIndex"
        >
          <p class="mb-0"><b>Week</b></p>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="tablecell; context: { data: dataItem }"
          ></ng-container>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="d-flex justify-content-between view-detail">
      <kendo-switch [(ngModel)]="viewDetails"></kendo-switch>
      <p class="ml-8 mb-0">View Details</p>
      <p class="ml-auto pl-4 mb-0 secondary">
        Total Unpaid:
        <span class="bold-total">{{ overallTimeTotal.hoursUnpaid }}</span>
      </p>
      <p class="pl-4 mb-0 secondary">
        Total Paid:
        <span class="bold-total">{{ overallTimeTotal.hoursPaid }}</span>
      </p>
      <p class="pl-4 mb-0 secondary">
        Total:
        <span class="bold-total">{{
          overallTimeTotal.hours | number : "1.1-1"
        }}</span>
      </p>
    </div>
  </kendo-card-body>
</kendo-card>

<ng-template #tablecell let-data="data">
  <span
    class="dynamic-color"
    [ngClass]="{
      grey: data.unpaidEntries?.length <= 0 && data.paidEntries?.length > 0,
      red: !data.currentPayPeriod && data.unpaidEntries?.length > 0 && data?.totalUnpaid < 40,
      yellow:
        data.unpaidEntries?.length > 0 &&
        ((data?.totalUnpaid > 8 && !data?.fromDate) ||
          (data?.totalUnpaid > 40 && data?.fromDate))
    }"
  ></span>
  <section
    class="user-payroll-table-section-cell"
    [ngClass]="{
      grey: data.unpaidEntries?.length <= 0 && data.paidEntries?.length > 0,
      red: !data.currentPayPeriod && data.unpaidEntries?.length > 0 && data?.totalUnpaid < 40,
      yellow:
        data.unpaidEntries?.length > 0 &&
        ((data?.totalUnpaid > 8 && !data?.fromDate) ||
          (data?.totalUnpaid > 40 && data?.fromDate))
    }"
  >
    <div
      class="section-inner"
      (click)="
        data.unpaidEntries?.length > 0 && !data?.fromDate
          ? (data.selected = !data.selected)
          : null;
        toggleCheckbox(data)
      "
    >
      <ng-container *ngIf="data?.paidEntries?.length <= 0 && data?.unpaidEntries?.length <= 0">
        <kendo-gridlayout
          [rows]="['auto']"
          [gap]="{ rows: 5, cols: 16 }"
          [cols]="['repeat(1,minmax(0,1fr))']"
        >
          <kendo-gridlayout-item>
            <ng-container *ngIf="!data?.fromDate">
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="data.selected"
                [disabled]="data?.unpaidEntries?.length <= 0"
                (change)="toggleCheckbox(data)"
              />
            </ng-container>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </ng-container>
      <!-- viewDetails NO -->
      <ng-container
        *ngIf="(data.paidEntries?.length > 0 || data?.unpaidEntries?.length > 0) && !viewDetails"
      >
        <kendo-gridlayout
          [rows]="['auto']"
          [gap]="{ rows: 5, cols: 16 }"
          [cols]="['repeat(2,minmax(0,1fr))']"
        >
          <kendo-gridlayout-item>
            <ng-container
              *ngIf="
                !data?.fromDate &&
                !(
                  data.unpaidEntries?.length <= 0 &&
                  data.paidEntries?.length > 0 &&
                  !data?.fromDate
                )
              "
            >
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="data.selected"
                [disabled]="data.unpaidEntries?.length <= 0"
                (change)="toggleCheckbox(data)"
              />
            </ng-container>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item style="text-align: right">
            <p class="paid-span secondary mb-4">{{ data?.totalPaid }} Paid</p>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
        <p class="text-right" *ngIf="data?.unpaidEntries?.length">
          <b>{{ data.totalUnpaid }}</b> Unpaid
        </p>
        <div class="description">
          <div *ngFor="let entry of data.summaryTypeUnpaid">
            <b>{{ entry.hours }}</b>
            <p [innerHTML]="entry?.name | timeNameShort"></p>
          </div>
        </div>
      </ng-container>
      <!-- viewDetails YES -->
      <ng-container
        *ngIf="(data.paidEntries?.length > 0 || data.unpaidEntries?.length > 0) && viewDetails"
      >
        <kendo-gridlayout
          [rows]="['auto']"
          [gap]="{ rows: 5, cols: 16 }"
          [cols]="['repeat(1,minmax(0,1fr))']"
        >
          <kendo-gridlayout-item>
            <ng-container
              *ngIf="
                !data?.fromDate &&
                !(
                  data.unpaidEntries?.length <= 0 &&
                  data.paidEntries?.length > 0 &&
                  !data?.fromDate
                )
              "
            >
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="data.selected"
                [disabled]="data?.unpaidEntries?.length <= 0"
                (change)="toggleCheckbox(data)"
              />
            </ng-container>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
        <div class="detail-description mt-auto">
          <p *ngFor="let entry of data.summaryInvestUnpaid" class="mt-2">
            <span class="d-flex">
              <span class="mr-auto link">
                <p *ngIf="entry?.id === null">
                  {{ entry?.name }}
                  <span
                    *ngIf="entry?.description"
                    kendoTooltip
                    position="left"
                    [tooltipTemplate]="assignTooltip"
                    filter="a[href]"
                    tooltipClass="tooltipwidth200"
                    style="margin-left: 4px"
                  >
                    <a href="javascript:void(0)"
                      ><i class="fa-solid fa-circle-info"></i
                    ></a>
                    <ng-template #assignTooltip>
                      {{ entry?.description }}
                    </ng-template>
                  </span>
                </p>
                <a
                  *ngIf="entry?.id"
                  [routerLink]="['/investigations', entry.id]"
                  [queryParams]="{ tab: 'expenses' }"
                  target="_blank"
                  >{{ entry.name }}</a
                ></span
              >
              <span class="hours" [innerHTML]="entry.hours"></span>
            </span>
            <span
              class="time-description"
              [innerHTML]="(entry?.timeEntryName | timeNameShort) || ''"
            ></span>
          </p>
          <hr
            *ngIf="data.summaryInvestUnpaid?.length > 0"
            class="total-divider"
          />
          <!-- <ng-template> -->
          <!-- Unpaid Hours -->
          <div class="d-flex mb-4">
            <p class="mr-auto font-weight-bold mb-0">Total Unpaid</p>
            <p class="font-weight-bold mb-0">{{ data.totalUnpaid }}</p>
          </div>
          <!-- Paid Hours -->
          <div class="d-flex">
            <p class="mr-auto paid-span secondary mb-0">Total Paid</p>
            <p class="paid-span secondary mb-0">{{ data?.totalPaid }}</p>
          </div>
          <!-- </ng-template> -->
        </div>
      </ng-container>
    </div>
  </section>
</ng-template>
