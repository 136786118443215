import { IApiUserFilterType, IApiUserOrderBy } from './../../../../shared/modules/graphql/types/types';
import { UserDataSource } from './../../../../shared/services/user/user.datasource';
import { UserService } from 'src/app/shared/services';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { switchMap, filter, tap, map } from 'rxjs/operators';
import { IApiPermissionRole, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionRoleService } from 'src/app/shared/services';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { of, forkJoin } from "rxjs";

@Component({
  templateUrl: './permission-role-assign-modal.component.html',
  styleUrls: ['./permission-role-assign-modal.component.scss']
})
export class PermissionRoleAssignModalComponent {
  @ViewChild("search") searchControl: ElementRef;

  public isSubmitted = false;

  public selected: any = {};
  public get selectedUsers() {
    return Object.keys(this.selected).map(id => this.selected[id]).sort((a, b) => a.lastName > b.lastName ? 1 : 0);
  }

  private _role: IApiPermissionRole;
  public get role() {
    return this._role;
  }

  private _dataSource: UserDataSource;
  public get dataSource() {
    return this._dataSource;
  }

  public displayedColumns = ["checked", "LASTNAME", "position", "location", "EMAIL", "permissionRole", "phone"];

  public searchValue = "";

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  constructor(
    private permissionRoles: PermissionRoleService,
    public dialogRef: MatDialogRef<PermissionRoleAssignModalComponent>,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private users: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._role = data;

    this._dataSource = new UserDataSource(this.users);
    this.loader.attachObservable(this._dataSource.loading$);

    this._dataSource.listPage.limit = 10;

    // populate the data
    this.load();
  }

  public cancel(): void {
    of(this.selectedUsers).pipe(
      switchMap(users => users.length
        ? this.notifications.confirm("You have selected users; discard and close?", "Hang on a second!").afterClosed()
        : of(true)
      ),
      filter(result => result === true)
    ).subscribe(() => this.dialogRef.close(false));
  }

  public load(search?: string, positionId?: string) {
    // start by excluding all the users assigned
    const userIds = this.role.AssignedUsers.map(user => user.id);
    const filters: Array<any> = [
      {
        type: IApiUserFilterType.NotUserId,
        value: JSON.stringify(userIds)
      }
    ];

    if (search) filters.push({
      type: IApiUserFilterType.Search,
      value: search
    });
    else filters.filter((e) => e.type !== IApiUserFilterType.Search);

    this.dataSource.pagingReset();
    filters.push({
      type: IApiUserFilterType.ViewAssignmentStaffRole,
      value: 'true'
    });
    if (filters && filters?.length > 0) {
      filters.map((obj) => {
        this.dataSource.applyFilter(obj.type, obj.value);
      });
    }
    this.dataSource.load(filters);
  }

  public toggleUser(user: IApiUser) {
    if (this.selected[user.id]) {
      this.removeUser(user);
      return;
    }

    this.selected[user.id] = user;
  }

  public removeUser({ id }: IApiUser) {
    delete this.selected[id];
  }

  public assignUsers() {
    this.loader.show$(
      forkJoin(
        this.selectedUsers.map(user => {
          const { id: userId } = user;
          return this.permissionRoles.assignUser({
            userId,
            permissionRoleId: this.role.id
          }).pipe(
            // remove successfully added users as they succeed leaving only potential failures
            tap(() => this.removeUser(user))
          );
        })
      )
    ).pipe(
      this.notifications.alertPipe("Successfully added Users to Permission Role!"),
      this.notifications.catchAlertPipe("Error adding User(s); please try again.")
    ).subscribe(() => this.dialogRef.close(true));
  }
}
