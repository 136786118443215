<section>
    <app-investigations-report-filter
        [filterColumns]="filterColumns"
        [reportPage]="'investigationReport'"
        [colSpan]="1"
        (applyFilter)="applyFilter($event)"
        (filters)="filterReport($event)"
        (clearFilter)="clearFilter($event)"
    >
    </app-investigations-report-filter>
    <div class="pt-0 mb-16 ml-24">
      <button kendoButton themeColor="light" fillMode="solid" iconClass="fa-solid fa-file-export" (click)="exportCsv()"
        class="mr-10">Export as CSV</button>
    </div>

    <kendo-grid
        class="report-list"
        [data]="{
            data: tableData,
            total: (dataSource?.pageInfo$ | async)?.totalCount
        }"
        [pageSize]="pageOptions?.limit"
        [skip]="pageOptions?.offset"
        [pageable]="{
            buttonCount: 6,
            type: 'numeric',
            pageSizes: [5, 10, 25, 100],
            previousNext: true,
            responsive: true,
            position: 'bottom'
        }"
        (pageChange)="pageChange($event)"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [navigatable]="false"
        [resizable]="true"
        [scrollable]="true"
    >

        <!-- nefco number -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('nefcoNumber') > -1"
            field="NEFCO_NUMBER"
            title="NEFCO Number"
            [width]="150"
            class="id-column"
            [locked]="true"
            [resizable]="false"
            [headerClass]="'sort-header'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <a
                    target="_blank"
                    [routerLink]="['/investigations/', dataItem?.id]"
                    tel=""
                    class="text-decoration"
                >
                    {{ dataItem?.nefcoNumber || "Unassigned" }}
                </a>
            </ng-template>
        </kendo-grid-column>

        <!-- Status -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('status') > -1"
            field="status"
            title="Status"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="text-overflow">
                    {{
                    dataItem?.status === "PENDING"
                        ? "Pending"
                        : dataItem?.status === "" || !dataItem?.status
                        ? "-"
                        : investigationStatusNames[dataItem?.status]
                    }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <!-- Risk Date -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('lossDate') > -1"
            field="LOSS_DATE"
            title="Loss Date"
            [width]="150"
            [sortable]="true"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.lossDate | customDate : "MM/dd/yyyy" : true }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Insured -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('insured') > -1"
            field="Insured"
            title="Insured"
            [width]="200"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ (dataItem?.Insured | insuredName) || '-' }}<br /></span>
                <span class="phone-number" *ngIf="dataItem?.Insured?.Phones">{{
                    (dataItem?.Insured?.Phones | primaryAssociation)?.number
                    | phoneFormat : "US"
                }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Received Date -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('dateReceived') > -1"
            field="LOSS_DATE"
            title="Date Received"
            [width]="150"
            [sortable]="true"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.receivedDate | customDate : "MM/dd/yyyy" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Investigator Onscene Date -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('investigatorOnSceneDate') > -1"
            field="ONSCENE_DATE"
            title="Investigator On-Scene Date"
            [width]="175"
            [sortable]="true"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ (dataItem?.OnSceneDate | customDate : "MM/dd/yyyy") || '-' }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Expense Date -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('expenseDate') > -1"
            field="LOSS_DATE"
            title="Expense Date"
            [width]="150"
            [sortable]="true"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ (dataItem?.Expenses?.[0]?.expenseDate | customDate : "MM/dd/yyyy") || '-' }}<br/></span>
                <span class="phone-number">{{dataItem?.Expenses?.[0]?.expenseDate | customDate : "hh:mm a" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- riskType -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('riskType') > -1"
            field="RiskType"
            title="Risk Type"
            [width]="200"
            [sortable]="false"
            [resizable]="false"
            class="font-weight600"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="risk-type">
                    <img
                    class="risk-type-img"
                    src="{{ getIcon(dataItem?.RiskType?.name) }}"
                    />
                    <span class="text-overflow mt-5">{{ dataItem?.RiskType?.name }}</span>
                </div>
            </ng-template>
        </kendo-grid-column>

        <!-- Address 1 -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('address') > -1"
            field="LossAddress"
            title="Address 1"
            [width]="200"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.LossAddress?.address1 || '-'}}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- City -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('city') > -1"
            field="city"
            title="City"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.LossAddress?.city || '-'}}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- State -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('state') > -1"
            field="state"
            title="State"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.LossAddress?.state || '-'}}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Postal -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('postal') > -1"
            field="postal"
            title="Postal"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.LossAddress?.postal || '-'}}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Claim Number -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('claimNumber') > -1"
            field="Detail"
            title="Claim Number"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem?.Detail?.claimNumber || "-" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Policy Number -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('policyNumber') > -1"
            field="policyNumber"
            title="Policy Number"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem?.Detail?.policyNumber || "-" }}</span>
            </ng-template>
        </kendo-grid-column>

        <!-- Bill To -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('billTo') > -1"
            field="BillTo"
            title="Bill To"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ (dataItem?.BillTo | fullName) || '-' }} </span>
            </ng-template>
        </kendo-grid-column>

        <!-- Billing Rule -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('billingRule') > -1"
            field="Billing Rule Company"
            title="Billing Rule Company"
            [width]="300"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.Company?.name || '-' }} </span>
            </ng-template>
        </kendo-grid-column>

        <!-- Investigator -->
        <kendo-grid-column
            *ngIf="columnsNameList?.indexOf('investigator') > -1"
            field="investigator"
            title="Investigator"
            [width]="150"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ (dataItem?.InvestigationStaff?.[0]?.User | fullName) || '-' }} </span>
            </ng-template>
        </kendo-grid-column>

        <!-- Billed Invoice Total -->
        <kendo-grid-column
            class="text-center"
            *ngIf="columnsNameList?.indexOf('billedInvoiceTotal') > -1"
            field="billedInvoiceTotal"
            title="Billed Invoice Total"
            [width]="200"
            [sortable]="false"
            [resizable]="false"
            [headerClass]="'nocursor'"
        >
            <ng-template kendoGridCellTemplate let-dataItem>
            <span>${{dataItem?.InvoiceTotal | number: '1.2-2'}}</span>
            </ng-template>
        </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate>
        <p class="mt-24 mb-24">There are no results to display.</p>
      </ng-template>
    </kendo-grid>
</section>
