<!-- <full-calendar #calendar [options]="calendarOptions"></full-calendar> -->
<kendo-scheduler #scheduler [slotClass]="getSlotClass" [kendoSchedulerBinding]="calendarOptions?.events"
  (dateChange)="dateChange($event)" [(selectedViewIndex)]="selectedViewIndex" [selectedDate]="selectedDate"
  (eventClick)="viewEvent($event)" [loading]="calendarLoading" [eventClass]="getEventClass">
  <ng-template kendoSchedulerToolbarTemplate let-selectedDate="selectedDate">
    <app-calendar-view-navigation (reload)="reload()" [from]="from" [investigation]="investigation"
      (calendarView)="setCalendarViewIndex($event)" [selectedViewIndex]="selectedViewIndex"
      [selectedDate]="selectedDate"></app-calendar-view-navigation>
  </ng-template>
  <ng-template kendoSchedulerAllDayEventTemplate let-event="event">
    <div class="event-day-view">
      <span class="time-range mb-0">{{event?.start | date: "hh:MM a"}} - {{event?.end | date: "hh:MM a"}}</span>
      <span class="event-title">{{event.title}}</span>
    </div>
  </ng-template>
  <ng-template kendoSchedulerEventTemplate let-event="event">
    <div class="event-month-view" *ngIf="selectedViewIndex === 1">
      <span class="blue-dot"><i class="fa-solid fa-circle"></i></span>
      <span class="start-time">{{event?.start | date: "h a"}}</span>
      <span class="event-title">{{event.title}}</span>
    </div>
    <div class="event-day-view" *ngIf="selectedViewIndex === 0">
      <p class="time-range mb-0">{{event?.start | date: "hh:MM a"}} - {{event?.end | date: "hh:MM a"}}</p>
      <span class="event-title">{{event.title}}</span>
    </div>
  </ng-template>
  <kendo-scheduler-day-view [startTime]="'12:00'"></kendo-scheduler-day-view>
  <kendo-scheduler-month-view></kendo-scheduler-month-view>
</kendo-scheduler>

<kendo-dialog #editschedule *ngIf="isViewEventModelOpen" class="signature-modal" (close)="toggleViewEventModel(false);"
[minWidth]="600" [width]="600" [dialogRef]="editschedule" (appClickOutside)="toggleViewEventModel(false);">
  <kendo-dialog-titlebar>
    <div class="modal-title">
      <span>On Scene Exam Investigation Details</span>
    </div>
  </kendo-dialog-titlebar>

  <div class="model-body p-24">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 12, cols: 0}" [cols]="['repeat(3,minmax(0,1fr))']" class="p-12">
      <kendo-gridlayout-item>
        <h6>
          <small>Investigation Id</small>
        </h6>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="'2'">
        <p class="font-weight600">{{viewEventData?.nefcoNumber || '-'}}</p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <h6>
          <small>Staff Member</small>
        </h6>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="'2'">
        <p class="font-weight600">{{viewEventData?.userName || '-'}}</p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <h6>
          <small>Location</small>
        </h6>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="'2'">
        <p class="font-weight600" [innerText]="(viewEventData?.location) || '-'"></p>

      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <h6>
          <small>Date/Time</small>
        </h6>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [colSpan]="'2'">
        <p class="font-weight600">{{(viewEventData?.start | customDate: "MM/dd/YYYY") || '-'}} <br>
          {{(viewEventData?.start | customDate: "hh:mm a")}} - {{(viewEventData?.end | customDate: "hh:mm a")}}</p>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>

  <kendo-dialog-actions class="schedule-action p-16-24" layout="normal">
    <kendo-button class="link-button" kendoButton themeColor="none" fillMode="link" size="medium" iconClass="fa-solid fa-trash" (click)="deleteSingle(viewEventData?.onSceneId)" >Delete On
      Scene Exam Investigation</kendo-button>
    <div>
      <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="toggleViewEventModel(false)">
        Cancel
      </button>
      <button kendoButton [size]="'medium'" themeColor="primary" (click)="editDetails()">
        Edit Details
      </button>
    </div>
  </kendo-dialog-actions>
</kendo-dialog>
