import _ from 'lodash';
import { Subject } from 'rxjs';
import { IGqlBaseOptions } from '../modules/graphql/interfaces/base.interfaces';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { IApiPageInfo } from "../modules/graphql/types/types";

export class ListPage implements IGqlBaseOptions, IApiPageInfo {
  public paginate$ = new Subject<void>();

  private _limit = 25;
  public get limit() {
    return this._limit;
  }
  public set limit(val: number) {
    if (this.limit !== val) {
      this.after = "";
      this.before = "";
    }
    this._limit = val;
  }

  public offset = 0;
  public after = "";
  public before = '';
  public sortOrder = SortOrder.ASC;
  public orderBy;
  public totalCount = 0;

  public hasNextPage = false;
  public hasPreviousPage = false;
  public startCursor = '';
  public endCursor = '';

  public previousPageIndex = 0;
  private _currentPageIndex = 0;
  public set currentPageIndex(value: number) {
    this.offset = value * this.limit;
    this._currentPageIndex = value;
  }
  public get currentPageIndex(): number {
    return this._currentPageIndex;
  }

  constructor(limit = 25) {
    // leaving in case needed later (5/18/21)
    // if (optionsFragment) _.assign(this, _.pick(optionsFragment, _.keys(this)));
    this._limit = limit;
  }

  public sort(event: Sort) {
    this.sortOrder = event.direction === "asc" ? SortOrder.ASC : SortOrder.DESC;
    this.orderBy = event.active;
    this.paginate$.next();
  }

  public paginate(e: PageEvent | any) {
    this.currentPageIndex = e?.pageIndex || (e?.skip / e?.take);
    this.previousPageIndex = e?.previousPageIndex || ((e?.skip / e?.take) - 1);
    this.limit = e?.pageSize || e?.take;
    
    this.paginate$.next();

    window.scrollTo(0, 0);
  }

  public getPageOptions(): IGqlBaseOptions {
    const { limit, offset, after, before, orderBy, sortOrder } = this;
    return {
      limit,
      offset,
      after,
      before,
      orderBy,
      sortOrder
    };
  }
}
