<section>
  <h4>Invoice List</h4>
  <div class="content-wrapper">
    <mat-card class="filters mat-elevation-z4 mb-4">
      <div class="d-flex justify-content-between">
        <div>
          <button mat-button (click)="filtersActive = !filtersActive">
            <mat-icon color="primary">filter_list</mat-icon>
            Filters
          </button>
        </div>
        <mat-form-field class="search" appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" (appDebouncedInputChange)="filterTable()" name="search" />
          <button mat-icon-button disableRipple matSuffix (click)="searchValue = null; filterTable()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>

      </div>
      <ng-container *ngIf="filtersActive">
        <div class="align-items-center">
          <div class="filters-wrapper">
            <div class="flex-1">
              <mat-form-field class="search" appearance="outline">
                <input matInput placeholder="Invoice #" [(ngModel)]="invoiceNumber" name="invoiceNumber" />
                <button mat-icon-button disableRipple matSuffix (click)="invoiceNumber = null">
                  <mat-icon>clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="flex-1">
              <mat-form-field class="search" appearance="outline">
                <input matInput placeholder="Investigation ID" [(ngModel)]="investigationId" name="investigationId" />
                <button mat-icon-button disableRipple matSuffix (click)="investigationId = null">
                  <mat-icon>clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="flex-1">
              <app-user-select [userViewFilter]="userViewFilter" label="Created By" placeholder="Search ..." [userId]="createdBy" [includeInactive]="true" (selectionChanged)="createdBy = $event?.id || null"></app-user-select>
            </div>
            <div class="flex-1">
              <mat-form-field>
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="start" [required]="endDate" [(ngModel)]="startDate">
                <mat-datepicker-toggle matPrefix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
                <mat-error *ngIf="!startDate && endDate">Please enter an end date</mat-error>
              </mat-form-field>
            </div>
            <div class="flex-1">
              <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="end" [required]="startDate" [(ngModel)]="endDate">
                <mat-datepicker-toggle matPrefix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
                <mat-error *ngIf="startDate && !endDate">Please enter a start date</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button mat-button class="clear-button" (click)="clearFilters()">
            CLEAR
          </button>
          <button mat-button class="filter-button" (click)="filterTable()">
            FILTER
          </button>
        </div>
      </ng-container>
    </mat-card>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)" matSortDirection="desc" matSortActive="createdAt">
      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.InvoiceNumber">Invoice</th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.invoiceNumber"></td>
      </ng-container>
      <ng-container matColumnDef="invId">
        <th mat-header-cell *matHeaderCellDef> Investigation </th>
        <td mat-cell *matCellDef="let row">
          <a target="_blank" [routerLink]="['/investigations/', row.Investigation.id]" [innerHTML]="row.Investigation.nefcoNumber || '~~ Open ~~'"></a>
        </td>
      </ng-container>
      <ng-container matColumnDef="billTo">
        <th mat-header-cell *matHeaderCellDef> Bill To </th>
        <td mat-cell *matCellDef="let row">
          <span class="font-weight-bold">Name:</span> {{row.Investigation.BillTo | fullName}}<br />
          <span class="font-weight-bold">Company:</span> {{row.Investigation.BillToBranch?.Company?.name}}<br />
          <span class="font-weight-bold">Branch:</span> {{row.Investigation.BillToBranch?.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef> Client </th>
        <td mat-cell *matCellDef="let row">
          <span class="font-weight-bold">Name:</span> {{row.Investigation.Client | fullName}}<br />
          <span class="font-weight-bold">Company:</span> {{row.Investigation.ClientBranch?.Company?.name}}<br />
          <span class="font-weight-bold">Branch:</span> {{row.Investigation.ClientBranch?.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef> Created By </th>
        <td mat-cell *matCellDef="let row">
          {{ row.createdBy | fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.CreatedAt" start="desc"> Created </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.createdAt | customDate: 'MM/dd/yyyy'"></td>
      </ng-container>
      <ng-container matColumnDef="qbErrors">
        <th mat-header-cell *matHeaderCellDef> Import Errors </th>
        <td mat-cell *matCellDef="let entry">
          <p *ngFor="let error of entry.QbErrors">
            <strong>{{error.statusCode}}</strong>: {{error.statusMessage}}
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.Status"> Status </th>
        <td mat-cell *matCellDef="let row">
          <strong *ngIf="row.qbExported && !row.QbErrors.length" class="text-success">Complete</strong>
          <strong *ngIf="(!row.qbExported && !row.qbTicketId) || (!row.qbExported && row.qbTicketId && !row.QbErrors.length)" class="text-warning">Pending</strong>
          <strong *ngIf="row.qbTicketId && row.QbErrors.length" class="text-danger">Failed</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="actions">
          <ng-container *ngIf="!row.qbExported">
            <button mat-icon-button (click)="resubmitInvoice(row)" [appEnforcePermission]="authConfig.bookKeepingInvoiceListUpdate">
              <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button (click)="openQbDialog(row)" [appEnforcePermission]="authConfig.bookKeepingInvoiceListUpdate">
              <mat-icon>edit</mat-icon>
            </button>
          </ng-container>
          <button mat-icon-button (click)="deleteInvoice(row)" [appEnforcePermission]="authConfig.bookKeepingInvoiceListDelete">
            <mat-icon>delete</mat-icon>
          </button>
          <button *ngIf="row.qbExported" mat-icon-button (click)="openQbDialog(row, true)">
            <mat-icon color="accent">list_alt</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
  </div>
</section>
