import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiDocumentCategory, IApiDocumentCategoryOrderBy, IApiDocumentTemplate } from './../../../../shared/modules/graphql/types/types';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiAddDocumentTypeInput, IApiDocumentType, IApiSubServiceType, IApiUpdateDocumentTypeInput } from "src/app/shared/modules/graphql/types/types";
import { DocumentCategoryService, DocumentTemplateService, DocumentTypeService, SubServiceTypeService } from "src/app/shared/services";
import { NgForm } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';

@Component({
  templateUrl: './document-type-modal.component.html',
  styleUrls: ['./document-type-modal.component.scss']
})
export class DocumentTypeModalComponent {

  @ViewChild("form") form: NgForm;

  public model: IApiAddDocumentTypeInput;
  public subServiceTypes: IApiSubServiceType[];
  public documentCategories: IApiDocumentCategory[];
  public documentTemplates: IApiDocumentTemplate[];

  public get mapToService() {
    return this.model.SubServiceTypeId;
  }

  constructor(
    private dialogRef: MatDialogRef<DocumentTypeModalComponent>,
    private notifications: NotificationsService,
    private documentTypeService: DocumentTypeService,
    private _subServiceTypes: SubServiceTypeService,
    private _documentCategories: DocumentCategoryService,
    private _documentTemplates: DocumentTemplateService,
    @Inject(MAT_DIALOG_DATA) public data: IApiDocumentType
  ) {
    const {
      id = "",
      name = "",
      Category = { id: null },
      SubServiceTypeId = null,
      Template = null
    } = data || {};

    this.model = {
      name,
      CategoryId: Category.id,
      SubServiceTypeId,
      isCourtDocument: false,
    };

    if (Template) this.model.TemplateId = Template.id;

    this._subServiceTypes.get().pipe(
      unwrapConnection()
    ).subscribe(results => this.subServiceTypes = results);

    this._documentCategories.get([], { orderBy: IApiDocumentCategoryOrderBy.Name, sortOrder: SortOrder.DESC, limit: -1 }).pipe(
      unwrapConnection()
    ).subscribe(results => this.documentCategories = results);

    this._documentTemplates.get().pipe(
      unwrapConnection()
    ).subscribe(results => this.documentTemplates = results);
  }

  public cancel(savedDocumentType = null, created = false) {
    this.dialogRef.close({ savedDocumentType, created });
  }

  onSubmit() {
    this.model.name = this.model.name?.trim();
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  private save() {
    return this.documentTypeService.update({ id: this.data.id, ...this.model } as IApiUpdateDocumentTypeInput).pipe(
      this.notifications.catchAlertPipe("Error saving Document Type")
    ).subscribe(() => this.dialogRef.close());
  }

  private add() {
    const createData = { ...this.model };
    return this.documentTypeService.add(createData as IApiAddDocumentTypeInput).pipe(
      this.notifications.catchAlertPipe("Error adding Document Type")
    ).subscribe(() => this.dialogRef.close());
  }

  public updateDefaultTitle(value: string) {
    // sync default title with document type unless it's been touched
    const defaultTitleControl = this.form.form.controls.defaultTitle;
    if (defaultTitleControl.pristine) {
      this.model.defaultTitle = value;
    }
  }
}
