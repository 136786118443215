<!-- Permissions -->
  <div [appEnforcePermission]="authConfig.companies" #companies="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsProfileContactInformationUpdate" #contactsProfileContactInformationUpdate="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.companiesCreateConfig" #companiesCreateConfig="appEnforcePermission"></div>
  <div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationUpdate" #contactsProfileCompanyInformationUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<mat-dialog-content *ngIf="contact">
  <form #contactForm="ngForm">
    <!-- Message -->
    <p *ngIf="data?.message" [innerHTML]="data?.message"></p>
    <!-- CONTACT INFO -->
    <section *ngIf="contactsProfileContactInformationUpdate?.enabled || contactsCreateConfig?.enabled">
    <h5>Contact Information</h5>
    <div class="row">
      <div class="col-5 d-inline-flex align-items-center">
        <mat-form-field class="stateSelect w-100">
          <mat-label>Role</mat-label>
          <mat-select [(ngModel)]="contact.Role" (ngModelChange)="contact.companyNotRequired = $event.isPrivate" required name="roleId" [disabled]="disableRoleSelect">
            <mat-option *ngFor="let role of roles" [value]="role">
              {{role.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- TODO Checkboxes below .. needed? Add values to gql typedef -->
        <ng-container *ngIf="!contact.Role?.isPrivate">
          <mat-checkbox class="ml-3" [(ngModel)]="contact.companyNotRequired" name="companyNotRequired">Company Not Required</mat-checkbox>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="isInsured()">
        <mat-form-field class="w-100">
          <mat-label>Insured Name</mat-label>
          <input matInput [(ngModel)]="contact.insuredName" appNoWhiteSpace [maxLength]="255" #insuredName="ngModel" name="insuredName" />
          <mat-error *ngIf="!insuredName?.valid && (insuredName?.dirty || insuredName?.touched) && insuredName?.errors" >Please enter valid insured name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput [(ngModel)]="contact.firstName" appNoWhiteSpace [maxLength]="255" #firstName="ngModel" name="firstName" required />
          <mat-error *ngIf="!firstName?.valid && (firstName?.dirty || firstName?.touched) && firstName?.errors" >Please enter valid first name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2" *ngIf="!contact.Role?.isPrivate">
        <mat-form-field class="w-100">
          <mat-label>Middle Name</mat-label>
          <input matInput [(ngModel)]="contact.middleName" appNoWhiteSpace [maxLength]="255" #middleName="ngModel" name="middleName" />
          <mat-error *ngIf="!middleName?.valid && (middleName?.dirty || middleName?.touched) && middleName?.errors" >Please enter valid middle name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-3">
        <mat-form-field class="w-100">
          <mat-label>Last Name</mat-label>
          <input matInput [(ngModel)]="contact.lastName" appNoWhiteSpace [maxLength]="255" #lastName="ngModel" name="lastName" required />
          <mat-error *ngIf="!lastName?.valid && (lastName?.dirty || lastName?.touched) && lastName?.errors" >Please enter valid last name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>Suffix</mat-label>
          <input matInput [(ngModel)]="contact.suffix" appNoWhiteSpace [maxLength]="255" #suffix="ngModel" name="suffix" />
          <mat-error *ngIf="!suffix?.valid && (suffix?.dirty || suffix?.touched) && suffix?.errors" >Please enter valid suffix</mat-error>
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="w-100">
          <mat-label>{{isInsured() ? "Relationship / Title" : "Nickname"}}</mat-label>
          <input matInput [(ngModel)]="contact.nickName" [maxLength]="255" #nickName="ngModel" name="nickName" />
          <mat-error *ngIf="!nickName?.valid && (nickName?.dirty || nickName?.touched) && nickName?.errors" >Please enter valid nick name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field>
          <mat-label>Quickbooks List Id</mat-label>
          <input matInput [(ngModel)]="contact.qbListId" [maxLength]="255" #qbListId="ngModel" name="qbListId" />
          <mat-error *ngIf="!qbListId?.valid && (qbListId?.dirty || qbListId?.touched) && qbListId?.errors" >Please enter valid quickbooks list id</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <h5 class="col-12 font-weight-bold mt-3 mb-1">Phones</h5>
      <div class="col-6" *ngFor="let phone of contact.Phones; let i = index;">
        <div class="d-flex">
          <app-phone class="mb-3" [(ngModel)]="contact.Phones[i]" [name]="'phone' + i" (isPrimary)="isPrimaryToggle($event, i)"></app-phone>
          <button mat-icon-button (click)="removePhone(i)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
      <h5 class="col-12 font-weight-bold mt-3 mb-1">Emails</h5>
      <div class="col-6" *ngFor="let email of contact.Emails; let i = index">
        <div class="d-flex">
          <app-email class="mb-3" [(ngModel)]="contact.Emails[i]" [name]="'email' + i"></app-email>
          <button mat-icon-button (click)="removeEmail(i)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
      <h5 class="col-12 font-weight-bold mt-3 mb-1">Addresses</h5>
      <div class="col-6" *ngFor="let address of contact.Addresses; let i = index;">
        <div class="d-flex">
          <app-address [(ngModel)]="contact.Addresses[i]" [address]="contact.Addresses[i]" [name]="'address' + i" [config]="{ privateContactType: contact.Role?.isPrivate }" (addressChange)="contact.Addresses[i] = $event"></app-address>
          <button mat-icon-button (click)="removeAddress(i)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="add-options">
      <button mat-icon-button (click)="addPhone();">
        <mat-icon>add</mat-icon>
        add phone
      </button>
      <button mat-icon-button (click)="addEmail();">
        <mat-icon>add</mat-icon>
        add email
      </button>
      <button mat-icon-button (click)="addAddress();">
        <mat-icon>add</mat-icon>
        add address
      </button>
    </div>
    </section>
    <!-- Select Company / Branch -->
    <section *ngIf="!contact.companyNotRequired && (companies?.enabled && (companiesCreateConfig?.enabled || contactsProfileCompanyInformationUpdate?.enabled))">
      <section *ngIf="companies?.enabled">
      <h5>Select Company / Branch</h5>
      <div class="filters">
        <div class="row">
          <div class="col-2">
            <mat-form-field class="stateSelect w-100">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="setStateFilter($event)">
                <mat-option [value]="null">All</mat-option>
                <mat-option *ngFor="let state of stateService.allStates" [value]="state.value">
                  {{ state.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6 d-inline-flex">
            <mat-form-field class="flex-fill">
              <mat-label>Company</mat-label>
              <input matInput [(ngModel)]="filters.company" name="company" (appDebouncedInputChange)="setCompanyFilter()" />
            </mat-form-field>
          </div>
          <div class="col-md-4 d-flex justify-content-end align-items-center">
            <button mat-raised-button class="search-button rounded-button" (click)="search()">
              <mat-icon>search</mat-icon>
              Search
            </button>
          </div>
        </div>
      </div>
      <table class="text-left nested-table" mat-table [dataSource]="dataSource" *ngIf="!selectedBranch; else branchList">
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Company</th>
          <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>
        <ng-container matColumnDef="branch">
          <th mat-header-cell *matHeaderCellDef width="80%">
            <table class="text-left">
              <thead>
                <tr>
                  <th width="50%">Branch</th>
                  <th width="50%">Phone Number</th>
                </tr>
              </thead>
            </table>
          </th>

          <td mat-cell *matCellDef="let row">
            <table class="text-left">
              <tbody>
                <tr *ngFor="let branch of row.Branches">
                  <td width="50%">
                    <div class="d-flex" *ngFor="let address of branch.Addresses">
                      <button mat-icon-button class="mr-2" (click)="selectBranch(branch)" color="primary">
                        <mat-icon>playlist_add</mat-icon>
                      </button>
                      <span>{{ address.city }}, {{ address.state }}</span>
                    </div>
                  </td>
                  <td width="50%">
                    <app-email-phone-view class="table-phone" *ngFor="let phone of branch.Phones" [phone]="phone"></app-email-phone-view>
                  </td>
                </tr>
              </tbody>
            </table>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      </section>
      <button mat-button (click)="addNewCompany()" class="mt-16 mb-16" color="accent" *ngIf="companiesCreateConfig?.enabled">
        <mat-icon>playlist_add</mat-icon>
        add new company
      </button>
    </section>
    <ng-template #branchList>
      <ng-container *ngIf="selectedBranch">
        <div class="d-flex flex-row-reverse">
          <button mat-icon-button (click)="selectedBranch = null">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
        <ng-container *ngTemplateOutlet="branchCard; context: { assignment: selectedBranch }"></ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngFor="let assignment of contact.BranchAssignments">
      <ng-container *ngTemplateOutlet="branchCard; context: { assignment: assignment }"></ng-container>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="dialogRef.close()" *ngIf="!this.data?.disableCancel">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!hasCompany() || !contactForm?.valid">Save</button>
</mat-dialog-actions>

<ng-template #branchCard let-assignment="assignment">
  <mat-card>
    <div class="row align-content-end">
      <div class="col-4">
        <p class="muted">Company Name</p>
        <!-- TODO query param enum for company management -->
        <a (click)="dialogRef.close()" [routerLink]="['/admin', 'companies', 'management', assignment.Branch.Company.id]" [queryParams]="{tab: 'company'}">{{ assignment.Branch.Company.name }}</a>
      </div>
      <div class="col-4">
        <p class="muted">Branch Name</p>
        <a (click)="dialogRef.close()" [routerLink]="['/admin', 'companies', 'management', assignment.Branch.Company.id]" [queryParams]="{tab: 'branches', branchId: assignment.Branch.id}">{{ assignment.Branch.name }}</a>
      </div>
      <div class="col-4">
        <p class="muted">Primary Address: ({{ (assignment.Branch.Addresses | primaryAssociation)?.Type?.name ? (assignment.Branch.Addresses | primaryAssociation)?.Type?.name : 'N/A' }})</p>
        <app-address-view class="mb-3" [address]="(assignment.Branch.Addresses | primaryAssociation)"></app-address-view>
      </div>
      <div class="col-4">
        <p class="muted">Phones</p>
        <app-email-phone-view class="mb-3" *ngFor="let companyPhone of assignment.Branch.Phones;" [phone]="companyPhone"></app-email-phone-view>
      </div>
      <div class="col-4">
        <p class="muted">Emails</p>
        <app-email-phone-view class="mb-3" *ngFor="let companyEmail of assignment.Branch.Emails;" [email]="companyEmail"></app-email-phone-view>
      </div>
      <div class="col-2">
        <app-datepicker [(ngModel)]="assignment.startDate" name="start" [label]="'Start'"></app-datepicker>
      </div>
      <div class="col-2">
        <app-datepicker [(ngModel)]="assignment.endDate" name="end" [label]="'End'"></app-datepicker>
      </div>
    </div>
  </mat-card>
</ng-template>
