<ng-container *ngIf="investigations.length === 0; else investigationsList">
  <div class="mat-share-card no-results">
    <br>
    <mat-card>
      <div class="body">
        <p class="text-center">There are no investigations within the given distance</p>
      </div>
    </mat-card>
  </div>
</ng-container>
<ng-template #investigationsList>
  <div class="loss-table">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Loss Date </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.lossDate | customDate:'short' : true"></td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID# </th>
        <td mat-cell *matCellDef="let row">
          <a appEventLink queryParamsHandling="preserve" (click)="openInvestigation(row)" [innerHTML]="row.nefcoNumber | nefcoNumber : row.id"></a>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef> Loss Location </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.LossAddress | addressFormat"></td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Risk Type </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.RiskType?.name"></td>
      </ng-container>

      <ng-container matColumnDef="insured">
        <th mat-header-cell *matHeaderCellDef> Insured </th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.Insured | insuredName"></td>
      </ng-container>

      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-card-actions class="link-action">
            <button mat-raised-button [color]="row.selected ? 'primary' : ''" (click)="row.selected = !row.selected">
              <mat-icon matSuffix>{{ row.selected ? 'link' : 'link_off'}}</mat-icon> {{ row.selected ? 'linked' : 'not linked'}}
            </button>
          </mat-card-actions>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="lossCheckColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: lossCheckColumns;" [ngClass]="{highlighted: selectedRow?.id === row.id}" (click)="rowClick.emit(row.id); selectedRow = row"></tr>
    </table>
  </div>
</ng-template>
