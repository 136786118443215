<div class="{{'time-exp-filter-wrapper template-form' + (mode === 'week' ? ' pl-0 pr-0' : '')}}">
  <div class="date-section" >
    <kendo-gridlayout [rows]="['auto']"
      [gap]="smallScreen && currentFilter === 'DATE_RANGE' ? {rows: 5, cols: 8} : {rows: 5, cols: 16}"
      [cols]="smallScreen && currentFilter === 'DATE_RANGE' ? ['35%', '70%'] : columnDisplay">
      <kendo-gridlayout-item>
        <kendo-formfield class="kendo-formfield">
          <kendo-dropdownlist [data]="filterOptions" textField="text" valueField="slug" fillMode="outline" [valuePrimitive]="true"
            name="filterType" (selectionChange)="filterChange($event)"
            [(ngModel)]="currentFilter"
            style="width: 190px;"
            [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }">
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span class="placeholder"> {{ dataItem?.text}} </span>
            </ng-template>
            <ng-template kendoDropDownListNoDataTemplate>
            </ng-template>
          </kendo-dropdownlist>
        </kendo-formfield>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="month-view">
        <div (click)="changeWeekMonth('backward')" *ngIf="currentFilter === 'EXPENSE_WEEK' || currentFilter === 'EXPENSE_MONTH'">
          <i class="fa-solid fa-angle-left"></i>
        </div>
        <div class="week-ending" *ngIf="currentFilter === 'EXPENSE_WEEK' || currentFilter === 'EXPENSE_MONTH'">
          <ng-container *ngIf="mode==='week'">
            Week Ending: {{ selectedWeek | amDateFormat: 'MM/DD/YYYY' }}
          </ng-container>
          <ng-container *ngIf="mode==='week' && getRange">
            Week Ending: 1
          </ng-container>
          <ng-container *ngIf="mode==='month'">
            Month Ending: {{ getRange ?  (selectedMonthRange?.startDate | amDateFormat: 'MMMM YYYY') : (selectedMonth | amDateFormat: 'MM/DD/YYYY') }}
          </ng-container>
        </div>
        <div class="kendo-formfield date-range-wrapper">
          <kendo-daterange #dateRange [ngStyle]=" {'display':  currentFilter === 'DATE_RANGE'  ? 'block' :'none'}" >
            <kendo-dateinput #startDateRange (click)="focusDateInput(true)" calendarType="classic" class="mr-16" placeholder="Start Date"
            [fillMode]="'outline'" kendoDateRangeStartInput [(ngModel)]="range.startDate"></kendo-dateinput>
            <kendo-dateinput #endDateRange (click)="focusDateInput(false)" id="endDateRange"  calendarType="classic" class="mr-16" placeholder="End Date"
            [fillMode]="'outline'" kendoDateRangeEndInput [(ngModel)]="range.endDate"></kendo-dateinput>
            <kendo-daterange-popup #dateRangePopup autoCorrectOn="blur"></kendo-daterange-popup>
          </kendo-daterange>
        </div>
        <div (click)="changeWeekMonth('forward')" *ngIf="currentFilter === 'EXPENSE_WEEK' || currentFilter === 'EXPENSE_MONTH'">
          <i class="fa-solid fa-angle-right"></i>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>
  <kendo-gridlayout [rows]="['auto']" *ngIf="!investigationView">
    <kendo-gridlayout-item style="display:flex; justify-content: right;">
      <kendo-formfield class="kendo-formfield">
        <kendo-dropdownlist [data]="investigations" textField="nefcoNumber" valueField="id" fillMode="outline"
          [valuePrimitive]="true" [defaultItem]="{id: null, nefcoNumber: 'Investigation ID'}" name="investigationId"
          (valueChange)="changeInvestigation($event)" style="width: 250px" [(ngModel)]="selectedInvestigationId"
          [popupSettings]="{appendTo: 'component', animate: false, popupClass: 'nodata', width: 250 }">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.nefcoNumber || dataItem.id}} </span>
            <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.nefcoNumber || dataItem.id}} </span>
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
