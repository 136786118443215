import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiCompanyFilterType } from './../../modules/graphql/types/types';
import { CompanyService } from 'src/app/shared/services';
import { IApiCompany } from 'src/app/shared/modules/graphql/types/types';
import { Component, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import {
  companies
} from "src/app/shared/helpers/auth-config/companies.config";
@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanySelectComponent),
      multi: true
    }
  ]
})
export class CompanySelectComponent implements ControlValueAccessor {
  public authConfig = {
    companies
  };

  @Input() placeholder = "Search for a Company";
  @Input() label = "";

  @Output() selectionChanged = new EventEmitter<IApiCompany | null>();

  public selected: IApiCompany = null;
  public companies: IApiCompany[];

  public onChange: any = (_: string) => { };
  public onTouch: any = () => { };

  constructor(
    private companyService: CompanyService,
    private loader: LoaderService
  ) { }

  public select(selected: IApiCompany | null) {
    this.selected = selected;
    this.selectionChanged.emit(selected);
    this.onChange(this.selected);
  }

  public onSearchChange(search: string) {
    this.companies = [];
    if (search?.length < 3) return;

    this.loader.show$(
      this.companyService.get([{
        type: IApiCompanyFilterType.Name,
        value: search
      }]).pipe(
        unwrapConnection()
      )
    ).subscribe(results => this.companies = results);
  }

  public selectedDisplay(val: IApiCompany) {
    return val?.name || "";
  }

  public writeValue(value: null | IApiCompany): void {
    this.selected = value;
  }

  public registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (_: Partial<string>) => {}): void {
    this.onTouch = fn;
  }

  public closed() {
    if (typeof this.selected === "string") this.select(null);
  }

  public blur() {
    if (typeof this.selected === "string" && (this.selected as string).length < 3) this.select(null);
  }

}
