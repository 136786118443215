<!-- Permissions -->
<div [appEnforcePermission]="permissionConfig.contactsProfileCompanyInformationView" #contactsProfileCompanyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsProfileContactInformationViewAssigned" #contactsProfileContactInformationViewAssigned="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsInvestigations" #contactsInvestigations="appEnforcePermission"></div>
<div [appEnforcePermission]="permissionConfig.contactsInvestigationsView" #contactsInvestigationsView="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="flexContainer">
    <div class="leftContent">
      <h4 [innerHTML]="contact | fullName"></h4>
    </div>
    <div class="rightContent">
      <button mat-flat-button class="transparent" (click)="goBack()">
        <mat-icon color="accent">keyboard_backspace</mat-icon>
        <span class="backText">Back</span>
      </button>
      <button mat-flat-button class="transparent">
        <mat-icon aria-hidden="false" color="accent">open_in_new</mat-icon>
      </button>
    </div>
  </div>
  <div class="matTabWrapper mat-elevation-z2 mainWrapper">
    <mat-tab-group>
      <mat-tab label="PROFILE" *ngIf="contactsProfileCompanyInformationView?.enabled || contactsProfileContactInformationViewAssigned?.enabled">
        <app-contact-detail></app-contact-detail>
      </mat-tab>
      <mat-tab *ngIf="contactsInvestigations?.enabled">
        <ng-template mat-tab-label>
          <div (click)="investigationTable.load()">INVESTIGATIONS</div>
        </ng-template>
        <div class="p-3">
          <app-investigations-table [contactView]="contactsInvestigationsView?.enabled" [config]="contactConfig" #investigationTable></app-investigations-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>