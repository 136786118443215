<p class="d-flex">
  <a routerLink="/">Dashboard</a>
  <ng-container *ngIf="investigationId">
    <mat-icon>arrow_right_alt</mat-icon>
    <a [routerLink]="['/investigations', investigationId]">Investigation Detail</a>
  </ng-container>
  <ng-container *ngIf="investigationId">
    <mat-icon>arrow_right_alt</mat-icon>
    Edit Investigation
  </ng-container>
</p>
