import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { InvestigationClientReviewModalComponent } from '../investigation-client-review-modal/investigation-client-review-modal.component';
import { MatDialog } from "@angular/material/dialog";
import { IApiAddress, IApiInvestigation, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUpdateInvestigationInput } from '../../../../shared/modules/graphql/types/types';
import { DownloadHelper, InvestigationService } from 'src/app/shared/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { NotesModalComponent } from 'src/app/shared/modules/notes/notes-modal/notes-modal.component';
import { of } from 'rxjs';
import {
  investigationInformationListView,
  investigationInformationUpdate,
  investigationReject,
  investigationReceivedDateUpdate,
  investigationScheduledDateUpdateOwn,
  investigationScheduledDateUpdateAll,
  investigationScheduledDateView,
  investigationPartiesListView,
  investigationPartiesUpdate,
  investigationBillingInformationView,
  investigationBillingInformationUpdate,
  investigationLinkedInvestigationView,
  investigationLinkedInvestigationUpdate,
  investigationVehiclesListView,
  investigationVehiclesCreate,
  investigationVehiclesUpdate,
  investigationVehiclesDelete,
  investigationAssignStaffListView,
  investigationAssignStaffCreate,
  investigationAssignStaffUpdate,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { staffViewAll } from 'src/app/shared/helpers/auth-config/staff.config';
import {
  contactsProfileContactInformationViewAssigned,
  contactsProfileCompanyInformationView,
  contactsInvestigations,
} from "src/app/shared/helpers/auth-config/contacts.config";
import { companiesViewAll } from 'src/app/shared/helpers/auth-config/companies.config';
import { IApiInvestigationRoleNames } from 'src/app/shared/modules/graphql/types/types';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationEditScheduleDateComponent } from '../investigation-edit-schedule-date/investigation-edit-schedule-date.component';
import moment from 'moment';
import { CustomDatePipe } from 'src/app/shared/pipes';
import { NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import _ from 'lodash';
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { CheckBoxComponent } from '@progress/kendo-angular-treeview/checkbox/checkbox.component';
import { CalendarProfileSidebarComponent } from 'src/app/shared/components';

@UntilDestroy()
@Component({
  selector: 'app-investigation-detail',
  templateUrl: './investigation-detail.component.html',
  styleUrls: ['./investigation-detail.component.scss']
})
export class InvestigationDetailComponent implements AfterViewInit, OnChanges {
  @ViewChild('investigationReject') investigationRejectPerm;
  @ViewChild('investigationInformationUpdate') investigationInformationUpdatePerm;
  @ViewChild('investigationBillingInformationView') investigationBillingInformationViewPerm;
  @ViewChild('investigationBillingInformationUpdate') investigationBillingInformationUpdatePerm;
  @ViewChild('investigationLinkedInvestigationView') investigationLinkedInvestigationViewPerm;
  @ViewChild('investigationLinkedInvestigationUpdate') investigationLinkedInvestigationUpdatePerm;
  @ViewChild('investigationVehiclesListView') investigationVehiclesListViewPerm;
  @ViewChild('investigationVehiclesCreate') investigationVehiclesCreatePerm;
  @ViewChild('investigationVehiclesUpdate') investigationVehiclesUpdatePerm;
  @ViewChild('investigationScheduledDateView') investigationScheduledDateViewPerm;
  @ViewChild('investigationScheduledDateUpdateAll') investigationScheduledDateUpdateAllPerm;
  @ViewChild('investigationInformationListView') investigationInformationListViewPerm;

  @ViewChild('receivedDateForm', { static: false }) receivedDateForm: NgForm;

  public authConfig = {
    investigationInformationListView,
    investigationInformationUpdate,
    investigationReject,
    investigationReceivedDateUpdate,
    investigationScheduledDateUpdateAll,
    investigationScheduledDateUpdateOwn,
    investigationScheduledDateView,
    investigationPartiesListView,
    investigationPartiesUpdate,
    investigationBillingInformationView,
    investigationBillingInformationUpdate,
    investigationLinkedInvestigationView,
    investigationLinkedInvestigationUpdate,
    investigationVehiclesListView,
    investigationVehiclesCreate,
    investigationVehiclesUpdate,
    investigationVehiclesDelete,
    investigationAssignStaffListView,
    investigationAssignStaffCreate,
    investigationAssignStaffUpdate,
    staffViewAll,
    contactsProfileContactInformationViewAssigned,
    contactsProfileCompanyInformationView,
    contactsInvestigations,
    companiesViewAll,
  };

  private _investigation: IApiInvestigation;

  public get investigation(): IApiInvestigation {
    return this._investigation;
  }

  @Output() reload = new EventEmitter<boolean>();
  // Set here to get updates from other components
  @Input() public set investigation(val: IApiInvestigation) {
    // Order investigation staff correctly
    // 1) Lead Investigator 2) Lead Tech Reviewer/Engineer 3) Remaining staff, alpha order
    if (val?.InvestigationStaff && val?.InvestigationStaff.length) {
      // First sort by created Date
      // This way we find the correct investigator / tech reviewer
      val.InvestigationStaff = val.InvestigationStaff.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);

      // Then find lead investigator / tech reviewer
      const leadInvestigator = val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator && obj.isPrimary === true)
        || val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator);

      const leadTech = val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer && obj.isPrimary === true)
        || val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.ElectricalEngineer && obj.isPrimary === true)
        || val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.FireProtectionEngineer && obj.isPrimary === true)
        || val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer)
        || val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.ElectricalEngineer)
        || val.InvestigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.FireProtectionEngineer);

      // Then sort by alpha order
      val.InvestigationStaff = val.InvestigationStaff.sort((a, b) => a.User.lastName > b.User.lastName ? 1 : -1);

      // Move items in array
      if (leadTech) {
        val.InvestigationStaff = val.InvestigationStaff.filter((obj) => obj.id !== leadTech.id);
        val.InvestigationStaff.unshift(leadTech);
      }
      if (leadInvestigator) {
        val.InvestigationStaff = val.InvestigationStaff.filter((obj) => obj.id !== leadInvestigator.id);
        val.InvestigationStaff.unshift(leadInvestigator);
      }
    }

    if (val?.receivedDate) {
      this.recievedDate = new Date(this.customDatePipe.transform(val?.receivedDate, 'MM-dd-yyyy, hh:mm a'));
    }

    this._investigation = val;
  }

  public get getLatestInvestigationScheduledEntry() {
    const next = this.pendingCompletedInvestigationScheduledEntry?.pending?.sort((a: any, b: any) => new Date(a.scheduledDate).valueOf() - new Date(b.scheduledDate).valueOf());
    return next?.length ? next[0] : null;
  }

  public get pendingCompletedInvestigationScheduledEntry() {
    const pendingArray = [];
    const completedArray = [];
    const datesArray = this.investigation?.OnSceneHistory?.filter((onScene) => onScene?.status === InvestigationOnSceneStatus.SCHEDULED).sort((a: any, b: any) => new Date(b.scheduledDate).valueOf() - new Date(a.scheduledDate).valueOf());
    for (const entry of datesArray) {
      const offScene = this.investigation?.OnSceneHistory?.some(onScene => onScene?.status === InvestigationOnSceneStatus.OFF_SCENE && entry?.User?.id === onScene?.User?.id && entry?.scheduledDate === onScene?.scheduledDate);
      const scheduled = !this.investigation?.OnSceneHistory?.some(onScene => onScene?.status !== InvestigationOnSceneStatus.SCHEDULED && entry?.User?.id === onScene?.User?.id && entry?.scheduledDate === onScene?.scheduledDate);
      // if off-scene exist then add to completed OR add to pending
      if (offScene) {
        completedArray.push(entry);
      }
      if (scheduled) {
        pendingArray.push(entry);
      }
    }
    return {
      pending: pendingArray,
      completed: completedArray
    }
  }

  public isOpenMoreActions = false;
  public investigationRejectReactivateModal = false;
  public investigationRejectReactivateModalMode = '';
  public isEditReceivedModal = false;
  public recievedDate = null;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private investigationService: InvestigationService,
    private notifications: NotificationsService,
    private dialogService: DialogService,
    private customDatePipe: CustomDatePipe,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private downloadHelper: DownloadHelper,
  ) { }

  public editDetails(section: string) {
    switch (section) {
      case 'editInfo':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { editInfo: true } });
        break;
      case 'Add Billing Information':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { addBillingInfo: true } });
        break;
      case 'editBillingInfo':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { editBillingInfo: true } });
        break;
      case 'Add Linked Investigation':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { addLinkedInvestigation: true } });
        break;
      case 'editLinkedInvestigation':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { editLinkedInvestigation: true } });
        break;
      case 'editParties':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { editParties: true } });
        break;
      case 'editStaff':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { editStaff: true } });
        break;
      case 'Add Vehicle':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { addVehicles: true } });
        break;
      case 'editVehicles':
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id], { queryParams: { editVehicles: true } });
        break;
      case 'Reactivate Investigation':
        this.openRejectReactivateModel('reactivate');
        break;
      case 'Reject Investigation':
        this.openRejectReactivateModel('reject');
        break;
      case "Schedule On Scene Investigation Date":
        this.editScheduledDate("NEW_SCHEDULE");
        break;
      case "View Calendar":
        this.openStaffSidebar(this.investigation);
        break;
      default:
        this.router.navigate(['/', 'admin', 'investigations', 'edit', this.investigation.id]);
        break;
    }
    this.cdr.detectChanges();
  }

  editActions: { text: string }[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (_.isEqual(this.investigation, changes?.investigation?.currentValue)) {
      this.reInItPermission();
    }
  }

  ngAfterViewInit(): void {
    this.reInItPermission();
  }

  public reInItPermission() {
    this.editActions = [];
    /* Investigation Billing Information Permission */
    if (
      this.investigationBillingInformationViewPerm?.enabled &&
      this.investigationBillingInformationUpdatePerm?.enabled &&
      _.findIndex(this.editActions, { text: "Add Billing Information" }) === -1
    ) {
      this.editActions.push({ text: "Add Billing Information" });
    }

    /* Investigation Linked Investigation Permission */
    if (
      this.investigationLinkedInvestigationUpdatePerm?.enabled &&
      this.investigationLinkedInvestigationViewPerm?.enabled &&
      _.findIndex(this.editActions, { text: "Add Linked Investigation" }) === -1
    ) {
      this.editActions.push({ text: "Add Linked Investigation" });
    }


    /* Investigation Vehicles Permission */
    if (
      this.investigationInformationUpdatePerm?.enabled &&
      this.investigationVehiclesListViewPerm?.enabled &&
      this.investigationVehiclesCreatePerm?.enabled &&
      _.findIndex(this.editActions, { text: "Add Vehicle" }) === -1
    ) {
      this.editActions.push({ text: "Add Vehicle" });
    }

    /* Investigation on scene permission */
    if (
      this.investigation?.InvestigationStaff?.find(staff => staff?.Role.title === IApiInvestigationRoleNames.Investigator) &&
      this.investigationScheduledDateViewPerm?.enabled &&
      _.findIndex(this.editActions, { text: "Schedule On Scene Investigation Date" }) === -1
    ) {
      this.editActions.push({ text: "Schedule On Scene Investigation Date" });
    }
    /* Investigation Reject / Reactivate Permission */
    if (this.investigationRejectPerm?.enabled &&
      this.investigation?.deletedAt && _.findIndex(this.editActions, { 'text': 'Reactivate Investigation' }) === -1) {
      this.editActions.push({ text: "Reactivate Investigation" });
    }

    if (this.investigation?.InvestigationStaff?.find(staff => staff?.Role.title === IApiInvestigationRoleNames.Investigator) && this.investigationInformationListViewPerm?.enabled && _.findIndex(this.editActions, { text: "View Calendar" }) === -1) {
      this.editActions.push({ text: "View Calendar" });
    }

    if (
      this.investigationRejectPerm?.enabled &&
      !this.investigation?.deletedAt && _.findIndex(this.editActions, { text: "Reject Investigation" }) === -1) {
      this.editActions.push({ text: "Reject Investigation" });
    }

    this.cdr.detectChanges();
  }

  public markIncendiary(e: CheckBoxComponent) {
    this.updateInvestigation({ isIncendiary: e.checked }).subscribe((res) => !res && (this.investigation.isIncendiary = !e.checked));
  }

  public markOnHold(e: CheckBoxComponent) {
    this.updateInvestigation({ onHold: e.checked }).subscribe((res) => !res && (this.investigation.onHold = !e.checked));
  }

  public markOngoing(e: CheckBoxComponent) {
    this.updateInvestigation({ isOngoing: e.checked }).subscribe((res) => !res && (this.investigation.isOngoing = !e.checked));
  }

  public markRetainerReceived(e: CheckBoxComponent) {
    this.updateInvestigation({ isRetainerReceived: e.checked }).subscribe((res) => !res && (this.investigation.isRetainerReceived = !e.checked));
  }

  private updateInvestigation(update: Partial<IApiUpdateInvestigationInput>) {
    // Trigger comment dialog and, then update investigation with History attribute
    const { id } = this.investigation;
    const data = {
      notes: "",
      title: "Please add a comment on what you are updating.",
      original: ""
    };

    const dialogRef = this.dialog.open(NotesModalComponent, {
      width: "40%",
      data
    });

    return dialogRef.afterClosed().pipe(
      switchMap((comment) => {
        // If no comment came back, return false so we can revert the checkbox to its previous state
        if (comment) return this.loaderService.show$(this.investigationService.update({ id, History: { InvestigationId: id, updateType: IApiInvestigationUpdateTypes.Update, updateCategory: IApiInvestigationUpdateCategories.Detail, comment }, ...update })).pipe(
          this.notifications.catchAlertPipe()
        );
        else return of(false);
      }),
    );
  }

  public openMap(address: IApiAddress) {
    const { address1, city, state, postal } = address;
    const googleMapUrl = encodeURI(`https://www.google.com/maps/search/?api=1&query=${address1} ${city} ${state} ${postal}`);
    window.open(googleMapUrl, "_blank");
  }

  // Find primary address
  public getPrimary(data: any[]) {
    return data?.find((v) => v?.isPrimary) || {};
  }

  public reviewClient() {
    const client = this.investigation.Client?.Emails.find(item => item.isPrimary);
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationClientReviewModalComponent,
      width: 660,
      maxWidth: 660,
      cssClass: 'client-approved-reject',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const userInfo = dialog.content.instance as InvestigationClientReviewModalComponent;
    userInfo.email = client?.address;
    userInfo.id = this.investigation?.Client?.id;

    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === 'approved' || result === 'reject') {
        this.reload.emit(true);
      }
    })
  }

  public editScheduledDate(mode) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEditScheduleDateComponent,
      width: 800,
      maxWidth: 800,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });



    const userInfo = dialog.content.instance as InvestigationEditScheduleDateComponent;
    userInfo.investigationStaff = this.investigation?.InvestigationStaff;
    switch (mode) {
      case "NEXT_SCHEDULE":
        userInfo.investigationOnScenes = [this.getLatestInvestigationScheduledEntry];
        break;
      case "NEW_SCHEDULE":
        userInfo.investigationOnScenes = [];
        break;
      case "EDIT_PENDING_SCHEDULE":
        userInfo.investigationOnScenes = this.pendingCompletedInvestigationScheduledEntry?.pending;
        break;
      default:
        userInfo.investigationOnScenes = this.pendingCompletedInvestigationScheduledEntry?.pending;
        break;
    }

    userInfo.investigation = this.investigation;
    userInfo.headerIcon = 'assets/svg/warning.svg';
    userInfo.mode = mode || "NEXT_SCHEDULE";

    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.reload.emit(true);
      }
    })
  }

  public disabledDates = (date: Date): boolean => {
    return moment(date).isAfter();
  };

  public openRejectReactivateModel(mode): void {
    this.investigationRejectReactivateModalMode = mode;
    this.investigationRejectReactivateModal = true;
  }

  public investigationRejectReactivateModalResponse(action) {
    this.investigationRejectReactivateModal = false;
    this.investigationRejectReactivateModalMode = ''
    if (action === 'rejected' || action === 'reactivated') {
      this.reload.emit(true);
    }
  }

  public openRecivedDateModal(isOpen = true) {
    this.isEditReceivedModal = isOpen;
    if (this.isEditReceivedModal) {
      this.recievedDate = new Date(this.customDatePipe.transform(this.investigation?.receivedDate, 'MM-dd-yyyy, hh:mm a'));
    }
  }

  public editReceivedDate(): void {
    this.receivedDateForm.form.markAllAsTouched();
    if (this.receivedDateForm?.invalid) return;
    this.updateInvestigation({ receivedDate: moment.utc(this.recievedDate) }).subscribe((res) => {
      if (res) this.investigation = { ...this.investigation, receivedDate: this.recievedDate };
      this.openRecivedDateModal(false);
    });
  }

  public openStaffSidebar(data: IApiInvestigation) {
    try {
      const dialog: DialogRef = this.dialogService.open({
        content: CalendarProfileSidebarComponent,
        width: "55%",
        height: "100vh",
        cssClass: 'right-position calendar-sidebar',
        preventAction: (ev) => {
          return ev !== 'closed' as any;
        },
      });
      const dialogInstance = dialog.content.instance as CalendarProfileSidebarComponent;
      dialogInstance.data = data;
      dialogInstance.from = 'INVESTIGATION_DETAILS';

      dialog.result.subscribe((result: DialogCloseResult) => {
        this.reload.emit(true);
      })
    } catch (e) {
      console.log(e);
    }

  }

}
