import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from "../../directives/enforce-permission.directive";
import { PermissionActions, PermissionCategories } from "../../modules/graphql/enums/permissions.enums";

export const dashboardPendingRequests = {
  category: PermissionCategories.DASHBOARD_PENDING_REQUESTS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigationsPending: IEnforcePermissionConfig = {
  category: PermissionCategories.DASHBOARD_INVESTIGATIONS_PENDING,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigatorsOnScene = {
  category: PermissionCategories.DASHBOARD_INVESTIGATORS_ON_SCENE,
  appliedPermissions: {
    All: [PermissionActions.LIST, PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigatorsOnSceneView = {
  category: PermissionCategories.DASHBOARD_INVESTIGATORS_ON_SCENE,
  appliedPermissions: {
    All: [PermissionActions.VIEW],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigatorsOnSceneUpdate = {
  category: PermissionCategories.DASHBOARD_INVESTIGATORS_ON_SCENE,
  appliedPermissions: {
    All: [PermissionActions.UPDATE],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigationsAssigned = {
  category: PermissionCategories.DASHBOARD_INVESTIGATIONS_ASSIGNED,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardReportsReadyForEditor = {
  category: PermissionCategories.DASHBOARD_REPORTS_READY_FOR_EDITOR,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardReportsInEditorReview = {
  category: PermissionCategories.DASHBOARD_REPORTS_EDITOR_REVIEW,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardReportsInTechReview = {
  category: PermissionCategories.DASHBOARD_REPORTS_TECH_REVIEW,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigationsInProgress = {
  category: PermissionCategories.DASHBOARD_INVESTIGATIONS_IN_PROGRESS,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigationsReadyToBill = {
  category: PermissionCategories.DASHBOARD_INVESTIGATIONS_READY_TO_BILL,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [],
    Own: [PermissionActions.LIST]
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};

export const dashboardInvestigationsOngoing = {
  category: PermissionCategories.DASHBOARD_INVESTIGATIONS_ONGOING,
  appliedPermissions: {
    All: [PermissionActions.LIST],
    AllAssigned: [],
    Assigned: [PermissionActions.LIST],
    Own: [],
  },
  displayMode: EnforcePermissionDisplayModes.Hidden
};
