
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GqlInsuredPartyService } from '../../modules/graphql/services/insured-party/insured-party.service';
import { IApiAddInsuredPartyInput, IApiInsuredParty, IApiInsuredPartyFilter, IApiUpdateInsuredPartyInput } from '../../modules/graphql/types/types';
import { IGqlBaseOptions } from '../../modules/graphql/interfaces';
import { ListPage } from '../../helpers/list-page.class';



@Injectable()
export class InsuredPartyService {

  constructor(
    private gqlInsuredPartyService: GqlInsuredPartyService
  ) { }

  public get(filters?: IApiInsuredPartyFilter[], options?: IGqlBaseOptions): Observable<IApiInsuredParty> {
    return this.gqlInsuredPartyService.get(filters, options || new ListPage());
  }

  public add(input: IApiAddInsuredPartyInput) {
    return this.gqlInsuredPartyService.add(input);
  }

  public update(input: IApiUpdateInsuredPartyInput) {
    return this.gqlInsuredPartyService.update(input);
  }

}
