import { IApiInvestigationRoleNames } from '../../modules/graphql/types/types';
import { debounceTime, map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { StandardNamePipe } from '../../pipes/name.pipe';
import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { UserService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IApiUser } from "../../modules/graphql/types/types";
import { unwrapConnection } from '../../rxjs.pipes';
import { AutoCompleteComponent, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { staff } from "src/app/shared/helpers/auth-config/staff.config";
@Component({
  selector: 'app-user-multi-select-kendo',
  templateUrl: './user-multi-select-kendo.component.html',
  styleUrls: ['./user-multi-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserMultiSelectKendoComponent),
      multi: true
    }
  ]
})
export class UserMultiSelectKendoComponent implements ControlValueAccessor, OnInit, OnChanges {
  public authConfig = {
    staff
  }
  @ViewChild("autoCompleteUser", { static: false }) public autoCompleteUser: AutoCompleteComponent;
  @ViewChild("multiSelect", { static: false }) public multiSelect: MultiSelectComponent;
  @Input() placeholder = "Search for a User";
  @Input() label = "";
  @Input() staticUsers: IApiUser[];
  @Input() investigationRole: IApiInvestigationRoleNames = null;
  @Input() disabled = null;
  @Input() includeInactive = false;
  @Input() userViewFilter = null;

  @Input() selectedId: Array<string> = [];
  public users: IApiUser[] = [];

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selected: any = null;
  public loading: boolean = false;
  public searchString: string = '';
  private selectedUsers = [];
  public selectedValue = [];

  @Input() public set user(val: IApiUser) {
    this.selectUser(val?.id);
  }

  constructor(
    private userService: UserService,
    private fullNamePipe: StandardNamePipe
  ) { }

  ngOnInit(): void {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
      this.selectedUsers = [];
    }
  }

  public ngOnChanges() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
      this.selectedUsers = [];
    }
  }

  public selectUser(selected: any) {
    this.selectedUsers = this.users.filter(usr => selected?.includes(usr.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }

  public onSearchFocus(event) {
    if (this.selectedUsers) {
      this.users = this.selectedUsers;
    }
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selectedUsers)
      this.users = this.selectedUsers;
    else
      this.users = [];
    if (search?.length < 3) return;

    const filters = [{
      type: IApiUserFilterType.Search,
      value: search?.trim()
    }];

    if (this.investigationRole) filters.push({
      type: IApiUserFilterType.Position,
      value: JSON.stringify(this.investigationRole.valueOf())
    });

    if (this.includeInactive) filters.push({
      type: IApiUserFilterType.IsInactive,
      value: 'true'
    });

    if (this.userViewFilter) {
      filters.push({
        type: this.userViewFilter,
        value: 'true'
      });
    }
    this.loading = true;
    this.multiSelect.toggle(false)
    this.userService.get(filters).pipe(
      debounceTime(800),
      unwrapConnection(),
      map((item) => {
        const newContact = item.map((i) => {
          return {
            ...i,
            itemFullName: `${i.firstName} ${i.lastName}`
          }
        });
        if (this.staticUsers) newContact.push(...this.staticUsers);
        return newContact;
      })
    ).subscribe(results => {
      this.loading = false;
      this.multiSelect.toggle(true);
      const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
      this.users = [...this.selectedUsers, ...newResult];
    });
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.selectedId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

  public selectedDisplay(val: IApiUser) {
    return this.fullNamePipe.transform(val);
  }

  public closed() {
    if (typeof this.selected === "string") this.selectUser(null);
  }

  public blur() {
    if (typeof this.selected === "string" && (this.selected as string).length < 3) this.selectUser(null);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }


}
