import { NgForm } from '@angular/forms';
import { Optional, ViewChild } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { IApiAddAddressInput, IApiAddContactInput, IApiAddEmailInput, IApiAddNewContactBranchAssignmentInput, IApiAddPhoneInput, IApiAddress, IApiBranch, IApiCompanyFilterType, IApiContact, IApiContactBranchAssignment, IApiContactRole, IApiContactRoleFilterType, IApiContactRoleOrderBy, IApiEmail, IApiPhone, IApiPhoneType, IApiUpdateAddressInput, IApiUpdateContactInput, IApiUpdateEmailInput, IApiUpdatePhoneInput } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { BranchService, CompanyService, ContactRoleService, ContactService, StateService } from 'src/app/shared/services';
import { CompanyDataSource } from 'src/app/shared/services/company/company.datasource';
import { CompanyCreateUpdateComponent } from 'src/app/components/shared/companies/company-create-update/company-create-update.component';
import { cloneDeep } from "lodash";
import { tap, switchMap } from 'rxjs/operators';
import { companies, companiesCreateConfig } from "src/app/shared/helpers/auth-config/companies.config";
import { contacts, contactsCreateConfig, contactsProfileContactInformationUpdate, contactsProfileCompanyInformationUpdate } from "src/app/shared/helpers/auth-config/contacts.config";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
export interface ICreateUpdateContactData {
  contactId?: string;
  branchId?: string;
  role?: string;
  message?: string;
  lossAddress?: IApiAddress;
  disableCancel?: boolean;
  disableRoleSelect?: boolean;
  includePrivate?: boolean;
  companyNotRequired?: boolean;
  companyRoles?: boolean;
  partyAdd?: boolean;
}

@Component({
  selector: 'app-create-update-contact',
  templateUrl: './create-update-contact.component.html',
  styleUrls: ['./create-update-contact.component.scss']
})
export class CreateUpdateContactComponent {
  @ViewChild("contactForm") public contactForm: NgForm;
  public authConfig = {
    contacts,
    contactsCreateConfig,
    contactsProfileContactInformationUpdate,
    companies,
    companiesCreateConfig,
    contactsProfileCompanyInformationUpdate
  };

  public contact: IApiContact = {
    id: null,
    Role: {
      name: "",
      id: ""
    },
    Emails: [],
    Phones: [],
    Addresses: [],
  };

  public roles: IApiContactRole[];
  public phoneTypes: IApiPhoneType[];

  public selectedBranch: IApiContactBranchAssignment = null;
  public disableRoleSelect = false;

  public addPhonesList: IApiPhone[] = [];
  public addEmailsList: IApiEmail[] = [];
  public addAddressesList: IApiAddress[] = [];
  public sameLossAddress = true;

  // public initClaimNumber: string;

  // Table Config
  public filters = {
    state: null,
    company: null
  };
  public contacts: IApiContact[] = [];
  public displayedColumns = ['company', 'branch'];

  private _dataSource: CompanyDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public set dataSource(val) {
    this._dataSource = val;
  }

  public isPrimaryToggle(isPrimary: boolean, index: number) {
    if (isPrimary) {
      this.contact.Phones.forEach(obj => obj.isPrimary = false);
      this.contact.Phones[index].isPrimary = true;
    }
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ICreateUpdateContactData,
    private contactService: ContactService,
    public stateService: StateService,
    private contactRoleService: ContactRoleService,
    private loaderService: LoaderService,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<CreateUpdateContactComponent>,
    private dialog: MatDialog,
    private branchService: BranchService
  ) {
    this.disableRoleSelect = !this.data?.disableRoleSelect ? false : true;
    this.dataSource = new CompanyDataSource(this.companyService);
    this.loaderService.attachObservable(this.dataSource.loading$);

    this.loaderService.show$(
      // Need options based otherwise we get limited to 25 results instead of all of the roles, we want all of them.
      this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
        unwrapConnection(),
        tap(roles => {
          const companyRoleList = ["attorney", "paralegal", "adjuster", "subro adjuster", "siu", "inside adjuster", "outside adjuster", "risk manager"];

          // If adding a contact from "Company" screen, only include company roles
          if (this.data?.companyRoles) this.roles = roles.filter((e) => companyRoleList.includes(e.name.toLowerCase()));
          
          // Else if not including "private" roles AND no role was passed in from data AND no user was passed, filter out "Private" roles
          else if (!this.data?.includePrivate && !this.data?.role && !this.data?.contactId) this.roles = roles.filter((e) => !e.isPrivate);
          
          // Else default to showing all roles
          else this.roles = roles;

          if (this.data?.role) this.contact.Role = this.roles.find(({ name }) => name === this.data.role);
          if (this.contact?.Role?.isPrivate) this.contact.companyNotRequired = true;
          else this.contact.companyNotRequired = false;
        }),
        switchMap(async () => {
          if (this.data?.contactId) {
            await this.contactService.getById(this.data.contactId).toPromise().then((contact) => {
              this.contact = contact;
              if (this.contact?.Role?.isPrivate) this.contact.companyNotRequired = true;
            });
          }

          if (this.data?.branchId) {
            await this.branchService.getById(this.data.branchId).toPromise().then((branch) => {
              this.selectedBranch = {
                startDate: new Date(),
                BranchId: this.data.branchId,
                Branch: branch
              };
            });
          }

          if (this.data?.lossAddress) {
            this.contact.Addresses.push(this.data.lossAddress);
          }

          return;
        })
      ),
    ).subscribe(() => {
      if (this.contact.Role) this.contact.Role = this.roles.find(({ id }) => this.contact.Role.id === id);
      // If specificed in "data", set "companyNotRequired"
      if (this.data?.companyNotRequired) this.contact.companyNotRequired = this.data?.companyNotRequired;
    });
  }

  public setCompanyFilter() {
    this.dataSource.applyFilter(IApiCompanyFilterType.Name, this.filters.company);
  }

  public setStateFilter(e: MatSelectChange) {
    this.dataSource.applyFilter(IApiCompanyFilterType.State, e.value);
  }

  public search() {
    this.dataSource.load();
  }

  public isInsured() {
    if (this.roles && this.roles.length) {
      const selectedRole = this.contact?.Role?.id;
      const insured = this.roles.find(x => x.name === 'Insured');
      return (!!selectedRole && !!insured && (selectedRole === insured?.id));
    }
    else {
      return false;
    }
  }

  private removeExtras(obj: any) {
    // no error thrown by delete if the key isn't there so lump all of these together into one function we can use
    delete obj.__typename;
    delete obj.Role;
    delete obj.Type;
    delete obj.updatedAt;
    delete obj.createdAt;
    delete obj.addressableTypeId;
    delete obj.addressableType;
    delete obj.Branches;
  }

  private compileContactInformation(copy: IApiContact) {
    // Remove all ids as these will get re-created
    const emails: IApiAddEmailInput[] = copy.Emails.map((v) => {
      const typeId = v.Type.id;
      delete v.id;
      this.removeExtras(v);
      return { ...v, TypeId: typeId };
    });

    const addresses: IApiAddAddressInput[] = copy.Addresses.map((v) => {
      this.removeExtras(v);
      delete v.id;
      return v as IApiAddAddressInput;
    });

    const phones: IApiAddPhoneInput[] = copy.Phones.map((v) => {
      const typeId = v.Type.id;
      delete v.id;
      this.removeExtras(v);
      return { ...v, TypeId: typeId };
    });

    return { emails, phones, addresses };

  }

  private updateContact() {
    const copy = cloneDeep(this.contact);
    const roleId = copy.Role.id;

    const { emails, phones, addresses } = this.compileContactInformation(copy);

    const branchIds = copy.Branches.map(v => v.id);

    this.removeExtras(copy);

    // if (copy.claimNumber === this.initClaimNumber) {
    // delete copy.claimNumber;
    // }

    const payload: IApiUpdateContactInput = {
      ...copy,
      Role: roleId,
      Emails: emails,
      Phones: phones,
      Addresses: addresses,
      BranchIds: branchIds,
      BranchAssignments: copy.BranchAssignments.map(b => ({ isPrimary: b.isPrimary, startDate: b.startDate, endDate: b.endDate, BranchId: b.BranchId, ContactId: copy.id })),
    };

    if (this.selectedBranch) payload.AddBranch = { isPrimary: false, startDate: this.selectedBranch.startDate, BranchId: this.selectedBranch.Branch.id, ContactId: copy.id };

    this.loaderService.show$(
      this.contactService.update(payload)
    ).subscribe(() => this.dialogRef.close());
  }

  private async createContact() {
    const copy = cloneDeep(this.contact);

    const { emails, phones, addresses } = this.compileContactInformation(copy);

    const roleId = copy.Role.id;
    delete copy.id;
    delete copy.__typename;
    delete copy.Emails;
    delete copy.Phones;
    delete copy.Branches;
    delete copy.Addresses;
    delete copy.Role;


    const payload: IApiAddContactInput = {
      ...copy,
      RoleId: roleId,
      Phones: phones,
      Emails: emails,
      Addresses: addresses,
      isInternal: this.data?.partyAdd ? true : false /* Handle notification not trigger while add ADD CONTACT instead trigger ADD PARTY CONTACT */
    } as IApiAddContactInput;

    if (this.selectedBranch) payload.BranchAssignment = { isPrimary: false, startDate: this.selectedBranch.startDate, BranchId: this.selectedBranch.Branch.id } as IApiAddNewContactBranchAssignmentInput;

    this.loaderService.show$(
      this.contactService.add(payload as IApiAddContactInput)
    ).subscribe((data) => this.dialogRef.close(data));
  }

  public save() {
    if (this.data?.contactId) {
      this.updateContact();
    } else {
      this.createContact();
    }
  }

  public addNewCompany() {
    this.dialog.open(CompanyCreateUpdateComponent, {
      width: "80%"
    });
  }

  public addPhone() {
    this.contact.Phones.push({
      id: "",
      number: "",
      extension: "",
      isPrimary: !this.contact.Phones.length,
      Type: {
        id: "",
        name: ""
      },
      TypeId: null,
    });
  }

  public removePhone(index: number) {
    this.contact.Phones.splice(index, 1);
  }

  public addEmail() {
    this.contact.Emails.push({
      id: "",
      Type: {
        id: "",
        name: ""
      },
      TypeId: null,
      address: "",
      isPrimary: false,
    });
  }

  public removeEmail(index: number) {
    this.contact.Emails.splice(index, 1);
  }

  public addAddress() {
    this.contact.Addresses.push({
      id: "",
      address1: "",
      city: "",
      state: "",
      postal: "",
      country: "United States",
      isPrimary: false,
    });
  }

  public removeAddress(index: number) {
    this.contact.Addresses.splice(index, 1);
  }

  public selectBranch(branch: IApiBranch) {
    this.selectedBranch = {
      Branch: { ...branch },
      startDate: new Date(),
      endDate: null
    };
  }

  public hasCompany() {
    return this.contact?.Role?.isPrivate || !!this.selectedBranch || this.contact.companyNotRequired || this.contact?.BranchAssignments;
  }

}
