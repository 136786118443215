import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from '@angular/material/paginator';
import { filter, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { EvidenceStorageLocationService, EvidenceStorageLocationDataSource } from 'src/app/shared/services/evidence/';
import { IApiEvidenceStorageLocationFilterType, IApiEvidenceStorageLocation, IApiEvidenceStorageLocationFilter } from 'src/app/shared/modules/graphql/types/types';
import { EvidenceStorageLocationModalComponent } from '../evidence-storage-location-modal/evidence-storage-location-modal.component';
import { isArray } from 'lodash';
import { Router } from '@angular/router';
import { manageEvidenceLocationsCreate, manageEvidenceLocationsUpdate, manageEvidenceLocationsDelete, manageEvidenceLocationsDeactivate } from "../../../../shared/helpers/auth-config/manage.config";

@Component({
  selector: 'app-evidence-storage-location',
  templateUrl: './evidence-storage-location.component.html',
  styleUrls: ['./evidence-storage-location.component.scss']
})
export class EvidenceStorageLocationComponent implements OnInit {

  public authConfig = {
    manageEvidenceLocationsCreate,
    manageEvidenceLocationsUpdate,
    manageEvidenceLocationsDelete,
    manageEvidenceLocationsDeactivate
  }

  public dataSource: EvidenceStorageLocationDataSource;
  public get pageOptions() {
    return this.dataSource.listPage;
  }

  public selectedStatus = null;
  public selectedLocations = null;

  public columnsToDisplay = ["NAME", "TYPE", "address", "contactInfo", "details", "additionalDetails", "actions"];

  @ViewChild(MatPaginator) viewPaginator: MatPaginator;
  @ViewChild(MatSort) viewSort: MatSort;

  private _filters: IApiEvidenceStorageLocationFilter[] = [];
  filterTypes = IApiEvidenceStorageLocationFilterType;
  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  constructor(
    private loader: LoaderService,
    private router: Router,
    private notifications: NotificationsService,
    private evidenceStorageLocations: EvidenceStorageLocationService,
    private dialog: MatDialog) { }

  // events
  public openAddNewLocationModal() {
    const dialogRef = this.dialog.open(EvidenceStorageLocationModalComponent, {
      width: '60%'
    }).afterClosed().pipe(filter(result => !!result)).subscribe(() => this.load());
  }

  public edit(data: IApiEvidenceStorageLocation) {
    this.dialog.open(EvidenceStorageLocationModalComponent, {
      data,
      width: "50%"
    }).afterClosed().pipe(filter(result => !!result)).subscribe(() => this.load());
  }

  public toggleActivation({ id }: IApiEvidenceStorageLocation) {
    this.loader.show$(this.evidenceStorageLocations.toggleActivation(id))
      .subscribe(res => {
        this.load();
      }, (err) => {
        if (err?.message?.includes('You cannot deactivate this location as it still contains evidence.')) {
          this.notifications.kendoConfirm(
            err?.message,
            "Error Deactivating The Location",
            "Cancel",
            "Go to Evidence",
            550
          ).subscribe((res) => {
            if (res) {
              this.router.navigate(['evidence/list'], {
                queryParams: {
                  locationId: id
                }
              });
            }
          });
        } else {
          this.notifications.alert("Error toggling activation")
        }
      });
  }

  public deleteLocation(id): void {
    this.notifications.kendoConfirm(
      'Are you sure you want to delete this item?',
      "Confirm Delete",
      "No",
      "Yes",
      550
    ).subscribe((res) => {
      if (res) {
        this.loader.show$(this.evidenceStorageLocations.remove(id))
          .subscribe(res => {
            this.notifications.notify("Evidence location deleted successfully!");
            this.load();
          }, (err) => {
            if (err?.message?.includes('You cannot delete this location as it still contains evidence.')) {
              this.notifications.kendoConfirm(
                err?.message,
                "Error Deleting The Location",
                "Cancel",
                "Go to Evidence",
                550
              ).subscribe((res) => {
                if (res) {
                  this.router.navigate(['evidence/list'], {
                    queryParams: {
                      locationId: id
                    }
                  });
                }
              });
            } else {
              this.notifications.alert(err?.message);
            }
          });
      }
    });

  }

  public load() {
    if (this.selectedStatus === null) this.dataSource.removeFilter(IApiEvidenceStorageLocationFilterType.IsActive);
    else this.dataSource.applyFilter(IApiEvidenceStorageLocationFilterType.IsActive, this.selectedStatus.toString());
    this.dataSource.pagingReset();

    this.dataSource.load();
  }

  ngOnInit() {
    this.dataSource = new EvidenceStorageLocationDataSource(this.evidenceStorageLocations);
    this.dataSource.applyFilter(IApiEvidenceStorageLocationFilterType.ViewWithoutTransfers, 'true');
    this.loader.attachObservable(this.dataSource.loading$);
    this.load();
  }

  public filterValue(filter: IApiEvidenceStorageLocationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters?.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public setFilters(value: string | undefined, type: IApiEvidenceStorageLocationFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const parsedValue = isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value;
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: parsedValue
    }] : filtersCopy;
    this.dataSource.applyFilter(type, parsedValue);
  }

  clearAll() {
    this.selectedLocations = null;
    this.filters = [];
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }

  itemSelected(event, item) {
    switch (event?.text) {
      case "Edit":
        this.edit(item)
        break;
      case "Activate":
      case "Deactivate":
        this.toggleActivation(item)
        break;
      case "Delete":
        this.deleteLocation(item.id)
        break;
    }
  }
}
