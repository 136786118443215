<mat-dialog-content class="mat-typography">
  <!-- Modal Header -->
  <div class="header">
    <h2 class="text-center">Select Contact / Company</h2>
  </div>

  <!-- Modal Body Select Contact / Company -->

  <!-- Input Form -->
  <div class="flexContainer">
    <div class="equal">
      <div class="col text-left">
        <mat-form-field class="">
          <mat-label>First Name</mat-label>
          <input matInput [(ngModel)]="firstName" />
        </mat-form-field>
      </div>
    </div>

    <div class="equal">
      <div class="col text-left">
        <mat-form-field class="">
          <mat-label>Last Name</mat-label>
          <input matInput [(ngModel)]="lastName" />
        </mat-form-field>
      </div>
    </div>

    <div class="equal">
      <div class="col text-left">
        <mat-form-field>
          <mat-label> State </mat-label>
          <mat-select [(ngModel)]="selectedCompanyState">
            <mat-option [value]="null">All</mat-option>
            <mat-option *ngFor="let state of stateService.allStates" [value]="state.value"
              [innerHTML]="state.text"></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="equal">
      <div class="col text-left">
        <app-company-select label="Company" placeholder="Search ..."
          (selectionChanged)="selectedCompany = $event?.id"></app-company-select>
      </div>
    </div>

    <div class="equal">
      <div class="col text-left">
        <button mat-raised-button class="actionButton d-flex flex-row-reverse" (click)="searchContacts()">
          <mat-icon color="primary">search</mat-icon>
          Search
        </button>
      </div>
    </div>

  </div>
  <!-- <ng-container *ngIf="!contacts.length; else contactsList">
    <div class="mat-share-card no-results">
      <br>
      <mat-card>
        <div class="body">
          <p class="text-center">Please enter search criteria to see results</p>
        </div>
      </mat-card>
    </div>
  </ng-container> -->

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let contact">
        <div class="contact-name">
          <button mat-icon-button (click)="openContactModal(contact);">
            <mat-icon color="accent">edit</mat-icon>
          </button>
          <span>{{ contact.lastName }} {{ contact.firstName }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="position-company">
      <th mat-header-cell *matHeaderCellDef>Position/Company</th>
      <td mat-cell *matCellDef="let contact">
        <ng-container *ngFor="let branch of contact.BranchAssignments">
          <div *ngIf="!branch?.endDate">
            <button mat-icon-button (click)="selectContact(contact, branch.Branch);">
              <mat-icon color="accent">playlist_add</mat-icon>
            </button>
            <span>{{findCompany(contact, branch.Branch.id)}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!contact.BranchAssignments.length">
          <button mat-icon-button (click)="selectContact(contact);">
            <mat-icon color="accent">playlist_add</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef>Branch</th>
      <td mat-cell *matCellDef="let contact">
        <ng-container *ngFor="let branch of contact.BranchAssignments">
          <p class="mb-0" *ngIf="!branch?.endDate" [innerHTML]="branch.Branch.name"></p>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let contact" [innerHTML]="contact.Role.name"></td>
    </ng-container>

    <ng-container matColumnDef="emails">
      <th class="text-left" mat-header-cell *matHeaderCellDef> Emails </th>
      <td class="text-left" mat-cell *matCellDef="let contact">
        <app-email-phone-view *ngFor="let email of contact.Emails" [email]="email"></app-email-phone-view>
      </td>
    </ng-container>

    <ng-container matColumnDef="phones">
      <th class="text-left" mat-header-cell *matHeaderCellDef> Phones </th>
      <td class="text-left" mat-cell *matCellDef="let contact">
        <app-email-phone-view *ngFor="let phone of contact.Phones" [phone]="phone"></app-email-phone-view>
      </td>
    </ng-container>
    <!-- If Empty -->
    <ng-container matColumnDef="noResults">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-footer-cell *matFooterCellDef colspan="6">
        <p class="text-center font-weight-bold">Please enter search criteria to see results</p>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
    <tr mat-footer-row *matFooterRowDef="['noResults']" [hidden]="hasData"></tr>
  </table>
  <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit"
    [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-icon-button class="float-right" color="accent" (click)="openContactModal()">
    <mat-icon>playlist_add</mat-icon>create new contact
  </button>
</mat-dialog-actions>
