import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ToolbarService } from '@progress/kendo-angular-scheduler';
import { InvestigationEditScheduleDateComponent } from 'src/app/components/shared/investigations/investigation-edit-schedule-date/investigation-edit-schedule-date.component';
import { investigationScheduledDateUpdateAll, investigationScheduledDateUpdateOwn, investigationScheduledDateView } from 'src/app/shared/helpers/auth-config/investigations.config';
import { IApiInvestigation } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: 'app-calendar-view-navigation',
  templateUrl: './calendar-view-navigation.component.html',
  styleUrls: ['./calendar-view-navigation.component.scss']
})
export class CalendarViewNavigationComponent {
  public authConfig = {
    investigationScheduledDateView,
    investigationScheduledDateUpdateAll,
    investigationScheduledDateUpdateOwn
  }

  @Input() public selectedDate: Date;
  public calendarViews = [
    { text: "Day", value: 0 },
    { text: "Month", value: 1 }
  ];

  @Input() selectedViewIndex = 0;
  @Input() public from: string;
  @Input() public investigation: IApiInvestigation

  @Output() calendarView = new EventEmitter<number>();
  @Output() reload = new EventEmitter<boolean>();

  constructor(public toolbarService: ToolbarService, private dialogService: DialogService) { }

  public next(): void {
    this.toolbarService.navigate({
      type: 'next'
    });
  }

  public prev(): void {
    this.toolbarService.navigate({
      type: 'prev'
    });
  }


  public navigate(action) {
    try {
      this.toolbarService.navigate({ type: action });
    } catch (e) {
      console.log(e);
    }
  }

  public onCalendarViewChanges(event) {
    this.calendarView.emit(event);
  }

  public schedule(){
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEditScheduleDateComponent,
      width: 800,
      maxWidth: 800,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const userInfo = dialog.content.instance as InvestigationEditScheduleDateComponent;
    userInfo.investigationStaff = this.investigation?.InvestigationStaff;
    userInfo.investigationOnScenes = [];
    userInfo.investigation = this.investigation;
    userInfo.headerIcon = 'assets/svg/warning.svg';
    userInfo.mode = "NEW_SCHEDULE_CALENDAR";

    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.reload.emit(true);
      }
    })
  }

}
