<!-- Permissions -->
<div [appEnforcePermission]="authConfig.contactsCreateConfig" #contactsCreateConfig="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileContactInformationUpdate" #contactsProfileContactInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsProfileCompanyInformationUpdate" #contactsProfileCompanyInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contactsDelete" #contactsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesCreate" #investigationPartiesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesUpdate" #investigationPartiesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationPartiesDelete" #investigationPartiesDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationInformationUpdate" #investigationInformationUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <mat-card-header>
    <mat-card-title>Parties</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <hr />
    <ng-container *ngIf="sceneContact">
      <p>
        <strong>Scene Contact: &nbsp;</strong> {{ sceneContact | fullName }}
      </p>
    </ng-container>
    <ng-container *ngIf="parties?.length && parties[0]?.id && investigationInformationUpdate?.enabled">
      <strong>Change Scene Contact: &nbsp;</strong>
      <mat-form-field class="sceneContact">
        <mat-label>Choose ...</mat-label>
        <mat-select
          [(ngModel)]="sceneContactId"
          (ngModelChange)="updateSceneContact($event)"
          required
        >
          <mat-option *ngFor="let contact of contacts" [value]="contact.id"
            >{{ contact.Role?.name || "Client" }} -
            {{ contact | fullName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <hr />
    </ng-container>

    <form #frm="ngForm" *ngIf="parties?.length || insured?.id">
      <mat-accordion>
        <mat-expansion-panel *ngIf="insured?.id">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Insured
            </mat-panel-title>
            <mat-panel-description>
              {{ insured?.insuredName }} {{ insured?.contactName }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="float-right">
            <!-- TODO - support setting another contact (other than first) as the Insured -->
            <button
              mat-flat-button
              color="primary"
              (click)="editInsured(insured)"
              class="ml-3"
              *ngIf="investigationPartiesUpdate?.enabled"
            >
              <mat-icon matSuffix>edit</mat-icon> Edit Contact
            </button>
            <!-- <button mat-flat-button color="primary" *ngIf="!party.id" [disabled]="!frm.valid" (click)="addContact(party)">
              <mat-icon matSuffix>add_circle</mat-icon> Add Contact
            </button> -->
          </div>
          <div class="clearfix"></div>
          <!-- Displaying Contact Information -->

          <kendo-card
            class="accordion-body"
            width="100%"
          >
            <h4>Contact Information</h4>
            <kendo-gridlayout
              [rows]="['auto']"
              [cols]="[
                { width: '20%' },
                { width: '20%' },
                { width: '20%' },
                { width: '20%' },
                { width: '20%' }
              ]"
              [gap]="{ rows: 26, cols: 5 }"
            >
              <kendo-gridlayout-item>
                <p class="kendo-caption">Type</p>
                <kendo-badge
                  class="fw600"
                  position="none"
                  size="medium"
                  rounded="full"
                  themeColor="primary"
                  >{{
                    insured?.contactName ? "Business" : "Individual"
                  }}</kendo-badge
                >
              </kendo-gridlayout-item>

              <kendo-gridlayout-item *ngIf="!insured?.contactName">
                <p class="kendo-caption">Insured Name</p>
                <p class="kendo-caption-body">
                  <span [innerHTML]="insured?.insuredName || '-'"></span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item *ngIf="insured.contactName">
                <p class="kendo-caption">Insured Company Name</p>
                <p class="kendo-caption-body">
                  <span [innerHTML]="insured?.insuredName || '-'"></span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item *ngIf="insured.contactName">
                <p class="kendo-caption">Contact Name</p>
                <p class="kendo-caption-body">
                  <span [innerHTML]="insured?.contactName || '-'"></span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Relationship/Title</p>
                <p class="kendo-caption-body">{{ insured?.title || '-' }}</p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Email</p>
                <p class="kendo-caption-body">
                  <app-email-phone-view
                    *ngFor="let email of insured?.Emails"
                    [email]="email"
                  ></app-email-phone-view>
                  <span *ngIf="insured?.Emails?.length === 0">-</span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Phone Number</p>
                <p class="kendo-caption-body">
                  <app-email-phone-view
                    *ngFor="let phone of insured.Phones"
                    [phone]="phone"
                  ></app-email-phone-view>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Suffix</p>
                <p class="kendo-caption-body">
                  {{ insured.suffix || "-" }}
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card>
          <!-- Displaying Addresses -->
          <div class="row info-div">
            <div class="col-12">
              <h3>Address</h3>
            </div>
            <div class="col-3" *ngFor="let address of insured.Addresses">
              <app-address-view [address]="address"></app-address-view>
            </div>
          </div>
          <!-- Displaying Company/Branch -->
          <div class="row info-div">
            <div class="col-12">
              <h3>Company Information</h3>
            </div>
            <ng-container *ngFor="let branch of insured?.Branches">
              <div class="col-3">
                <p class="mat-small mat-caption">Company Name</p>
                <p [innerHTML]="branch.Company.name"></p>
              </div>
              <div class="col-3">
                <p class="mat-small mat-caption">Branch Name</p>
                <p [innerHTML]="branch.name"></p>
              </div>
              <div class="col-2">
                <p class="mat-small mat-caption">Branch Phones</p>
                <app-email-phone-view
                  *ngFor="let phone of branch.Phones"
                  [phone]="phone"
                ></app-email-phone-view>
              </div>
              <div class="col-2">
                <p class="mat-small mat-caption">Branch Emails</p>
                <app-email-phone-view
                  *ngFor="let email of branch.Emails"
                  [email]="email"
                ></app-email-phone-view>
              </div>
              <div class="col-2">
                <p class="mat-small mat-caption">Branch Addresses</p>
                <app-address-view
                  *ngFor="let address of branch.Addresses"
                  [address]="address"
                ></app-address-view>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngFor="let party of parties; index as i">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ party.Role?.name }}
            </mat-panel-title>
            <mat-panel-description>
              {{ party.Contact?.insuredName }} {{ party.Contact | fullName }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="float-right">
            <!-- TODO - support setting another contact (other than first) as the Insured -->
            <button
              mat-flat-button
              color="warn"
              (click)="removeParty(party)"
              *ngIf="!isInsured(party.Contact.Role) && investigationPartiesDelete?.enabled"
            >
              <mat-icon matSuffix>remove_circle</mat-icon> Remove
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="editInsured(party)"
              class="ml-3"
              *ngIf="isInsured(party.Contact.Role) && (contactsProfileContactInformationUpdate?.enabled || contactsProfileCompanyInformationUpdate?.enabled) && investigationPartiesUpdate?.enabled"
            >
              <mat-icon matSuffix>edit</mat-icon> Edit Contact
            </button>
            <button
              mat-flat-button
              color="primary"
              (click)="editContact(party.Contact.id)"
              class="ml-3"
              *ngIf="!isInsured(party.Contact.Role) && (contactsProfileContactInformationUpdate?.enabled || contactsProfileCompanyInformationUpdate?.enabled) && investigationPartiesUpdate?.enabled"
            >
              <mat-icon matSuffix>edit</mat-icon> Edit Contact
            </button>
            <!-- <button mat-flat-button color="primary" *ngIf="!party.id" [disabled]="!frm.valid" (click)="addContact(party)">
              <mat-icon matSuffix>add_circle</mat-icon> Add Contact
            </button> -->
          </div>
          <div class="clearfix"></div>
          <!-- Displaying Contact Information -->

          <kendo-card
            class="accordion-body"
            width="100%"
            *ngIf="isInsured(party.Contact.Role)"
          >
            <h4>Contact Information</h4>
            <kendo-gridlayout
              [rows]="['auto']"
              [cols]="[
                { width: '20%' },
                { width: '20%' },
                { width: '20%' },
                { width: '20%' },
                { width: '20%' }
              ]"
              [gap]="{ rows: 26, cols: 5 }"
            >
              <kendo-gridlayout-item>
                <p class="kendo-caption">Type</p>
                <kendo-badge
                  class="fw600"
                  position="none"
                  size="medium"
                  rounded="full"
                  themeColor="primary"
                  >{{
                    party.Contact?.insuredName ? "Business" : "Individual"
                  }}</kendo-badge
                >
              </kendo-gridlayout-item>

              <kendo-gridlayout-item *ngIf="!party.Contact?.insuredName">
                <p class="kendo-caption">Insured Name</p>
                <p class="kendo-caption-body">
                  <span [innerHTML]="party.Contact | fullName"></span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item *ngIf="party.Contact?.insuredName">
                <p class="kendo-caption">Insured Company Name</p>
                <p class="kendo-caption-body">
                  <span [innerHTML]="party.Contact.insuredName"></span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item *ngIf="party.Contact?.insuredName">
                <p class="kendo-caption">Contact Name</p>
                <p class="kendo-caption-body">
                  <span [innerHTML]="party.Contact | fullName"></span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Relationship/Title</p>
                <p class="kendo-caption-body">{{ party.Contact.nickName }}</p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Email</p>
                <p class="kendo-caption-body">
                  <app-email-phone-view
                    *ngFor="let email of party.Contact?.Emails"
                    [email]="email"
                  ></app-email-phone-view>
                  <span *ngIf="party.Contact?.Emails?.length === 0">-</span>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Phone Number</p>
                <p class="kendo-caption-body">
                  <app-email-phone-view
                    *ngFor="let phone of party.Contact.Phones"
                    [phone]="phone"
                  ></app-email-phone-view>
                </p>
              </kendo-gridlayout-item>

              <kendo-gridlayout-item>
                <p class="kendo-caption">Suffix</p>
                <p class="kendo-caption-body">
                  {{ party.Contact.suffix || "-" }}
                </p>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
          </kendo-card>

          <div class="row info-div" *ngIf="!isInsured(party.Contact.Role)">
            <div class="col-12">
              <h3>Contact Information</h3>
            </div>
            <div class="col-3" *ngIf="party.Contact?.insuredName">
              <p class="mat-small mat-caption">Insured Name</p>
              <p [innerHTML]="party.Contact.insuredName"></p>
            </div>
            <div class="col-3">
              <p class="mat-small mat-caption">First Name</p>
              <p [innerHTML]="party.Contact.firstName"></p>
            </div>
            <div class="col-3">
              <p class="mat-small mat-caption">Last Name</p>
              <p [innerHTML]="party.Contact.lastName"></p>
            </div>
            <div class="col-3">
              <p class="mat-small mat-caption">
                {{
                  isInsured(party.Contact.Role)
                    ? "Relationship / Title"
                    : "Nickname"
                }}
              </p>
              <p [innerHTML]="party.Contact.nickName"></p>
            </div>
            <div class="col-3">
              <p class="mat-small mat-caption">Suffix</p>
              <p [innerHTML]="party.Contact.suffix"></p>
            </div>
            <div class="col-3">
              <p
                class="mat-small mat-caption"
                *ngIf="party.Contact.policyNumber"
              >
                Policy #
              </p>
              <p [innerHTML]="party.Contact.policyNumber"></p>
            </div>
            <div class="col-3">
              <p
                class="mat-small mat-caption"
                *ngIf="party.Contact.claimNumber"
              >
                Claim #
              </p>
              <p [innerHTML]="party.Contact.claimNumber"></p>
            </div>
            <div class="col-6">
              <p class="mat-small mat-caption">Phones</p>
              <app-email-phone-view
                *ngFor="let phone of party.Contact.Phones"
                [phone]="phone"
              ></app-email-phone-view>
            </div>
            <div class="col-6">
              <p class="mat-small mat-caption">Emails</p>
              <app-email-phone-view
                *ngFor="let email of party.Contact.Emails"
                [email]="email"
              ></app-email-phone-view>
            </div>
          </div>
          <!-- Displaying Addresses -->
          <div class="row info-div">
            <div class="col-12">
              <h3>Address</h3>
            </div>
            <div class="col-3" *ngFor="let address of party.Contact.Addresses">
              <app-address-view [address]="address"></app-address-view>
            </div>
          </div>
          <!-- Displaying Company/Branch -->
          <div class="row info-div">
            <div class="col-12">
              <h3>Company Information</h3>
            </div>
            <ng-container *ngFor="let branch of party.Contact.Branches">
              <div class="col-3">
                <p class="mat-small mat-caption">Company Name</p>
                <p [innerHTML]="branch.Company.name"></p>
              </div>
              <div class="col-3">
                <p class="mat-small mat-caption">Branch Name</p>
                <p [innerHTML]="branch.name"></p>
              </div>
              <div class="col-2">
                <p class="mat-small mat-caption">Branch Phones</p>
                <app-email-phone-view
                  *ngFor="let phone of branch.Phones"
                  [phone]="phone"
                ></app-email-phone-view>
              </div>
              <div class="col-2">
                <p class="mat-small mat-caption">Branch Emails</p>
                <app-email-phone-view
                  *ngFor="let email of branch.Emails"
                  [email]="email"
                ></app-email-phone-view>
              </div>
              <div class="col-2">
                <p class="mat-small mat-caption">Branch Addresses</p>
                <app-address-view
                  *ngFor="let address of branch.Addresses"
                  [address]="address"
                ></app-address-view>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>

    <ng-container *ngIf="investigationPartiesCreate?.enabled && contactsCreateConfig?.enabled">
    <hr />
    <h3>Add another Party</h3>
    <div class="d-flex justify-content-start">
      <mat-form-field #role class="add-party">
        <mat-label> Select Contact Role: </mat-label>
        <mat-select [(ngModel)]="contactRole" name="role">
          <mat-option
            *ngFor="let role of roles"
            [value]="role"
            [innerHTML]="role.name | quiet"
          ></mat-option>
        </mat-select>
        <mat-icon matSuffix>playlist_add</mat-icon>
      </mat-form-field>
      <button
        mat-button
        class=""
        (click)="openModal(contactRole)"
        [disabled]="!contactRole"
      >
        <mat-icon>add</mat-icon> Add Contact
      </button>
    </div>
    </ng-container>
  </mat-card-content>
</section>