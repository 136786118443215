<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationVehiclesListView"
  #investigationVehiclesListView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationVehiclesCreate"
  #investigationVehiclesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffListView"
  #investigationAssignStaffListView="appEnforcePermission"></div>
<!-- Permissions -->
<app-breadcrumbs></app-breadcrumbs>
<section class="mat-typography">
  <h2>
    {{ id ? "Modify" : "Create" }} Investigation
    <span *ngIf="investigation?.nefcoNumber"># {{ investigation.nefcoNumber }}</span>
    <button mat-button class="investigation-button float-right ml-3" [disabled]="!id"
      routerLink="/investigations/{{ id }}" [ngClass]="{ 'investigation-button-disabled': !id }">
      Go To Investigation Detail
    </button>
  </h2>

  <div class="content-wrapper">
    <!-- Main Form -->
    <form #form="ngForm" appDebouncedChange [appDirtyFormNotify]="form">

      <div class="flexContainer inv-info" *ngIf="(param?.editInfo && id)">
        <!-- recievedDate-->
        <div class="equal align-self-center">
          <span class="display-block" *ngIf="id">
            <strong>Status:</strong> &nbsp; {{ investigation.status }}
          </span>
          <p class="date-time">
            Received:
            {{ model.receivedDate | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
          <p *ngIf="investigation.deletedAt" class="date-time">
            Cancelled:
            {{ investigation.deletedAt | customDate : "MM-dd-yyyy hh:mm:ss a" }}
          </p>
        </div>

        <!-- Priority select -->
        <div class="equal align-self-center">
          <app-investigation-priorities-select name="priorities" [(ngModel)]="model.PriorityId"
            required></app-investigation-priorities-select>
        </div>

        <div class="btn-group align-self-center">
          <div class="equal">
            <mat-button-toggle-group class="text-right" id="show-trigger">
              <!-- onHold -->
              <mat-slide-toggle name="onHold" [(ngModel)]="model.onHold" color="primary">On Hold</mat-slide-toggle>

              <!-- rejected -->
              <!-- TODO hide once investigation has been created based on a value TBD ... has detail? has someone assigned? etc. -->
              <mat-slide-toggle name="isRejected" [(ngModel)]="isRejected" [checked]="isRejected" (change)="openRejectReactivateModel()" *ngIf="id" color="primary">
                Case Rejected
              </mat-slide-toggle>
            </mat-button-toggle-group>
          </div>
        </div>

        <!-- save indicator -->
        <div class="align-self-center">
          <div class="with-icon d-flex-center">
            <ng-container *ngIf="id; else failedIcon">
              <mat-icon matSuffix class="inline-icon" color="accent">check_circle</mat-icon>
              {{ model["updatedAt"] | customDate : "MM-dd-yyyy hh:mm:ss a" }}
            </ng-container>
            <ng-template #failedIcon>
              <mat-icon matSuffix class="inline-icon" color="warn">warning</mat-icon>
              Not Saved
            </ng-template>
          </div>
        </div>
      </div>

      <!-- Edit Information -->
      <mat-card class="mat-elevation-z8" [ngStyle]="{'display': (param?.editInfo && id) ? 'block' : 'none'}">
        <!--  title -->
        <div class="flexContainer">
          <div class="equal">
            <mat-card-header>
              <mat-card-title>Investigation Information</mat-card-title>
            </mat-card-header>
          </div>
        </div>

        <div class="row">
          <div class="column col-1-input-fields">
            <!-- client and billing info-->
            <app-investigation-client [investigation]="this.baseModel" (selected)="setClientInfo($event)"
              [resetForm]="resetForm"></app-investigation-client>

            <input type="hidden" name="clientId" [(ngModel)]="this.model.ClientId" />
            <input type="hidden" name="ClientBranchId" [(ngModel)]="this.model.ClientBranchId" />
            <input type="hidden" name="billToId" [(ngModel)]="this.model.BillToId" required />
            <input type="hidden" name="billToBranchId" [(ngModel)]="this.model.BillToBranchId" />
            <input type="hidden" name="companyId" [(ngModel)]="this.model.CompanyId" />

            <br />

            <div class="flexContainer">
              <!-- lossDate -->
              <div class="equal">
                <app-datepicker [(ngModel)]="lossDate" name="lossDate" [label]="'Loss Date'"
                  [isLossDate]="true"></app-datepicker>
              </div>

              <!-- lossTime -->
              <div class="equal">
                <!-- TODO - allow direct input-->
                <app-timepicker name="lossTime" [disabled]="!lossDate" [(ngModel)]="lossTime"
                  [label]="'Loss Time'"></app-timepicker>
              </div>

              <!-- RiskType -->
              <div class="equal">
                <app-risk-types-select name="riskTypeId" [(ngModel)]="model.RiskTypeId"
                  required></app-risk-types-select>
              </div>
            </div>

            <!-- RiskDescription -->
            <div class="flexContainer">
              <div class="equal description">
                <mat-form-field class="description">
                  <mat-label> Risk Description </mat-label>
                  <textarea matInput name="riskDescription" [(ngModel)]="model.Detail.riskDescription"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="flexContainer">
              <div class="equal mr-2 mb-2">
                <mat-form-field class="w-100">
                  <mat-label>Policy #</mat-label>
                  <input matInput [(ngModel)]="model.Detail.policyNumber" name="policyNumber" #policyNumber="ngModel"
                    appNoWhiteSpace />
                  <mat-hint align="start" *ngIf="
                      !model.Detail.policyNumber && !model.Detail.claimNumber
                    " class="text-danger">A Policy # OR Claim # must be entered</mat-hint>
                </mat-form-field>
              </div>
              <div class="equal">
                <mat-form-field class="w-100">
                  <mat-label>Claim #</mat-label>
                  <input matInput [(ngModel)]="model.Detail.claimNumber" name="claimNumber" #claimNumber="ngModel"
                    appNoWhiteSpace />
                </mat-form-field>
              </div>
            </div>

            <app-notes name="detailNotes" title="Investigation Notes:"
              [(ngModel)]="model.Detail.notes">message</app-notes>
          </div>

          <div class="column col-2-input-fields">
            <!-- Loss Address -->
            <div class="flexContainer">
              <mat-card-content>
                <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="model.LossAddress"
                  (setAddress)="setLossAddress($event)" [loadAPI]="false"
                  [resetForm]="resetForm"></app-address-autocomplete>
                <!-- <app-nefco-address name="lossAddress" [(ngModel)]="model.LossAddress" required></app-nefco-address> -->
              </mat-card-content>
            </div>

            <br />

            <div class="flexContainer">
              <div class="equal">
                <mat-card-content>
                  <p>
                    <span class="font-weight-bold">Note:</span> Mapping features
                    only apply when the address search is used. Custom addresses
                    will not return proximity results.
                  </p>
                </mat-card-content>
              </div>
              <button mat-stroked-button (click)="checkLossAddress()"
                [disabled]="!model.LossAddress || !model.LossAddress.longitude">
                <mat-icon matSuffix class="inline-icon" [color]="addressCheckComplete ? 'accent' : 'warn'">{{
                  addressCheckComplete ? "check_circle" : "warning"
                  }}</mat-icon>
                Check
              </button>
            </div>

            <br />

            <!-- Investigation Address / Scene Exam Address -->
            <div class="flexContainer">
              <div class="equal">
                <mat-card-content>
                  Scene Exam Address:
                  <mat-checkbox color="accent" name="isLossSameAddress"
                    [(ngModel)]="isInvestigationAddressSameAsLoss">Same as loss address</mat-checkbox>
                </mat-card-content>
              </div>

              <!-- TODO -->
              <ng-container *ngIf="!isInvestigationAddressSameAsLoss">
                <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="model.InvestigationAddress"
                  (setAddress)="setInvestigationAddress($event)" [loadAPI]="false"></app-address-autocomplete>
                <!-- <app-nefco-address name="investigationAddress" [(ngModel)]="model.InvestigationAddress" [touched]="form.touched"></app-nefco-address> -->
                <br />
              </ng-container>
            </div>

            <br />

            <div class="flexContainer">
              <div class="equal">
                <mat-card-content>
                  Docusketch Imaging:
                  <mat-checkbox color="accent" name="docusketch" [(ngModel)]="model.docusketchRequested">
                    <p class="mb-0"><span class="font-weight-bold">Does the client require Docusketch imaging?</span>
                    </p>
                  </mat-checkbox>
                  <kendo-formhint class="mt-0" style="margin-left: 187px">* Additional fees apply</kendo-formhint>
                </mat-card-content>
              </div>
            </div>

            <div class="flexContainer" *ngIf="model?.docusketchRequested">
              <div class="equal mr-2 mb-2">
                <mat-card-content class="w-100">
                  Please Indicate:
                  <mat-checkbox class="mr-16" style="margin-left: 34px;" color="accent" name="isXactimate"
                    [(ngModel)]="model.isXactimate">
                    <p class="mb-0"><span class="font-weight-bold">Xactimate</span></p>
                  </mat-checkbox>
                  <mat-checkbox color="accent" name="isSymbility" [(ngModel)]="model.isSymbility">
                    <p class="mb-0"><span class="font-weight-bold">Symbility</span></p>
                  </mat-checkbox>
                  <kendo-formhint class="mt-0 text-danger" style="margin-left: 164px;"
                    *ngIf="(!model.isXactimate && !model.isSymbility) || (model.isXactimate && model.isSymbility)">
                    Please fill out either the Xactimate or Symbility
                  </kendo-formhint>
                </mat-card-content>
              </div>
            </div>

            <!-- specialInstructions -->
            <div class="flexContainer">
              <div class="equal">
                <mat-form-field class="description">
                  <mat-label> Special Billing Instructions </mat-label>
                  <textarea matInput name="specialInstructions"
                    [(ngModel)]="model.Detail.specialInstructions"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </form>

    <!-- Billing Card -->
    <div *ngIf="(param?.editBillingInfo || param?.addBillingInfo) && id" class="contentWrapper">
      <div class="h-action">
        <h2 class="mat-h2 mb-0">Investigation Billing Information</h2>
      </div>
      <kendo-card class="plain-kendo-card" width="100%">
        <form #bForm="ngForm" class="d-flex flex-row pt-2 clearfix" [appDirtyFormNotify]="bForm">
          <kendo-card-body>
            <h3 class="mb-24">Client</h3>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <kendo-formfield class="kendo-formfield">
                  <kendo-label class="asterisk" [text]="'Budget Amount'"></kendo-label>
                  <div class="numeric-text-suffix">
                    <i class="fa-solid fa-dollar-sign"></i>
                    <kendo-numerictextbox placeholder="0.00" name="budgetAmount" fillMode="outline" required="true"
                      [autoCorrect]="false" [(ngModel)]="model.Detail.budgetAmount" #budgetAmount="ngModel">
                    </kendo-numerictextbox>
                  </div>
                  <kendo-formerror *ngIf="!budgetAmount?.valid && (budgetAmount?.dirty || budgetAmount?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="budgetAmount?.errors?.required">
                      Budget Amount cannot be left blank
                    </span>
                    <span *ngIf="budgetAmount?.errors?.minError">
                      Budget Amount cannot be left blank
                    </span>
                  </kendo-formerror>
                </kendo-formfield> </kendo-gridlayout-item>
              <kendo-gridlayout-item [colSpan]="2">
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <kendo-formfield>
                  <kendo-label class="asterisk" text="Billing Instructions"></kendo-label>
                  <kendo-textarea placeholder="Enter Billing Instructions" fillMode="outline" name="billingInstructions"
                    class="min-height-96" resizable="both" #billingInstructions="ngModel"
                    [(ngModel)]="model.billingInstructions" [required]="true" appNoWhiteSpace></kendo-textarea>
                  <kendo-formerror
                    *ngIf="!billingInstructions?.valid && (billingInstructions?.dirty || billingInstructions?.touched)">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="billingInstructions?.errors?.required">Billing instructions cannot be left blank</span>
                  </kendo-formerror>
                </kendo-formfield> </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-gridlayout> <app-notes name="billingNotes" title="Billing Instructions Notes:"
                [(ngModel)]="model.Detail.billingInstructionNotes"
                (ngModelChange)="save()">message</app-notes></kendo-gridlayout>
          </kendo-card-body>
        </form>
      </kendo-card>
      <br />
    </div>

    <!-- Linked Investigations Card -->
    <div *ngIf="(param?.editLinkedInvestigation || param?.addLinkedInvestigation) && id">
      <mat-card class="mat-elevation-z8">
        <mat-card-header class="d-flex justify-content-between">
          <button mat-raised-button class="float-right" type="button" (click)="checkLossAddress()">
            <mat-icon>location_on</mat-icon>
            Proximity Check
          </button>
          <mat-card-title>Linked Investigations</mat-card-title>
        </mat-card-header>
        <div class="p-15" *ngFor="
            let linkedInvestigation of baseModel.LinkedInvestigations;
            let i = index
          ">
          <mat-chip-list>
            <mat-chip [removable]="true" (removed)="unlinkInvestigation(i)">
              {{ linkedInvestigation.nefcoNumber || linkedInvestigation.id }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div>
          <div class="column col-1-billing-fields">
            <div class="flexContainer parties-info">
              <div class="equal">
                <mat-form-field>
                  <mat-label>Investigation #</mat-label>
                  <input matInput type="text" #linkCtl />
                </mat-form-field>
                <mat-card-actions>
                  <button mat-button class="" (click)="linkInvestigation(linkCtl)" [disabled]="!linkCtl.value?.length">
                    <mat-icon matSuffix>add</mat-icon> Add Linked Investigation
                  </button>
                </mat-card-actions>
              </div>
            </div>
          </div>
        </div>
        <app-notes name="linkedNotes" title="Linked Investigation Notes:"
          [(ngModel)]="model.Detail.linkedInvestigationNotes" (ngModelChange)="save()">message</app-notes>
      </mat-card>
    </div>

    <!-- Vehicles Card -->
    <div *ngIf="(param?.editVehicles || param?.addVehicles) && id && investigationVehiclesListView?.enabled">
      <mat-card class="mat-elevation-z8">
        <!-- Vehicles Header and Content Title -->
        <div class="flexContainer vehicles-info" *ngIf="id; else vehicleMoreInfo">
          <div class="equal">
            <mat-card-header>
              <mat-card-title>Vehicles</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <!-- <div class="flexContainer vehicles-info"> -->
              <ng-container *ngFor="let vehicle of vehicles; let i = index">
                <h3 class="mb-1 mt-2">Vehicle {{ i + 1 }}:</h3>
                <app-investigation-vehicle [investigationId]="id" [vehicle]="vehicle"
                  (changed)="loadVehicles()"></app-investigation-vehicle>
              </ng-container>
              <ng-container *ngIf="investigationVehiclesCreate?.enabled">
                <br />
                <h3 class="mb-1 mt-3 mr-3">New Vehicle:</h3>
                <app-investigation-vehicle [investigationId]="id"
                  (changed)="loadVehicles()"></app-investigation-vehicle>
              </ng-container>
              <!-- </div> -->
            </mat-card-content>
            <app-notes name="vehicleNotes" title="Vehicle Notes:" [(ngModel)]="model.Detail.vehicleNotes"
              (ngModelChange)="save()">message</app-notes>
          </div>
        </div>
        <ng-template #vehicleMoreInfo>
          <h2>More Detail Needed</h2>
          <p>
            To add vehicles more primary information is needed above. This
            section will automatically become available.
          </p>
        </ng-template>
      </mat-card>
    </div>

    <!-- Parties Card -->
    <div *ngIf="id && param?.editParties">
      <mat-card class="mat-elevation-z8">
        <app-investigation-party [investigation]="investigation"></app-investigation-party>
        <app-notes name="partyNotes" title="Party Notes:" [(ngModel)]="model.Detail.partyNotes"
          (ngModelChange)="save()">message</app-notes>
      </mat-card>
    </div>

    <!-- Investigation Staff -->
    <div *ngIf="id && param?.editStaff && investigationAssignStaffListView?.enabled">
      <mat-card class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title>Staff</mat-card-title>
        </mat-card-header>
        <app-investigation-assignments-container [staff]="baseModel?.InvestigationStaff" [address]="model.LossAddress"
          [investigation]="investigation" [clientId]="model.ClientId"
          (updated)="reloadInvestigation()"></app-investigation-assignments-container>
        <br />
        <!-- This is outside the main form, so we have to trigger a save after updating -->
        <app-notes name="staffNotes" title="Investigation Staff Notes:" [(ngModel)]="model.Detail.staffNotes"
          (ngModelChange)="save()">message</app-notes>
      </mat-card>
    </div>
  </div>
</section>
<footer>
  <div class="right">
    <button kendoButton class="mr-24" themeColor="primary" fillMode="link" (click)="clearPage()"
      *ngIf="(param?.editInfo || param.editBillingInfo || param.addBillingInfo) && id">Clear Page</button>
    <button kendoButton class="mr-16" themeColor="light" fillMode="solid" routerLink="/investigations/{{ id }}"
      *ngIf="(param?.editInfo || param.editBillingInfo || param.addBillingInfo) && id">Discard Edits</button>
    <button kendoButton class="mr-16" themeColor="primary" fillMode="solid" (click)="save(true)"
      [disabled]="validateForm" *ngIf="param?.editInfo && id">Save Edit</button>
    <!-- save button for billing information form -->
    <button kendoButton class="mr-16" themeColor="primary" fillMode="solid" (click)=" saveBillingDetail()"
      [disabled]="validateBForm" *ngIf="(param.editBillingInfo || param.addBillingInfo) && id">Save
      Edit</button>
  </div>
</footer>
<app-reject-reactivate-modal *ngIf="investigationRejectReactivateModal" [opened]="investigationRejectReactivateModal"
  [mode]="investigationRejectReactivateModalMode" [id]="id" [headerIcon]="'assets/svg/warning.svg'"
  (closeModel)="investigationRejectReactivateModalResponse($event)"></app-reject-reactivate-modal>