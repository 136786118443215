import { EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IApiPhone, IApiPhoneType } from 'src/app/shared/modules/graphql/types/types';
import { removeTypename, unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { PhoneTypeService } from 'src/app/shared/services/phones';

export interface IPhoneInputConfig {
  hidePrimary: boolean;
  hideType: boolean;
  static?: boolean;
}

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneComponent),
      multi: true
    }
  ]
})
export class PhoneComponent implements OnInit, ControlValueAccessor {

  @Input() public config: IPhoneInputConfig = {
    hidePrimary: false,
    hideType: false
  };

  @Input() public name;

  @Output() isPrimary = new EventEmitter<boolean>();

  private _value: IApiPhone = {
    id: null,
    isPrimary: false,
    number: null,
    Type: {
      id: "",
      name: ""
    },
    TypeId: null
  };

  public set value(val: IApiPhone) {
    if (val) {
      this._value = val;
      this.onChange(val);
    }
  }

  public get value() {
    return this._value;
  }

  public phoneTypes: IApiPhoneType[] = [];

  constructor(
    private phoneTypeService: PhoneTypeService
  ) { }

  private onChange: any = (): void => {};
  private onTouch: any = (): void => {};

  public writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.value.TypeId = this.value.Type ? this.value.Type.id : null;
    }
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public selectCompare(option, selection) {
    return option.id === selection.id;
  }

  public togglePrimary({ checked }: MatCheckboxChange) {
    this.isPrimary.emit(checked);
  }

  ngOnInit() {
    this.phoneTypeService.get().pipe(
      unwrapConnection(),
      removeTypename()
      ).subscribe((types) => {
        this.phoneTypes = types;
        if (!this.value?.Type?.id) this.value.Type = types[0];
      });
  }

}
