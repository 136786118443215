<!-- Permissions -->
<div [appEnforcePermission]="authConfig.manageDocumentTypesCreate" #manageDocumentTypesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageDocumentTypesUpdate" #manageDocumentTypesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageDocumentTypesDelete" #manageDocumentTypesDelete="appEnforcePermission"></div>
<!-- Permissions -->
<!-- Screen 38.1 -->
<section class="mat-typography">

  <h2>Manage Document Types</h2>
  <div class="contentWrapper">
    <div class="d-flex flex-row-reverse py-3" *ngIf="manageDocumentTypesCreate?.enabled">
      <button mat-raised-button class="rounded-button" (click)="edit()">
        <mat-icon color="primary">playlist_add</mat-icon>
        New Document Type
      </button>
    </div>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)">
      <ng-container matColumnDef="NAME">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Document Type</th>
        <td mat-cell *matCellDef="let type">
          <a appEventLink (click)="edit(type)" *ngIf="manageDocumentTypesUpdate?.enabled else onlyName" [innerHTML]="type.name"></a>
          <ng-template #onlyName>
            {{type.name}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="CATEGORY">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let type" [innerHTML]="type.Category?.name"> </td>
      </ng-container>
      <ng-container matColumnDef="defaultTitle">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Default Title</th>
        <td mat-cell *matCellDef="let type" [innerHTML]="type.defaultTitle"> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{manageDocumentTypesUpdate?.enabled || manageDocumentTypesDelete?.enabled ? 'Actions' : ''}}
        </th>
        <td mat-cell *matCellDef="let type">
          <button mat-icon-button [matMenuTriggerFor]="docOptions" *ngIf="manageDocumentTypesUpdate?.enabled || manageDocumentTypesDelete?.enabled">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #docOptions="matMenu">
            <button mat-menu-item (click)="edit(type)" *ngIf="manageDocumentTypesUpdate?.enabled">Edit</button>
            <button mat-menu-item (click)="delete(type.id)" *ngIf="manageDocumentTypesDelete?.enabled">Delete</button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
  </div>
  <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>

</section>