import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { debounceTime } from 'rxjs/operators';
import { ContactService } from 'src/app/shared/services';
import { IApiContact, IApiContactFilterType, IApiContactRole } from '../../modules/graphql/types/types';
import { unwrapConnection } from '../../rxjs.pipes';
import { contacts } from "src/app/shared/helpers/auth-config/contacts.config";

@Component({
  selector: 'app-contact-multi-select-kendo',
  templateUrl: './contact-multi-select-kendo.component.html',
  styleUrls: ['./contact-multi-select-kendo.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactMultiSelectKendoComponent),
      multi: true
    }
  ]
})
export class ContactMultiSelectKendoComponent implements OnInit, OnChanges {
  public authConfig = {
    contacts
  };

  @ViewChild("multiSelect", { static: false }) public multiSelect: MultiSelectComponent;

  @Input() placeholder = "Search for a Contact";
  @Input() label = "";
  @Input() contactRole: IApiContactRole = null;
  @Input() disabled = null;
  @Input() selectedId: string = null;

  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public contacts: IApiContact[] = [];
  public selected: IApiContact[] = [];
  public selectedValue = [];

  public loading: boolean = false;
  public searchString: string = '';

  constructor(
    private contactService: ContactService,
  ) { }

  ngOnInit() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  public ngOnChanges() {
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
      this.selected = [];
    }
  }

  public select(selected: any) {
    this.selected = this.contacts?.filter(item => selected?.includes(item.id));
    this.selectionChanged.emit(this.selectedValue);
    this._propagateChange(this.selectedValue);
  }


  public onSearchFocus(event){
    if (this.selected){
      this.contacts = this.selected;
    }
  }

  public onSearchChange(search: string) {
    this.searchString = search?.trim();
    this.multiSelect.toggle(false);
    if (this.selected) {
      this.contacts = this.selected;
    } else {
      this.contacts = [];
    }
    if ((search?.trim())?.length < 3) return;

    const filters = [{
      type: IApiContactFilterType.FullName,
      value: search?.trim()
    }];

    if (this.contactRole) filters.push({
      type: IApiContactFilterType.Position,
      value: this.contactRole.toString()
    });

    this.loading = true;
    this.multiSelect.toggle(false)
    this.contactService.searchBy(filters).pipe(
      debounceTime(800),
      unwrapConnection(),
    ).subscribe(results => {
      this.loading = false;
      this.multiSelect.toggle(true);
      const newResult = results?.filter((res) => !this.selectedValue?.includes(res.id))
      this.contacts = [...this.selected, ...newResult];
    });
  }

  private _propagateChange = (_: any) => { };

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
