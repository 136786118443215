import { filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from 'src/app/shared/directives/enforce-permission.directive';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IApiDocumentType } from "src/app/shared/modules/graphql/types/types";
import { DocumentTypeService } from "src/app/shared/services";
import { DocumentTypeModalComponent } from "../document-type-modal/document-type-modal.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { DocumentTypeDataSource } from "src/app/shared/services/document/document-type/document-type.datasource";
import { manageDocumentTypesCreate, manageDocumentTypesUpdate, manageDocumentTypesDelete } from "../../../../shared/helpers/auth-config/manage.config";

@Component({
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss']
})
export class DocumentTypeListComponent implements OnInit {

  public authConfig = {
    manageDocumentTypesCreate,
    manageDocumentTypesUpdate,
    manageDocumentTypesDelete
  }

  @ViewChild(MatPaginator) viewPaginator: MatPaginator;
  @ViewChild(MatSort) viewSort: MatSort;

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  private _dataSource: DocumentTypeDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  // Column headers
  public columnsToDisplay = ['NAME', 'CATEGORY', 'defaultTitle', 'action'];

  constructor(
    private dialog: MatDialog,
    private documentTypes: DocumentTypeService,
    private loader: LoaderService,
    private notifications: NotificationsService
  ) {
  }

  public edit(data?: IApiDocumentType) {
    return this.dialog.open(DocumentTypeModalComponent, {
      data,
      panelClass: 'oversized'
    }).afterClosed().subscribe((data) => {
      if (data?.created !== false) {
        this.load()
      }
    });
  }

  public delete(id: string) {
    this.notifications.confirm("Really delete this Document Type?").afterClosed().pipe(
      filter(v => !!v),
      switchMap(() => this.documentTypes.remove(id)),
      this.notifications.catchAlertPipe("Error deleting Document Type")
    ).subscribe(() => {
      this.notifications.notify('Document type has been deleted successfully.')
      this.load()
    });
  }

  public ngOnInit() {
    this._dataSource = new DocumentTypeDataSource(this.documentTypes);
    this.loader.attachObservable(this._dataSource.loading$);

    // populate the data
    this.load();
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

}
