import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IUser } from 'src/app/shared/interfaces/user.interfaces';
import { IPayPeriod } from 'src/app/shared/interfaces/time-entry.interfaces';
import moment from 'moment';
import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { groupBy } from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';
import { IApiExpenseItem, IApiTimeEntry, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { MatCheckboxChange } from '@angular/material/checkbox';

dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

enum ITimeReducerType {
  Investigation = 'INVESTIGATION',
  TimeType = 'TIME_TYPE',
  Paid = 'PAID'
}

export interface IPayRollSummary {
  hours: number;
  name: string;
  id?: string;
}

export interface IPayRollDay {
  unpaidEntries: IApiExpenseItem[];
  paidEntries: IApiExpenseItem[];
  summaryTypeUnpaid: IPayRollSummary[];
  summaryInvestUnpaid: IPayRollSummary[];
  totalPaid: number;
  totalUnpaid: number;
  currentPayPeriod?: boolean;
  selected?: boolean;
}

export interface IPayRollWeek {
  fromDate: string;
  toDate: string;
  unpaidEntries: IApiExpenseItem[];
  paidEntries: IApiExpenseItem[];
  days: IPayRollDay[];
  totalPaid: number;
  totalUnpaid: number;
  summaryTypeUnpaid: IPayRollSummary[];
  summaryInvestUnpaid: IPayRollSummary[];
  currentPayPeriod?: boolean;
}

@Component({
  selector: 'app-time-kendo-card',
  templateUrl: './time-kendo-card.component.html',
  styleUrls: ['./time-kendo-card.component.scss']
})
export class TimeKendoCardComponent implements OnInit {

  @Input() user: IApiUser;
  @Input() payPeriod: IPayPeriod;

  private _selectAllEntries: boolean;
  @Input() set selectAllEntries(val) {
    this.toggleAll({ checked: val });
    this._selectAllEntries = val;
  }

  get selectAllEntries() {
    return this._selectAllEntries;
  }

  public displayedColumns = ["payPeriod", "dayOne", "dayTwo", "dayThree", "dayFour", "dayFive", "daySix", "daySeven", "weekTotals"];
  public roles: string[] = [];
  public totalHours: 0;

  public payPeriodData: IPayRollWeek[] = [];
  public unpaidDaysForCalendar: number[] = [];

  public get overallTimeTotal() {
    if (this.payPeriodData.length) {
      return this.payPeriodData.reduce((prev, curr) => {
        prev.hoursPaid += curr.totalPaid;
        prev.hoursUnpaid += curr.totalUnpaid;
        prev.hours += (curr.totalPaid + curr.totalUnpaid);
        return prev;
      }, { hoursPaid: 0, hoursUnpaid: 0, hours: 0 });
    }
    else return { hoursPaid: 0, hoursUnpaid: 0, hours: 0 };
  }

  // Used for "select all" toggle
  public get unpaidDays() {
    return this.payPeriodData.map((week) => {
      return week.days;
    }).flat(1).filter((obj => obj.unpaidEntries?.length > 0));
  }

  public selectedTimeEntries = new SelectionModel<IPayRollDay>(true, []);
  @Output() payTimeEntries = new EventEmitter<IPayRollDay>();

  public viewDetails = false;

  constructor() {
  }

  private timeReducer(timeEntries: IApiTimeEntry[], type: ITimeReducerType, isPaid = false): IPayRollSummary | number | any {
    const results: IPayRollSummary[] = [];

    // Filter out paid/unpaid items
    timeEntries = timeEntries.filter((obj) => obj.paidDate ? isPaid : !isPaid);

    // Reduce values
    switch (type) {
      case ITimeReducerType.Investigation:
        timeEntries.reduce((prev, curr) => {
          const nefcoNum = curr.Investigation ? curr.Investigation.nefcoNumber : 'Unlinked';
          if (!prev[nefcoNum]) {
            prev[nefcoNum] = { id: curr.Investigation ? curr.Investigation.id : null, name: nefcoNum, hours: 0, timeEntryName: curr.Type.name, description: curr?.description };
            results.push(prev[nefcoNum]);
          }
          prev[nefcoNum].hours += (curr.hours + curr.nonBillableHours);
          return prev;
        }, {});

        return results;

      case ITimeReducerType.TimeType:
        timeEntries.reduce((prev, curr) => {
          if (!prev[curr.Type.name]) {
            prev[curr.Type.name] = { name: curr.Type.name, hours: 0 };
            results.push(prev[curr.Type.name]);
          }
          prev[curr.Type.name].hours += (curr.hours + curr.nonBillableHours);
          return prev;
        }, {});

        return results;

      case ITimeReducerType.Paid:
        return timeEntries.reduce((prev, curr) => prev + (curr.hours + curr.nonBillableHours), 0);

      default:
        return [];
    }
  }

  private initWeeks(entries) {

    // Group entries by week
    let groupedWeeks = groupBy(entries, obj => dayjs(obj.workday).startOf('week').format());

    // Initialize pay period weeks even if values are empty
    groupedWeeks = {
      [dayjs(this.payPeriod.start).startOf('week').format()]: [],
      [dayjs(this.payPeriod.end).startOf('week').format()]: [],
      ...groupedWeeks
    };

    const foundWeeks = Object.keys(groupedWeeks);
    // Return formatted data for the table
    return foundWeeks.map((key) => {

      // See if this item is in the current pay period, needed for styling logic
      const isCurrentPayPeriod = ((dayjs(this.payPeriod.start).startOf('week').format() === dayjs(key).format()) || (dayjs(this.payPeriod.end).format() === dayjs(key).add(6, 'day').endOf('day').format()));
      // Group entries by day, and initialize all days
      const groupedDays = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        ...groupBy(groupedWeeks[key], obj => dayjs(obj.workday).day())
      };
      const foundDays = Object.keys(groupedDays);

      // Return formatted entries
      const formattedEntry: IPayRollWeek = {
        fromDate: dayjs(key).format(),
        toDate: dayjs(key).add(6, 'day').endOf('day').format(),
        unpaidEntries: groupedWeeks[key].filter(obj => !obj.paidDate),
        paidEntries: groupedWeeks[key].filter(obj => !!obj.paidDate),
        days: foundDays.map(dayKey => {
          return {
            unpaidEntries: groupedDays[dayKey].filter(obj => !obj.paidDate),
            paidEntries: groupedDays[dayKey].filter(obj => !!obj.paidDate),
            summaryTypeUnpaid: this.timeReducer(groupedDays[dayKey], ITimeReducerType.TimeType),
            summaryInvestUnpaid: this.timeReducer(groupedDays[dayKey], ITimeReducerType.Investigation),
            totalPaid: this.timeReducer(groupedDays[dayKey], ITimeReducerType.Paid, true),
            totalUnpaid: this.timeReducer(groupedDays[dayKey], ITimeReducerType.Paid, false),
            currentPayPeriod: isCurrentPayPeriod,
            selected: false,
            date: dayjs(key).add(parseInt(dayKey, 10), 'day')
          };
        }),
        totalPaid: this.timeReducer(groupedWeeks[key], ITimeReducerType.Paid, true),
        totalUnpaid: this.timeReducer(groupedWeeks[key], ITimeReducerType.Paid, false),
        summaryTypeUnpaid: this.timeReducer(groupedWeeks[key], ITimeReducerType.TimeType),
        summaryInvestUnpaid: this.timeReducer(groupedWeeks[key], ITimeReducerType.Investigation),
        currentPayPeriod: isCurrentPayPeriod,
      };
      return formattedEntry;
    }).sort((a, b) => a.fromDate < b.fromDate ? 1 : -1);
  }

  private initUnpaidDays(entries) {
    // Filter out unpaid items
    entries = entries.filter((obj) => !obj.paidDate);

    return entries.map((obj) => dayjs(obj.workday).day());
  }

  public findDay(num) {
    // 0 for Sunday was returning false so I added 1 to found number
    return (this.unpaidDaysForCalendar.find((element) => element === num) + 1) ? true : false;
  }

  public toggleCheckbox(data: IPayRollDay) {
    data.selected ? this.selectedTimeEntries.select(data) : this.selectedTimeEntries.deselect(data);
    this.payTimeEntries.emit(data);
  }

  public toggleAll({ checked }) {
    this.payPeriodData.forEach((week, i) => {
      week.days.forEach((day, j) => {
        if (day.unpaidEntries.length > 0) {
          this.payPeriodData[i].days[j].selected = checked;
          checked ? this.selectedTimeEntries.select(this.payPeriodData[i].days[j]) : this.selectedTimeEntries.deselect(this.payPeriodData[i].days[j]);
          this.payTimeEntries.emit(this.payPeriodData[i].days[j]);
        }
      });
    });
  }

  ngOnInit(): void {
    this.roles = [...new Set(this.user?.Positions.map(role => role.title))];
    this.payPeriodData = this.initWeeks(this.user.TimeEntries);
    this.unpaidDaysForCalendar = this.initUnpaidDays(this.user.TimeEntries);
  }
}
