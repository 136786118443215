<!-- Permissions -->
<div [appEnforcePermission]="authConfig.contacts" #contactsPerm="appEnforcePermission"></div>
<!-- Permissions -->
<div class="kendo-formfield left-search-icon">
  <kendo-label [ngClass]="{'asterisk': required}" [for]="" text="{{label}}"></kendo-label>
  <div style="position: relative;">
    <i class="fa fa-search" aria-hidden="true"></i>
    <kendo-autocomplete
      [data]="contacts"
      [loading]="loading"
      [value]="selected?.itemFullName"
      valueField="id"
      fillMode="outline"
      [filterable]="true"
      (valueChange)="select($event)"
      (filterChange)="onSearchChange($event)"
      [placeholder]="placeholder"
      [disabled]="disabled || !contactsPerm?.enabled"
      [popupSettings]="{appendTo: 'component', animate: false, width: 250 }"
    >
      <ng-template *ngIf="searchString?.length > 2" kendoAutoCompleteItemTemplate let-dataItem>
        <span>{{ dataItem?.itemFullName }}
          <br *ngIf="showAdditionalDetails && dataItem?.emailData?.address">
          <span *ngIf="showAdditionalDetails && dataItem?.emailData?.address">{{ dataItem?.emailData?.address || '' }}</span>
          <br *ngIf="showAdditionalDetails && dataItem?.company?.Company?.name">
          <span *ngIf="showAdditionalDetails && dataItem?.company?.Company?.name">{{dataItem?.company?.Company?.name || ''}}</span>
        </span>
      </ng-template>
    </kendo-autocomplete>
  </div>
</div>
