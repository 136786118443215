<mat-dialog-content>
  <div class="text-center mb-3">
    <h4>Select Staff</h4>
    <h5>{{ data.user | fullName }}</h5>
  </div>
  <div class="d-flex align-items-center">
    <div class="mr-2">
      <mat-form-field>
        <mat-label>Position</mat-label>
        <mat-select (selectionChange)="positionSelected($event)">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let position of positions" [value]="position.title" [innerHTML]="position.title">
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ml-auto">
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" [(ngModel)]="search" name="search" (appDebouncedInputChange)="onSearchChange()">
        <button mat-icon-button disableRipple matSuffix>
          <mat-icon (click)="search = null; onSearchChange()">clear</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="d-inline-flex align-items-center w-100 mb-3">
    <mat-chip-list>
      <mat-chip *ngFor="let id of selection.selected">
        {{ findUser(id) | fullName }}
        <mat-icon matChipRemove (click)="selection.deselect(id)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <table mat-table [dataSource]="dataSource" matSort class="mt-2">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox [checked]="allSelected" (change)="masterToggle($event)"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let staff">
        <mat-checkbox [checked]="selection.isSelected(staff.id)" (change)="select($event, staff)"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let staff">
        {{ staff | fullName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>Position(s)</th>
      <td mat-cell *matCellDef="let staff">
        <span class="d-block" *ngFor="let position of staff.Positions">{{position.title}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let staff">
        <app-address-view [address]="primary(staff.Addresses)"></app-address-view>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let staff">
        <app-email-phone-view [email]="primary(staff.Emails)"></app-email-phone-view>
      </td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let staff">
        <app-email-phone-view [phone]="primary(staff.Phones)"></app-email-phone-view>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)"></mat-paginator>
  <div class="buttons col-12 d-flex justify-content-end mt-3">
    <button mat-button class="cancel mr-1 pl-5 pr-5 pb-1 pt-1" color="primary" (click)="cancel();">
      Cancel
    </button>
    <button mat-raised-button class="save ml-1 pl-5 pr-5 pb-1 pt-1" color="primary" (click)="save();">
      Save
    </button>
  </div>
</mat-dialog-content>