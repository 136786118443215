<p>Email</p>
<div class="row">
  <div class="col-3" *ngIf="!config.hideType">
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select [name]="'type' + name" [(ngModel)]="value.Type" [compareWith]="selectCompare" [disabled]="config.static" required>
        <mat-option *ngFor="let emailType of emailTypes" [value]="emailType">{{ emailType.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-6">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput [name]="'email' + name" [(ngModel)]="value.address" required type="email" [maxLength]="255" appEmailValidator [disabled]="config.static" #email="ngModel" />
      <mat-error *ngIf="!email?.valid && (email?.dirty || email?.touched) && email?.errors" >A valid email must be entered</mat-error>
    </mat-form-field>
  </div>
  <div class="col-3 align-self-end pb-3" *ngIf="!config.hidePrimary">
    <mat-checkbox [(ngModel)]="value.isPrimary" [name]="'isPrimary' + name" >Primary</mat-checkbox>
  </div>
</div>
