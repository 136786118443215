<!-- Permissions -->
<div [appEnforcePermission]="authConfig.dashboardInvestigatorsOnSceneView" #dashboardInvestigatorsOnSceneView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.dashboardInvestigatorsOnSceneUpdate" #dashboardInvestigatorsOnSceneUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section>
  <div class="last-updated mb-16">
    <span>Last Updated: {{lastUpdated?.format('MM/DD/yyyy')}} at {{ lastUpdated?.format('hh:mm A') }}</span>
  </div>

  <kendo-gridlayout
    [rows]="['auto']"
    [gap]="{ rows: 5, cols: 16 }"
    [cols]="['repeat(5,minmax(0,1fr))']"
  >
    <kendo-gridlayout-item>
      <app-user-multi-select-kendo
        label="Investigator"
        placeholder="Search by staff member"
        [investigationRole]="roleName.Investigator"
        [userViewFilter]="userViewFilter"
        [includeInactive]="false"
        [selectedId]="filterValue(filterTypes.UserIdIn)"
        (selectionChanged)="setVal($event, filterTypes.UserIdIn)"
      ></app-user-multi-select-kendo>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <app-investigation-multi-select-kendo
        label="Investigation ID"
        placeholder="Search by nefco number"
        [selectedIds]="filterValue(filterTypes.InvestigationIdIn)"
        (selectionChanged)="setVal($event, filterTypes.InvestigationIdIn)"
      ></app-investigation-multi-select-kendo>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="" text="Scene Exam State"></kendo-label>
        <div>
          <kendo-multiselect #multiSelect
            [checkboxes]="true"
            [autoClose]="false"
            [valuePrimitive]="true"
            [data]="stateList"
            [value]="filterValue(filterTypes.SceneExamState)"
            [rounded]="'full'"
            (valueChange)="setVal($event, filterTypes.SceneExamState)"
            valueField="value"
            textField="text"
            [tagMapper]="tagMapper"
            [placeholder]="'All States'"
            [fillMode]="'outline'"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span style="padding-left: 5px;">{{ dataItem?.text }}</span>
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item>
      <div class="kendo-formfield">
        <kendo-label [for]="" text="Status"></kendo-label>
        <div>
          <kendo-multiselect #multiSelect
            [checkboxes]="true"
            [autoClose]="false"
            [valuePrimitive]="true"
            [data]="investigationOnSceneStatus | keyvalue"
            [value]="filterValue(filterTypes.OnSceneStatus)"
            [rounded]="'full'"
            (valueChange)="setVal($event, filterTypes.OnSceneStatus)"
            valueField="value"
            textField="value"
            [tagMapper]="tagMapper"
            [placeholder]="'All Statuses'"
            [fillMode]="'outline'"
            [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span style="padding-left: 5px;">{{ dataItem?.value }}</span>
            </ng-template>
          </kendo-multiselect>
        </div>
      </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item class="text-right">
      <div>
        <kendo-label text=""></kendo-label>
        <div>
          <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="appliedFilter()">Apply Filters</kendo-button>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>

  <hr class="mb-0" />

  <kendo-card width="100%" class="mt-8 mb-8">
    <kendo-card-header style="padding: 8px 16px;">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
            <kendo-gridlayout-item class="select-All mb-0">
                <kendo-formfield>
                    <div class="k-checkbox-wrap select-All">
                        <input
                            #selectAllChecked
                            type="checkbox"
                            kendoCheckBox
                            [(ngModel)]="selectAll"
                            [ngModelOptions]="{standalone: true}"
                            (change)="selectAllCheckboxChange($event)"
                            [indeterminate]="selectAllInterminate"
                            [(ngModel)]="selectAll"
                            [ngModelOptions]="{standalone: true}"
                        />
                        <kendo-label
                        [for]="selectAllChecked"
                        class="k-checkbox-label font-weight-600 checkbox-margin ml-0"
                        style="margin-bottom: 0"
                        text="Select All"
                        ></kendo-label>
                    </div>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="actions-button mb-0">
                <h6 class="fs-16 mr-16 mb-0" *ngIf="selected?.length">{{selected?.length}} Selected</h6>
                <span class="mr-16" style="color:rgba(1, 24, 98, 0.08)" *ngIf="selected?.length">|</span>
                <div>
                  <button kendoButton class="mr-16" themeColor="light" fillMode="solid" (click)="clear()">Clear</button>
                  <button kendoButton themeColor="light" fillMode="solid" (click)="changeStatus(null, false)"
                    [disabled]="selected?.length === 0" *ngIf="dashboardInvestigatorsOnSceneUpdate?.enabled">Change Status</button>
                </div>
            </kendo-gridlayout-item>
        </kendo-gridlayout>
    </kendo-card-header>
  </kendo-card>
</section>
<section>
  <kendo-grid
    class="pending-request"
    [data]="{
      data: onSceneList,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="{
      buttonCount: 6,
      type: 'numeric',
      pageSizes: [5, 10, 25, 100],
      previousNext: true,
      responsive: true,
      position: 'bottom'
    }"
    (pageChange)="pageOptions?.paginate($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
		[selectable]="{checkboxOnly: true, mode: 'multiple' }"
		kendoGridSelectBy="id"
		[(selectedKeys)]="selected">

    <kendo-grid-column field="USER_NAME" title="Name" [width]="20" [sortable]="true" [headerClass]="'sort-header'" class="id-column font-weight600">
      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox"
          class="mr-16"
					kendoCheckBox
					[checked]="selected.includes(dataItem.id)"
					(click)="singleCheckboxChecked($event?.target?.checked, dataItem)"/>
        <a
          target="_blank"
          [routerLink]="['/investigations/', dataItem?.Investigation?.id]"
          tel=""
          class="text-decoration"
        >{{dataItem?.User?.lastName }} {{dataItem?.User?.firstName}}</a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="orderBy.ScheduledDate" title="Start Time" [width]="10" [sortable]="true" [headerClass]="'sort-header'" class="id-column font-weight600">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>
          {{ dataItem?.scheduledDate | customDate: 'h:mm a' }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="orderBy.NefcoNumber" title="ID" [width]="10" [sortable]="true" [headerClass]="'sort-header'" class="id-column font-weight600">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a target="_blank" [routerLink]="['/investigations/', dataItem?.Investigation?.id]" tel="" class="text-decoration">
          {{ dataItem?.Investigation?.nefcoNumber }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="'id'" title="Scene Exam Address" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="text-overflow">
          <ng-container *ngIf="dataItem?.Investigation?.InvestigationAddress?.address1">
            {{dataItem?.Investigation?.InvestigationAddress?.address1}}<ng-container *ngIf="dataItem?.Investigation?.InvestigationAddress?.address2">,</ng-container>
            <ng-container *ngIf="dataItem?.Investigation?.InvestigationAddress?.address2">
              {{ dataItem?.Investigation?.InvestigationAddress?.address2 }}<ng-container *ngIf="dataItem?.Investigation?.InvestigationAddress?.address3">,</ng-container>
            </ng-container>
            <ng-container *ngIf="dataItem?.Investigation?.InvestigationAddress?.address3">
              {{ dataItem?.Investigation?.InvestigationAddress?.address3 }}
            </ng-container>
          </ng-container>
        </span>
        <br />
        <span>{{dataItem?.Investigation?.InvestigationAddress?.city}}, {{dataItem?.Investigation?.InvestigationAddress?.state}} {{dataItem?.Investigation?.InvestigationAddress?.postal}}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="'id'" title="Phone Number" [width]="10" [sortable]="false" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>
          {{ (dataItem?.User?.twilioPhoneNumber | phoneFormat : "US") || '-' }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="orderBy.OnSceneStatus" title="Status" [width]="10" [sortable]="true" [headerClass]="'sort-header'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>
          <kendo-badge
          class="{{
            'fw600 ' +
              (dataItem?.LatestInvestigationOnScene?.status === investigationOnSceneStatus.EN_ROUTE ||
              dataItem?.LatestInvestigationOnScene?.status === investigationOnSceneStatus.OFF_SCENE
                ? 'fcolor'
                : '')
          }}"
          position="none"
          size="medium"
          rounded="full"
          themeColor="{{
            dataItem?.LatestInvestigationOnScene?.status === investigationOnSceneStatus.SCHEDULED
              ? 'primary'
              : dataItem?.LatestInvestigationOnScene?.status === investigationOnSceneStatus.EN_ROUTE
              ? 'warning'
              : dataItem?.LatestInvestigationOnScene?.status === investigationOnSceneStatus.ON_SCENE
              ? 'success'
              : dataItem?.LatestInvestigationOnScene?.status === investigationOnSceneStatus.OFF_SCENE
              ? 'tertiary'
              : ''
          }}"
          >{{ dataItem?.LatestInvestigationOnScene?.status }}</kendo-badge>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="'id'" title="Actions" [width]="15" [sortable]="true" [headerClass]="'nocursor'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>
          <button kendoButton class="mr-16" themeColor="primary" fillMode="link" (click)="changeStatus(dataItem, true)"
          *ngIf="dashboardInvestigatorsOnSceneUpdate?.enabled">Change Status</button>
          <kendo-dropdownbutton
              [data]="dashboardInvestigatorsOnSceneView?.enabled && dashboardInvestigatorsOnSceneUpdate?.enabled
              ? [{ text: 'Send Message' }, { text: 'View Activity Log' }] : dashboardInvestigatorsOnSceneUpdate?.enabled
              ? [{ text: 'Send Message' }] : dashboardInvestigatorsOnSceneView?.enabled
              ? [{ text: 'View Activity Log' }] : []"
              themeColor="primary"
              fillMode="link"
              buttonClass="grid-action-items"
              iconClass="fa-solid fa-ellipsis"
              (itemClick)="actionSelected($event, dataItem)"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu'
              }"
            >
            </kendo-dropdownbutton>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>
<kendo-dialog #changeStatusDialog *ngIf="showDialogStatusChange" [minWidth]="600" [width]="600"
[dialogRef]="changeStatusDialog" (appClickOutside)="showDialogStatusChangeOpen(false);">
  <kendo-dialog-titlebar (close)="showDialogStatusChangeOpen(false)">
    <div class="modal-title">
      <i class="fa-solid fa-circle-exclamation"></i>
      <div class="ml-8">
        <span>Change Status</span>
        <p class="secondary">{{singleSelected ? 1 : selected.length}} investigators selected</p>
      </div>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body mt-24 mb-24">
    <p class="mb-16">Are you sure you want to change the status of these investigators?</p>
    <form #filterForm="ngForm">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-0">
          <kendo-formfield class="kendo-formfield">
            <kendo-label class="asterisk" text="Status"></kendo-label>
            <kendo-dropdownlist [data]="investigationOnSceneStatusAll" fillMode="outline"
              textField="text" valueField="value"  name="status" #status="ngModel"
                [valuePrimitive]="true" [defaultItem]="{text: 'All Status', value: ''}" [(ngModel)]="selectedStatus"
                [popupSettings]="{appendTo: 'component', animate: false, width: 250 }" required>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span *ngIf="!dataItem?.value" class="placeholder"> {{ dataItem?.text }} </span>
                    <span *ngIf="dataItem?.value" class="not-placeholder"> {{ dataItem?.text }} </span>
                </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!status?.valid && (status?.dirty || status?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="status?.errors?.required">
                Status cannot be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="showDialogStatusChangeOpen(false)">Cancel</button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="saveStatus()">Save</button>
</kendo-dialog-actions>
</kendo-dialog>