<mat-dialog-content>
  <mat-card-title>
    <h3 align="center">
      <ng-container *ngIf="investigation; else unlinked">
        {{ !data?.disableEdit ? "Send" : "Sent" }} to Quickbooks <br />
        <small>Investigation# {{ investigation?.nefcoNumber || investigation?.id }}</small>
      </ng-container>
      <ng-template #unlinked>
        Unlinked Entry
      </ng-template>
    </h3>
  </mat-card-title>
  <mat-card>
    <div class="row client-info">
      <div class="column input-fields">
        <div class="actionWrapper">
          <div class="flexContainer">

            <!-- Client Info Definition -->
            <div class="equal">
              <mat-card-content>
                <mat-card-title>
                  <h5>Invoice Number</h5>
                </mat-card-title>

                <input *ngIf="!data?.disableEdit; else viewOnly_InvoiceNum;" type="text" class="mb-3" [(ngModel)]="invoiceNumber" [required]="true" maxlength="11" />
                <ng-template #viewOnly_InvoiceNum><p>{{ data?.invoice?.invoiceNumber }}</p></ng-template>

                <ng-container *ngIf="investigation">
                    <mat-card-title>
                      <h5>Client</h5>
                    </mat-card-title>
  
                    <p class="client-info">{{ investigation?.Client?.firstName }} {{ investigation?.Client?.lastName }}</p>
                    <p class="client-info">{{ investigation?.ClientBranch?.Company?.name }}</p>
                    <p class="client-info">{{ investigation?.ClientBranch?.name }}</p>
                    <br>
                    <mat-card-title>
                      <h5>Bill To</h5>
                    </mat-card-title>
                    <ng-container *ngIf="!data?.invoice; else invoiceBillTo">
                      <p class="client-info">{{ investigation?.BillTo?.firstName }} {{ investigation?.BillTo?.lastName }}</p>
                      <p class="client-info" *ngIf="investigation?.BillToBranch?.Company?.name" [innerHTML]="investigation?.BillToBranch?.Company?.name"></p>
                      <p class="client-info" *ngIf="investigation?.BillToBranch?.BillingAddress; else billToAddress" [innerHTML]="investigation?.BillToBranch?.BillingAddress | addressFormat"></p>
                      <ng-template #billToAddress>
                        <p class="client-info" *ngIf="investigation?.BillTo?.BillingAddress" [innerHTML]="investigation?.BillTo?.BillingAddress | addressFormat"></p>
                      </ng-template>
                    </ng-container>
                    <ng-template #invoiceBillTo>
                      <p class="client-info">{{ data.invoice?.BillTo?.firstName }} {{ data.invoice?.BillTo?.lastName }}</p>
                      <p class="client-info" *ngIf="data?.invoice?.BillToBranch?.Company?.name" [innerHTML]="data?.invoice?.BillToBranch?.Company?.name"></p>
                      <p class="client-info" *ngIf="data?.invoice?.BillToBranch?.BillingAddress; else billToAddress" [innerHTML]="data?.invoice?.BillToBranch?.BillingAddress | addressFormat"></p>
                      <ng-template #billToAddress>
                        <p class="client-info" *ngIf="data?.invoice?.BillTo?.BillingAddress" [innerHTML]="data?.invoice?.BillTo?.BillingAddress | addressFormat"></p>
                      </ng-template>
                    </ng-template>
                </ng-container>

              </mat-card-content>
            </div>
          </div>
        </div>
      </div>

      <mat-divider [vertical]="true" class=""></mat-divider>

      <div class="column input-fields" *ngIf="investigation">
        <div class="actionWrapper">
          <div class="flexContainer">

            <!-- Client Info Definition -->
            <div class="equal">
              <mat-card-content>
                <!-- Special Billing Instructions -->
                <mat-card-title>
                  <h5>Special Billing Instructions:</h5>
                </mat-card-title>

                <div class="flexContainer row-subtitle">
                  <div class="flex-grow-1">
                    <p>{{ investigation?.Detail?.specialInstructions }}</p>
                  </div>
                </div>

                <!-- Evidence Billing -->
                <mat-card-title class="mt-3">
                  <h5>Evidence Billing:</h5>
                </mat-card-title>

                <div class="flexContainer row-subtitle">
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Bill Rate:
                    </mat-card-subtitle>
                    <p [innerHTML]="investigation?.EvidenceBilling?.rate"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Billing Schedule:
                    </mat-card-subtitle>
                    <p [innerHTML]="investigation?.EvidenceBilling?.BillingFrequency?.name"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Billing Date:
                    </mat-card-subtitle>
                    <p [innerHTML]="investigation?.EvidenceBilling?.startDate | customDate: 'MM-dd-yyyy'"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Billable:
                    </mat-card-subtitle>
                    <p [innerHTML]="investigation?.EvidenceBilling?.billable"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Notes:
                    </mat-card-subtitle>
                    <p [innerHTML]="investigation?.EvidenceBilling?.notes"></p>
                  </div>
                </div>

                <!-- Billing Rate Overides -->
                <mat-card-title class="mt-3">
                  <h5>Billing Rate Overides:</h5>
                </mat-card-title>

                <div class="flexContainer row-subtitle" *ngFor="let rule of investigation?.Company?.BillingRules">
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Category:
                    </mat-card-subtitle>
                    <p [innerHTML]="rule?.ExpenseItem?.id ? 'Expense' : 'Hours'"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Type:
                    </mat-card-subtitle>
                    <p [innerHTML]="rule?.ExpenseItem?.id ? rule?.ExpenseItem?.name : rule?.TimeEntryType?.name"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Rate:
                    </mat-card-subtitle>
                    <p> ${{ rule.value }} /hr</p>
                  </div>
                </div>

                <!-- Flat Rate for Standard Investigations -->
                <mat-card-title  class="mt-3">
                  <h5>Flat Rate for Standard Investigations:</h5>
                </mat-card-title>

                <div class="flexContainer row-subtitle" *ngFor="let rate of investigation?.Company?.FlatRates">
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Risk Type:
                    </mat-card-subtitle>
                    <p [innerHtml]="rate.Risk.name"></p>
                  </div>
                  <div class="flex-grow-1">
                    <mat-card-subtitle>
                      Rate:
                    </mat-card-subtitle>
                    <p> ${{ rate.value }} </p>
                  </div>
                </div>

                <!-- Budget Specified -->
                <div class="flexContainer row-subtitle mt-3">
                  <div class="flex-grow-2">
                    <mat-card-title>
                      <h5>Budget Specified:</h5>
                    </mat-card-title>
                    <p> {{ investigation?.Detail?.budgetAmount | currency }}</p>
                  </div>
                  <div class="flex-grow-2 ml-3">
                    <mat-card-title>
                      <h5>Investigation Billing Instruction:</h5>
                    </mat-card-title>
                    <p> {{ investigation.billingInstructions }} </p>
                    <p> {{ investigation?.Detail?.billingInstructionNotes }} </p>
                  </div>
                </div>
              </mat-card-content>
            </div>
          </div>
        </div>
      </div>

    </div>
  </mat-card>

  <br>

  <ng-container *ngIf="!data?.disableEdit && !this.data.invoice">
    <button mat-raised-button class="mb-1" [disabled]="selectedLines.length < 2 || !sameType" color="primary" (click)="combine()">Combine Selected</button>
    <button mat-flat-button class="mb-1 ml-1" [disabled]="!selectedLines.length" color="warn" (click)="deleteLines()">Delete Selected</button>
    <!-- <button mat-flat-button class="mb-1" color="" (click)="newLine()">New Line</button> -->
  </ng-container>

  <!-- Table Qb Billable -->
  <div class="flexContainer">
    <table mat-table #dataTable [dataSource]="invoiceLines" cdkDropList (cdkDropListDropped)="drop($event)">

      <!-- Checkbox Definition -->
      <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let line">
          <button mat-icon-button><mat-icon>drag_handle</mat-icon></button>
          <mat-checkbox [(ngModel)]="line.selected"></mat-checkbox>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- Date Definition -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>DATE</mat-header-cell>
        <mat-cell *matCellDef="let line">
          <ng-container *ngIf="!data.disableEdit; else viewOnly_date;">
            <app-datepicker [(ngModel)]="line.createdAt" name="expenseDate" [label]="''"></app-datepicker>
          </ng-container>
          <ng-template #viewOnly_date><span *ngIf="line.createdAt" [innerHtml]="line.createdAt | customDate: 'MM/dd/yyyy'"></span></ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- Staff Definition -->
      <ng-container matColumnDef="staff">
        <mat-header-cell *matHeaderCellDef >STAFF</mat-header-cell>
        <mat-cell *matCellDef="let line" >
          {{line.staffNames}}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- Description Definition -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef >DESCRIPTION</mat-header-cell>
        <mat-cell *matCellDef="let line" >
          <mat-form-field *ngIf="!data?.disableEdit; else viewOnly_desc;">
            <textarea matInput [(ngModel)]="line.description" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" maxlength="4000"></textarea>
            <mat-hint align="end" [ngClass]="{'text-danger': line.description.length === 4000 }" *ngIf="line.description.length >= 3000" >{{line.description.length}} / 4000</mat-hint>
          </mat-form-field>
          <ng-template #viewOnly_desc>{{line.description}}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>


      <!-- Qty Definition -->
      <ng-container matColumnDef="qty">
        <mat-header-cell *matHeaderCellDef>QTY</mat-header-cell>
        <mat-cell *matCellDef="let line">
          <input *ngIf="!data?.disableEdit; else viewOnly_qty;" type="number" [(ngModel)]="line.quantity" min=0 />
          <ng-template #viewOnly_qty>{{line.quantity}}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>TYPE</mat-header-cell>
        <mat-cell *matCellDef="let line">
          <ng-container *ngIf="!data?.disableEdit; else viewOnly_type;">
            <mat-select [(ngModel)]="line.ExpenseType.name" name="selectedExpenseType">
              <mat-option value="{{ formExpenseType.MILEAGE }}">Mileage</mat-option>
              <mat-option value="{{ formExpenseType.EXPENSE }}">Expense</mat-option>
              <mat-option value="{{ formExpenseType.HOURS }}">Hours</mat-option>
            </mat-select>
          </ng-container>
          <ng-template #viewOnly_type>{{line.ExpenseType?.name || line.expenseType}}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="qbAccount">
        <mat-header-cell *matHeaderCellDef>QB ACCT</mat-header-cell>
        <mat-cell *matCellDef="let line">
          <mat-select *ngIf="!data?.disableEdit; else viewOnly_qbAccount;" [(ngModel)]="line.qbAccount">
            <mat-option *ngFor="let acct of qbAccounts" [value]="acct">{{ acct }}</mat-option>
          </mat-select>
          <ng-template #viewOnly_qbAccount>{{line.qbAccount}}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- Rate Definition -->
      <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef>RATE</mat-header-cell>
        <mat-cell *matCellDef="let line">
          <input *ngIf="!data?.disableEdit; else viewOnly_rate;"  type="number" [(ngModel)]="line.rate" />
          <ng-template #viewOnly_rate>{{line.rate}}</ng-template>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <!-- Total Definition -->
      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>TOTAL</mat-header-cell>
        <mat-cell *matCellDef="let line">
          {{line.quantity * line.rate | currency}}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{ total | currency }}</mat-footer-cell>
      </ng-container>

      <!-- Header and Row Declarations -->
      <mat-header-row *matHeaderRowDef="qbBillColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: qbBillColumns" cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="data.disableEdit"></mat-row>
      <div *cdkDragPlaceholder></div>
      <mat-footer-row *matFooterRowDef="qbBillColumns"></mat-footer-row>
    </table>
  </div>
  <textarea *ngIf="!data?.disableEdit; else viewOnly_details;" name="details" class="invoice-detail" matInput [(ngModel)]="details" placeholder="Invoice detail / instructions ..."></textarea>
  <ng-template #viewOnly_details>
    <h5>Invoice Detail / Instructions</h5>
    <p>{{ data?.invoice?.details }}</p>
  </ng-template>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close color="primary">{{ !data?.disableEdit ? "Cancel" : "Close" }}</button>
  <button mat-raised-button color="accent" *ngIf="!data.disableEdit" [disabled]="!investigation?.BillTo?.firstName || !(total > 0)" (click)="send()">{{ investigation?.id ? "Send" : "Save" }}</button>
</mat-dialog-actions>