import { IApiAuthorizationResponse, IApiUser, IApiEffectivePermissionCategory } from './../../modules/graphql/types/types';
import { IGqlPermissionSet } from '../../modules/graphql/services/authorization/authorization.interfaces';
import { switchMap, map, filter, mergeMap, tap, startWith, take, catchError, mapTo,  } from 'rxjs/operators';
import { ReplaySubject, Observable, MonoTypeOperatorFunction, pipe, throwError, merge, of, fromEvent } from 'rxjs';
import { Injectable } from '@angular/core';
import { GqlAuthorizationService } from "../../modules/graphql/services/authorization/authorization.service";
import { PermissionActions, PermissionCategories, PermissionScopes } from "../../modules/graphql/enums/permissions.enums";
import { AssignedDelegate } from "../../types/permissions.types";
import { hasCategoryPermission } from "../../rxjs.pipes";

import { AuthService as Auth } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { LocalStorageService } from '../portal-services';
import { NotificationsService } from '../../modules/notifications/notifications.service';

@Injectable()
export class AuthService {
  public online$: Observable<boolean>;
  private _authorization$ = new ReplaySubject<IApiAuthorizationResponse>(1);

  public get authenticatedUser(): Observable<IApiUser> {
    return this._authorization$.pipe(
      map(r => r.User)
    );
  }

  public isPermissionRoles(role: string[]): Observable<boolean> {
    return this._authorization$.pipe(
      map(r => r.User?.PermissionRoles?.some(item => role.includes(item?.name)))
    );
  }

  constructor(
    private auth: GqlAuthorizationService,
    private auth0: Auth,
    private router: Router,
    private local: LocalStorageService,
    private notifications: NotificationsService
  ) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
      );
    // only do this once when the singleton is loaded
    this.load().pipe().subscribe(r => this._authorization$.next(Object.freeze(r)));
  }

  public load(): Observable<IApiAuthorizationResponse | any> {
    return this.auth0.idTokenClaims$.pipe(
      mergeMap(authed => {
        return this._authorization$.pipe(
          startWith(false),
          take(1),
          // check to see if auth status has changed after emitting
          tap(resp => {
            if (resp && !authed) this._authorization$.next(null);
          }),
          map(() => authed)
        );
      }),
      filter(authed => !!authed),
      switchMap((res) => {
        const intakeForm = this.local.getValueFromSession('clientemail');
        const formemail = this.local.getValueFromSession('formemail');
        if (intakeForm || ((this.router?.url === '/portal/login' || this.router?.url === '/portal/intake') && formemail)) {
          this.local.removeValueFromSession('clientemail');
          if (intakeForm) {
            this.local.setValueFromSession('formemail', intakeForm);
          }
          if (res.email !== intakeForm) {
            this.local.removePortalEmail();
            this.notifications.error("Miss-match credential, please try again");
            this.auth0.logout({
              federated: true,
              returnTo: window.origin + '/portal/login',
            });
          } else {
            this.router.navigate(['portal/intake']);
          }
        } else {
          return this.auth.Authorization.pipe(map(res1 => {
            return res1;
          }), catchError(userdetailserror => {
            if (res.email) {
              this.local.setValueFromSession('formemail', res.email);
              this.router.navigate(['portal/intake']);
            } else {
              this.auth0.logout({
                federated: true,
                returnTo: window.origin + '/portal/login',
              });
              return throwError(userdetailserror);
            }
            // this.router.navigate(['portal/error']);
          }));
        }
        // return this.auth.Authorization;
      }),
      take(1)
    );
  }

  public logout(): void {
    // NOTE: This will redirect the app to Auth0 and then back again
    this.auth0.logout({
      federated: true,
      returnTo: window.origin,
    });
  }

  public get assignedCategories(): Observable<IApiEffectivePermissionCategory[]> {
    return this._authorization$.pipe(
      map(({ EffectivePermissions }) => EffectivePermissions)
    );
  }

  public allByCategory(category: PermissionCategories): Observable<IApiEffectivePermissionCategory[]> {
    return this.assignedCategories.pipe(
      map(responses => {
        return responses.filter(r => r.name === category);
      })
    );
  }

  public hasCategoryPermission(
    category: PermissionCategories,
    actions: IGqlPermissionSet,
    assignedDelegate?: AssignedDelegate
  ): Observable<boolean> {
    return this.allByCategory(category).pipe(
      hasCategoryPermission(category, actions, assignedDelegate),
      take(1)
    );
  }

  public async checkPermission(scope: PermissionScopes, action: PermissionActions, category: PermissionCategories) {
    return await this.hasCategoryPermission(category, {
      [scope]: [action]
    }).toPromise();
  }

  public loadPipe(): MonoTypeOperatorFunction<any> {
    return pipe(
      switchMap((response) => this.load().pipe(
        map((r) => {this._authorization$.next(Object.freeze(r)); return response})
      ))
    );
  }

}
