<!-- Permissions -->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDownload" #evidenceDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceView" #evidenceView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdate" #evidenceUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdateOwn" #evidenceUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDelete" #evidenceDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferCreate" #evidenceTransferCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferUpdate" #evidenceTransferUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section *ngIf="evidence?.enabled">
<div class="row align-items-end">
  <div class="col-auto">
    <h4 class="page-title">Evidence</h4>
  </div>
  <div class="col-auto ml-auto">
    <mat-form-field class="search" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput placeholder="Search by description" [(ngModel)]="searchValue" name="search" (appDebouncedInputChange)="onSearchChange()" />
      <button mat-icon-button disableRipple matSuffix (click)="searchValue = null; onSearchChange()">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="col-auto pb-4">
    <button mat-raised-button class="rounded-button ml-3" *ngIf="evidenceDownload?.enabled" (click)="downloadEvidenceItems()">
      <mat-icon color="accent">save_alt</mat-icon>
      Download
    </button>
  </div>
</div>
<div class="content-wrapper">
  <div class="page-filters">
    <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">

      <kendo-gridlayout-item>
        <app-state-select-kendo [label]="'State'" [placeholder]="'Select state'"
          [selectedId]="filterValue(filterTypes.State)"
          (selectionChanged)="setFilters($event, filterTypes.State)">
        </app-state-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon multi">
          <kendo-label text="Location"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-multiselect
                [checkboxes]="true"
                [autoClose]="false"
                [valuePrimitive]="true"
                [data]="evidenceStorageLocations"
                [value]="filterByIdValue(filterTypes.Locations)"
                [rounded]="'full'"
                (valueChange)="setVal($event, filterTypes.Locations)"
                valueField="id"
                textField="name"
                [tagMapper]="tagMapper"
                [placeholder]="'Select location'"
                [fillMode]="'outline'"
                [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  <span style="padding-left: 5px;">{{ dataItem?.name }}</span>
                </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <div class="kendo-formfield left-search-icon multi">
          <kendo-label text="Status"></kendo-label>
          <div style="position: relative;">
            <i class="fa fa-search" aria-hidden="true"></i>
            <kendo-multiselect
                [checkboxes]="true"
                [autoClose]="false"
                [valuePrimitive]="true"
                [data]="evidenceStatus"
                [value]="filterByIdValue(filterTypes.Statuses)"
                [rounded]="'full'"
                (valueChange)="setVal($event, filterTypes.Statuses)"
                valueField="value"
                textField="text"
                [tagMapper]="tagMapper"
                [placeholder]="'Select status'"
                [fillMode]="'outline'"
                [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                  <span style="padding-left: 5px;">{{ dataItem?.text }}</span>
                </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [colSpan]="2">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
          <kendo-gridlayout-item class="mb-0">
            <div class="kendo-formfield">
              <kendo-label text="Arrival Start Date"></kendo-label>
              <kendo-datepicker
                  id="startDate"
                  name="startDate"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="Start arrival date"
                  format="MM/dd/yyyy"
                  [(ngModel)]="startDateFilter"
                  (valueChange)="setDateFilter()"
                  formatPlaceholder="formatPattern"
                  #startDate="ngModel"
              ></kendo-datepicker>
            </div>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item class="mb-0">
            <div class="kendo-formfield">
              <kendo-label text="Arrival End Date"></kendo-label>
              <kendo-datepicker
                  id="endDate"
                  name="endDate"
                  calendarType="classic"
                  [fillMode]="'outline'"
                  placeholder="End arrival date"
                  format="MM/dd/yyyy"
                  [(ngModel)]="endDateFilter"
                  (valueChange)="setDateFilter()"
                  formatPlaceholder="formatPattern"
                  #endDate="ngModel"
              ></kendo-datepicker>
            </div>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-investigation-multi-select-kendo
          label="NEFCO #"
          placeholder="Select NEFCO #"
          [selectedIds]="filterByIdValue(filterTypes.InvestigationIds)"
          (selectionChanged)="setVal($event, filterTypes.InvestigationIds)"
        ></app-investigation-multi-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-uenumber-multi-select-kendo
          label="UE #"
          placeholder="Select UE #"
          [selectedId]="filterValue(filterTypes.Ids)"
          (selectionChanged)="setFilters($event, filterTypes.Ids)"
        ></app-uenumber-multi-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-company-select-kendo
          label="Company"
          placeholder="Select company"
          [selectedId]="filterByIdValue(filterTypes.CompanyIds)"
          (selectionChanged)="setFilters($event, filterTypes.CompanyIds)"
        ></app-company-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
        <app-user-multi-select-kendo
          label="Investigator"
          [userViewFilter]="userViewFilter"
          placeholder="Select investigator"
          [selectedId]="filterValue(filterTypes.InvestigatorIds)"
          [investigationRole]="userRoleName.Investigator"
          [includeInactive]="true"
          (selectionChanged)="setFilters($event, filterTypes.InvestigatorIds)"
        ></app-user-multi-select-kendo>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item [colSpan]="4" style="position: relative; display: flex; align-items: center;">
        <div>
          <ng-container  *ngIf="evidenceTransferCreate?.enabled
          || evidenceDownload?.enabled
          || evidenceDelete?.enabled
          || evidenceUpdate?.enabled
          || evidenceUpdateOwn?.enabled">
          <ng-template #selectItems>
            <button mat-raised-button color="primary" (click)="pleaseSelect()">
              Actions
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </ng-template>
          <button mat-raised-button color="primary" [matMenuTriggerFor]="actionMenu" *ngIf="selectedEvidence.selected.length > 0; else selectItems">
            Actions
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="downloadDisposalLetter()" *ngIf="evidenceDownload?.enabled">Download Disposal Letter</button>
            <ng-container>
              <mat-divider *ngIf="evidenceDownload?.enabled"></mat-divider>
              <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.DisposalRequested)" *ngIf="(evidenceUpdate?.enabled || evidenceUpdateOwn?.enabled)">Move to Disposal Requested</button>
              <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.AwaitingDisposal)" *ngIf="(evidenceUpdate?.enabled || evidenceUpdateOwn?.enabled)">Move to Awaiting Disposal</button>
              <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.Disposed)" *ngIf="!disposedFilter && (evidenceUpdate?.enabled || evidenceUpdateOwn?.enabled)">Move to Disposed</button>
              <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.AwaitingDisposal)" *ngIf="disposedFilter && (evidenceUpdate?.enabled || evidenceUpdateOwn?.enabled)">Undispose</button>
              <button mat-menu-item (click)="setSelectedStatus(evidenceStatusType.Destroyed)" *ngIf="(evidenceUpdate?.enabled || evidenceUpdateOwn?.enabled)">Move to Destroyed</button>
              <button mat-menu-item (click)="openTransferModal()" *ngIf="evidenceTransferCreate?.enabled">Transfer</button>
              <button mat-menu-item (click)="deleteItems()" *ngIf="evidenceDelete?.enabled">Delete</button>
            </ng-container>
          </mat-menu>
          </ng-container>
        </div>
        <div style="display: flex;">
          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="ml-24 mr-16"
                #isDisposed
                kendoCheckBox
                [(ngModel)]="isDisposedChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.Disposed)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isDisposed"
                text="Is Disposed"
              ></kendo-label>
            </div>
          </div>

          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="mr-16"
                #isDistroyed
                kendoCheckBox
                [(ngModel)]="isDestroyedChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.Destroyed)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isDistroyed"
                text="Is Destroyed"
              ></kendo-label>
            </div>
          </div>

          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="mr-16"
                #isBillable
                kendoCheckBox
                [(ngModel)]="isIsBillableChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.IsBillable)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isBillable"
                text="Is Billable"
              ></kendo-label>
            </div>
          </div>

          <div class="kendo-formfield checkbox-field">
            <kendo-label text=""></kendo-label>
            <div>
              <input type="checkbox"
                class="mr-16"
                #isNonBillable
                kendoCheckBox
                [(ngModel)]="isIsNonBillableChecked"
                (change)="onCheckboxChange($event?.target?.checked, filterTypes.IsNonBillable)"/>
              <kendo-label
                class="k-checkbox-filter-label mr-24"
                [for]="isNonBillable"
                text="Is Non-Billable"
              ></kendo-label>
            </div>
          </div>
        </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="filter-actions">
        <div class="mr-16" style="display: flex; align-items: center;">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium" (click)="applyFilters()">Apply
              Filters</kendo-button>
          </div>
        </div>
        <div class="mt-0" style="display: flex; align-items: center;">
          <kendo-label text=""></kendo-label>
          <div>
            <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium" (click)="clearAll()">Clear
              All</kendo-button>
          </div>
        </div>
      </kendo-gridlayout-item>

    </kendo-gridlayout>
  </div>
  <!-- Table -->
  <div class="row mt-8">
    <div class="col-12">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="pageOptions.sort($event)">
        <ng-container matColumnDef="nefcoNumber">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.Investigation">Nefco #</th>
          <td mat-cell *matCellDef="let item">
            <a [routerLink]="['/investigations/', item?.Investigation?.id]" [queryParams]="{tab: 'evidence'}" target="_blank">
              {{item?.Investigation?.nefcoNumber ? item?.Investigation?.nefcoNumber : 'Unknown'}}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="ueNumber">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.EvidenceNumber">UE #</th>
          <td mat-cell *matCellDef="let item">
            {{ item?.ueNumber }}
          </td>
        </ng-container>
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="selectEvidence($event, null, true)" [checked]="(selectedEvidence.selected.length === evidenceVisible.length) && evidenceVisible.length > 0">
              Evidence #
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let item">
              <mat-checkbox (change)="selectEvidence($event, item, false)" [checked]="selectedEvidence.isSelected(item)" [disabled]="!(evidenceUpdate?.enabled || (isOwn(item?.CreatedBy?.id) && evidenceUpdateOwn?.enabled))">
                {{ (item?.evidenceNumber) }}
              </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="w-25">Description</th>
          <td mat-cell *matCellDef="let item" [innerHTML]="item.description"></td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Location</th>
          <td mat-cell *matCellDef="let item" [innerHtml]="item?.EvidenceStorageLocation?.name"></td>
        </ng-container>
        <ng-container matColumnDef="section">
          <th mat-header-cell *matHeaderCellDef>Section</th>
          <td mat-cell *matCellDef="let item" [innerHtml]="item?.section"></td>
        </ng-container>
        <ng-container matColumnDef="row">
          <th mat-header-cell *matHeaderCellDef>Row</th>
          <td mat-cell *matCellDef="let item" [innerHtml]="item?.row"></td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let item" [innerHtml]="item.Status"></td>
        </ng-container>
        <ng-container matColumnDef="arrivalDate">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="orderByOptions.CreatedAt" start="desc">Arrived</th>
          <td mat-cell *matCellDef="let item" [innerHtml]="item.createdAt | customDate: 'MM-dd-yyyy'"></td>
        </ng-container>
        <ng-container matColumnDef="transferDate">
          <th mat-header-cell *matHeaderCellDef>Transferred</th>
          <td mat-cell *matCellDef="let item">{{ (item?.Transfers) ? (item?.Transfers[item?.Transfers.length-1]?.receivedDate | customDate: 'MM-dd-yyyy' ) : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let item">
            <button [hidden]="!item?.CurrentTransfer" mat-icon-button (click)="receiveItem(item)" *ngIf="evidenceTransferUpdate?.enabled">
              <mat-icon color="accent">next_plan</mat-icon>
            </button>
            <button mat-icon-button (click)="openSidebar(item)" *ngIf="evidenceView?.enabled">
              <mat-icon color="accent">open_in_new</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="text-center mat-cell" [attr.colspan]="11">
            There is no result to display.
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [length]="pageOptions?.totalCount" [pageSize]="pageOptions?.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageOptions?.paginate($event)" (click)="resetSelect()"></mat-paginator>
    </div>
  </div>
</div>
</section>
