<section class="mat-typography">
  <!-- Modal Header -->
  <div mat-dialog-title class="header">
    <h2 class="text-center">{{ data?.id ? "Edit" : "Add" }} Document Type</h2>
  </div>
  <div class="flexContainer">
    <form #form="ngForm" (submit)="onSubmit()">
      <div class="equal">
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Document Type</mat-label>
              <input appNoWhiteSpace matInput name="model" type="text" [(ngModel)]="model.name" #name="ngModel"  (ngModelChange)="updateDefaultTitle($event)" required  maxlength="255"/>
              <mat-error *ngIf="!name?.valid && (name?.dirty || name?.touched || model?.name?.length)" >Document Type cannot be left blank</mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Default Title</mat-label>
              <input appNoWhiteSpace matInput name="defaultTitle" type="text" [(ngModel)]="model.defaultTitle" #defaultTitle="ngModel" required />
              <mat-error *ngIf="!defaultTitle?.valid && (defaultTitle?.dirty || defaultTitle?.touched || model?.defaultTitle?.length)" >Default Title cannot be left blank</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Category</mat-label>
              <mat-select name="category" [(ngModel)]="this.model.CategoryId" required>
                <mat-option *ngFor="let cat of documentCategories" [value]="cat.id">
                  {{ cat.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Map to Service:</mat-label>
              <mat-select name="subservicetype" [(ngModel)]="this.model.SubServiceTypeId">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let type of subServiceTypes" [value]="type.id" [innerHTML]="type.name"></mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>o365 Template:</mat-label>
              <mat-select name="template" [(ngModel)]="this.model.TemplateId">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let template of documentTemplates" [value]="template.id" [innerHTML]="template.name"></mat-option>
                <mat-option value="other">Other</mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="this.model.TemplateId === 'other'">
              <mat-form-field class="w-100">
                <mat-label>Template Name</mat-label>
                <input matInput name="templateName" type="text" [(ngModel)]="this.model.templateName" required />
              </mat-form-field>
              <mat-form-field class="w-100">
                <mat-label>Template OneDrive Id</mat-label>
                <input matInput name="templateOneDriveId" type="text" [(ngModel)]="this.model.templateId" required />
              </mat-form-field>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>Comments</mat-label>
              <textarea matInput rows="10" name="comments" [(ngModel)]="model.notes"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="d-flex flex-row-reverse">
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid">{{ data?.id ? "Save" : "Insert" }}</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</section>