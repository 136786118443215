<section>
  <h4 class="page-title">Report Sent Metric</h4>
  <kendo-card width="100%">
    <div class="content-wrapper">
      <kendo-gridlayout
        [rows]="['auto']"
        [gap]="{ rows: 3, cols: 12 }"
        [cols]="['repeat(7,minmax(0,1fr))']"
      >
        <kendo-gridlayout-item [colSpan]="2" [col]="0" [row]="0">
          <div class="kendo-formfield date-range-wrapper">
            <kendo-daterange #dateRangePicker style="gap: 0;">
              <kendo-label text="Sent Date (Start)" class="mr-16">
                <kendo-dateinput
                  #startDateRange
                  calendarType="classic"
                  class="mr-16 dateinput"
                  placeholder="Start Date"
                  [fillMode]="'outline'"
                  kendoDateRangeStartInput
                  [(ngModel)]="range.startDate"
                  (ngModelChange)="updateDateFilters($event)"
                  (click)="focusDateInput(true)" 
                ></kendo-dateinput>
              </kendo-label>
              <kendo-label text="Sent Date (End)">
                <kendo-dateinput
                  #endDateRange
                  calendarType="classic"
                  class="mr-0 dateinput"
                  placeholder="End Date"
                  [fillMode]="'outline'"
                  kendoDateRangeEndInput
                  [(ngModel)]="range.endDate"
                  (ngModelChange)="updateDateFilters($event)"
                  (click)="focusDateInput(false)"
                ></kendo-dateinput>
              </kendo-label>
              <kendo-daterange-popup
                #dateRangePickerPopup
                autoCorrectOn="blur"
              ></kendo-daterange-popup>
            </kendo-daterange>
          </div>
        </kendo-gridlayout-item>
        
        <kendo-gridlayout-item [colSpan]="2">
          <app-user-multi-select-kendo
            label="Editor"
            [userViewFilter]="userViewFilter"
            placeholder="Select editors"
            [selectedId]="filterValue(filterTypes.Editors)"
            [investigationRole]="userRoleName.Editor"
            (selectionChanged)="setFilters($event, filterTypes.Editors)"
          ></app-user-multi-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <app-investigation-multi-select-kendo
            label="Investigation ID"
            placeholder="Search by nefco number"
            [selectedIds]="filterValue(filterTypes.Investigations)"
            (selectionChanged)="setFilters($event, filterTypes.Investigations)"
          ></app-investigation-multi-select-kendo>
        </kendo-gridlayout-item>
       
        <kendo-gridlayout-item [colSpan]="1">
          <div class="filter-actions">
            <div class="mr-16 applied-filter">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button
                  kendoButton
                  themeColor="primary"
                  fillMode="solid"
                  size="medium"
                  (click)="applyFilter()"
                  >Apply Filters</kendo-button
                >
              </div>
            </div>
            <div class="mt-5 clear-filter">
              <kendo-label text=""></kendo-label>
              <div>
                <kendo-button
                  kendoButton
                  themeColor="primary"
                  fillMode="link"
                  size="medium"
                  (click)="clearFilter()"
                  >Clear All</kendo-button
                >
              </div>
            </div>
          </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <app-company-select-kendo
            label="Client (Company)"
            placeholder="Search Company"
            [selectedId]="filterByIdValue(filterTypes.ClientCompany)"
            (selectionChanged)="setFilters($event, filterTypes.ClientCompany)"
          ></app-company-select-kendo>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [colSpan]="2">
          <app-contact-multi-select-kendo
            placeholder="Search contact"
            label="Client (Contact)"
            [selectedId]="filterValue(filterTypes.ClientContact)"
            (selectionChanged)="setFilters($event, filterTypes.ClientContact)"
          >
          </app-contact-multi-select-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>

      <kendo-grid
        class="quarterly-list td-20-16"
        [data]="{
          data: data,
          total: (dataSource?.pageInfo$ | async)?.totalCount
        }"
        [pageSize]="pageOptions?.limit"
        [skip]="pageOptions?.offset"
        [pageable]="
          pageOptions
            ? {
                buttonCount: 6,
                type: 'numeric',
                pageSizes: [5, 10, 25, 100],
                previousNext: true,
                responsive: true,
                position: 'bottom'
              }
            : false
        "
        (pageChange)="pageChange($event)"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [navigatable]="false"
        [resizable]="true"
        [scrollable]="true"
      >
        <kendo-grid-column
          field="SEND_DATE"
          title="Date"
          [width]="10"
          [sortable]="false"
          [resizable]="false"
          [headerClass]="'no-sort'"
        >
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            let-rowIndex="rowIndex"
          >
            <span class="font-weight-600">
              {{ dataItem?.sendDate | customDate : "MM/dd/yyyy" }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="COUNT"
          title="Count"
          [width]="90"
          [sortable]="false"
          [resizable]="false"
          [headerClass]="'no-sort'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="phone-number">
              {{ dataItem?.count }}
            </span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </kendo-card>
</section>
