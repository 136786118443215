<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staffContactInformationUpdate" #staffContactInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationUpdate" #staffUserInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationUpdate" #staffEmergencyInformationUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffWorkingHoursUpdate" #staffWorkingHoursUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnUpdate" #staffContactInformationOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnUpdate" #staffEmergencyInformationOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnUpdate" #staffUserInformationOwnUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffWorkingHoursOwnUpdate" #staffWorkingHoursOwnUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<mat-dialog-content class="p-4">

  <!-- CONTACT INFO -->
  <form #userForm="ngForm">
    <!-- --------------------------------------------------- -->
    <!-- Contact Information -->
    <!-- --------------------------------------------------- -->
    <mat-card
      *ngIf="staffContactInformationUpdate?.enabled || (staffContactInformationOwnUpdate?.enabled && isOwnerUser)">
      <div class="row">
        <div class="col-12">
          <h4>Contact Information</h4>
        </div>
      </div>
      <!-- Name Information -->
      <div class="row">
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput name="title" [(ngModel)]="user.title" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput required name="firstName" [(ngModel)]="user.firstName" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Middle Name</mat-label>
            <input matInput name="middleName" [(ngModel)]="user.middleName" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput required name="lastName" [(ngModel)]="user.lastName" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Suffix</mat-label>
            <input matInput name="suffix" [(ngModel)]="user.suffix" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>Nickname</mat-label>
            <input matInput name="nickname" [(ngModel)]="user.nickname" />
          </mat-form-field>
        </div>
      </div>
      <!-- MS Graph and Twilio Phone -->
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Microsoft O365 ID</mat-label>
            <input name="msGraphId" matInput [(ngModel)]="user.msGraphId">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>SMS/Voice Phone Number</mat-label>
            <span matPrefix>+1&nbsp;</span>
            <input name="twilioPhoneNumber" type="tel" maxlength="10" matInput pattern="^\d{10}$" ngModel
              [(ngModel)]="user.twilioPhoneNumber" required />
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field>
            <mat-label>Application E-Mail</mat-label>
            <input name="email" matInput required [(ngModel)]="user.email">
            <mat-hint>
              <span class="d-block" *ngIf="user.id">PLEASE NOTE: changing this e-mail will require the user to re-verify
                their e-mail to log into this application.</span>
              <span>This must be unique, no emails can be repeated between users for this entry.</span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4">
          <mat-form-field>
            <mat-label>Timezone</mat-label>
              <mat-select name="timezone" [(ngModel)]="user.timezone">
                <mat-option *ngFor="let timezone of timeZones" [value]="timezone.value">{{ timezone.key }}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3" *ngIf="!user.id">
          <mat-form-field>
            <mat-label>Temporary Password</mat-label>
            <input name="password" matInput required
              pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,22}$" [(ngModel)]="password">
            <mat-hint>Password needs to be at least 10 characters long, include (at least) one special character
              (@$!%*#?&) and one number.mat-icon</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-3" *ngIf="!user.id">
          <mat-form-field>
            <mat-label>Confirm Temporary Password</mat-label>
            <input name="password2" matInput required [(ngModel)]="verifyPassword">
            <mat-error color="warn" *ngIf="!passwordMatch">Passwords must match</mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Position and Assigned Investigators -->
      <div class="row">
        <div class="col-md-12">
          <!-- <h5 class="font-weight-bold mt-3 mb-1">Positions</h5> -->
        </div>
        <!-- <div class="col-md-6">
          <mat-form-field>
            <mat-label>Positions</mat-label>
            <mat-select multiple [(value)]="selectedRoles" required>
              <mat-option *ngFor="let role of roles" [value]="role.id">{{role.title}}</mat-option>
            </mat-select>
          </mat-form-field>
          <p *ngFor="let selectedRole of selectedRoles" [innerHtml]="selectedTitle(selectedRole, roles)"></p>
        </div> -->
      </div>
      <!-- Phones and Emails -->
      <div class="row">
        <div class="col-6">
          <!-- <h5 class="font-weight-bold mt-3 mb-1">Phones</h5> -->
          <div class="row mb-3" *ngFor="let phone of user.Phones; let i = index;">
            <div class="col">
              <app-phone [(ngModel)]="user.Phones[i]" [name]="'phone' + i"
                (isPrimary)="isPrimaryToggle($event, i)"></app-phone>
            </div>
            <div class="col-md-auto d-flex align-items-center">
              <button mat-icon-button (click)="removePhone(i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
          <button mat-flat-button (click)="addPhone()" class="rounded-button adder">
            <mat-icon color="accent">add</mat-icon>
            add phone
          </button>
        </div>
        <div class="col-6">
          <!-- <h5 class="font-weight-bold mt-3 mb-1">Emails</h5> -->
          <div class="row mb-3" *ngFor="let email of user.Emails; let i = index;">
            <div class="col">
              <app-email [(ngModel)]="user.Emails[i]" [name]="'email' + i"></app-email>
            </div>
            <div class="col-md-auto d-flex align-items-center">
              <button mat-icon-button (click)="removeEmail(i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
          <button mat-flat-button (click)="addEmail()" class="rounded-button adder">
            <mat-icon color="accent">add</mat-icon>
            add email
          </button>
        </div>
      </div>
      <!-- Addresses -->
      <div class="row">
        <div class="col-12">
          <!-- <h5 class="font-weight-bold mt-3 mb-1">Addresses</h5> -->
        </div>
        <div class="col-6" *ngFor="let address of user.Addresses; let i = index; trackBy: trackByIndex">
          <div class="row mb-3">
            <div class="col">
              <mat-label>Address</mat-label>
              <app-address-autocomplete *ngIf="!!mapsLoaded" [address]="user.Addresses[i]"
                (setAddress)="setAddress($event, i)" [loadAPI]="false" [showType]="true"
                defaultType="Home"></app-address-autocomplete>
            </div>
            <div class="col-md-auto d-flex align-items-center">
              <button mat-icon-button (click)="removeAddress(i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <button mat-flat-button (click)="addAddress()" class="rounded-button adder">
            <mat-icon color="accent">add</mat-icon>
            add address
          </button>
        </div>
      </div>
      <!-- Additional Information -->
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Additional Information</mat-label>
            <textarea matInput name="additionalInformation"
              [(ngModel)]="user.UserDetail.additionalInformation"></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <!-- --------------------------------------------------- -->
    <!-- Emergency Contact Information -->
    <!-- --------------------------------------------------- -->
    <mat-card
      *ngIf="staffEmergencyInformationUpdate?.enabled || (staffEmergencyInformationOwnUpdate?.enabled && isOwnerUser)">
      <div class="row">
        <div class="col-12">
          <h4>Emergency Contact Information</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput name="emergencyContactName" [(ngModel)]="user.UserDetail.emergencyContactName" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput name="emergencyContactInfo" [(ngModel)]="user.UserDetail.emergencyContactInfo" />
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <!-- --------------------------------------------------- -->
    <!-- User Information -->
    <!-- --------------------------------------------------- -->
    <mat-card *ngIf="staffUserInformationUpdate?.enabled || (staffUserInformationOwnUpdate?.enabled && isOwnerUser)">
      <div class="row">
        <div class="col-12">
          <h4>User Information</h4>
        </div>
      </div>
      <div class="row">
        <!-- Start/End Date, Pay Rate, Hours -->
        <div class="col-4">
          <div class="row">
            <div class="col-6">
              <app-datepicker [(ngModel)]="user.UserDetail.startDate" name="startDate" [label]="'Start Date'"
                [required]="true"></app-datepicker>
            </div>
            <div class="col-6">
              <app-datepicker [(ngModel)]="user.UserDetail.endDate" name="endDate"
                [label]="'End Date'"></app-datepicker>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Pay Rate</mat-label>
                <span matPrefix class="font-weight-bold">$&nbsp;</span>
                <input matInput name="hourlyPayRate" type="number" [(ngModel)]="user.UserDetail.payRateHourly">
                <span matSuffix class="font-weight-bold">/hr</span>
              </mat-form-field>
            </div>
            <div class="col-6"
              *ngIf="staffWorkingHoursUpdate?.enabled || (staffWorkingHoursOwnUpdate?.enabled && isOwnerUser)">
              <mat-form-field>
                <mat-label>Required Hours</mat-label>
                <input matInput required name="requiredHours" type="number"
                  [(ngModel)]="user.UserDetail.requiredHoursQuarterly">
                <span matSuffix class="font-weight-bold">/quarter</span>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Permission Role</mat-label>
                <mat-select multiple [(ngModel)]="PermissionIds" required name="PermissionIds">
                  <mat-option *ngFor="let role of permRoles" [value]="role.id">{{role.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6" *ngIf="user.RegionalManagerId || user.Positions?.filter(regionalOrInvestigatorFilter).length > 0">
              <app-user-select [userViewFilter]="userViewFilter" label="Assign Regional Manager" placeholder="Search ..."
                [user]="regionalManager ? regionalManager : null" [staticUsers]="regionalManager ? [regionalManager] : []" 
                (selectionChanged)="this.regionalManagerId = $event?.id" [width]="'100%'" [excludeLoginUserId]="this.data?.userId"></app-user-select>
            </div>

            <div class="col-6" *ngIf="user.Positions?.filter(investigatorFilter).length > 0">
              <p class="mb-2">Assigned Staff For Tech Reviewer: {{ (assignedStaff?.length || user.AssignedStaff?.length) || 0 }} </p>
              <button mat-button (click)="assignStaff()">
                <mat-icon>add</mat-icon>
                assign staff
              </button>
            </div>

            <div class="col-6" *ngIf="user.Positions?.filter(regionalManagerFilter).length > 0">
              <p class="mb-2">Assigned Staff For Regional Manager: {{ (assignedRegionalStaff?.length || user.AssignedRegionalStaff?.length) || 0 }} </p>
              <button mat-button (click)="assignStaffToRegionalManager()">
                <mat-icon>add</mat-icon>
                assign staff
              </button>
            </div>

          </div>
        </div>
        <!-- Status & Vehicle Information -->
        <div class="col-4">
          <!-- Status -->
          <p class="font-weight-bold">Status</p>
          <mat-radio-group name="userStatus" [(ngModel)]="user.UserDetail.status">
            <mat-radio-button class="mr-3" value="ACTIVE">ACTIVE</mat-radio-button>
            <mat-radio-button class="mr-3" value="ON_LEAVE">ON LEAVE</mat-radio-button>
            <mat-radio-button class="mb-3" value="INACTIVE">INACTIVE</mat-radio-button>
          </mat-radio-group>
          <p class="font-weight-bold">Full Time</p>
          <mat-radio-group required name="isFulltime" [(ngModel)]="user.UserDetail.fullTime">
            <mat-radio-button class="mr-3" [value]="true">YES</mat-radio-button>
            <mat-radio-button class="mb-3" [value]="false">NO</mat-radio-button>
          </mat-radio-group>
          <div class="row">
            <div class="col-6">
              <mat-checkbox class="word-break mb-3" name="hasCreditCard"
                [(ngModel)]="user.UserDetail.hasNefcoCreditCard">NEFCO Credit Card</mat-checkbox>
            </div>
            <div class="col-6">
              <mat-checkbox class="mr-3 word-break" name="hasVehicle"
                [(ngModel)]="user.UserDetail.hasNefcoVehicle">NEFCO Vehicle</mat-checkbox>
            </div>
          </div>
          <!-- Driver Information -->
          <div class="row">
            <div class="col-7">
              <mat-form-field>
                <mat-label>Drivers License #</mat-label>
                <input matInput pattern="^[a-zA-Z0-9]*$" name="driversLicenseNumber"
                  [(ngModel)]="user.UserDetail.driversLicenseNumber">
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <mat-label>Licensed State</mat-label>
                <mat-select name="driversLicenseState" [(ngModel)]="user.UserDetail.driversLicenseState">
                  <mat-option *ngFor="let state of statesService.allStates" [value]="state.value">{{ state.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <mat-label>Registered State</mat-label>
                <mat-select name="vehicleRegistrationState" [(ngModel)]="user.UserDetail.vehicleRegistrationState">
                  <mat-option *ngFor="let state of statesService.allStates" [value]="state.value">{{ state.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-7">
              <mat-form-field>
                <mat-label>Insurance Company</mat-label>
                <input name="vehicleInsurance" matInput [(ngModel)]="user.UserDetail.vehicleInsuranceCompany">
              </mat-form-field>
            </div>
          </div>
          <!-- Vehicles -->
          <div class="row" *ngFor="let vehicle of user.Vehicles; let i = index;">
            <div class="col">
              <mat-label>Vehicle</mat-label>
              <div class="row">
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>Vehicle Make</mat-label>
                    <input matInput name="vehicleMake{{i}}" required [(ngModel)]="vehicle.make">
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>Vehicle Model</mat-label>
                    <input matInput name="vehicleModel{{i}}" required [(ngModel)]="vehicle.model">
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>Year</mat-label>
                    <input matInput pattern="^[0-9]*$" length="4" required name="vehicleYear{{i}}"
                      [(ngModel)]="vehicle.year">
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field>
                    <mat-label>VIN</mat-label>
                    <input matInput length="17" name="vehicleVin{{i}}" required [(ngModel)]="vehicle.vinNumber">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-md-auto d-flex align-items-center">
              <button mat-icon-button (click)="removeVehicle(i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </div>
          </div>
          <!-- Remove vehicle button -->
          <button mat-flat-button (click)="addVehicle()" class="rounded-button adder">
            <mat-icon color="accent">add</mat-icon>
            add vehicle
          </button>

        </div>
        <!-- Restrictions -->
        <div class="col-4">
          <mat-form-field>
            <mat-label>Restrictions</mat-label>
            <mat-select multiple [(value)]="selectedRestrictions">
              <mat-option *ngFor="let restriction of restrictions"
                [value]="restriction.id">{{restriction.title}}</mat-option>
            </mat-select>
          </mat-form-field>
          <p *ngFor="let selectedRestriction of selectedRestrictions"
            [innerHtml]="selectedTitle(selectedRestriction, restrictions)"></p>
        </div>
      </div>
    </mat-card>
    <!-- --------------------------------------------------- -->
    <!-- Save Button -->
    <!-- --------------------------------------------------- -->
    <div class="d-flex flex-row-reverse mt-4">
      <button mat-raised-button class="blue-action-button ml-3" (click)="onUserSubmit()"
        [disabled]="!validForm()">Save</button>
      <button mat-flat-button (click)="dialogRef.close()">Cancel</button>
    </div>
  </form>
</mat-dialog-content>
