<div [dialogRef]="dialog" (appClickOutside)="close()">
<kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
        <span>ID: {{dataItem?.Investigation?.nefcoNumber}}</span>
        <div class="modal-subtitle">
            Investigator: {{dataItem?.User?.lastName}} {{dataItem?.User?.firstName}}
        </div>
    </div>
</kendo-dialog-titlebar>

<kendo-card width="100%" class="mt-16 mb-0">
    <kendo-card-header style="padding: 12px 16px;">
        <h6 class="mb-0" style="color: rgba(1, 24, 98, 0.87);">Activity Log</h6>
    </kendo-card-header>
    <kendo-card-body class="p-0">
        <kendo-grid
        class="investigation-list"
        [data]="data"
        [sortable]="true"
        [sort]="sort"
        (sortChange)="sortChange($event)"
        [navigatable]="false">
    
        <kendo-grid-column [field]="orderBy.CreatedAt" title="Time" [width]="10" [sortable]="true" [headerClass]="'sort-header'" class="id-column font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem?.createdAt | customDate: 'MM/dd/yyyy' }}</span> <br />
                <span class="time">{{ dataItem?.createdAt | customDate: 'hh:mm a' }}</span>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Status" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>
                <kendo-badge
                class="{{
                  'fw600 ' +
                    (dataItem?.status === investigationOnSceneStatus.EN_ROUTE ||
                    dataItem?.status === investigationOnSceneStatus.OFF_SCENE
                      ? 'fcolor'
                      : '')
                }}"
                position="none"
                size="medium"
                rounded="full"
                themeColor="{{
                  dataItem?.status === investigationOnSceneStatus.SCHEDULED
                    ? 'primary'
                    : dataItem?.status === investigationOnSceneStatus.EN_ROUTE
                    ? 'warning'
                    : dataItem?.status === investigationOnSceneStatus.ON_SCENE
                    ? 'success'
                    : dataItem?.status === investigationOnSceneStatus.OFF_SCENE
                    ? 'tertiary'
                    : ''
                }}"
                >{{ dataItem?.status }}</kendo-badge>
              </span>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="Source" [width]="15" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem?.source}}</span>
            </ng-template>
        </kendo-grid-column>
    
        <kendo-grid-column title="User" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem?.User?.lastName }} {{dataItem?.User?.firstName}}</span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
    </kendo-card-body>
</kendo-card>
</div>