import { filter, switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { InvestigationService, InvestigationStaffService } from 'src/app/shared/services';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IApiInvestigationStaff, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes } from 'src/app/shared/modules/graphql/types/types';
import {
  investigationAssignStaffUpdate,
  investigationAssignStaffDelete,
} from "src/app/shared/helpers/auth-config/investigations.config";
@Component({
  selector: 'app-investigation-assignments-table',
  templateUrl: './investigation-assignments-table.component.html',
  styleUrls: ['./investigation-assignments-table.component.scss']
})
export class InvestigationAssignmentsTableComponent {
  public authConfig = {
    investigationAssignStaffUpdate,
    investigationAssignStaffDelete,
  };

  private _data: IApiInvestigationStaff[] = [];

  @Output() public updated = new EventEmitter<void>();
  @Output() public deleted = new EventEmitter<void>();

  @Input() public columnsToDisplay: string[];
  @Input() public set data(val) {
    this._data = val;
  }

  public get data() {
    return this._data;
  }

  @Input() private investigationId: string;
  
  constructor(
    private staff: InvestigationStaffService,
    private investigationService: InvestigationService,
    private notifications: NotificationsService
  ) { }

  public update({ id, isDefault, isPrimary, clientRequested }: IApiInvestigationStaff) {
    this.staff.update({
      id,
      isDefault,
      isPrimary,
      clientRequested
    }).pipe(
      this.notifications.snackbarPipe("Investigation Updated!"),
      this.notifications.catchAlertPipe("Error updating staff record.")
    ).subscribe(() => this.updated.emit());
  }

  public delete(id: string) {
    this.notifications.confirm("Really remove this Investigation Staff member?").afterClosed().pipe(
      filter(r => !!r),
      switchMap(() => this.staff.remove(id))
    ).pipe(
      switchMap(() => {
        const foundUser = this.data.find((obj) => obj.id === id);
        return this.investigationService.update({
          id: this.investigationId,
          History: {
            InvestigationId: this.investigationId,
            updateCategory: IApiInvestigationUpdateCategories.Staff,
            updateType: IApiInvestigationUpdateTypes.Delete,
            comment: `Staff member ${foundUser.User.firstName} ${foundUser.User.lastName} with role ${foundUser.Role.title} removed from investigation`
          }
        });
      })
    ).subscribe(() => {
      this.deleted.emit()
    }, (err) => {
      if (err?.message === 'ON_SCENE_EXIST') this.notifications.alert("We can not remove this Investigator from investigation as it has an active on-scene schedule.");
      else this.notifications.alert("Error deleting staff record.")
    });
  }

}
