<form [formGroup]="clientForm">
  <div class="flexContainer">
    <!-- client -->
    <div class="equal">
      <mat-card-content>Client:</mat-card-content>

      <ng-container *ngIf="!f.Client.value; else clientCard">
        <mat-card-actions>
          <button mat-button type="button" (click)="selectClient()" color="accent">Select a Client</button>
        </mat-card-actions>
      </ng-container>

      <ng-template #clientCard>
        <mat-card-content class="dashboard-card-content">
          <div class="flexContainer">
            <button mat-icon-button class="remove-client" (click)="removeClient()" color="primary">
              <mat-icon>remove_circle</mat-icon>
            </button>
            <div class="equal">
              <div class="mat-caption">Contact</div>
              <div class="mat-body-2">{{ f.Client.value | fullName }}</div>
              <div class="flexContainer">
                <div class="equal">
                  <div class="mat-caption">Company</div>
                  <p class="mat-body-2">{{ f.ClientBranch.value ? f.ClientBranch.value?.Company.name : "N/A" }}</p>
                </div>
                <div class="equal">
                  <div class="mat-caption">Branch</div>
                  <p class="mat-body-2">{{ f.ClientBranch.value ? f.ClientBranch.value?.name : "N/A" }}</p>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </ng-template>
    </div>

    <!-- billTo / billingRules -->
    <div class="equal">
      <mat-card-content>
        <div class="flexContainer justified">
          <span>Bill To:</span>
          <mat-checkbox color="accent" formControlName="isBillToClient">Client</mat-checkbox>
        </div>
      </mat-card-content>

      <ng-container>

        <ng-container *ngIf="!f.BillTo.value; else setBillTo">
          <mat-card-actions>
            <button mat-button type="button" (click)="selectBillTo()" color="accent">Select a Client</button>
          </mat-card-actions>
        </ng-container>

        <ng-template #setBillTo>
          <mat-card-content class="dashboard-card-content">
            <div class="flexContainer">
              <button mat-icon-button type="button" class="remove-client" (click)="removeBillTo()" color="primary">
                <mat-icon>remove_circle</mat-icon>
              </button>
              <div class="equal">
                <div class="mat-caption">Contact</div>
                <div class="mat-body-2">{{ f.BillTo.value | fullName }}</div>
                <div class="flexContainer">
                  <div class="equal">
                    <div class="mat-caption">Company</div>
                    <p class="mat-body-2">{{ f.BillToBranch.value ? f.BillToBranch.value?.Company.name : "N/A" }}</p>
                  </div>
                  <div class="equal">
                    <div class="mat-caption">Branch</div>
                    <p class="mat-body-2">{{ f.BillToBranch.value ? f.BillToBranch.value?.name : "N/A" }}</p>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </ng-template>
      </ng-container>

      <mat-card-content>
        <div class="flexContainer justified">
          <span>Billing Rules:</span>
          <mat-checkbox #clientBill color="accent" [checked]="isClientBilling" (change)="isClientBilling = $event.checked">Client</mat-checkbox>
        </div>
      </mat-card-content>
      <!-- <ng-container *ngIf="!clientBill.checked"> -->
        <ng-container *ngIf="!f.Company?.value; else billingRulesCompany">
          <mat-card-actions>
            <app-company-select (selectionChanged)="selectBillingRulesCompany($event)"></app-company-select>            
          </mat-card-actions>
        </ng-container>

        <ng-template #billingRulesCompany>
          <mat-card-content class="dashboard-card-content">
            <div class="flexContainer">
              <button mat-icon-button type="button" class="remove-client" (click)="removeBillingRulesCompany()"
                color="primary">
                <mat-icon>remove_circle</mat-icon>
              </button>
              <div class="equal">
                <div class="mat-caption">Company</div>
                <div class="mat-body-2" [innerHTML]="f.Company?.value.name"></div>
              </div>
            </div>
          </mat-card-content>
        </ng-template>

      <!-- </ng-container> -->
    </div>
  </div>
</form>
