<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationAssignStaffUpdate" #investigationAssignStaffUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationAssignStaffDelete" #investigationAssignStaffDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section class="content-wrapper">
  <table mat-table [dataSource]="data">
    <!-- Name Definition -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let row" [innerHTML]="row.User | fullName"></td>
    </ng-container>

    <!-- Primary Definition -->
    <ng-container matColumnDef="primary">
      <th mat-header-cell *matHeaderCellDef> Primary </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox [(ngModel)]="row.isPrimary" (ngModelChange)="update(row)" *ngIf="investigationAssignStaffUpdate?.enabled"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Client Requested Definition -->
    <ng-container matColumnDef="requested">
      <th mat-header-cell *matHeaderCellDef> Client Requested </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox [(ngModel)]="row.clientRequested" (ngModelChange)="update(row)" *ngIf="investigationAssignStaffUpdate?.enabled"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Role definition -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef> Role </th>
      <td mat-cell *matCellDef="let row" [innerHTML]="row.Role.title"></td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button color="warn" (click)="delete(row.id)" *ngIf="investigationAssignStaffDelete?.enabled">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>
</section>
