<section class="mat-typography">
  <h2 class="text-center">Evidence #: {{ evidence.evidenceNumber | evidenceNumber }}</h2>
  <!-- LocationName -->
  <div class="container-fluid locationDetails">
    <p class="muted">Storage Location:</p>
    <p [innerHTML]="evidence.EvidenceStorageLocation.name"></p>
  </div>

  <!-- Company Details-->
  <div class="container-fluid row locationDetails my-3">
    <div class="col">
      <p class="muted">Company Name/Address:</p>
      <p [innerHTML]="evidence.EvidenceStorageLocation.companyName"></p>
      <app-address-view [address]="evidence.EvidenceStorageLocation.Address"></app-address-view>
    </div>
    <div class="col">
      <p class="muted">Contact/Phone:</p>
      <app-email-phone-view [phone]="evidence.EvidenceStorageLocation.Phone"></app-email-phone-view>
    </div>
    <div class="col">
      <p class="muted">Additional Details</p>
      <p> {{ evidence.EvidenceStorageLocation.additionalDetails }} </p>
    </div>
  </div>

  <!-- Transfer Documents -->
  <div class="container-fluid row locationDetails my-3">
    <div class="col">
      <p class="muted">Evidence Shipping Receipt(s):</p>
      <ng-container *ngFor="let transfer of evidence?.Transfers">
        <a *ngIf="transfer?.ShippingDocument?.id" href="{{transfer?.ShippingDocument?.s3Uri | s3}}" target="_blank" >{{transfer?.createdAt | customDate: 'MM-dd-yyyy'}} - From {{transfer?.FromLocation?.name}} to From {{transfer?.ToLocation?.name}}</a>
      </ng-container>
      <p [innerHTML]="evidence.EvidenceStorageLocation.companyName"></p>
    </div>
  </div>

  <mat-accordion multi>
    <!-- StatusHistory -->
    <mat-expansion-panel expanded class="p-0">
      <mat-expansion-panel-header>Item History</mat-expansion-panel-header>

      <table mat-table [dataSource]="evidence.StatusHistory" class="w-100">

        <!-- createdAt -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Date/Time</th>
          <td mat-cell *matCellDef="let table" [innerHTML]="table.createdAt | customDate: 'MM-dd-yyyy, h:mm a'"></td>
        </ng-container>

        <!-- name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let table" [innerHTML]="table.name | titlecase"> </td>
        </ng-container>

        <!-- comment -->
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>Comment</th>
          <td mat-cell *matCellDef="let table" [innerHTML]="table.comment"> </td>
        </ng-container>

        <!-- createdBy -->
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let table" [innerHTML]="table.CreatedBy | fullName"> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
      </table>
    </mat-expansion-panel><!-- /StatusHistory -->

    <!-- Comments -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>Comment Log</mat-expansion-panel-header>
      <app-comment-log-table [comments]="evidence.Comments"></app-comment-log-table>
    </mat-expansion-panel>
    <!-- /Comments -->
  </mat-accordion>

  <!-- AddComment-->
  <div class="d-flex mt-3">
    <mat-form-field class="flex-grow-1" appearance="outline">
      <mat-label>Comments</mat-label>
      <textarea matInput appearance="outline" minRows="4" [(ngModel)]="comment"></textarea>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-center" *ngIf="loading">
    <mat-spinner diameter="48"></mat-spinner>
  </div>
  <div class="d-flex flex-row-reverse">
    <button color="primary" mat-raised-button (click)="addComment()" [disabled]="loading">Add Comment</button>
    <button mat-button (click)="dialogRef.close()">Cancel</button>
  </div><!-- /AddComment-->
</section>