import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select/select';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IApiCertificationType, IApiCertificationTypeOrderBy, IApiUser, IApiUserDetailStatus, IApiUserFilter, IApiUserFilterType, IApiUserOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { AuthService, CertificationService, CertificationTypeService, InvestigationRoleService } from 'src/app/shared/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateUpdateStaffComponent } from 'src/app/components/shared/staff/create-update-staff/create-update-staff.component';

import { StateService } from 'src/app/shared/services/state.service';
import { UserDataSource, UserService } from 'src/app/shared/services/user';
import {
  staffCreateConfig, staffViewAll, staffInvestigationsList, staffCertificationsList, staffInvestigationsOwnList,
  staffCertificationsOwnList, staffEmergencyInformationView, staffEmergencyInformationOwnView, staffUserInformationView,
  staffUserInformationOwnView, staffContactInformationOwnView, staffContactInformationView
} from "src/app/shared/helpers/auth-config/staff.config";
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IUser } from 'src/app/shared/interfaces/user.interfaces';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
export interface IStaffListConfig {
  permissionsView?: boolean;
  permissionsType?: any; // Add interface for what roles can be passed in
  columns?: StaffListColumns[];
  selectableMode?: boolean;
}

export enum StaffListColumns {
  NAME = "name",
  NOTIFICATION_ENABLED = "notificationEnabled",
  POSITION = "position",
  ADDRESS = "address",
  PHONE = "phone",
  EMAIL = "email",
  CERTIFICATIONS = "certifications",
  ACTIONS = "actions",
  LOCATION = "location",
  SELECT = "select"
}

@UntilDestroy()
@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss']
})
export class StaffListComponent implements OnInit {

  public authConfig = {
    staffCreateConfig,
    staffViewAll,
    staffInvestigationsList,
    staffCertificationsList,
    staffInvestigationsOwnList,
    staffCertificationsOwnList,
    staffEmergencyInformationView,
    staffEmergencyInformationOwnView,
    staffUserInformationView,
    staffUserInformationOwnView,
    staffContactInformationOwnView,
    staffContactInformationView
  };

  // Fetch different data off of the type of permissions that are being viewed
  @Input() config: IStaffListConfig = {
    permissionsView: false,
    permissionsType: null,
    columns: null,
    selectableMode: false
  };
  @Output() done: EventEmitter<IApiUser[]> = new EventEmitter();
  @ViewChild("staffViewAll") staffViewAllPer;
  @ViewChild("staffEmergencyInformationView") staffEmergencyInformationViewPer;
  @ViewChild("staffEmergencyInformationOwnView") staffEmergencyInformationOwnViewPer;
  @ViewChild("staffUserInformationView") staffUserInformationViewPer;
  @ViewChild("staffUserInformationOwnView") staffUserInformationOwnViewPer;
  @ViewChild("staffContactInformationOwnView") staffContactInformationOwnViewPer;
  @ViewChild("staffContactInformationView") staffContactInformationViewPer;

  public columnsToDisplay = [StaffListColumns.NOTIFICATION_ENABLED, StaffListColumns.NAME, StaffListColumns.POSITION, StaffListColumns.ADDRESS, StaffListColumns.PHONE, StaffListColumns.EMAIL, StaffListColumns.CERTIFICATIONS, StaffListColumns.ACTIONS];
  public content: IApiUser[] = [];
  public selection = new SelectionModel<any>(true, []);
  public get isAllSelected(): boolean {
    return this.selection.selected.length === this.content.length;
  }

  public search = "";
  public orderByOptions = IApiUserOrderBy;

  public selectOpts = {
    positions: [],
    statuses: IApiUserDetailStatus,
    certifications: []
  };

  private _dataSource: UserDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  // Status Filter
  public statusFilter = this.selectOpts.statuses.Active;
  public authenticatedUserId = null;

  constructor(
    public stateService: StateService,
    private router: Router,
    private userService: UserService,
    private investigationRoleService: InvestigationRoleService,
    private certificationTypeService: CertificationTypeService,
    private loader: LoaderService,
    private certificationService: CertificationService,
    private dialog: MatDialog,
    private authService: AuthService,
    private notifications: NotificationsService
  ) {
    this.loader.show$(forkJoin([
      // Get Certifications
      this.certificationTypeService.get([], {
        sortOrder: SortOrder.ASC,
        orderBy: IApiCertificationTypeOrderBy.Title,
        limit: -1
      }).pipe(
        unwrapConnection(),
        tap((certifications) => {
          this.selectOpts.certifications = certifications;
        })
      ),
      // Get Investigation Roles / Positions
      this.investigationRoleService.investigationRoles.pipe(
        tap((roles) => this.selectOpts.positions = roles)
      )
    ])
    ).subscribe();
  }

  public ngOnInit() {
    if (this.config.permissionsView) {
      this.columnsToDisplay = this.config.columns;
    } else if (this.config.selectableMode) {
      this.columnsToDisplay = [StaffListColumns.SELECT, StaffListColumns.NAME, StaffListColumns.POSITION, StaffListColumns.ADDRESS, StaffListColumns.PHONE, StaffListColumns.EMAIL];
    }

    this._dataSource = new UserDataSource(this.userService);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.applyFilter(IApiUserFilterType.IsInactive, 'true');
    this._dataSource.applyFilter(IApiUserFilterType.ViewListView, 'true');
    this._dataSource.applyFilter(IApiUserFilterType.Status, this.selectOpts.statuses.Active);
    this._dataSource.listPage.orderBy = IApiUserOrderBy.Lastname;
    this.load();

    this.certificationService.get().pipe(
      untilDestroyed(this),
    ).subscribe();

    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe((data) => this.content = data);
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
  }

  // public createStaff = () => this.router.navigate(['/admin/staff/manage']);

  public createStaff() {
    this.dialog.open(CreateUpdateStaffComponent, {
      width: "80%",
      height: "90%",
      data: {}
    }).afterClosed().subscribe(() => {
      this.load();
    }, () => {
      this.load();
    });
  }

  public deactivateStaff(id) {
    // TODO deactivate staff member based on ID
  }

  // Filter change hooks
  public onSearchChange() {
    this.dataSource.applyFilter(IApiUserFilterType.Search, this.search);
    this.load();
  }

  public onPositionChange(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiUserFilterType.Position, JSON.stringify(event.value));
    this.load();
  }

  public onTimeChange(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiUserFilterType.IsFullTime, event.value || undefined);
    this.load();
  }

  public onStatusChange(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiUserFilterType.Status, event.value);
    this.load();
  }

  public onStateChange(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiUserFilterType.State, event.value);
    this.load();
  }

  public onCertificationChange(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiUserFilterType.Certification, event.value);
    this.load();
  }

  public masterToggle() {
    this.isAllSelected ? this.selection.clear() : this.content.forEach(row => this.selection.select(row));
  }

  public removeChip(sel) {
    const index = this.selection.selected.indexOf(sel);

    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
  }

  public save() {
    this.done.emit(this.selection.selected);
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public profileView(userId) {
    return this.staffViewAllPer?.enabled &&
      (
        (this.staffEmergencyInformationViewPer?.enabled || (this.staffEmergencyInformationOwnViewPer?.enabled && this.authenticatedUserId === userId)) ||
        (this.staffUserInformationViewPer?.enabled || (this.staffUserInformationOwnViewPer?.enabled && this.authenticatedUserId === userId)) ||
        (this.staffContactInformationViewPer?.enabled || (this.staffContactInformationOwnViewPer?.enabled && this.authenticatedUserId === userId))
      )
  }

  public updateUser(user: IApiUser, enabled: boolean = false) {
    this.userService.updateUser({ id: user?.id, notificationEnabled: enabled }).pipe(this.notifications.snackbarPipe(enabled ? 'Notifications Enabled' : 'Notifications Disabled')).subscribe();
  }

}
