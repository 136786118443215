import { filter, switchMap, tap } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CompanyBranchCreateModalComponent, ICompanyBranchCreateUpdateData } from '../company-branch-create-modal/company-branch-create-modal.component';

import { CompanyService } from '../../../../shared/services';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { ContactsListComponent, IContactListConfig } from '../../contacts/contacts-list/contacts-list.component';
import { CompanyBranchContactSelectComponent } from '../company-branch-contact-select/company-branch-contact-select.component';
import { removeTypename } from 'src/app/shared/rxjs.pipes';
import { IApiCompany } from 'src/app/shared/modules/graphql/types/types';
import { Observable } from "rxjs";
import { CompanyBranchListComponent } from '../company-branch-list/company-branch-list.component';
import { forkJoin } from 'rxjs';
import {
  companiesBranchesList,
  companiesBranchesCreate
} from "src/app/shared/helpers/auth-config/companies.config";

import {
  contacts,
  contactsCreateConfig
} from "src/app/shared/helpers/auth-config/contacts.config";

@UntilDestroy()
@Component({
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {

  public authConfig = {
    companiesBranchesList,
    companiesBranchesCreate,
    contacts,
    contactsCreateConfig
  };

  @ViewChild("contactList") contactList: ContactsListComponent;
  @ViewChild("branchList") branchList: CompanyBranchListComponent;

  public selectedTab = 0;
  public company: IApiCompany;

  public contactListConfig: IContactListConfig = {
    hideLists: true,
    companyId: "",
    hideAddContact: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private loader: LoaderService
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      untilDestroyed(this),
      switchMap((params) => this.loader.show$(this.getCompany(params.companyId)))
    ).subscribe();
    // TODO add ability to go directly to branches with a selected branch - see contact edit modal where a user can click on a branch, brings them here with branchId and tab=branches in params
    this.route.queryParams.pipe(
      untilDestroyed(this),
    ).subscribe(({ tab }) => {
      switch (tab) {
        case "branches":
          this.selectedTab = 1;
          break;
        case "contacts":
          this.selectedTab = 2;
          break;
        default:
          this.selectedTab = 0;
      }
    });

    const { companyId } = this.route.snapshot.params;
    this.contactListConfig.companyId = companyId;
  }

  private getCompany(id: string): Observable<IApiCompany> {
    return this.companyService.getById(id).pipe(
      removeTypename(),
      tap((company) => this.company = company)
    );
  }

  public goBack() {
    return this.router.navigate(['/admin', 'companies', 'list']);
  }

  public addContact() {
    const ref = this.dialog.open(CompanyBranchContactSelectComponent, {
      width: "80%",
      height: "90%",
      data: { company: this.company }
    });

    ref.afterClosed().pipe(
      filter((v) => !!v),
      switchMap(() => this.rehydrate())
    ).subscribe();

  }

  public reload() {
    this.rehydrate().subscribe();
  }

  public rehydrate(): Observable<any> {
    return this.loader.show$(
      this.getCompany(this.company.id).pipe(
        switchMap(() => forkJoin([
          this.branchList.load(),
          this.contactList.load()
        ]))
      )
    );
  }

  public addBranch() {
    const data: ICompanyBranchCreateUpdateData = {
      companyId: this.company.id,
      companyName: this.company.name
    };
    const ref = this.dialog.open(CompanyBranchCreateModalComponent, {
      width: "80%",
      height: "70%",
      data
    });

    ref.afterClosed().pipe(
      filter((v) => !!v),
      switchMap(() => this.rehydrate())
    ).subscribe();

  }
}
