// NOTE - the enums here drive the seeders for Categories, Scopes, and Actions. See /seeds/01-permissions*.seed.js files
// NOTE* - Angular app ALSO consumes this for consolidation
export enum PermissionCategories {
  // Investigations
  INVESTIGATION = "Investigations",
  INVESTIGATION_CLOSED = "Investigations (Closed)",
  INVESTIGATION_ASSIGNMENT = "Investigations Assign Staff",
  INVESTIGATION_REJECT = "Investigation Reject / Reactivate",
  INVESTIGATION_RECEIVED_DATE = "Investigation Received Date",
  INVESTIGATION_SCHEDULED_DATE = "Investigation Scheduled Dates",
  INVESTIGATION_INFORMATION = "Investigation Information",
  INVESTIGATION_PARTIES = "Investigation Parties",
  INVESTIGATION_STATUS = "Investigation Status",
  INVESTIGATION_CAUSE = "Investigation Cause",
  INVESTIGATION_BILLING_INFORMATION = "Investigation Billing Information",
  INVESTIGATION_LINKED_INVESTIGATION = "Investigation Linked Investigations",
  INVESTIGATION_VEHICLES = "Investigation Vehicles",
  INVESTIGATION_COMMENTS= "Investigation Comments",
  //
  INVESTIGATION_PHOTOS = "Investigation Photos",
  INVESTIGATION_PHOTO_DOWNLOADS = "Investigation Photos Download",
  INVESTIGATION_PHOTO_SHARE = "Investigation Photos Share",
  INVESTIGATION_PHOTO_SHARE_ADD_PARTIES = "Investigation Share Add Parties",
  //
  INVESTIGATION_DOCUMENTS = "Investigation Documents",
  INVESTIGATION_DOCUMENTS_SHARE = "Investigation Documents Share",
  INVESTIGATION_DOCUMENTS_SHARE_ADD_PARTIES = "Investigation Documents Share Add Parties",
  INVESTIGATION_DOCUMENTS_DOWNLOAD = "Investigation Documents Download",
  //
  INVESTIGATION_REPORTS = "Investigation Reports",
  INVESTIGATION_REPORTS_ASSIGN_EDITOR = "Investigation Reports Assign Editor",
  INVESTIGATION_REPORTS_DOWNLOAD = "Investigation Reports Download",
  INVESTIGATION_REPORTS_SHARE = "Investigation Reports Share",
  INVESTIGATION_REPORTS_SHARE_ADD_PARTIES = "Investigation Reports Share Add Parties",
  INVESTIGATION_REPORTS_SNAPSHOTS = "Investigation Report Snapshots",
  //
  INVESTIGATION_REPORTS_TECH_REVIEW = "Investigation Reports in Tech Review",
  INVESTIGATION_REPORTS_TECH_REVIEW_DOWNLOAD = "Investigation Reports in Tech Review Download",
  INVESTIGATION_REPORTS_TECH_REVIEW_SHARE = "Investigation Reports in Tech Review Share",
  //
  INVESTIGATION_REPORTS_EDITOR_REVIEW = "Investigation Reports in Editor Review",
  INVESTIGATION_REPORTS_EDITOR_REVIEW_DOWNLOAD = "Investigation Reports in Editor Review Download",
  INVESTIGATION_REPORTS_EDITOR_REVIEW_SHARE = "Investigation Reports in Editor Review Share",
  //
  INVESTIGATION_SERVICES = "Investigation Services",
  INVESTIGATION_SERVICES_MARK_COMPLETE = "Investigation Services Mark Complete",
  //
  INVESTIGATION_REQUESTS = "Investigation Requests",
  INVESTIGATION_REQUESTS_MARK_COMPLETE = "Investigation Requests Mark Complete",
  INVESTIGATION_REQUESTS_PENDING = "Investigation Requests Pending",

  // Evidence
  EVIDENCE = "Evidence",
  EVIDENCE_TRANSFER = "Evidence Transfer",
  EVIDENCE_BILLING = "Evidence Billing",
  EVIDENCE_DOWNLOAD = "Evidence Download",
  EVIDENCE_DESTROY = "Evidence Destroy",
  EVIDENCE_DISPOSE = "Evidence Dispose",
  EVIDENCE_TRANSFER_DOWNLOAD = "Evidence Transfer Download",

  // Companies
  COMPANIES = "Companies",
  COMPANIES_BILLING_RULES = "Companies - Company Billing Rules",
  COMPANIES_ELIGIBILITY = "Companies - Company Eligibility",
  COMPANIES_REQUIRED_SERVICES = "Companies - Company Required Services",
  COMPANIES_BRANCHES = "Companies - Branches",

  // Contacts
  CONTACTS = "Contacts",
  CONTACTS_PROFILE = "Contacts - Contact Profile",
  CONTACTS_PROFILE_CONTACT_INFORMATION = "Contacts - Contact Profile, Contact information",
  CONTACTS_PROFILE_COMPANY_INFORMATION = "Contacts - Contact Profile, Company information",
  CONTACTS_INVESTIGATIONS = "Contacts - Contact Investigations",


  // Staff
  STAFF = "Staff",
  STAFF_CONTACT_INFORMATION = "Staff Contact Information",
  STAFF_USER_INFORMATION = "Staff User Information",
  STAFF_EMERGENCY_CONTACT_INFORMATION = "Staff Emergency Contact Information",
  STAFF_WORKING_HOURS = "Staff - Working Hours",
  STAFF_CERTIFICATIONS = "Staff - Certifications",
  STAFF_INVESTIGATIONS = "Staff - Investigations",

  // Time
  TIME_ENTRY_UNLINKED_HOURS = "Time Entry - Unlinked Hours",
  TIME_ENTRY_INVESTIGATION_HOURS = "Time Entry - Investigation Hours",

  // Expenses
  EXPENSES_UNLINKED = "Expenses - Unlinked",
  EXPENSES_UNLINKED_MILEAGE = "Expenses - Unlinked Mileage",
  EXPENSES_UNLINKED_EXPENSE = "Expenses - Unlinked Expense",
  EXPENSES_INVESTIGATION_HOURS = "Expenses - Investigation Hours",
  EXPENSES_INVESTIGATION_EXPENSES = "Expenses - Investigation Expenses",
  EXPENSES_INVESTIGATION_MILEAGE = "Expenses - Investigation Mileage",
  EXPENSES_OUT_OF_POCKET_REPORT = "Expense Reports - Out of pocket",
  EXPENSES_NEFCO_CARD_REPORT = "Expense Reports - NEFCO Card",

  BOOKKEEPING_TIME_AND_EXPENSE_ENTRY_LIST_HOURS = "Bookkeeping, time & Expenses, Entry list, hours",
  BOOKKEEPING_TIME_AND_EXPENSE_ENTRY_LIST_EXPENSE_AND_MILEAGE = "Bookkeeping, time & Expenses, Entry list, expenses and mileage",
  BOOKKEEPING_TIME_AND_EXPENSE_ACCOUNT_SUMMARY_HOURS = "Bookkeeping, time & expenses, hours, account summary",
  BOOKKEEPING_TIME_AND_EXPENSE_ACCOUNT_SUMMARY_EXPENSE_AND_MILEAGE = "Bookkeeping, time & expenses, expenses and mileage, account summary",
  BOOKKEEPING_INVOICE_LIST = "Bookkeeping, invoice list",
  BOOKKEEPING_PAYROLL = "Bookkeeping, payroll",
  BOOKKEEPING_EXPENSE_REPORT = "Bookkeeping, expense reports",
  BOOKKEEPING_EVIDENCE_BILLING = "Bookkeeping, evidence billing",

  // Manage
  MANAGE = "Manage",
  MANAGE_EXPENSE_REPORT_CATEGORIES = "Manage - Expense Report Categories",
  MANAGE_EXPENSE_ITEMS = "Manage - Expense Items",
  MANAGE_EXPENSE_PURPOSES = "Manage - Expense Purposes",
  MANAGE_TIME_ENTRY_TYPES = "Manage - Time Entry Types",
  MANAGE_EVIDENCE_LOCATIONS = "Manage - Evidence Locations",
  MANAGE_CARRIERS = "Manage - Carriers",
  MANAGE_SERVICES = "Manage - Services",
  MANAGE_SUB_SERVICES = "Manage - Sub-Services",
  MANAGE_USER_ROLES = "Manage - User Roles",
  MANAGE_USER_ROLE_PERMISSIONS = "Manage - User Role Permissions",
  MANAGE_USER_ROLE_NOTIFICATIONS = "Manage - User Role Notifications",
  MANAGE_USER_ROLE_STAFF = "Manage - User Role Staff Members",
  MANAGE_CERTIFICATIONS = "Manage - Certifications",
  MANAGE_DOCUMENT_TYPES = "Manage - Document Types",
  MANAGE_DOCUMENT_TYPES_DEACTIVATE = "Manage - Client Portal Accounts",
  MANAGE_DOCUMENT_TEMPLATING = "Manage - Document Templating",

  // Dashboard Tabs
  DASHBOARD_INVESTIGATIONS_PENDING = "Dashboard Tab - Investigations Pending",
  DASHBOARD_INVESTIGATIONS_ASSIGNED = "Dashboard Tab - Investigations Assigned",
  DASHBOARD_INVESTIGATIONS_IN_PROGRESS = "Dashboard Tab - Investigations In Progress",
  DASHBOARD_INVESTIGATIONS_READY_TO_BILL = "Dashboard Tab - Investigations Ready to Bill",
  DASHBOARD_INVESTIGATIONS_ONGOING = "Dashboard Tab - Investigations Ongoing",
  DASHBOARD_REPORTS_READY_FOR_EDITOR = "Dashboard Tab - Reports Ready for Editor",
  DASHBOARD_REPORTS_TECH_REVIEW = "Dashboard Tab - Reports in Tech Review",
  DASHBOARD_REPORTS_EDITOR_REVIEW = "Dashboard Tab - Reports in Editor Review",
  DASHBOARD_PENDING_REQUESTS = "Dashboard Tab - Pending Requests",
  DASHBOARD_INVESTIGATORS_ON_SCENE = "Dashboard Investigators On Scene",

  // Miscellaneous
  MISC_INVESTIGATORS_ON_SCENE = "Misc Investigators On Scene",
  MISC_PAYROLL = "Misc Payroll",
  MISC_INVOICES = "Misc Invoices",

  INVESTIGATORS_ON_SCENE = "Investigators On-Scene",
  PAYROLL = "Payroll",
  INVOICES = "Invoices",
  CALENDER = "Calendar",

  // Investigation Reports
  COMPANY_INVESTIGATION_REPORT = "Company Investigation Report",
  COMPANY_INVESTIGATION_AND_EVIDENCE_REPORT = "Company Investigation and Evidence Report",
  INVESTIGATION_TIMELOG_PACING = "Investigation Timelog (Pacing)",
  INVESTIGATION_METRICS_RISK_TYPE = "Investigation Metrics - Risk Type",
  INVESTIGATION_METRICS_BILL_TO_COMPANY = "Investigation Metrics - Bill To Company",
  INVESTIGATION_METRICS_CLIENT_COMPANY = "Investigation Metrics - Client Company",
  INVESTIGATION_METRICS_CONTACT = "Investigation Metrics - Contact",
  INVESTIGATION_METRICS_INVESTIGATOR = "Investigation Metrics - Investigator",
  INVESTIGATION_METRICS_STATE = "Investigation Metrics - State",
  INVESTIGATION_METRICS_TECH_REVIEWER = "Investigation Metrics - Tech Reviewer",
  INVESTIGATION_REPORT_TIMELOG = "Investigation Report Timelog",
  INVESTIGATION_METRICS_BASIC_CHARTS = "Investigation Metrics - Basic Charts",
  INVESTIGATION_METRICS_DOCUSKETCH_REQUESTED = "Investigation Metrics - Docusketch Requested",
  INVESTIGATION_METRICS_DETERMINATION = "Investigation Metrics - Determination",
  REPORT_SENT_METRIC = "Report Sent Metric",
  QUARTERLY_UTILIZATION_REPORT = "Quarterly Utilization Report",
  QUARTERLY_HOUR_REPORT = "Quarterly Hour Report"
}

export enum PermissionActions {
  LIST = "List",
  VIEW = "View",
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
  DOWNLOAD = "Download",
  DEACTIVATE = "Deactivate"
}

export enum PermissionScopes {
  ALL = "All",
  OWN = "Own",
  ASSIGNED = "Assigned",
  ALL_ASSIGNED = "AllAssigned"
}
