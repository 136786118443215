import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';

import { CompanyService } from 'src/app/shared/services/company/company.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { StateService } from 'src/app/shared/services/state.service';
import { IApiCompanyFilter, IApiCompanyFilterType, IApiCompanyOrderBy, IApiCompanyType, IApiCompanyTypeOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { CompanyDataSource } from 'src/app/shared/services/company/company.datasource';
import { CompanyTypeService } from 'src/app/shared/services/company/company-type/company-type.service';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdateContactComponent, ICreateUpdateContactData } from '../../contacts/create-update-contact/create-update-contact.component';
import { CompanyCreateUpdateComponent } from '../company-create-update/company-create-update.component';
import {
  companiesCreateConfig,
  companiesViewAll,
  companiesBranchesList,
  companiesBranchesView,
  companiesBranchesCreate,
  companiesDelete
} from "src/app/shared/helpers/auth-config/companies.config";

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

  public authConfig = {
    companiesCreateConfig,
    companiesViewAll,
    companiesBranchesList,
    companiesBranchesView,
    companiesBranchesCreate,
    companiesDelete
  };

  public sortHeaders = IApiCompanyOrderBy;

  private _dataSource: CompanyDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  private _filters: IApiCompanyFilter[] = [];

  public companyTypes: IApiCompanyType[];
  public displayedColumns = [
    "NAME", "TYPE", "BRANCHES", "ACTION" // "branch", "address", "phone", "more"
  ];

  public searchValue = "";

  constructor(
    private companyService: CompanyService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    public stateService: StateService,
    private router: Router,
    private companyTypeService: CompanyTypeService,
    private dialog: MatDialog
  ) { }

  public ngOnInit() {

    this.companyTypeService.get().pipe(unwrapConnection()).subscribe((types) => this.companyTypes = types);

    this._dataSource = new CompanyDataSource(this.companyService);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.applyFilter(IApiCompanyFilterType.ViewListView, "true");
    this._dataSource.listPage.orderBy = IApiCompanyOrderBy.Name;
    this.load();
  }

  public setState(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiCompanyFilterType.State, event.value);
    this.load();
  }

  public setCompanyType(event: MatSelectChange) {
    this.dataSource.applyFilter(IApiCompanyFilterType.CompanyType, event.value);
    this.load();
  }

  public gotoCreateCompany() {
    // TODO modal navigation flow?
    // this.router.navigate(['admin/companies/create']);
    this.dialog.open(CompanyCreateUpdateComponent, {
      width: "80%",
      height: "90%"
    });
  }

  public addBranch() {
    // TODO launch add branch modal once it is built
  }

  public addContact() {
    // TODO send company company data to add the contact to the selected company?
    const data: ICreateUpdateContactData = {
      companyRoles: true
    };
    this.dialog.open(CreateUpdateContactComponent, {
      width: "80%",
      height: "90%",
      data
    });
  }

  public onSearchChange() {
    this.dataSource.applyFilter(IApiCompanyFilterType.Search, this.searchValue);
    this.load();
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public deleteCompany(company) {
    this.notifications.kendoConfirm(
      'Are you sure you want to delete this company?',
      "Confirm Delete",
      "No",
      "Yes",
      550
    ).subscribe((res) => {
      if (res) {
        this.loader.show$(this.companyService.remove(company?.id))
          .subscribe(res => {
            this.notifications.notify("Company deleted successfully!");
            this.load();
          }, (err) => {
            if (err?.message?.includes('You cannot delete this company as it is associated with contacts or investigations.')) {
              this.notifications.kendoConfirm(
                err?.message,
                "Error Deleting The Company",
                "Cancel",
                "Okay",
                550
              ).subscribe((res) => {
                if (res) {
                  this.load();
                }
              });
            } else {
              this.notifications.alert(err?.message);
            }
          });
      }
    });
  }

}
